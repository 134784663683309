import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorAuthService {
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(private apiManagerService: ApiManagerService) {}


  get2FAQrcode() {
    return this.apiManagerService.getRequest(
      RestEnds.get2FAQRCode, null
    );
  }

  getToggle2FA(data: any) {
    return this.apiManagerService.putRequest(RestEnds.getToggle2FA, data);
  }

  get2FAStatus() {
    return this.apiManagerService.getRequest(RestEnds.get2FAStatus, null);
  }

  reset2fa(data: any) {
    return this.apiManagerService.putRequest(RestEnds.reset2FA, data);
  }

  postVerifyQRCode(data: any) {
    return this.apiManagerService.postRequest(RestEnds.verifyQRCode, data);
  }

  postVerify2FA(data: any) {
    return this.apiManagerService.postRequest(RestEnds.verify2FA, data);
  }


  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }



}
