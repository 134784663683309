<div class="d-flex justify-content-between p10 card-container mt20">
  <div class="col-md-7 col-lg-6 col-sm-12 d-flex justify-content-between">
    <div class="">
      <img [src]="image" class="card-img" />
    </div>
    <div class="col-lg-5 col-md-6 col-sm-12 col-10 mt5 content-wrap">
      <label class="roboto-medium f13 black-2 clearfix text-wrap">{{ name }}</label>
      <label class="roboto-regular f12 black-2 clearfix text-wrap">{{ email }}</label>
    </div>
    <div class="col-lg-4 col-md-5 col-sm-12 col-6 mt5 content-wrap">
      <label class="roboto-medium f13 black-2 clearfix text-wrap">Date of Birth</label>
      <label class="roboto-regular f13 black-2 clearfix text-wrap">{{
        date | date: "dd-MMM-yyyy"
      }}</label>
    </div>
    <div class="col-lg-4 col-md-5 col-sm-12 col-7 mt5 content-wrap">
      <label class="roboto-medium f13 black-2 clearfix text-wrap">Company Name</label>
      <label
        class="roboto-regular f12 black-2 clearfix text-wrap"
        *ngIf="channelTitle !== null"
        >{{ channelTitle }}</label
      >
      <label
        class="roboto-regular f12 black-2 clearfix text-wrap"
        *ngIf="channelTitle === null"
        >-</label
      >
    </div>
    <div class="col-lg-4 col-md-5 col-sm-12 col-6 mt5 content-wrap">
      <label class="roboto-medium f13 black-2 clearfix text-wrap">Passport No.</label>
      <label class="roboto-regular f12 black-2 clearfix text-wrap">{{
        passportNumber
      }}</label>
    </div>
    <div class="col-lg-4 col-md-5 col-sm-12 col-8 mt5 content-wrap">
      <label class="roboto-medium f13 black-2 clearfix text-wrap"
        >Driving license No.</label
      >
      <label class="roboto-regular f12 black-2 clearfix text-wrap">{{
        drivingLicenseNo
      }}</label>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12 col-5 mt5 content-wrap">
      <a
        (click)="openUserList(agentGUID)"
        class="roboto-regular f12 clearfix view"
      >
        View Users
      </a>
    </div>
  </div>

  <!-- <div class="col-1">
            <button (click)="edit(guid)" matTooltip="Edit" type="button" 
                  class="btn-icon" mat-icon-button matRipple>
                  <mat-icon>edit</mat-icon>
                </button>
        </div> -->
</div>
<div class="d-flex justify-content-between p10 card-container col-md-8 col-lg-7 col-xl-4 bg-cornflower-two card-border card-top">
        <div class="col-12 d-flex justify-content-between pl-3">
                <div class="d-flex justify-content-between col-xl-6 col-lg-6 col-md-6 p-4 mr-6 pr-10">
            <label class="roboto-regular f13 white clearfix">Reg. Date:</label>
            <label class="roboto-medium f13 white clearfix pl-2 pr-10">{{createdDate | date: 'dd/MM/yyyy'}}</label>
        </div>
             <span class="white split-line"></span>
                    <div class="d-flex justify-content-center col-6  p-4" *ngIf="emailVerified === true">
                        <label class="roboto-regular f13 white clearfix mr-10" *ngIf="emailVerified === true">Email Verified</label>
                        <img src="../../../../assets/icons/verified.png" *ngIf="emailVerified === true" class="verify-status roboto-medium f13 white clearfix pl-2"
                            alt="">
                    </div>
                    <div class="d-flex justify-content-center row" *ngIf="emailVerified === false">
                        <button mat-raised-button class="bg-dusty-orange white roboto-regular f12 btn-mail col-10" *ngIf="emailVerified === false"
                            color="warning" (click)="agentResendMail(agentGUID)">
                            Resend verification Email
                        </button>
                    </div>
                    
        </div>
</div>
