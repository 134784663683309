import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { StorageService } from '../utils/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {


  private loginStatus = new BehaviorSubject<boolean>(this.hasBoolean());
  // isLoggedIn = this.loginStatus.asObservable();
  constructor(
    private apiManagerService: ApiManagerService,
    private localStorageService: StorageService
  ) {
  }

  changeStatus(message: boolean) {
    this.loginStatus.next(message);
  }

  isLoggedIn(): Observable<boolean> {
    // const LogIn = this.localStorageService.getItem('isLoggedIn');
    // debugger;
    // if (LogIn && LogIn === 'true') {
    //   this.loginStatus.next(true);
    // }
    return this.loginStatus.asObservable();
  }

  private hasBoolean(): boolean {
    let result: any = localStorage.getItem('isLoggedIn');
    return Boolean(JSON.parse(result));
  }

}
