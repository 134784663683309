import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  templateUrl: './deposit-popup.component.html',
  styleUrls: ['./deposit-popup.component.scss']
})
export class DepositPopupComponent implements OnInit {

  record;
  btnloading = false;

  constructor(
    public dialogRef: MatDialogRef<DepositPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.record = data;
  }

  close(): void {
    this.dialogRef.close(false);
  }

  deposit() {
    this.btnloading = true;
    this.dialogRef.close(true);
  }


  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

}
