<div class="bootstrap-wrapper">
<!-- <div class="container-fluid"> -->
    <div class="container">
      <div class="row modal-header p5">
        <div class="col-10 roboto-medium f16 title">{{type}}</div>
        <div class="col-2">
            <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20 title" (click)="onClose()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
      <div class="row property-info-container">
        <div class="d-flex flex-row justify-content-center col-lg-12 col-md-12 col-sm-12" id="detail-container">
          <div class="row d-flex flex-row justify-content-between col-lg-12 col-md-12 col-sm-12">
            <div class="col-lg-3 col-md-12 col-sm-12 pb-2 form-group my-auto">
              <label class="property-value">Shares Value</label>
            </div>
            <div class="col-lg-2 col-md-12 col-sm-12 pb-2 last-valuation form-group my-auto d-flex flex-column">
              <label class="label-title">Last Valuation</label>
              <label class="label-value">${{propertyDetails?.brickletLastValuation | number : '0.0-2'}}</label>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 pb-2 form-group my-auto valuation-diff d-flex flex-column">
              <label class="label-title">Valuation Difference to Last Price ($ / %)</label>
              <label class="label-value"> ${{propertyDetails?.brickletPriceIncrease| number : '0.0-2'}} /
                {{propertyDetails?.brickletPriceIncreaseInPercentage | number : '0.0-2'}}%</label>
            </div>
            <div class="col-lg-2 col-md-12 col-sm-12 pb-2 form-group my-auto d-flex flex-column">
              <label class="label-title">Latest Sell </label>
              <label class="label-sell-value">
                ${{propertyDetails?.brickletLatestSell | number : '0.0-2'}}
                <span>({{propertyDetails?.brickletLastTradeDateByDays}} days)</span>
              </label>
            </div>
            <div class="col-lg-2 col-md-12 col-sm-12 pb-2 form-group my-auto d-flex flex-column">
              <label class="label-title">Price history</label>
              <label><a class="view-link f24 primary-bold" (click)="onView()">View</a></label>
            </div>
          </div>
        </div>
    
    
        <div class="d-flex flex-row justify-content-center col-lg-12 col-md-12 col-sm-12 mt-2" id="detail-container">
          <div class="row d-flex flex-row justify-content-between col-lg-12 col-md-12 col-sm-12">
            <div class="col-lg-3 col-md-12 col-sm-12 pb-2 form-group my-auto">
              <label class="property-value">Property Value</label>
            </div>
            <div class="col-lg-2 col-md-12 col-sm-12 pb-2 last-valuation form-group my-auto d-flex flex-column">
              <label class="label-title">Last Valuation</label>
              <label class="label-value f16">${{propertyDetails?.propertyLastValuation | number : '0.0-2'}}</label>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 pb-2 form-group my-auto valuation-diff d-flex flex-column">
              <label class="label-title">Valuation Difference to Last Price ($ / %)</label>
              <label class="label-value f16"> ${{propertyDetails?.propertyPriceIncrease | number : '0.0-2'}} /
                {{propertyDetails?.propertyPriceIncreaseInPercentage | number : '0.0-2'}}%</label>
            </div>
            <div class="col-lg-2 col-md-12 col-sm-12 pb-2 form-group my-auto d-flex flex-column">
              <label class="label-title">Total Shares</label>
              <label class="label-value f16">{{propertyDetails?.totalBricklets}} shares</label>
            </div>
            <div class="col-lg-2 col-md-12 col-sm-12 pb-2 form-group my-auto d-flex flex-column">
              <label class="label-title">Total Price</label>
              <label class="label-value f16">${{propertyDetails?.totalPrice | number : '0.0-2'}}</label>
            </div>
          </div>
        </div>
    
    
    
    
        <!-- Property gallery & Offer Details-->
        <div class="col-lg-12 col-md-12 col-sm-12" id="gallery-offer-container">
          <div class="row d-flex flex-row justify-content-between">
            <div class="col-lg-8 col-md-12 col-sm-12 prop-gallery">
              <app-property-detail-gallery [propertyId]="propertyDetails?.propertyGUID" [propertyGallery]="propertyGallery" (change)="onChange($event)"></app-property-detail-gallery>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12" id="offer-info-container">
              <div class="col-lg-12 col-md-12 col-sm-12" id="offer-table">
                <p class="offer-label">Development Project</p>
                <p class="offer-title">{{propertyDetails?.offer}}</p>
                <div class="offer-table">
                  <div *ngFor="let offer of offerInfo; let i=index;" class="row d-flex flex-row justify-content-between">
                    <label class="key col-sm-12 col-lg-7">{{offer.key}}</label>
                    <label class="value col-sm-12 col-lg-5">{{offer.value}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
    
    
        <!-- Shares Price Table -->
        <div class="col-lg-12 col-md-12 col-sm-12 prop-detail-container">
          <div class="row d-flex flex-row justify-content-between">
            <div class="col-lg-8 col-md-12 col-sm-12 owned-info">
              <div class="col-lg-12 col-md-12 col-sm-12 price-table-container">
    
                <div class="d-flex flex-row row justify-content-between">
    
                  <div class="col-lg-3 col-md-12 col-sm-12 form-group d-flex flex-column total my-auto">
                    <label class="prop-title">Total Shares</label>
                    <label class="label-value">{{propertyDetails?.totalBricklets}}</label>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 form-group d-flex flex-column bricklet my-auto">
                    <label class="prop-title">Shares available</label>
                    <label class="label-value">{{propertyDetails?.brickletsAvailable}}</label>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 form-group d-flex flex-column min my-auto">
                    <label class="prop-title">Min. Sale Price</label>
                    <label class="label-value">${{propertyDetails?.minSalePrice | number : '0.0-2'}}</label>
                  </div>
                  <div class="col-lg-3 col-md-12 col-sm-12 form-group d-flex flex-column max my-auto">
                    <label class="prop-title">Max. Sale Price</label>
                    <label class="label-value">${{propertyDetails?.maxSalePrice | number : '0.0-2'}}</label>
                  </div>
                </div>
    
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 owned-info">
              <div class="col-lg-12 col-md-12 col-sm-12 owned-container">
    
                <div class="d-flex flex-row row justify-content-between">
                  <div class="col-lg-6 col-md-12 col-sm-12 form-group owned my-auto d-flex flex-column">
                    <label class="prop-title">Shares Owned</label>
                    <label class="label-owned-value ">{{propertyDetails?.brickletOwned}} shares</label>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                    <div class="col-lg-10 col-md-12 col-sm-12 buysell-btn row">
                      <!-- <button class="btn btn-secondary btn-info col-lg-12 col-md-12 col-sm-12"
                        (click)="onSell()">Sell</button>
                      <button class="btn btn-primary btn-info col-lg-12 col-sm-12" (click)="onBuy()">Buy</button> -->
                    </div>
                  </div>
                </div>
    
              </div>
            </div>
          </div>
        </div>
    
        <p class="about">About this property</p>
        <div class="col-lg-12 col-md-12 col-sm-12 d-flex flex-row flex-wrap prop-basic-container" id="prop-basic-container">
          <div class="col-lg-3 col-md-12 col-sm-12 form-group name">
            <p class="prop-value">{{propertyDetails?.name}}</p>
            <label class="name-label mt2">{{propertyDetails?.address}}</label>
    
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12 form-group category">
            <p class="value">{{propertyDetails?.propertyType}}</p>
            <label class="name-label mt-2">Category</label>
          </div>
          <div class="col-lg-2 col-md-12 col-sm-12 form-group size">
            <p class="amenities-value">
              <img class="amenities-img" src="../../../../assets/images/floor-icons/floor.svg">
              <span class="size-value">{{propertyDetails?.sqm}}</span>
              <span>Sqm</span>
            </p>
            <label class="name-label">Property size</label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 d-flex flex-row amenities row">
            <div class="col-lg-4 col-md-4 col-sm-12 form-group">
              <p class="amenities-value"><img class="amenities-img"
                  src="../../../../assets/images/floor-icons/bedroom.svg"><span
                  class="size-value">{{propertyDetails?.beD_ROOM}}</span></p>
              <label class="name-label">Bedroom</label>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 form-group">
              <p class="amenities-value"><img class="amenities-img"
                  src="../../../../assets/images/floor-icons/bathroom.svg"><span
                  class="size-value">{{propertyDetails?.batH_ROOM}}</span></p>
              <label class="name-label">Bathroom</label>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 form-group">
              <p class="amenities-value"><img class="amenities-img"
                  src="../../../../assets/images/floor-icons/carpark.svg"><span
                  class="size-value">{{propertyDetails?.caR_PARKING}}</span></p>
              <label class="name-label">Car Park</label>
            </div>
          </div>
        </div>
    
        <p [innerHTML]="propertyDetails?.description" class="prop-detail-desc">
        </p>
    
        <div class="d-flex flex-row row flex-wrap justify-content-between" id="highlight-container">
          <div *ngFor="let r of propHighlights" class="col-lg-4 col-md-12 col-sm-12 form-group">
            <div class="d-flex flex-column justify-content-center highlight">
              <h2 class="hightlight-title">{{r.title}}</h2>
              <p class="highlight-content">
                {{r.description}}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 form-group ">
            <div class="d-flex flex-column justify-content-center visit">
              <h2 class="visit-title">Visit the website</h2>
              <p class="value">
                More details available on the official website provided by the developer
              </p>
              <a [href]="propertyDetails?.website === '' ? '#' : propertyDetails?.website" target="_blank">
                <button
                  class="btn btn-secondary btn-info col-lg-12 col-md-12 col-sm-12 d-flex flex-row justify-content-center"
                  >Go to the Websites</button>
              </a>
            </div>
          </div>
        </div>
    
        <!-- PROPERTY DOCUMENT SECTION --------------------------------------------------------------------->
    
        <div class="d-flex justify-content-between row" id="prop-docs">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="prop-owner-info-box">
              <div class="col-lg-12 col-md-12 col-sm-12 lease">
                <p class="lease-title">Lease Type</p>
                <img class="prop-owner-logo" 
                  [src]="propertyDetails?.leaseType">
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 developer pt-3">
                <p class="developer-title">Property Developer</p>
                <img class="prop-owner-logo" 
                  [src]="propertyDetails?.propertyDeveloper">
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 pt-3 manager">
                <p class="manager-title">Property Manager</p>
                <img class="prop-owner-logo" 
                  [src]="propertyDetails?.propertyManager">
              </div>
            </div>
          </div>
          <div class=" col-lg-8 col-md-12">
            <h2 class="w-100 f30 primary-semi-bold">Property Documents</h2>
            <div class="col-lg-12 prop-doc-container">
              <table class="table document-table">
                <thead>
                  <tr>
                    <th class="w-50">
                      <div class="d-flex flex-wrap text-left name">Name </div>
                    </th>
                    <th class="w-25">
                      <div class="d-flex flex-wrap text-left date">Date</div>
                    </th>
                    <th class="w-25">
                      <div class="d-flex flex-wrap text-left download">Download</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
    
                  <tr *ngFor="let doc of propDocument; let i=index;">
                    <td class="name w-50">
                      <div class="d-flex flex-wrap text-left name"> {{doc?.documentName}} </div>
                    </td>
                    <td class="date w-50">
                      <div class="d-flex flex-wrap text-left date">
                        {{doc.documentDate | date : 'dd MMM yyyy'}}
                      </div>
                    </td>
                    <td class="download w-50 ps-0">
                      <div class="d-flex download">
                        <img src="../../../../assets/images/download-icons/download.svg"><a [href]="doc?.pdfFileName" target="_blank"
                          class="c-pointer  my-auto link">Download</a>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="propDocument.length === 0">
                    <td colspan="3">
                    <p class="no-doc col-12 d-flex justify-content-center">No Documents</p>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    
  
    </div>
  
    </div>
  <!-- </div> -->
  
</div>
  
<div class="modal fade mx-auto" id="view-history">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg modal-xl">
    <div class="modal-content">
      <div class="modal-body p-0">

        <!-- HEADER -->
        <!-- <div class="px30 d-flex justify-content-between align-items-center flex-row">
          <p class="m-0 exo2-medium f26 dark-blue-grey"></p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->

        <table class="table viewhistory-table">
          <thead>
            <tr>
              <th class="date">
                <div class="d-flex date-title">Date
                </div>
              </th>
              <th class="price">
                <div class="d-flex price-title">
                  Price<span>(Per shares)</span>
                </div>
              </th>
              <th class="manager">
                <div class="d-flex manager-title">
                  Property Manager
                </div>
              </th>

            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let bricklet of priceInfo; let i=index;">
              <td>
                <div class="d-flex flex-wrap text-left date"> {{bricklet?.date}}</div>
              </td>
              <td>
                <div class="d-flex flex-wrap text-left price">${{bricklet?.price}}<span class="current"
                    *ngIf="i==0">Current</span></div>
              </td>
              <td>
                <div class="d-flex flex-wrap text-left name">{{bricklet?.managerName}}</div>
              </td>

          </tbody>
        </table>


      </div>
    </div>
  </div>
</div>
  