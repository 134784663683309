import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { PageConfigs } from 'src/app/core/utils/access-level-config';
import { StorageService } from '../utils/storage.service';
import { Storage } from "../enum/storage";


@Injectable({
  providedIn: 'root'
})
export class UserRolesService {
  pages: any = [];
  userRole: string = '';
  userName: string = '';
  pages$: any;
  private pagesSubject = new Subject<any>();

  constructor(
    private apiManagerService: ApiManagerService,
    private router: Router,
    private storageService: StorageService
  ) {
     this.pages$ = this.pagesSubject.asObservable();
  }

  getPages(pagesData: any) {
    if (Array.isArray(pagesData)) this.pages = pagesData;
  }

  getRole(role: any) {
    this.userRole = role;
  }

  getUserEmail(email: any) {
    this.userName = email;
  }

  getRoleAccessByActionType(pageName: any, access: any) {
    this.pages = JSON.parse(this.storageService.getItem(Storage.PAGES) as any);
    if (this.pages.length > 0) {
      let pageInfo = this.pages.find((page: any) => page.page === pageName);
      return pageInfo !== undefined ? pageInfo.Permission[access] : null;
    } else {
      return null;
    }
  }

  getRoleAccessByPageName(pageName: any) {
    let pageInfo = this.pages.find((page: any) => page.page === pageName);
    return pageInfo.Permission;
  }

  getMenuViewAccess(...pages: any) {
    let result: any[] = [];
    pages.forEach((page: any) => {
      let access = this.getRoleAccessByActionType(page.name, page.read);
      result.push(access);
    });
    return result.includes(true);
  }

  getUserRolePermissions() {
    return this.apiManagerService.getRequest(RestEnds.getPermissions, null);
  }

  navigatePage() {
    switch (this.userRole) {
      case PageConfigs.UserRoles.superAdmin: {
        this.router.navigate(['/property']);
        break;
      }
      case PageConfigs.UserRoles.admin: {
        this.router.navigate(['/property']);
        break;
      }
      case PageConfigs.UserRoles.propertyAdmin: {
        this.router.navigate(['/property']);
        break;
      }
      case PageConfigs.UserRoles.financialAdmin: {
        this.router.navigate(['/withdrawals']);
        break;
      }
      case PageConfigs.UserRoles.marketingAdmin: {
        this.router.navigate(['/news']);
        break;
      }
      case PageConfigs.UserRoles.conveyancer: {
        this.router.navigate(['/conveyancer']);
        break;
      }
    }
  }

  getRoleName() {
    return this.userRole !== '' ? this.userRole : null;
  }

  getUserName() {
    return this.userName !== '' ? this.userName : null;
  }
}
