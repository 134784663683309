import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private spinnerEvent = new BehaviorSubject(false);
  spinnerStatus = this.spinnerEvent.asObservable();

  constructor() { }

  show() {
    this.spinnerEvent.next(true);
  }

  hide() {
    this.spinnerEvent.next(false);
  }
}
