import { Component, OnInit, Input } from '@angular/core';

import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { UserInfoCardInterface, CardDataInterface } from '../../../core/interfaces/user-info-card.interface';
import { MatDialog } from '@angular/material/dialog';
import { HelperService } from '../../../core/utils/helper.service';

@Component({
  selector: 'app-user-info-card',
  templateUrl: './user-info-card.component.html',
  styleUrls: ['./user-info-card.component.scss']
})
export class UserInfoCardComponent implements OnInit {

  @Input() config!: UserInfoCardInterface | any;

  data: CardDataInterface[] | any = null;
  
  constructor(
    public dialog: MatDialog,
    private helperService: HelperService
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

  imageViewer(url: string) {
    const dialogRef = this.dialog.open(ImageViewerComponent, {
      data: { imageUrl: url },
      panelClass: 'image-viewer-modal'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  getExt(filename: any) {
    var ext = filename.split('.').pop();
    if (ext == filename) return "";
    // console.log(ext);
    return ext;
  }

  getImage(data: any): any{
    this.helperService.getKYCImageDecrypt(data).subscribe(
      (r: any) => {
          let blob = new Blob([r], { type: 'application/octet-stream' });
         
          // this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
          let imgUrl = window.URL.createObjectURL(blob);
          return imgUrl;
      },
      (e: any) => {
          return null;
      }
  );
  
  }

}
