<div class="col-12 carousel-box p0">
    <div [@thumbState]="idx === counter ? 'active' : 'inactive'" class="tmb" *ngFor="let img of images; let idx = index">
        <img [src]="img.image" class="d-block w-100" />
        
    </div>
    <button mat-icon-button class="prev" (click)="prev()">
            <mat-icon>chevron_left</mat-icon>
        </button>
    <button mat-icon-button class="next" (click)="next()">
            <mat-icon>chevron_right</mat-icon>
        </button>
</div>
