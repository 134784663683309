import { Component, OnInit, OnDestroy, AfterViewInit, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss']
})
export class NotesDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() image = null;
  isUser = false;
  isFlag = false;
  guid = "";
  clientNotes: any;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formbuilder: FormBuilder,
  ) {

  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }
  ngAfterViewInit() {
    // this.image = this.data.image;
    this.isUser = this.data.message === null || this.data.message === "" ? false : true;
    this.clientNotes = this.data.message;
    this.cdr.detectChanges();
  }
  ngOnDestroy() {
    this.dialogRef.close();
  }
  onClose() {
    this.dialogRef.close();
  }

}
