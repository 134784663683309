import PageConfigs from '../utils/access-level-config';

export class StaticMessage {
  public static readonly amountReqMsg = 'Amount is required';
  public static readonly invalidAmountMsg = 'Invalid amount';
  public static readonly ImageFormat =
    'Please select anyone of the valid image format (.png, .jpg, .jpeg)';
  public static readonly IconImageFormat =
    'Please select anyone of the valid image format (.png, .jpg, .jpeg, .svg)';
}

export class StaticUrl {
  public static readonly  mediaUrl = "https://ampdemo.azureedge.net/azuremediaplayer.html?url="
}

export const PropertyMenu = [
  {
    Name: 'Car',
    Value: 'Add Property',
    img: '../../../assets/images/property/apartment.png',
    link: 'property/create',
    menuLabel: PageConfigs.Properties.name,
    status: false
  },
  // {
  //   Name: 'Property Lease Type',
  //   Value: 'Add Lease Type',
  //   img: '../../../assets/images/property/lease.png',
  //   menuLabel: PageConfigs.LeaseTypes.name,
  //   status: false
  // },
  {
    Name: 'Car Owner',
    Value: 'Add Car Owner',
    img: '../../../assets/images/property/pd.png',
    menuLabel: PageConfigs.PropertyDevelopers.name,
    status: false
  },
  {
    Name: 'Car Manager',
    Value: 'Add Car Manager',
    img: '../../../assets/images/property/pd.png',
    menuLabel: PageConfigs.PropertyManagers.name,
    status: false
  },
  // {
  //   Name: 'Property Amenities',
  //   Value: 'Add Property Amenities',
  //   img: '../../../assets/images/property/pd.png',
  //   menuLabel: PageConfigs.PropertyManagers.name,
  //   status: false
  // },
  // {
  //   Name: 'Create Conveyancer',
  //   Value: 'Add New Conveyancer',
  //   img: '../../../assets/images/property/pd.png',
  //   menuLabel: PageConfigs.Conveyancer.name,
  //   status: false
  // },
  // {
  //   Name: 'News',
  //   Value: 'Add News',
  //   img: '../../../assets/images/property/pd.png',
  //   menuLabel: PageConfigs.NewsList.name,
  //   status: false
  // },
  // {
  //   Name: 'News Category',
  //   Value: 'Add News Category',
  //   img: '../../../assets/images/property/pd.png',
  //   menuLabel: PageConfigs.NewsCategory.name,
  //   status: false
  // },
  // {
  //   Name: 'CPP Agent',
  //   Value: 'Add CPP Agent',
  //   img: '../../../assets/images/property/pd.png',
  //   menuLabel: PageConfigs.CppAgent.name,
  //   status: false
  // },
  {
    Name: 'Create User',
    Value: 'Add New User',
    img: '../../../assets/images/property/pd.png',
    menuLabel: PageConfigs.Users.name,
    status: false
  },
  // {
  //   Name: 'Create Category',
  //   Value: 'Add New Category',
  //   img: '../../../assets/images/property/pd.png',
  //   menuLabel: PageConfigs.CommunityCategory.name,
  //   status: false
  // },
  // {
  //   Name: 'Create SubCategory',
  //   Value: 'Add New SubCategory',
  //   img: '../../../assets/images/property/pd.png',
  //   menuLabel: PageConfigs.CommunityCategory.name,
  //   status: false
  // },
  // {
  //   Name: 'Article',
  //   Value: 'Add Article',
  //   img: '../../../assets/images/property/pd.png',
  //   menuLabel: PageConfigs.CommunityCategory.name,
  //   status: false
  // },
  // {
  //   Name: 'Feature Article',
  //   Value: 'Add Feature Article',
  //   img: '../../../assets/images/property/pd.png',
  //   menuLabel: PageConfigs.CommunityCategory.name,
  //   status: false
  // },
  // {
  //   Name: 'Video',
  //   Value: 'Add Video',
  //   img: '../../../assets/images/property/pd.png',
  //   menuLabel: PageConfigs.CommunityCategory.name,
  //   status: false
  // },
  // {
  //   Name: 'Podcast',
  //   Value: 'Add Podcast',
  //   img: '../../../assets/images/property/pd.png',
  //   menuLabel: PageConfigs.CommunityCategory.name,
  //   status: false
  // },
  
];

export class StaticSuccessMessage {
  public static readonly leaseType = 'Lease Type Saved Succesfully';
  public static readonly propertyDeveloper =
    'Property Developer Saved Succesfully';
  public static readonly propertyManager = 'Property Manager Saved Succesfully';
  public static readonly saveNewsCategroy = 'News Category Saved Succesfully';
  public static readonly updateNewsCategroy =
    'News Category Updated Succesfully';
  public static readonly update = 'Updated Succesfully';
  public static readonly save = 'Saved Succesfully';
  public static readonly saveUsers = 'User created successfully';
  public static readonly deleteCategory = 'Delete Category';
  public static readonly deleteSubCategory = 'Delete SubCategory';
}

export class FormStaticMessage {
  public static readonly Invalid = 'Please fill all the fileds!';
}

export class DialogStaticMessage {
  public static readonly NewsCategory = 'Delete News Category?';
}

export class RejectStaticMessage {
  public static readonly NewsCategory = 'Reject request?';
}

export class ApproveStaticMessage {
  public static readonly title =
    'Are you sure to approve the withdraw request?';
}

export class ReplaceManagerStaticMessage {
  public static readonly title =
    'Are you sure you want to replace manager?';
}

export class ApproveStaticRequestMessage {
  public static readonly title =
    'Are you sure to approve the Title Transfer request?';
    public static readonly conveyancertitle =
    'Are you sure to approve the Conveyancer Pending Order request?';
}

export enum ApproveDialog {
  Yes = 'Yes',
  No = 'No',
}

export class  ApproveKycAccountStaticMessage {
  public static readonly title =
  'Are you sure to approve the KYC account request?'
}

export class ApproveAccountStaticMessage {
  public static readonly title =
    'Are you sure to approve the kyc account request?';
}

export enum RejectDialog {
  RejectYes = 'Reject',
  RejectNo = 'Cancel',
}

export enum Dialog {
  DeleteYes = 'Delete',
  DeleteNo = 'Cancel',
}

export enum News {
  EditTitle = 'Edit News',
  ViewTitle = 'View News',
}

export enum PropertyDeveloper {
  typevalue = 'Edit Car Owner',
  typename = 'Car Owner',
  addtypevalue = 'Add Car Owner',
}

export enum LeaseType {
  typevalue = 'Edit LeaseType',
  typename = 'Property Lease Type',
  addtypevalue = 'Add Lease Type',
}

export enum PropertyManager {
  typevalue = 'Edit Car Manager',
  typename = 'Car Manager',
  addtypevalue = 'Add Car Manager',
}

export class Template {
  public static readonly HistoricalFile = 'template/Historical_Template.xlsx';
}

export class propsOwner {}

export enum UploadImage {
  invalidExtension = 'Invalid file extension.',
  imageFormat = 'Please select anyone of the valid image format ',
}

export class UploadImageOptions {
  public static exts = ['.png', '.jpg', '.jpeg'];
  public static size = 3145728;
  public static width = null;
  public static height = null;
  public static operation = 'min';
}

export class AmenitiesIconOptions {
  public static exts = ['.png', '.jpg', '.jpeg', '.svg'];
  public static extsType = ['.png', '.jpg', '.jpeg', '.svg+xml'];
  public static size = 3145728;
  public static width = 48;
  public static height = 48;
  public static operation = 'max';
}

export class NewsUploadImageOptions {
  public static exts = ['.png', '.jpg', '.jpeg'];
  public static size = 1048576;
  public static width = 760;
  public static height = 760;
  public static operation = 'min';
}

export class CommunityUploadImageOptions {
  public static exts = ['.png', '.jpg', '.jpeg'];
  public static size = 1048576;
  public static width = 1280;
  public static height = 400;
  public static operation = 'min';
}

export class ApproveStaticCloneMessage {
  public static readonly title =
    'Are you sure to clone this property?';
}

export class ApproveStaticDeleteMessage {
  public static readonly title =
    'Are you sure you want to delete this image?';
}

export enum Video {
  EditTitle = 'Edit Video',
  ViewTitle = 'View Video',
}

export enum Podcast {
  EditTitle = 'Edit Podcast',
  ViewTitle = 'View Podcast',
}


export enum Article {
  EditTitle = 'Edit Article',
  ViewTitle = 'View Article',
}

export enum FeatureArticle {
  EditTitle = 'Edit Feature Article',
  ViewTitle = 'View Feature Article',
}

export class ApproveStaticBPPRequestMessage {
  public static readonly title =
    'Are you sure to approve the BPP Title Transfer request?';
}

export class DeleteSellerPermissionMessage {
  public static readonly title =
    'Are you sure to delete the Seller Permission?';
}