import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Storage } from '../enum/storage';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { StorageService } from '../utils/storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginStatus = new BehaviorSubject<boolean>(this.hasBoolean());
  redirectUrl: string;
  constructor(
    private apiManager: ApiManagerService,
    private storageService: StorageService,
    private router: Router
  ) { }

  signIn(data: {
    EmailID: string,
    Password: string,
    Captcha: string
  }) {
    return this.apiManager.postRequest(RestEnds.login, data);
  }

  signUp() {

  }

  getAuthToken() {
    return this.storageService.getItem(Storage.AUTH_TOKEN);
  }

  login(data: any) {
    return this.apiManager.postRequest(RestEnds.login, data);
  }

  getRefreshToken(): Observable<string> {
    const data = {
      RefreshToken: this.storageService.getItem(Storage.REFRESH_TOKEN)
    };
    return this.apiManager.postRequest(RestEnds.refreshToken, data).pipe(
      map((root: any) => {
        this.storageService.setItem(
          Storage.AUTH_TOKEN,
          root.data.credential.token
        );
        this.storageService.setItem(
          Storage.REFRESH_TOKEN,
          root.data.credential.refreshToken
        );
        return root.data.credential.token;
      },
      (err: any) => {}
      )
    );
  }

  logout() {
    this.storageService.removeItem(Storage.AUTH_TOKEN);
    this.storageService.setItem(Storage.IS_LOGGEDIN, "false");
    this.storageService.removeItem(Storage.REFRESH_TOKEN);
    this.storageService.removeItem(Storage.CAPTCHA_TOKEN);
    this.storageService.removeItem(Storage.ROLE);
    this.storageService.removeItem(Storage.ROLE_ROUTE);
    localStorage.removeItem(Storage.ROLE);
    localStorage.removeItem(Storage.ROLE_ROUTE);
    this.storageService.clear();
    this.changeStatus(false);
    this.router.navigate(["/login"]);
  }

  changeStatus(message: boolean) {
    this.loginStatus.next(message);
  }

  isLoggedIn(): Observable<boolean> {
    return this.loginStatus.asObservable();
  }

  private hasBoolean(): boolean {
    let result = Boolean(this.storageService.getItem(Storage.IS_LOGGEDIN));
    return Boolean(result);
  }

  verifyEmail(data: any) {
    return this.apiManager.postRequest(RestEnds.verifyEmail, data);
  }

}
