import { Component, OnInit, Input } from '@angular/core';
import { KycConfigService } from '../../../core/services/kyc-config.service';
import { UnRegulatedTrust } from '../../../core/interfaces/kyc.interface';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';

@Component({
  selector: 'app-org-unregulated-trust',
  templateUrl: './org-unregulated-trust.component.html',
  styleUrls: ['./org-unregulated-trust.component.scss']
})
export class OrgUnregulatedTrustComponent implements OnInit {

  @Input() unregulatedTrustData: UnRegulatedTrust | any;

  personalInfo: UserInfoCardInterface | null = null;
  addressInfo: UserInfoCardInterface | null = null;
  idInfo: UserInfoCardInterface | null = null;
  addressVerifyInfo: UserInfoCardInterface | null = null;

  basicInfo: UserInfoCardInterface | null = null;
  regOffice: UserInfoCardInterface | null = null;
  entityRegOffice: UserInfoCardInterface | null = null;
  businessInfo: UserInfoCardInterface | null = null;

  typeInfo: UserInfoCardInterface | null = null;
  
  basicUnregulatedInfo: UserInfoCardInterface | null = null;

  isIndividual = false;
  isPrivate = false;
  isPublic = false;

  constructor(
    private kycConfigService: KycConfigService
  ) { }

  ngOnInit() {

    const entityRegOffice = this.kycConfigService.privateAddressInfo(this.unregulatedTrustData.registeredOffice);
      this.basicUnregulatedInfo = {
        title: 'UnRegulated Trust',
        column: 12,
        type: 'doc-data-text-only',
        data: [
          {  text: 'Trust Name', value: this.unregulatedTrustData.trustName, img: null },
          {  text: 'UnRegulated Trust Type', value: this.unregulatedTrustData.unTrustType, img: null },
          {  text: 'Trust Deed', value: null, img: this.unregulatedTrustData.trustDeedURL }
        ]
      };
      this.entityRegOffice = {
        title: 'Registered Office',
        column: 3,
        type: 'text-only',
        data: entityRegOffice
      };
      this.typeInfo = {
        title: '',
        column: 12,
        type: 'type-text-only',
        data: [
          {  text: 'Trustee Type', value: this.unregulatedTrustData.trusteeType, img: null }
        ]
      };

    if (this.unregulatedTrustData.individual) {
      this.isIndividual = true;

      const result: any = this.kycConfigService.kycOrgArrangedData(this.unregulatedTrustData.individual);
      
      
      this.personalInfo = {
        title: 'Personal Information',
        column: 4,
        type: 'text-only',
        data: result.personalInfo
      };
      this.addressInfo = {
        title: 'Address Information',
        column: 3,
        type: 'text-only',
        data: result.addressInfo
      };

      this.addressVerifyInfo = {
        title: 'Address verification documents',
        column: 0,
        type: 'doc-only',
        data: result.addressVerifyDocs
      };

      this.idInfo = {
        title: 'Identity verification documents',
        column: 0,
        type: 'doc-data-only',
        data: result.idInfo
      };
      // console.log(this.basicInfo);
    }
    else if (this.unregulatedTrustData.private) {
      this.isPrivate = true;
      const result: any = this.kycConfigService.kycArrangePrivateData(this.unregulatedTrustData.private);

      this.basicInfo = {
        title: 'Private Company',
        column: 12,
        type: 'doc-data-text-only',
        data: result.basicInfo
      };

      this.regOffice = {
        title: 'Registered Office',
        column: 3,
        type: 'text-only',
        data: result.regOffice
      };

      this.businessInfo = {
        title: 'Principal Place Of Business',
        column: 3,
        type: 'text-only',
        data: result.businessInfo
      };
      

    }
    else if (this.unregulatedTrustData.public) {
      this.isPublic = true;
      const result: any = this.kycConfigService.kycOrgArrangedPublicCompanyData(this.unregulatedTrustData.public);
      this.basicInfo = {
        title: 'Public Company',
        column: 6,
        type: 'text-only',
        data: result.basicPublicCompanyInfo
      };
    }

  }

}
