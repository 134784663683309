<a
  *ngIf="menuItems?.properties"
  mat-list-item
  routerLink="/property"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/building-alt.png" />
  <span class="pl15 roboto-regular f14">Cars</span>
</a>
<!-- <a
  *ngIf="menuItems?.properties"
  mat-list-item
  routerLink="/property/developer"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/building-alt.png" />
  <span class="pl15 roboto-regular f14">Car Owners</span>
</a>
<a
  *ngIf="menuItems?.properties"
  mat-list-item
  routerLink="/property/manager"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/building-alt.png" />
  <span class="pl15 roboto-regular f14">Car Managers</span>
</a> -->
<!-- <a
  *ngIf="menuItems?.deposits"
  mat-list-item
  routerLink="/deposits"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/money-bag.png" />
  <span class="pl15 roboto-regular f14">Deposits</span>
</a>
<a
  *ngIf="menuItems?.withdrawals"
  mat-list-item
  routerLink="/withdrawals"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/money-bag.png" />
  <span class="pl15 roboto-regular f14">Withdrawals</span>
</a>
<a
  *ngIf="menuItems?.transactions"
  mat-list-item
  routerLink="/all-transactions"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/money-bag.png" />
  <span class="pl15 roboto-regular f14">Transactions</span>
</a> -->
<!-- <a
  *ngIf="menuItems?.rent"
  mat-list-item
  routerLink="/rent"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/rent.png" />
  <span class="pl15 roboto-regular f14">Rent</span>
</a> -->
<a
  *ngIf="menuItems?.users"
  mat-list-item
  routerLink="/users"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/users-alt-5.png" />
  <span class="pl15 roboto-regular f14">Users</span>
</a>
<a
  *ngIf="menuItems?.kyc"
  mat-list-item
  routerLink="/kyc"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/users-alt-5.png" />
  <span class="pl15 roboto-regular f14">KYC</span>
</a>
<!-- <a
  *ngIf="menuItems?.coownership"
  mat-list-item
  routerLink="/coownership"
  routerLinkActive="active"
  class="list-items text-left mb10"
>
  <img src="../../../../assets/icons/money-bag.png" />
  <span class="pl15 roboto-regular f14">Title Transfer Request</span>
</a> -->
<!-- <mat-accordion
class="list-items text-center mb10"
(click)="itemselected(expanded)"  *ngIf="menuItems?.coownership"
>
<mat-expansion-panel class="expansion-panel" >
  <mat-expansion-panel-header class="pl0 expansion-panel-header">
    <mat-panel-title>
      <a
        mat-list-item
        *ngIf="menuItems?.reports"
        class="list-items text-center"
      >
      <img src="../../../../assets/icons/money-bag.png" />
        <span class="pl12 roboto-regular f14 text-left">
          BPP
        </span>
      </a>
    </mat-panel-title>
  </mat-expansion-panel-header> -->
  <!-- <div  *ngIf="expanded"> -->

<!-- 
  <a
  *ngIf="menuItems?.coownership"
  mat-list-item
  routerLink="/bppcoownership"
  routerLinkActive="active"
  class="sublist-item text-left mb10"
>
  <img src="../../../../assets/icons/money-bag.png" />
  <span class="pl12 roboto-regular f14 white">Title Transfer Request</span>
</a>

<a
*ngIf="menuItems?.coownership"
mat-list-item
routerLink="/bpplatepayment"
routerLinkActive="active"
class="sublist-item text-left mb10"
>
<img src="../../../../assets/icons/money-bag.png" />
<span class="pl12 roboto-regular f14 white">late payment</span>
</a>

<a
*ngIf="menuItems?.coownership"
mat-list-item
routerLink="/bppdocument"
routerLinkActive="active"
class="sublist-item text-left mb10"
>
<img src="../../../../assets/icons/money-bag.png" />
<span class="pl12 roboto-regular f14 white">Document</span>
</a> -->

<!-- <a
*ngIf="menuItems?.coownership"
mat-list-item
routerLink="/bppsettings"
routerLinkActive="active"
class="sublist-item text-left mb10"
>
<img src="../../../../assets/icons/money-bag.png" />
<span class="pl12 roboto-regular f14 white">Settings</span>
</a> -->

<!-- <a
*ngIf="menuItems?.coownership"
mat-list-item
routerLink="/bppseller"
routerLinkActive="active"
class="sublist-item text-left mb10"
>
<img src="../../../../assets/icons/money-bag.png" />
<span class="pl12 roboto-regular f14 white">Seller Permission</span>
</a> -->

  <!-- </div> -->
<!--   
</mat-expansion-panel>
</mat-accordion> -->

<a
  *ngIf="menuItems?.conveyancer"
  mat-list-item
  routerLink="/conveyancer"
  routerLinkActive="active"
  class="list-items text-left mb10"
>
  <img src="../../../../assets/icons/money-bag.png" />
  <span class="pl15 roboto-regular f14">Conveyancer</span>
</a>

<a
  *ngIf="menuItems?.adminconveyancer"
  mat-list-item
  routerLink="/conveyanceradmin/allhistory"
  routerLinkActive="active"
  class="list-items text-left mb10"
>
  <img src="../../../../assets/icons/money-bag.png" />
  <span class="pl15 roboto-regular f14">Conveyancer</span>
</a>
<a
mat-list-item
routerLink="/all-transactions"
routerLinkActive="active"
class="list-items text-center mb10"
*ngIf="menuItems?.transactions"
>
<img src="../../../../assets/icons/money-bag.png" />
<span class="pl15 roboto-regular f14">Transactions</span>
</a>
<!-- <a
  *ngIf="menuItems?.superadminconveyancer"
  mat-list-item
  routerLink="/conveyanceradmin/allpendingorder"
  routerLinkActive="active"
  class="list-items text-left mb10"
>
  <img src="../../../../assets/icons/money-bag.png" />
  <span class="pl15 roboto-regular f14">Conveyancer</span>
</a> -->

<!-- <a
  *ngIf="menuItems?.users"
  mat-list-item
  routerLink="/ledger"
  routerLinkActive="active"
  class="list-items text-left mb10"
>
  <img src="../../../../assets/icons/money-bag.png" />
  <span class="pl15 roboto-regular f14">Ledger</span>
</a> -->



<!-- <a
  *ngIf="menuItems?.communitycategory"
  mat-list-item
  routerLink="/FeatureArticle"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/newspaper.png" />
  <span class="pl15 roboto-regular f14">Feature Article</span>
</a>
<a
  *ngIf="menuItems?.communitycategory"
  mat-list-item
  routerLink="/Article"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/newspaper.png" />
  <span class="pl15 roboto-regular f14">Article</span>
</a>
<a
  *ngIf="menuItems?.communitycategory"
  mat-list-item
  routerLink="/Podcast"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/newspaper.png" />
  <span class="pl15 roboto-regular f14">Podcast</span>
</a>
<a
  *ngIf="menuItems?.communitycategory"
  mat-list-item
  routerLink="/Video"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/newspaper.png" />
  <span class="pl15 roboto-regular f14">Video</span>
</a>
<a
  *ngIf="menuItems?.communitycategory"
  mat-list-item
  routerLink="/community"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/newspaper.png" />
  <span class="pl15 roboto-regular f14">Community</span>
</a> -->
<!-- <a
  *ngIf="menuItems?.news"
  mat-list-item
  routerLink="/news"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/newspaper.png" />
  <span class="pl15 roboto-regular f14">News</span>
</a> -->
<!-- <a mat-list-item  routerLinkActive="active" class="list-items text-center mb10" (click)="itemselected(expanded)"
    [ngClass]="{'active': expanded ? router.isActive(expanded, true): false, 'active': expanded}">
        <img src="../../../../assets/icons/settings.png">
        <span class="pl15 roboto-regular f14 ">
            Settings

        </span>
        <span fxFlex class="pl40 d-flex ml10">
            <span fxFlex></span>
            <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
                expand_more
            </mat-icon>
        </span>



    </a> -->

    <mat-accordion
    class="list-items text-center mb10"
    (click)="itemselected(expanded)"  *ngIf="menuItems?.reports"
  >
    <mat-expansion-panel class="expansion-panel" >
      <mat-expansion-panel-header class="pl0 expansion-panel-header">
        <mat-panel-title>
          <a
            mat-list-item
            *ngIf="menuItems?.reports"
            class="list-items text-center"
          >
            <img src="../../../../assets/icons/report-icon3.png" />
            <span class="pl12 roboto-regular f14 text-left">
              All Data Report
            </span>
          </a>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <a
        mat-list-item
        routerLink="/reports/users"
        routerLinkActive="active"
        class="sublist-item text-center mb10"
        *ngIf="menuItems?.reports"
      >
      <img src="../../../../assets/icons/users-alt-5.png" />
        <span class="pl12 roboto-regular f14 white">All Users</span>
      </a>
       <a
      mat-list-item
      routerLink="/reports/tradecars"
      routerLinkActive="active"
      class="sublist-item text-center mb10"
      *ngIf="menuItems?.reports"
    >
    <img src="../../../../assets/icons/users-alt-5.png" />
      <span class="pl12 roboto-regular f14 white text-left">Trade Cars</span>
    </a>
    <a
    mat-list-item
    routerLink="/reports/revenue"
    routerLinkActive="active"
    class="sublist-item text-center mb10"
    *ngIf="menuItems?.reports"
  >
  <img src="../../../../assets/icons/users-alt-5.png" />
    <span class="pl12 roboto-regular f14 white text-left">Revenue</span>
  </a>
 <!-- <a
  mat-list-item
  routerLink="/reports/propertiesusers"
  routerLinkActive="active"
  class="sublist-item text-center mb10 h-75"
  *ngIf="menuItems?.reports"
>
<img src="../../../../assets/icons/users-alt-5.png" />
  <span class="pl12 roboto-regular f14 white text-left">Properties including user details who holding Shares</span>
</a> -->
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion class="menu-dropdown list-items text-center mb10">
    <!-- Other sections here -->
  
    <!-- Financial Operations Dropdown -->
    <mat-expansion-panel
      class="list-items expansion-panel"
      *ngIf="
        menuItems?.deposits || menuItems?.withdrawals || menuItems?.transactions || menuItems?.users
      "
    >
      <mat-expansion-panel-header class="pl0 expansion-panel-header">
        <mat-panel-title>
          <a mat-list-item class="list-items text-center">
            <img src="../../../../assets/icons/money-bag.png" />
            <span class="pl12 roboto-regular f14 text-left">
              Financial Operations
            </span>
          </a>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="menu-section">
  
        <a
          mat-list-item
          routerLink="/deposits"
          routerLinkActive="active"
          class="list-items text-center mb10"
          *ngIf="menuItems?.deposits"
        >
          <img src="../../../../assets/icons/money-bag.png" />
          <span class="pl15 roboto-regular f14">Deposit</span>
        </a>
  
        <a
          mat-list-item
          routerLink="/dividends"
          routerLinkActive="active"
          class="list-items text-center mb10"
          *ngIf="menuItems?.dividends"
        >
          <img src="../../../../assets/icons/money-bag.png" />
          <span class="pl15 roboto-regular f14">Dividends</span>
        </a>

        <a
        mat-list-item
        routerLink="/withdrawals"
        routerLinkActive="active"
        class="list-items text-center mb10"
        *ngIf="menuItems?.withdrawals"
      >
        <img src="../../../../assets/icons/money-bag.png" />
        <span class="pl15 roboto-regular f14">Withdraw</span>
      </a>
        <!-- <a
          mat-list-item
          routerLink="/all-transactions"
          routerLinkActive="active"
          class="list-items text-center mb10"
          *ngIf="menuItems?.transactions"
        >
          <img src="../../../../assets/icons/money-bag.png" />
          <span class="pl15 roboto-regular f14">Transactions</span>
        </a> -->
        <a
          mat-list-item
          routerLink="/ledger"
          routerLinkActive="active"
          class="list-items text-left mb10"
          *ngIf="menuItems?.ledgers"
        >
          <img src="../../../../assets/icons/money-bag.png" />
          <span class="pl15 roboto-regular f14">Ledger</span>
        </a>
      </div>
    </mat-expansion-panel>
  </mat-accordion>


  <!-- <mat-accordion
  class="list-items text-center mb10"
  (click)="itemselected(expanded)" *ngIf="menuItems?.reports"
>
  <mat-expansion-panel class="expansion-panel">
    <mat-expansion-panel-header class="pl0 expansion-panel-header">
      <mat-panel-title>
        <a
          mat-list-item
          class="list-items text-center"
          *ngIf="menuItems?.reports"
        >
          <img src="../../../../assets/icons/report-icon3.png" />
          <span class="pl12 roboto-regular f14 text-left">
            Operations Data Report
          </span>
        </a>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <a
      mat-list-item
      routerLink="/reports/buyerorder"
      routerLinkActive="active"
      class="sublist-item text-center mb10"
      *ngIf="menuItems?.reports"
    >
    <img src="../../../../assets/icons/building-alt.png" />
      <span class="pl12 roboto-regular f14 white">Buyer Order</span>
    </a>
    <a
    mat-list-item
    routerLink="/reports/transactiontransfer"
    routerLinkActive="active"
    class="sublist-item text-center mb10"
    *ngIf="menuItems?.reports"
  >
  <img src="../../../../assets/icons/money-bag.png" />
    <span class="pl12 roboto-regular f14 white text-left">Transaction - Transfer Done</span>
  </a>
  <a
  mat-list-item
  routerLink="/reports/brickletholders"
  routerLinkActive="active"
  class="sublist-item text-center mb10"
  *ngIf="menuItems?.reports"
>
<img src="../../../../assets/icons/building-alt.png" />
  <span class="pl12 roboto-regular f14 white text-left">Shares Holders List</span>
</a>
<a
mat-list-item
routerLink="/reports/depositsusers"
routerLinkActive="active"
class="sublist-item text-center mb10"
*ngIf="menuItems?.reports"
>
<img src="../../../../assets/icons/money-bag.png" />
<span class="pl12 roboto-regular f14 white text-left">Deposits including user details</span>
</a>
<a
mat-list-item
routerLink="/reports/withdrawalusers"
routerLinkActive="active"
class="sublist-item text-center mb10"
*ngIf="menuItems?.reports"
>
<img src="../../../../assets/icons/money-bag.png" />
<span class="pl12 roboto-regular f14 white text-left">Withdrawal including user details</span>
</a>

<a
    mat-list-item
    routerLink="/reports/brickletsalestransactions"
    routerLinkActive="active"
    class="sublist-item text-center mb10"
    *ngIf="menuItems?.reports"
  >
  <img src="../../../../assets/icons/money-bag.png" />
    <span class="pl12 roboto-regular f14 white text-left">Shares Sales Transactions</span>
  </a>

  <a
    mat-list-item
    routerLink="/reports/brickletdetails"
    routerLinkActive="active"
    class="sublist-item text-center mb10"
    *ngIf="menuItems?.reports"
  >
  <img src="../../../../assets/icons/money-bag.png" />
    <span class="pl12 roboto-regular f14 white text-left">Shares Details</span>
  </a>

  <a
    mat-list-item
    routerLink="/reports/customerdetails"
    routerLinkActive="active"
    class="sublist-item text-center mb10"
    *ngIf="menuItems?.reports"
  >
  <img src="../../../../assets/icons/money-bag.png" />
    <span class="pl12 roboto-regular f14 white text-left">Customer Details</span>
  </a>

  <a
  mat-list-item
  routerLink="/reports/bppbuy"
  routerLinkActive="active"
  class="sublist-item text-center mb10"
  *ngIf="menuItems?.reports"
>
<img src="../../../../assets/icons/money-bag.png" />
  <span class="pl12 roboto-regular f14 white text-left">BPP Buy Report</span>
</a>

<a
  mat-list-item
  routerLink="/reports/pendingbricklet"
  routerLinkActive="active"
  class="sublist-item text-center mb10"
  *ngIf="menuItems?.reports"
>
<img src="../../../../assets/icons/money-bag.png" />
  <span class="pl12 roboto-regular f14 white text-left">Pending Shares Details Report</span>
</a>


<a
mat-list-item
routerLink="/reports/sellorder"
routerLinkActive="active"
class="sublist-item text-center mb10"
*ngIf="menuItems?.reports"
>
<img src="../../../../assets/icons/money-bag.png" />
<span class="pl12 roboto-regular f14 white text-left">Sell Order Report</span>
</a>



  </mat-expansion-panel>
</mat-accordion> -->

<!-- <a
  *ngIf="menuItems?.reports"
  mat-list-item
  routerLink="/reports/allreports"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/report-icon3.png" />
  <span class="pl15 roboto-regular f14">All Cars Reports</span>
</a> -->

<!-- <mat-accordion
  class="list-items text-center mb10"
  (click)="itemselected(expanded)"
>
  <mat-expansion-panel class="expansion-panel" routerLinkActive="active">
    <mat-expansion-panel-header class="pl0 expansion-panel-header">
      <mat-panel-title>
        <a
          mat-list-item
          routerLinkActive="active"
          class="list-items text-center"
        >
          <img src="../../../../assets/icons/settings.png" />
          <span class="pl15 roboto-regular f14">
            Settings
          </span>
        </a>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <a
      mat-list-item
      routerLink="/setting"
      routerLinkActive="active"
      class="sublist-item text-center mb10"
    >
      <span class="pl15 roboto-regular f14 white">2FA Authentication</span>
    </a>
  </mat-expansion-panel>
</mat-accordion> -->



<!-- <a
  *ngIf="menuItems?.cppAgent"
  mat-list-item
  routerLink="/agent"
  routerLinkActive="active"
  class="list-items text-center mb10"
>
  <img src="../../../../assets/icons/newspaper.png" />
  <span class="pl15 roboto-regular f14">CPP Agent</span>
</a> -->

<a
  mat-list-item
  class="list-items text-center mb10 cursor-pointer"
  (click)="onLogout()"
>
  <img src="../../../../assets/icons/logout.png" />
  <span class="pl15 roboto-regular f14">Logout</span>
</a>
