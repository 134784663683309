import { Component, OnInit, Input } from '@angular/core';
import { KycConfigService } from '../../../core/services/kyc-config.service';
import { RegulatedTrust } from '../../../core/interfaces/kyc.interface';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';

@Component({
  selector: 'app-org-regulated-trust',
  templateUrl: './org-regulated-trust.component.html',
  styleUrls: ['./org-regulated-trust.component.scss']
})
export class OrgRegulatedTrustComponent implements OnInit {

  @Input() regulatedTrustData: RegulatedTrust | any;

  personalInfo: UserInfoCardInterface | null = null;
  addressInfo: UserInfoCardInterface | null = null;
  idInfo: UserInfoCardInterface | null = null;
  addressVerifyInfo: UserInfoCardInterface | null = null;

  basicInfo: UserInfoCardInterface | null = null;
  regOffice: UserInfoCardInterface | null = null;
  businessInfo: UserInfoCardInterface | null = null;
  
  basicSoleTraderInfo: UserInfoCardInterface | null = null;

  constructor(
    private kycConfigService: KycConfigService
  ) { }

  ngOnInit() {
      this.basicInfo = {
        title: 'Regulated Trust',
        column: 6,
        type: 'text-only',
        data: [
          {  text: 'ABN', value: this.regulatedTrustData.abn, img: null },
          {  text: 'Trust Name', value: this.regulatedTrustData.trustName, img: null },
          {  text: 'Trust Type', value: this.regulatedTrustData.trustType, img: null }
        ]
        // result.basicRegualtedTrustInfo
      };
      // console.log(this.basicInfo);
  }

}
