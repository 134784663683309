<div class="bootstrap-wrapper mt-div">
    <div class="container">
        <div class="row modal-header p5">
            <div class="col-lg-10 col-md-10 col-sm-6 roboto-medium f16 title"></div>
            <div class="col-lg-2 col-md-2 col-sm-6 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20 title"
                    (click)="onClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        
        <div class="title-div row col-lg-12 col-md-12 col-sm-12">
            <div class="pl20 pt10 col-md-12 col-sm-12 p0">
                <div class="col-md-12 col-sm-12 row">
                    <div class="col-lg-3 col-md-4 col-sm-12 header-id-info">
                        <span class="name">Property ID</span>
                        <span class="value">{{propertyCode}}</span>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-12 name-info-div">
                        <span class="label">Property Name</span>
                        <span class="value">{{propertyName}} </span>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-12 header-value-info-div align-item-end">
                        <span class="label">Total No. of Shares</span>
                        <span class="value pr-55">{{noofBricklet}}</span>
                    </div>


                </div>

            </div>



        </div>

        <div class="main-div row scroll-x col-lg-12 col-md-12 col-sm-12 p0">
        
            <div class="detail-div pl20" >
            <mat-accordion multi class="mat-table col-lg-12 col-md-12 col-sm-12 p0 pt-8" is-open="true">
                <mat-expansion-panel class="p0 mb-8" *ngFor="let record of propertyRecord">
                  <mat-expansion-panel-header class="mat-row p0 h-70 mb-10">
                    <mat-panel-title class="col-lg-9 col-md-9 col-sm-12">
                        <div class="col-lg-6 col-md-6 col-sm-12 user-info-div pl-10">
                            <span class="name roboto-bold">{{record.accountName}} <span class="pl-12"><i class="fa fa-map-marker torch-red" tooltip="{{getaddress(record)}}" placement="right" delay="100" aria-hidden="true"></i></span></span> 
                            <span class="value">{{record.email}}</span>
                        </div>
                    </mat-panel-title>
                    <mat-panel-description class="col-lg-3 col-md-3 col-sm-12 justify-content-end">
                        <div class="col-lg-12 col-md-12 col-sm-12 id-info-div pl-33 align-item-end">
                            <span class="label roboto-bold cornflower-blue" *ngIf="record.iD_AdminApprovalStatus === 2">Approved</span>
                            <span class="value" *ngIf="record.iD_AdminApprovalStatus === 2">Approved on: <span class="text-transform-upper">{{record.approvedOn | date:
                                'dd-MMM-yyyy'}}</span></span>
                                <span class="label roboto-bold torch-red" *ngIf="record.iD_AdminApprovalStatus === 3">Rejected</span>
                                <span class="value" *ngIf="record.iD_AdminApprovalStatus === 3">Rejected on: <span class="text-transform-upper">{{record.approvedOn | date:
                                    'dd-MMM-yyyy'}}</span></span>
                        </div>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="col-lg-12 col-md-12 col-sm-12 row pl-7 space-between desc-content bg-table">
                    <div class="col-lg-2 col-md-2 col-sm-12 user-info-div pl-20 align-item-center">
                        <span class="name roboto-bold">Date of Purchase</span>
                        <span class="value cornflower-blue text-transform-upper">{{record.dateOfPurchase | date: 'dd-MMM-yyyy'}}</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 price-info-div pl-10 align-item-center">
                        <span class="name roboto-bold">Date of Conveyance Completed</span>
                        <span class="value cornflower-blue text-transform-upper">{{record.dateOfConveyanceCompleted | date: 'dd-MMM-yyyy'}}</span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 price-info-div pl-10 align-item-center">
                        <span class="name roboto-bold">Number of Shares</span>
                        <span class="value f12">{{record.noOfBricklets}}</span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 total-info-div pl-10 pr0 align-item-center">
                        <span class="name roboto-bold">Total Shares Price</span>
                        <span class="value f12">${{record.totalBrickletPrices === null ? '0' : record.totalBrickletPrices | number : '1.2-2'}}</span>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 row pl-7 space-between bg-table mt-10 mat-elevation-z8">
                    
                    <!-- <div class="mat-elevation-z8 mt-2"> -->
                        <div class="table-responsive  mt-2">

                            <!-- {{getString(record.brickletDetails)}} -->
                            <!-- <table mat-table class="table">
                                <tr>
                                    <th class="table-header exo2-bold f14"> Shares Reference ID </th>
                                    <th class="table-header exo2-bold f14"> Sell requested On </th>
                                    <th class="table-header exo2-bold f14">Sell completed on</th>
                                    <th class="table-header exo2-bold f14"> Sell Conveyance Completed On </th>
                                    <th class="table-header exo2-bold f14">Seller</th>
                                    <th class="table-header exo2-bold f14">Shares Prices</th>
                                </tr>
                                <tr *ngFor="let i of getString(record.brickletDetails)">
                                    <td class="td-pl exo2-medium f14"> {{i.ReferenceID}} </td>
                                    <td class="td-pl exo2-medium f14"></td>
                                    <td class="td-pl exo2-medium f14"></td>
                                    <td class="td-pl exo2-medium f14"></td>
                                    <td class="td-pl exo2-medium f14"></td>
                                    <td class="td-pl exo2-medium f14"></td>
                                </tr>
                            </table> -->
                            
                            <table mat-table [dataSource]="getString(record.brickletDetails)" class="table">
                                <ng-container matColumnDef="ReferenceID">
                                    <th mat-header-cell *matHeaderCellDef class="table-header exo2-bold f14 th-w pl-22 black"> Shares Reference ID </th>
                                    <td mat-cell *matCellDef="let element" class="td-pl exo2-medium f12 pl-1"> {{element.ReferenceID}} </td>
                                </ng-container>
                
                                <ng-container matColumnDef="SellRequestedOn">
                                    <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14 th-w-60 pl-4 black"> Sell requested On </th>
                                    <td mat-cell *matCellDef="let element" class="roboto-regular f12 pl-18 text-transform-upper"> {{element.SellRequestedOn === '1900-01-01T00:00:00' ? '' : element.SellRequestedOn | date: 'dd-MMM-yyyy'}} </td>
                                </ng-container>
                
                
                                <ng-container matColumnDef="SellCompletedOn">
                                    <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14 th-w pl-1 black"> Sell completed on </th>
                                    <td mat-cell *matCellDef="let element" class="roboto-regular f12 pl-20 text-transform-upper"> {{element.SellCompletedOn | date: 'dd-MMM-yyyy'}}
                                    </td>
                                </ng-container>
                
                
                                <ng-container matColumnDef="SellConveyanceCompletedOn">
                                    <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14 th-completed pl-12 black"> Sell Conveyance Completed On </th>
                                    <td mat-cell *matCellDef="let element" class="roboto-regular f12 pl-27 text-transform-upper"> {{element.SellConveyanceCompletedOn | date: 'dd-MMM-yyyy'}} </td>
                                </ng-container>
                
                                <ng-container matColumnDef="Seller">
                                    <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14 th-w pl-12 black"> Seller </th>
                                    <td mat-cell *matCellDef="let element" class="roboto-regular f12 pl-25"> <p class="pl-1">{{element.AccountName}}</p>
                                    </td>
                                </ng-container>
                
                                <ng-container matColumnDef="BrickletPrices">
                                    <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14 th-w pl-12 black"> Price(Including tax) </th>
                                    <td mat-cell *matCellDef="let element" class="roboto-regular f12 pl-25 cornflower-blue"><p class="pl-4"> ${{element.BrickletPrices | number : '1.2-2'}} </p></td>
                                </ng-container>
                
                                
                
                
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                                
                                
                        </div>
                
                
                    <!-- </div> -->
                </div>
                  
                </mat-expansion-panel>
               
              </mat-accordion>
              
              </div>
              

            
            <app-loader class="loader" *ngIf="isLoading"></app-loader>
            
        </div>
        <mat-paginator #paginator class="table mt-2" [length]="resultsLength" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPaginateChange($event)"
        showFirstLastButtons></mat-paginator>
    </div>
</div>