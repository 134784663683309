<div class="d-flex justify-content-between p10 card-container mt20">
  <div
    class="col-6 d-flex justify-content-between"
    [ngClass]="isActive ? 'enabled' : 'disabled'"
  >
    <div class="">
      <img [src]="image" class="card-img" />
    </div>
    <div class="col-12 mt5">
      <label class="roboto-medium f13 slate-grey-two clearfix">{{
        name
      }}</label><br>
      <label class="roboto-regular f12 slate-grey-two clearfix">{{
        date | date: "dd/MMM/yyyy"
      }}</label>
    </div>
  </div>
  <div class="col-1">
    <button
      [disabled]="!isEditEnabled"
      (click)="edit(guid)"
      matTooltip="Edit"
      type="button"
      class="btn-icon"
      mat-icon-button
      matRipple
      [ngClass]="isEditEnabled ? '' : 'opac-3'"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</div>
