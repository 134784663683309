import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { StorageService } from '../../core/utils/storage.service';
import { Router } from '@angular/router';
import { CommonService } from '../../core/utils/common.service';
import { MatDialog } from '@angular/material/dialog';
import { UserRolesService } from '../../core/services/user-roles.service';
import { AuthService } from '../../core/services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Storage } from 'src/app/core/enum/storage';
import { HelperService } from '../../core/utils/helper.service';
import { PatternValidator } from 'src/app/shared/validators/patternvalidator';
import { Subscription } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
    ]),
  ]
})
export class LoginComponent implements OnInit, OnDestroy {

  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private router: Router,
    private commonService: CommonService,
    private dialogRef: MatDialog,
    private userRolesService: UserRolesService,
    public helper: HelperService
  ) {
    this.dialogRef.closeAll();
    this.commonService.hideLoader();
  }

  ///  c ='03AGdBq25i4gK45JtScDEKUo0X2lTdyij4Y7sP2rpyuyV6t8esAH2ST8Yga28TyK2jOnbCK4RXFW8GbolU_V8zkA1ZZinPVSnkcZpqz2FMnVL54Et6R_gSO-bWfeI3tKQxwGlL2HIXoCMSHwRrwj3nKu3TRkwdwASimSJLpsJWiC3HYP2eDy4jJGejC4R0rAw1d6QkFLzO0s2WiOrManv4h9AxpcH1tTIxAbzle627BhR8qDKrT17SrxGBuTwVupXU2_xqfHGRdY-e6UWpj4C_A5nHD1cuEeEQqMYgXKz47f0Ymsm0eeGf-fWykimGvDOr-41fYbAmrrSkVguZL4YtmhF8zpTN1u3enIMi_ckKY65HjgpSoCswBuDorVmDxX8IWWikef-G3yGNFs6JZI2pqzC7hfgzJiK3z0cWDbRXNRAcNeF0MQgZzakOg4iLvhfGTDN_FXdswrUZeiiuTZipLNOiiYs2pCt35GXBn-8DRPvFsBRJzGVP-HfjdlgWs7oasEv0194v-nfi';

  loginForm: FormGroup = new FormGroup({
    txtEmail: new FormControl("", [
      Validators.required,
      Validators.maxLength(60),
      PatternValidator(
        // tslint:disable-next-line:max-line-length
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
    ]),
    txtPassword: new FormControl("", Validators.required),
    // txtRecaptcha: new FormControl("", Validators.required),
  });
  @ViewChild("regRecaptcha", { static: false }) recaptcha!: RecaptchaComponent;
  passwordHide = false;
  @Output() loginEvent = new EventEmitter<boolean>();
  loading = false;
  isLoggedIn = false;
  subscription: any;

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    /// this.storageService.clear();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSignIn() {
    this.loading = true;
    this.commonService.showLoader();
    const data = {
      EmailID: this.f.txtEmail.value,
      Password: this.f.txtPassword.value,
      // Captcha: this.f.txtRecaptcha.value,
    };
    this.authService.login(data).subscribe(
      (res: any) => {
        this.loading = false;
        this.commonService.hideLoader();
        const result: any = res;
        if (result.data !== null) {
          
          if (result.data.isNeed2fa === undefined) {
            this.isLoggedIn = true;
            this.storageService.setItem(
              Storage.AUTH_TOKEN,
              result.data.credential.token
            );
            this.storageService.setItem(
              Storage.REFRESH_TOKEN,
              result.data.credential.refreshToken
            );
            this.storageService.setItem(Storage.IS_LOGGEDIN, "true");
            this.loginEvent.emit(true);
            this.authService.changeStatus(true);
            this.getUserRolePermissions();
          } else {
            this.storageService.setItem(
              Storage.TWOFA_TOKEN,
              result.data.twofa_token
            );
            this.storageService.setItem(
              Storage.ISNEED_2FA,
              result.data.isNeed2fa
            );
            this.storageService.setItem(Storage.ISCONFIG_2FA, "true");
            this.storageService.setItem(Storage.IS_LOGGEDIN, "true");
            this.router.navigate(["/2fa"]);
          }
        } else {
          this.helper.error(result.message);
          this.loginForm.patchValue({
            txtRecaptcha: null,
          });
          this.isLoggedIn = false;
        }
      },
      (error: any) => {
        this.loading = false;
        this.commonService.hideLoader();
        // this.helper.error(error);
        const err = error.message !== undefined ? error.message : error;
        if (err === 'Session Timed Out') {
          this.helper.info(err);
        } else {
          this.helper.error(err);
        }
        // this.recaptcha.reset();
        this.loginForm.patchValue({
          txtRecaptcha: null,
        });
        this.isLoggedIn = false;
      }
    );
  }

  getUserRolePermissions() {
    this.userRolesService.getUserRolePermissions().subscribe(
      (result: any) => {
        let permissionResponse = result.data.data;
        console.log(permissionResponse);
        this.storageService.setItem(Storage.PAGES, JSON.stringify(permissionResponse.Pages));
        this.storageService.setItem(Storage.ROLE, permissionResponse.UserRole.Role);
        this.storageService.setItem(Storage.ROLE_ROUTE,String(permissionResponse.UserRole.Role).replace(" ", "_"));
        localStorage.setItem(Storage.ROLE, permissionResponse.UserRole.Role);
        localStorage.setItem(Storage.ROLE_ROUTE,String(permissionResponse.UserRole.Role).replace(" ", "_"));
        this.userRolesService.getPages(permissionResponse.Pages);
        this.userRolesService.getRole(permissionResponse.UserRole.Role);
        this.userRolesService.getUserEmail(permissionResponse.UserDetails.Email);
        this.userRolesService.navigatePage();
      },
      (error) => {
        // this.helper.error(error);
      }
    );
  }

  get f() {
    return this.loginForm.controls;
  }

  resolved(captchaResponse: string) {
    this.loginForm.patchValue({
      txtRecaptcha: `${captchaResponse}`,
    });
    this.storageService.setItem(Storage.CAPTCHA_TOKEN, `${captchaResponse}`);
  }

  reset() {
    this.recaptcha.reset();
    this.loginForm.patchValue({
      txtRecaptcha: null,
    });
  }

  toggleEye() {
    this.passwordHide = !this.passwordHide;
  }

}
