import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  showSpinner = false;
  constructor(
    private spinnerService: SpinnerService
  ) { }

  ngOnInit() {
    this.spinnerService.spinnerStatus.subscribe(
      (r) => {
        if (r) {
          this.showSpinner = true;
        } else {
          this.showSpinner = false;
        }
      },
      (e) => {
        this.showSpinner = false;
      }
    );
  }

}
