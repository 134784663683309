<div class="bootstrap-wrapper">

<div class="container" >
  
      <div class="row modal-header p5 detail-header-title">
        <div class="col-10 roboto-medium f16 title">{{type}}</div>
        <div class="col-2">
            <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20 title close-title" (click)="onClose()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="row" id="detail-container">
      <div class="col-lg-12 col-md-12 col-sm-12" id="gallery-offer-container" *ngIf="propertyGallery.length > 0">
        <div class="row d-flex flex-row justify-content-between">
          <div class="prop-gallery pos-rel p-0">
            <app-property-detail-gallery-new [propertyId]="propertyDetails?.propertyGUID" [propertyGallery]="propertyGallery" (change)="onChange($event)"></app-property-detail-gallery-new>
            
            <!-- <div class="slider">
              <ng-container *ngFor="let img of propertyGallery;let idx=index;">
                <div class="slider-box" *ngIf="img?.video">
                  <img *ngIf="!videoInPlay" src="/assets/images/video-play.svg" (click)="playVideo()" class="play-icon"
                    alt="">
                  <video class="slider-img" id="videoPlayer" (click)="playVideo()">
                    <source [src]="img?.video">
                  </video>
                </div>
                <div class="slider-box" *ngIf="img.image">
                  <img class="slider-img" src={{img.image}} alt="">
                </div>
              </ng-container>
            </div> -->
          </div>
        </div>
      </div>
  
      <div class="row py-4 px-2 bg-white" id="bottom-img-slider">
        <div class="col-lg-6 col-md-6 col-sm-12 row">
          <div class="col-12 d-flex flex-column">
            <div>
              <h4 class="f32 clr-1">{{propertyDetails?.carMake}} {{propertyDetails?.carModel}}</h4>
              <p class="f12 clr-2">{{propertyDetails?.address}}</p>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <div class="amenities-value">
                <img class="amenities-img" src="/assets/images/floor-icons/floor.svg">
                <span class="size-value">{{propertyDetails?.year}} Year</span>
              </div>
              <!-- <div *ngFor="let a of propertyAmenities" class="amenities-value">
                <img class="amenities-img" [src]="a.iconURL">
                <span class="size-value">{{a.count}}</span>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 row">
          <div class="col-4 d-flex flex-column align-items-center justify-content-between">
            <label for="" class="clr-2 f12 primary-regular">SHARES AVAILABLE</label>
            <label for="" class="clr-1 f32 primary-bold">{{propertyDetails?.sharesAvailable}}</label>
            <!-- <button class="btn btn-primary wishlist-btn outlined" *ngIf="!isFavorite" (click)="onWatch()">
              Add to wishlist
            </button>
            <button class="btn btn-primary wishlist-btn outlined" *ngIf="isFavorite" (click)="onWatch()">
              Remove from wishlist
            </button> -->
          </div>
          <div class="col-4 d-flex flex-column align-items-center justify-content-between bdr">
            <label for="" class="clr-2 f12 primary-regular">Shares Price</label>
            <label for="" class="clr-1 f32 primary-bold">${{propertyDetails?.sharePrice | number : '0.2-2'}}</label>
            <!-- <button class="btn btn-primary" [disabled]="!canBuy()" (click)="onBuyNow();">Place an order</button> -->
          </div>
          <div class="col-4 d-flex flex-column align-items-center justify-content-between">
            <label for="" class="clr-2 f12 primary-regular">Total shares</label>
            <label for="" class="clr-1 f32 primary-bold">{{ propertyDetails?.totalShares }}</label>
            <!-- <button class="btn btn-primary" [disabled]="!canSell()" (click)="submitSellBtnHandler();">Request for sell</button> -->
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <table class="table detail-table">
            <tbody>
              <tr>
                <td>CAR TYPE</td>
                <td>{{propertyDetails?.carType}}</td>
              </tr>
              <tr>
                <td>CAR VALUE</td>
                <td>${{propertyDetails?.carValue | number : '0.2-2'}}</td>
              </tr>
              <!-- <tr>
                <td>Historical Suburb Growth</td>
                <td>{{propertyDetails?.historicalSuburbGrowth}}</td>
              </tr> -->
              <tr>
                <td>ESTIMATE RENT PER MONTH</td>
                <td>${{propertyDetails?.estimatedRentPerMonth | number : '0.2-2'}}</td>
              </tr>
              <tr>
                <td>ESTIMATED YIELD PER YEAR</td>
                <td>${{propertyDetails?.estimatedYieldPerYear | number : '0.2-2'}}</td>
              </tr>
            </tbody>
            <!-- <tfoot>
              <tr>
                <th colspan="2" *ngIf="propertyDetails?.website!==''">
                  <a [href]="propertyDetails?.website" target="_blank">
                    <button class="btn btn-primary">Visit official property website</button>
                  </a>
                </th>
              </tr>
            </tfoot> -->
          </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <table class="table detail-table">
            <tbody>
              <tr>
                <td>SHARES AVAILABLE</td>
                <td>{{propertyDetails?.sharesAvailable}}</td>
              </tr>
              <tr>
                <td>TOTAL SHARES</td>
                <td>{{propertyDetails?.totalShares}}</td>
              </tr>
              <tr>
                <td>SHARE PRICE</td>
                <td>${{propertyDetails?.sharePrice | number : '0.2-2'}}</td>
              </tr>
              <tr>
                <td>ODOMETER</td>
                <td>{{propertyDetails?.odometer}}</td>
              </tr>
              <!-- <tr>
                <td>Gain/Loss(%)</td>
                <td>{{propertyDetails?.brickletPriceIncreaseInPercentage| number : '0.2-2'}}%</td>
              </tr> -->
            </tbody>
            <!-- <tfoot>
              <tr>
                <th colspan="2">
                  <button class="btn btn-primary" (click)="onView()">Visit Shares Price History</button>
                </th>
              </tr>
            </tfoot> -->
          </table>
        </div>
      </div>
  
     
      <div id="about-property">
        <h3 class="primary-bold">ABOUT THIS CAR</h3>
        <p class="primary-regular">
          {{propertyDetails?.aboutThisCar}}
        </p>
      </div>
  
      <div class="d-flex justify-content-between row" id="prop-docs">
        <h3 class="w-100 primary-bold">CAR DOCUMENTS</h3>
        <!-- <empty-state *ngIf="propDocument.length<=0" [description]="'No documents added...'"></empty-state> -->
        <div class="row row align-items-center justify-content-center mt-5 mb-5" *ngIf="propDocument.length<=0">
            <div class="col-xl-1 col-lg-2 col-md-2 col-sm-4 col-5">
                <img class="w-100" [src]="imageSrc" alt="No Records found..">
            </div>
           
        </div>
        <div class="col-lg-12 prop-doc-container">
  
          <table class="table document-table w-100" *ngIf="propDocument.length>0">
            <thead>
              <tr>
                <th class="w-50">
                  <div class="d-flex flex-wrap text-left name">Name </div>
                </th>
                <th class="w-25 desktop-date-cell">
                  <div class="d-flex flex-wrap text-left date">Date</div>
                </th>
                <th class="w-25">
                  <div class="d-flex flex-wrap text-left download">Download</div>
                </th>
              </tr>
            </thead>
            <tbody>
  
              <tr *ngFor="let doc of propDocument; let i=index;">
                <td class="name w-50">
                  <div class="d-flex flex-wrap text-left name"> {{doc.documentName}} </div>
                  <div class="flex-wrap text-left  mobile-date-view secondary-semi-bold f13 generic-palette-b-w-gray">
                    {{doc.documentDate | date : 'dd MMM yyyy'}}
                  </div>
                </td>
                <td class="date w-50 desktop-date-cell">
                  <div class="d-flex flex-wrap text-left date">
                    {{doc.documentDate | date : 'dd MMM yyyy'}}
                  </div>
                </td>
                <td class="download w-50 ps-0">
                  <div class="d-flex download">
                    <a [href]="doc.pdfFileName" target="_blank" class="d-flex">
                      <img src="../../../../assets/images/download-icons/download.svg">
                      <a target="_blank" class="c-pointer  my-auto link"
                        (click)="onDownload(doc.pdfFileName)">Download</a>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
      <div class="mt-4 mb-4 row">


        <agm-map #agmMap
  [latitude]="lat_new"
  [longitude]="lng_new"
  [zoom]="zoom_new"
  [disableDefaultUI]="false"
  [zoomControl]="true"
  >

  <agm-marker 
      *ngFor="let m of markers_new; let i = index"
      (markerClick)="clickedMarker(infowindow)"
      [latitude]="m.lat"
      [longitude]="m.lng">
      
      <agm-info-window #infowindow>
        <strong style="font-weight: bold;">{{propertyDetails?.name}}</strong><br />
        <strong>{{propertyDetails?.address}}</strong><br />
        <strong>{{propertyDetails?.state}}</strong><br />
        <strong>{{propertyDetails?.zipCode}}</strong><br />
        <small *ngIf="propertyDetails?.website"><a (click)="webSiteRedirect()">view on website</a></small>
      </agm-info-window>
    
  </agm-marker>
  
  <!-- <agm-circle [latitude]="lat_new + 0.3" [longitude]="lng_new" 
      [radius]="5000"
      [fillColor]="'red'"
      [circleDraggable]="true"
      [editable]="true">
  </agm-circle> -->

</agm-map>

        <!-- <div class="form-group col-12">
          <mat-form-field class="example-full-width">
              <mat-label>Enter address</mat-label>
        <input type="text"  matInput (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
          </mat-form-field>
      </div>

        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [zoomControl]="true">
          <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
            (dragEnd)="markerDragEnd($event)"  (markerClick)="clickedMarker(infowindow)">
            <agm-info-window #infowindow>
              <strong style="font-weight: bold;">{{propertyDetails?.name}}</strong><br />
              <strong>{{propertyDetails?.address}}</strong><br />
              <strong>{{propertyDetails?.state}}</strong><br />
              <strong>{{propertyDetails?.zipCode}}</strong><br />
              <small *ngIf="propertyDetails?.website"><a (click)="webSiteRedirect()">view on website</a></small>
            </agm-info-window>
          </agm-marker>
        </agm-map> -->

        <!-- <agm-map #agmMap [latitude]="lat" [longitude]="lng" [zoom]="zoom" [zoomControl]="true">
  
  
          <agm-marker *ngFor="let marker of markers" [latitude]="marker.position.lat" [longitude]="marker.position.lng"
            [label]="marker.label" (markerClick)="clickedMarker(infowindow)">
  
            <agm-info-window #infowindow>
              <strong style="font-weight: bold;">{{propertyDetails.name}}</strong><br />
              <strong>{{propertyDetails.address}}</strong><br />
              <strong>{{propertyDetails.state}}</strong><br />
              <strong>{{propertyDetails.zipCode}}</strong><br />
              <small *ngIf="propertyDetails?.website"><a (click)="webSiteRedirect()">view on website</a></small>
            </agm-info-window>
          </agm-marker>
  
        </agm-map> -->
  
      </div>
  
    </div>
    <div class="row mt20 d-flex justify-content-center mb-3">
      <button mat-raised-button class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-8" id="submit" [class.spinner]="isbtnLoading" (click)="onPublishDialog()">Publish</button>
  </div>
  </div>
</div>
  <div class="modal fade mx-auto" id="view-history">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg modal-xl">
      <div class="modal-content">
        <div class="modal-body p-0">
  
          <!-- HEADER -->
          <!-- <div class="px30 d-flex justify-content-between align-items-center flex-row">
            <p class="m-0 exo2-medium f26 dark-blue-grey"></p>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div> -->
  
          <table class="table viewhistory-table">
            <thead>
              <tr>
                <th class="date">
                  <div class="d-flex date-title">Date
                  </div>
                </th>
                <th class="price">
                  <div class="d-flex price-title">
                    Price<span>(per Shares)</span>
                  </div>
                </th>
  
  
              </tr>
            </thead>
            <tbody>
  
              <tr *ngFor="let bricklet of priceInfo; let i=index;">
                <td>
                  <div class="d-flex flex-wrap text-left date"> {{bricklet?.priceDate | date : 'dd-MM-yyyy'}}</div>
                </td>
                <td>
                  <div class="d-flex flex-wrap text-left price">${{bricklet?.price | number : '0.0-2'}}<span
                      class="current" *ngIf="i===0">Current</span></div>
                </td>
              </tr>
              <tr *ngIf="priceInfo.length<=0">
                <td colspan="2">No Records Found</td>
              </tr>
            </tbody>
          </table>
  
  
        </div>
      </div>
    </div>
  </div>