import { Component, OnInit, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() color?: ThemePalette;
  // tslint:disable-next-line:no-inferrable-types
  @Input() diameter?: number = 40;
  @Input() mode?: ProgressSpinnerMode | any;
  @Input() strokeWidth?: number;
  @Input() value?: number;
  @Input() backdropEnabled = true;
  @Input() positionGloballyCenter = true;
  @Input() displayProgressSpinner: boolean = false;

  constructor() {
    this.color = 'primary';
    this.mode = 'indeterminate';
    this.value = 50;
    this.strokeWidth = 4;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

}
