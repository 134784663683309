<div class="bootstrap-wrapper" @fade>
    <div class="container">
        <div class="row">
            <div class="col-10 p0 roboto-medium f16">{{type}}</div>
            <div class="col-2 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20">
                    <mat-icon>close</mat-icon>
                </button> 
            </div>
        </div>
        <form [formGroup]="form">
            <div class="p10 mt10">
                <mat-dialog-content>
                    <div class="d-flex justify-content-center">
                    <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                        <mat-label>Category*</mat-label>
                         <mat-select class="roboto-regular f13" formControlName="categoryguid" >
                             <mat-option [value]="">
                                 Select Category
                             </mat-option>
                             <mat-option *ngFor="let item of categoryList" [value]="item.categoryGUID">{{item.name}}</mat-option>
                         </mat-select>
                     </mat-form-field>
                    </div>
                    <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                            <mat-label>SubCategory Name<span class="star-red">*</span></mat-label>
                            <input matInput formControlName="subcategoryname" placeholder="SubCategory Name" class="roboto-regular f14" autocomplete="off">
                            <mat-error *ngIf="form.get('categoryname')?.hasError('required')">SubCategory Name is required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                            <mat-label>Description<span class="star-red">*</span></mat-label>
                            <input matInput formControlName="description" placeholder="description" class="roboto-regular f14" autocomplete="off">
                            <mat-error *ngIf="form.get('description')?.hasError('required')">Description is required</mat-error>
                        </mat-form-field>
                    </div>
                </mat-dialog-content>

                <mat-dialog-actions class="d-flex justify-content-center pl5 mb10">
                    <button mat-raised-button color="primary" class="col-5 roboto-medium f12" [disabled]="!form.valid || isbtnLoading" [class.spinner]='isbtnLoading'
                        (click)="onSave()">Submit</button>
                </mat-dialog-actions>
            </div>
        </form>
    </div>
</div>