import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { UsersService } from 'src/app/core/services/users.service';
import { KycIndividualComponent } from 'src/app/shared/components/kyc-individual/kyc-individual.component';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FileUploadService } from '../../../core/services/file-upload.service';
import { OnDestroy } from '@angular/core';
import { ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component';
import { UserDetailInterface } from '../../../core/interfaces/users.interface';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FileUploadFilters } from '../../../core/interfaces/file-upload-filter.interface';
import { KycOrgComponent } from '../kyc-org/kyc-org.component';
declare var $: any;

export interface DialogData {
  id: string;
  userType: string;
  update: boolean;
  approve: boolean;
  KycTypeOfUserGUID: string;
  accountGUID: string;
  userRole: string;
  manualapprove: boolean;
}

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  userDetail: UserDetailInterface | null | any = null;
  type: any;
  isbtnUploadLoading = false;
  userGUID: any;

  public uploadPOAForm: FormGroup;

  @ViewChild('fileUpload', { static: false }) fileUpload!: ElementRef;

  docName = 'Image';
  imageFile: File | any = null;
  previewPath = '';
  fileInput: HTMLInputElement | any;
  imgUrl: any = '';
  isUpdateEnable: boolean = false;

  docs: {
    POA: string;
  } = {
    POA: '',
  };
  isbtnResendLoading = false;
  clientNotes: any;

  constructor(
    public dialogRef: MatDialogRef<UserDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: UsersService,
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private helper: HelperService
  ) {
    this.uploadPOAForm = this.fb.group({
      fileName: ['', Validators.required],
      file: ['', Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.isUpdateEnable = this.data.accountGUID === null ? false : this.data.update;
    this.type = this.data.userType;
    this.userGUID = this.data.id;
    this.userService.getUserDetail(this.data.id, this.data.KycTypeOfUserGUID, this.data.accountGUID).subscribe(
      (r: any) => {
        const result: UserDetailInterface = r.data.profile;
        this.userDetail = result;
      },
      (e) => {
        // this.helper.error(e);
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );

    this.userService.currentMessage.subscribe((msg) => {
      if (msg === true) {
        this.userService.getUserDetail(this.data.id, this.data.KycTypeOfUserGUID, this.data.accountGUID).subscribe(
          (r: any) => {
            const result: UserDetailInterface = r.data.profile;
            this.userDetail = result;
          },
          (e) => {
            // this.helper.error(e);
            const errSt = e.message !== undefined ? e.message : e;
            if (errSt === 'Session Timed Out') {
              this.helper.info(errSt);
            } else {
              this.helper.error(errSt);
            }
          }
        );
      }
    });
  }

  ngAfterViewInit() {
    this.fileInput = document.getElementById('file-input') as HTMLInputElement;
  }

  ngOnDestroy() {
    this.dialogRef.close('all');
  }

  onChange(e: any) {
    const selFile: any = (e.target as HTMLInputElement).files;
    if (selFile.length > 0) {
      this.fileUploadService.checkFileStatus(
        e,
        (s: {
          fileType: boolean;
          previewPath: string;
          fileExt: string;
          imageFile: File;
        }) => {
          if (s.fileType) {
            this.imgUrl = '';
            this.imageFile = s.imageFile;
            this.previewPath = s.previewPath;
          }
        }
      );
    }
  }

  updateUserStatus(e: MatSlideToggleChange) {
    const status = e.checked;
    this.userService.updateUserStatus(status, this.data.id).subscribe(
      (s: any) => {
        this.helper.success(s.data.message);
      },
      (err) => {
        // this.helper.error(err);
        const errSt = err.message !== undefined ? err.message : err;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  updatePremiumStatus(e: MatSlideToggleChange) {
    const status = e.checked;
    this.userService.updateUserPremiumStatus(status, this.data.accountGUID).subscribe(
      (s: any) => {
        this.helper.success(s.data.message);
      },
      (err) => {
        // this.helper.error(err);
        const errSt = err.message !== undefined ? err.message : err;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  openKycIndividual(userId: string) {
    const dialogRef = this.dialog.open(KycIndividualComponent, {
      data: { id: userId, title: 'KYC Detail - Individual', btnDisable: true },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // if (result && result.refresh) {
      //   this.getUsers();
      // }
    });
  }

  openKycOrg(userId: string) {
    const dialogRef = this.dialog.open(KycOrgComponent, {
      data: {
        id: userId,
        title: 'KYC Detail - Organization',
        btnDisable: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // if (result && result.refresh) {
      //   this.getUsers();
      // }
    });
  }

  openKycDetail(data: any) {
    if (data.isKYCLevelOne && this.data.userType === 'INDIVIDUAL') {
      this.openKycIndividual(this.data.id);
    } else if (data.isKYCLevelOne && this.data.userType === 'ORGANISATION') {
      this.openKycOrg(this.data.id);
    }
  }

  get f() {
    return this.uploadPOAForm.controls;
  }

  uploadImage() {
    // this.fileUpload.nativeElement.click();
    this.fileInput = document.getElementById('file-input') as HTMLInputElement;
    this.fileInput.click();
  }

  docsFileSubmit(e: any, docType: string): any {
    const fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;

    if (fileList && fileList.length > 0) {
      if (fileList.length > 1) {
        this.helper.warning('Please select one file');
        return false;
      }
      for (const f of fileList) {
        const filters: FileUploadFilters = {
          dimension: null,
          size: 26214400,
          ext: ['pdf'],
          fileType: null,
        };

        this.fileUploadService.uploadFile(f, filters, (fileResult: any) => {
          if (fileResult.status) {
            if (e.dataTransfer) {
              switch (docType) {
                case 'POA':
                  this.uploadPOAForm.patchValue({
                    file: fileList,
                    // fileName: f.name
                  });
                  this.docs.POA = f.name;
                  break;

                default:
                  break;
              }
            }
            switch (docType) {
              case 'POA':
                this.uploadPOAForm.patchValue({
                  file: fileList,
                  // fileName: f.name
                });
                this.docs.POA = f.name;
                break;

              default:
                break;
            }
          } else {
            this.helper.warning(fileResult.msg);
            this.uploadPOAForm.patchValue({
              // fileName: '',
              file: '',
            });
            this.docs.POA = '';
          }
        });
      }
    } else {
      this.uploadPOAForm.patchValue({
        // fileName: '',
        file: '',
      });
      this.docs.POA = '';
    }
  }

  uploadFile() {
    if (this.uploadPOAForm.valid) {
      this.isbtnUploadLoading = true;

      const formData = new FormData();
      formData.append('POA', this.uploadPOAForm.controls.file.value[0]);
      formData.append(
        'FileName',
        this.uploadPOAForm.controls.fileName.value + '.pdf'
      );
      formData.append('UserGUID', this.data.id);

      this.userService.postUploadPOA(formData).subscribe(
        (r: any) => {
          this.isbtnUploadLoading = false;
          const result = r;
          this.helper.success(result.data.message);
          this.userService.changeMessage(true);
          this.uploadPOAForm.patchValue({
            file: '',
            fileName: '',
          });
        },
        (e) => {
          this.isbtnUploadLoading = false;
          // this.helper.error(e);
          const errSt = e.message !== undefined ? e.message : e;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
          this.uploadPOAForm.patchValue({
            file: '',
            fileName: '',
          });
        }
      );
    }
  }

  hasError(fieldName: string) {
    return (
      this.uploadPOAForm.controls[fieldName].touched &&
      this.uploadPOAForm.controls[fieldName].status === 'INVALID'
    );
  }

  hasFieldError(fieldName: string, type: string) {
    const fieldControl: any = this.uploadPOAForm.controls[fieldName].errors;
    return fieldControl[type];
  }

  userResendMail(guid: any) {
    this.isbtnResendLoading = true;
    this.userService.userResendMail(guid).subscribe(
      (res) => {
        const result: any = res;
        if (result.data != null) {
          this.isbtnResendLoading = false;
          const data = result.data;
          this.userService.changeMessage(true);
          this.helper.success(data.message);


        } else {
          this.isbtnResendLoading = false;
          this.helper.error(result.message);
        }
      },
      (error) => {
        this.isbtnResendLoading = false;
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  userNotes(data: any) {
    const message = data;

    const dialogData = new ConfirmDialogModel(
      "Notes",
      message,
      "yes",
      "no",
      "news-title"
    );

    const dialogRef = this.dialog.open(NotesDialogComponent, {
      width: "60%",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {

      }
    });
  }
}
