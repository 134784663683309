import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { Property } from '../interfaces/property';

@Injectable({
  providedIn: 'root'
})
export class LeaseTypeService {
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(private apiManagerService: ApiManagerService, private http: HttpClient) { }



  getAdminLeaseTypeList() {
    return this.apiManagerService.getRequest(RestEnds.leaseTypeList, null);
  }


  uploadPropertyImages(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.propertyImageUpload,
      data
    );
  }
  addLeaseType(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addLeaseType,
      data
    );
  }
  editLeaseType(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.editLeaseType,
      data
    );
  }

  getLeaseType(data: any) {
    return this.apiManagerService.getRequest(RestEnds.getLeaseType + data, null);
  }

  getIsExists(guid: any, name: any) {
    return this.apiManagerService.getRequest(RestEnds.getLeaseTypeIsExist + '?leaseTypeGUID=' + guid + '&description=' + name, null);
  }

  getIsExistsCarOwner(guid: any, name: any) {
    return this.apiManagerService.getRequest(RestEnds.getCarOwnerIsExist + '?carOwnerGUID=' + guid + '&description=' + name, null);
  }

  getIsExistsManager(guid: any, name: any) {
    return this.apiManagerService.getRequest(RestEnds.getCarMangerIsExist + '?carMangerGUID=' + guid + '&description=' + name, null);
  }

  createProperty(data: Property) {
    return this.apiManagerService.postRequest(RestEnds.property, data);
  }

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }
}
