import { Component, OnInit, Input } from '@angular/core';
import { KycConfigService } from 'src/app/core/services/kyc-config.service';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';
import { UbOs } from '../../../core/interfaces/kyc.interface';

@Component({
  selector: 'app-org-ubo',
  templateUrl: './org-ubo.component.html',
  styleUrls: ['./org-ubo.component.scss']
})
export class OrgUboComponent implements OnInit {

  @Input() uboData: UbOs | any;

  personalInfo: UserInfoCardInterface | null = null;
  addressInfo: UserInfoCardInterface | null = null;
  idInfo: UserInfoCardInterface | null = null;
  addressVerifyInfo: UserInfoCardInterface | null = null;

  constructor(
    private kycConfigService: KycConfigService
  ) { }

  ngOnInit() {
    // console.log(this.uboData);
    
    if (this.uboData) {
      const result: any = this.kycConfigService.kycOrgArrangedData(this.uboData);
      this.personalInfo = {
        title: 'Personal Information',
        column: 4,
        type: 'text-only',
        data: result.personalInfo
      };
      this.addressInfo = {
        title: 'Address Information',
        column: 3,
        type: 'text-only',
        data: result.addressInfo
      };

      this.addressVerifyInfo = {
        title: 'Address verification documents',
        column: 0,
        type: 'doc-only',
        data: result.addressVerifyDocs
      };

      this.idInfo = {
        title: 'Identity verification documents',
        column: 0,
        type: 'doc-data-only',
        data: result.idInfo
      };

    }
  }

}
