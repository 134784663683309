import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../utils/config.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(private apiManagerService: ApiManagerService,
    private configService: ConfigService,
    private http: HttpClient) { }

  getUserList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.userlist, data);
  }

  getUserDetail(userId: string, kYCTypeOfUserGUID: string, accountGUID: string) {
    let params: string = "";
    if (accountGUID !== null) {
      params = params + '&accountGUID=' + accountGUID;
    }
    if (kYCTypeOfUserGUID !== null) {
      params = params + '&kYCTypeOfUserGUID=' + kYCTypeOfUserGUID;
    }
    return this.apiManagerService.getRequest(RestEnds.userdetail + '?userGUID=' + userId + params, null);
  }

  getKycLevelOne(userId: string, kYCTypeOfUserGUID: string) {
    return this.apiManagerService.getRequest(RestEnds.kyclevel1 + '?userGUID=' + userId + '&kYCTypeOfUserGUID=' + kYCTypeOfUserGUID, null);
  }

  
  postUploadPOA(data: any) {
    return this.apiManagerService.postRequest(RestEnds.uploadPOA, data);
  }
  // acceptKycLevelOne(userId: string) {
  //   return this.apiManagerService.putRequest(RestEnds.acceptKycLevel1 + '?userGUID=' + userId, null);
  // }

  // acceptKycLevelTwo(userId: string) {
  //   return this.apiManagerService.putRequest(RestEnds.acceptKycLevel2 + '?userGUID=' + userId, null);
  // }

  rejectKycLevelOne(data: { UserGUID: string; Reason: string; Level: Number; KYCTypeOfUserGUID: string }) {
    return this.apiManagerService.putRequest(RestEnds.rejectKYC, data as any);
  }

  rejectKycLevelTwo(data: { UserGUID: string; Reason: string; Level: Number }) {
    return this.apiManagerService.putRequest(RestEnds.rejectKYC, data as any);
  }

  updateUserStatus(status: boolean, userId: string) {
    return this.apiManagerService.putRequest(RestEnds.changeUserStatus + '?isActive=' + status + '&userGUID=' + userId, null);
  }

  updateUserPremiumStatus(status: boolean, userId: string) {
    return this.apiManagerService.putRequest(RestEnds.changePremiumStatus, {
      AccountGUID: userId,
      IsPermium: status
    } as any);
  }

  approveKyc(data: any) {
    return this.apiManagerService.putRequest(RestEnds.approveKYC, data);
  }

  getKYCRejectReason(guid: any, level: any) {
    return this.apiManagerService.getRequest(RestEnds.rejectReasonKYC + '?kYCTypeOfUserGUID=' + guid + '&Level=' + level, null);
  }

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }

  getUserRoles() {
    return this.apiManagerService.getRequest(RestEnds.userRoles, null);
  }

  createUser(data: any) {
    return this.apiManagerService.postRequest(RestEnds.addUser, data);
  }


  userResendMail(guid: string){
    return this.apiManagerService.getRequest(RestEnds.userResendMail + '?userGUID=' + guid, null);
  }

  getUserKYCDetail(data: any){
    return this.apiManagerService.getRequest(RestEnds.userKycDetail + '?email=' + data, null);
  }

  updateManager(userGUID: string, accountGUID: string) {
    return this.apiManagerService.putRequest(RestEnds.updateManager, {
      AccountGUID: accountGUID,
      UserGUID: userGUID
    } as any);
  }


  getKYCImageDecrypt(filename: any) {
    return this.http.get(`${this.configService.getApiBaseUrl() + RestEnds.getKYCProofDecrypt}?filename=` + filename, { observe: 'body', responseType: 'blob' as 'json' });
  }

  // get users for property owner mapping
  getUsersForPropertyOwnerMapping() {
    return this.apiManagerService.getRequest(RestEnds.getUsersForPropertyOwnerMapping, null);
  }

  getAccountListForProductOwnerMapping(guid: any){
    return this.apiManagerService.getRequest(`${RestEnds.getAccountListForPropertyOwnerMapping + guid}`, null);
  }

  //get user owned proeprty list
  getUserOwnedProperty(data: any){
    return this.apiManagerService.postRequest(`${RestEnds.getuserOwnedProperty}`, data);
  }
}
