import { Component, OnInit, Input } from '@angular/core';
import { KycConfigService } from '../../../core/services/kyc-config.service';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';
import { PrivateCompany } from '../../../core/interfaces/kyc.interface';

@Component({
  selector: 'app-org-private-company',
  templateUrl: './org-private-company.component.html',
  styleUrls: ['./org-private-company.component.scss']
})
export class OrgPrivateCompanyComponent implements OnInit {

  @Input() privateCompanyData: PrivateCompany | any;

  personalInfo: UserInfoCardInterface | null = null;
  addressInfo: UserInfoCardInterface | null = null;
  idInfo: UserInfoCardInterface | null = null;
  addressVerifyInfo: UserInfoCardInterface | null = null;

  basicInfo: UserInfoCardInterface | null = null;
  regOffice: UserInfoCardInterface | null = null;
  businessInfo: UserInfoCardInterface | null = null;
  
  basicSoleTraderInfo: UserInfoCardInterface | null = null;

  constructor(
    private kycConfigService: KycConfigService
  ) { }

  ngOnInit() {
    
      const result = this.kycConfigService.kycArrangePrivateData(this.privateCompanyData);

      this.basicInfo = {
        title: 'Private Company',
        column: 12,
        type: 'doc-data-text-only',
        data: result.basicInfo
      };

      this.regOffice = {
        title: 'Registered Office',
        column: 3,
        type: 'text-only',
        data: result.regOffice
      };

      this.businessInfo = {
        title: 'Principal Place Of Business',
        column: 3,
        type: 'text-only',
        data: result.businessInfo
      };
    
  }

}
