import { Component, OnInit, Input } from '@angular/core';
import { KycConfigService } from '../../../core/services/kyc-config.service';
import { PublicCompany } from '../../../core/interfaces/kyc.interface';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';

@Component({
  selector: 'app-org-public-company',
  templateUrl: './org-public-company.component.html',
  styleUrls: ['./org-public-company.component.scss']
})
export class OrgPublicCompanyComponent implements OnInit {

  @Input() publicCompanyData: PublicCompany | any;

  personalInfo: UserInfoCardInterface | null = null;
  addressInfo: UserInfoCardInterface | null = null;
  idInfo: UserInfoCardInterface | null = null;
  addressVerifyInfo: UserInfoCardInterface | null = null;

  basicInfo: UserInfoCardInterface | null = null;
  regOffice: UserInfoCardInterface | null = null;
  businessInfo: UserInfoCardInterface | null = null;
  
  basicSoleTraderInfo: UserInfoCardInterface | null = null;

  constructor(
    private kycConfigService: KycConfigService
  ) { }

  ngOnInit() {
    

      const result: any = this.kycConfigService.kycOrgArrangedPublicCompanyData(this.publicCompanyData);
      this.basicInfo = {
        title: 'Public Company',
        column: 6,
        type: 'text-only',
        data: result.basicPublicCompanyInfo
      };
      // console.log(this.basicInfo);
    
  }

}
