import { Component, OnInit, Input } from '@angular/core';
import { KycConfigService } from '../../../core/services/kyc-config.service';
import { PartnerShip } from '../../../core/interfaces/kyc.interface';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';

@Component({
  selector: 'app-org-partnership',
  templateUrl: './org-partnership.component.html',
  styleUrls: ['./org-partnership.component.scss']
})
export class OrgPartnershipComponent implements OnInit {

  @Input() partnershipData: PartnerShip | any;

  personalInfo: UserInfoCardInterface | null = null;
  addressInfo: UserInfoCardInterface | null = null;
  idInfo: UserInfoCardInterface | null = null;
  addressVerifyInfo: UserInfoCardInterface | null = null;

  basicInfo: UserInfoCardInterface | null = null;
  regOffice: UserInfoCardInterface | null = null;
  businessInfo: UserInfoCardInterface | null = null;
  partnerTypeInfo: UserInfoCardInterface | null = null;

  isPrivate = false;

  constructor(
    private kycConfigService: KycConfigService
  ) { }

  ngOnInit() {

    const businessInfo = this.kycConfigService.privateAddressInfo(this.partnershipData.principalPlaceOfBusiness);
      this.basicInfo = {
        title: 'PartnerShip',
        column: 12,
        type: 'doc-data-text-only',
        data: [
          {  text: 'ABN', value: this.partnershipData.abn, img: null },
          {  text: 'Partnership Name', value: this.partnershipData.partnershipName, img: null },
          {  text: 'Partnership Agreement', value: null, img: this.partnershipData.partnershipAgreementURL }
        ]
      };
      this.businessInfo = {
        title: 'Principal Place Of Business',
        column: 3,
        type: 'text-only',
        data: businessInfo
      };
      this.partnerTypeInfo = {
        title: '',
        column: 12,
        type: 'type-text-only',
        data: [
          {  text: 'Partner Type', value: this.partnershipData.partnerType, img: null }
         
        ]
      };
      

    if (this.partnershipData.individual) {
      this.isPrivate = false;

      const result: any = this.kycConfigService.kycOrgArrangedData(this.partnershipData.individual);
      
      
      this.personalInfo = {
        title: 'Personal Information',
        column: 4,
        type: 'text-only',
        data: result.personalInfo
      };
      this.addressInfo = {
        title: 'Address Information',
        column: 3,
        type: 'text-only',
        data: result.addressInfo
      };

      this.addressVerifyInfo = {
        title: 'Address verification documents',
        column: 0,
        type: 'doc-only',
        data: result.addressVerifyDocs
      };

      this.idInfo = {
        title: 'Identity verification documents',
        column: 0,
        type: 'doc-data-only',
        data: result.idInfo
      };
    }

  }

}
