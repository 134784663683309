import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef, Inject, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss'],
  animations: [trigger('detail', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(500, style({ opacity: 1 }))
    ])
  ])]
})
export class PropertyDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() guid: any = null;
  form!: FormGroup;
  type: any;
  coverImage: any;
  propertyDetails: any;
  addressInfo: any;
  // @ViewChild(HistoricalGrowthChartComponent, { static: true }) HistoricalGrowthChartComp;
  index: any;
  isLoading = false;
  chart: any;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private helper: HelperService,
    private dialog: MatDialog
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    // this.coverImage = '../../../../assets/images/home-grow-market.png';
  }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.cdr.detectChanges();
  }


  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  onClose() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  formatChartData(chartData: any) {
    if (chartData.length > 0) {
      // this.HistoricalGrowthChartComp.generateChart(chartData);
    }
  }

  onChange(idx: any) {
    this.index =  idx;
  }

  // load(data) {
  //   console.log('check');
  //   console.log(data);
  //   this.chart = data;
  //  // this.formatChartData(this.chart);
  //   // if (this.chart.length > 0) {
  //   //   this.HistoricalGrowthChartComp.generateChart(this.chart);
  //   // }
  // }

  getDate(data: any) {
    // console.log(data);
    // console.log(moment.utc(data).local().format('DD-MM-YYYY HH:mm a'));
    return data === null ? null : moment.utc(data).local().format('DD-MM-YYYY hh:mm a');
  }


}

