import { Component, OnInit, Input, ChangeDetectorRef, Inject, AfterViewInit, OnDestroy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadImageService } from '../../../core/services/upload-image.service';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { StaticMessage, UploadImageOptions } from '../../../core/enum/constants';
import { imageUploadConfig } from '../../../core/interfaces/image-upload.interface';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() type: any = null;
  typename: any;
  public form: FormGroup;
  showImg = false;
  isFileSelected = false;
  fileList: any;
  fileType: any;
  imageSrc: any = [];
  imageList: ImageFiles[] = [];
  GUID = null;
  isbtnLoading = false;
  isExists = false;
  color: ThemePalette = 'primary';
  lblStatus = 'InActive';
  @Output() outputEvent = new EventEmitter<any>();
  @Output() outputAddEvent = new EventEmitter<any>();

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private uploadImageService: UploadImageService,
    private helperService: HelperService
  ) {
    this.form = this.formbuilder.group({
      guid: [''],
      imageName: ['', Validators.required],
      files: ['',Validators.required],
      imageURL: [null],
      isActive: [true]
    });
    this.dialogRef.disableClose = true;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void { }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.typename = this.data.name;
    this.GUID = this.data.guid;
    if (this.GUID !== null) {
      // this.getDetailByGUID(this.GUID);
    }
    this.cdr.detectChanges();
  }
  ngOnDestroy() {
    this.dialogRef.close();
  }
  onClose() {
    this.dialogRef.close();
  }

  public submit(e: any) {
    // console.log('files:', e.dataTransfer.files);
    // this.uploadImage(e);
    this.uploadImage_new(e);
  }

  handleInputChange(e: any) {
    // this.uploadImage(e);
    this.uploadImage_new(e);
  }

  uploadImage(e: any): any {
    this.fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    this.fileType = null;
    const reader = new FileReader();
    const filelist = [];
    if (this.fileList.length > 1) {
      this.helperService.warning('Please select one image');
      return false;
    }

    if (this.fileList.length > 0) {
      
      for (const item of this.fileList) {
        this.fileType = item.type;
        const fileSize = item.size;
        filelist.push(item);

        if (
          this.fileType !== 'image/png' &&
          this.fileType !== 'image/jpg' &&
          this.fileType !== 'image/jpeg'
        ) {
          this.helperService.error(StaticMessage.ImageFormat);
          e.target.value = null;
          return false;
        }
        if (fileSize > 1048576) {
          this.helperService.error(
            'Please upload image size not more than 1 MB'
          );
          e.target.value = null;
          return false;
        }

        this.showImg = false;
        this.imageSrc = [];
        this.imageList = []; // Single image upload
        this.isFileSelected = false;

        this.imageList.push({
          imageType: this.findAndReplace(this.fileType, 'image/', '.'),
          imageFile: item
        });

        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(item);
      }
    }
  }

  uploadImage_new(e: any): any {
    this.fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    const reader = new FileReader();
    const filelist = [];
    const config: imageUploadConfig = {
      dimension: {
        width: UploadImageOptions.width,
        height: UploadImageOptions.height,
        operation: UploadImageOptions.operation
      },
      size: UploadImageOptions.size,
      fileExt: UploadImageOptions.exts,
      fileType: null
    };
    if (this.fileList.length > 1) {
      this.helperService.warning('Please select one image');
      return false;
    }

    if (this.fileList.length > 0) {

      for (const item of this.fileList) {
        filelist.push(item);
        this.showImg = false;
        this.imageSrc = [];
        this.imageList = []; // Single image upload
        this.isFileSelected = false;


        this.uploadImageService.uploadImage(item, config, reader, (result: any) => {
          if (result.status) {
            this.imageList.push({
              imageType: result.type,
              imageFile: result.imageFile
            });
            this.form.patchValue({
              files: this.fileList
            });
            this.imageSrc.push(result.imagePreview);
            this.isFileSelected = true;
          } else {
            this.helperService.warning(result.msg);
            this.form.patchValue({
              files: ''
            });
          }
        });
      }
      this.cdr.markForCheck();
    }
  }

  _handleReaderLoaded(e: any) {
    const reader = e.target;
    this.imageSrc.push(reader.result);
    this.isFileSelected = true;
  }

  // tslint:disable-next-line:variable-name
  findAndReplace(string: any, target: any, replacement: any) {
    return (string = string.replace(target, replacement));
  }



  get f() {
    return this.form.controls;
  }




  onSave() {
    this.isbtnLoading = true;
    this.outputAddEvent.emit(this.form);
  }



  onUpdate() {
    this.isbtnLoading = true;
    this.outputEvent.emit(this.form);
  }





  onToggle(event: any) {
    if (event.checked) {
      this.lblStatus = 'Active';
    } else {
      this.lblStatus = 'InActive';
    }
  }


  checkValid(){
    // console.log(!this.form.valid+' || '+ this.isbtnLoading);

    return !this.form.valid || this.isbtnLoading;
  }

  UpdatecheckValid(){
    // console.log(!this.form.valid +'&&'+ !(this.f.imageURL.value!= null || this.imageList.length > 0) +'||'+ this.isbtnLoading);

    return !this.form.valid && !(this.f.imageURL.value!= null || this.imageList.length > 0) || this.isbtnLoading;
  }
}

export interface ImageFiles {
  imageType: string;
  imageFile: any;
}
