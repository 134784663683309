<div class="col-12 mt20 mb10">
    <div class="mat-elevation-z8 bg-light-grey-two">
      <div class="pt10 pb10 bootstrap-wrapper">
        <form [formGroup]="filterForm">
          <div class="row col-12">
            <div class="col-md-10 col-sm-12 row">
            <mat-form-field class="col-md-3 col-sm-12 f14">
              <input
                matInput
                formControlName="startDate"
                [matDatepicker]="fromDate"
                placeholder="From Date"
                class="roboto-regular f14"
                (dateInput)="fromDateInput($event)" readonly
                #from
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fromDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-md-3 col-sm-12 f14">
              <input
                matInput
                formControlName="endDate"
                [matDatepicker]="endDate"
                [min]="minDate"
                placeholder="To Date"
                class="roboto-regular f14" readonly
                #to
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
  
            <mat-form-field class="col-md-4 col-sm-12 f14">
              <mat-label>User email</mat-label>
              <input matInput type="text" formControlName="emailSearch" />
              <mat-error *ngIf="!email.valid">
                  {{getEmailError()}}
              </mat-error>
            </mat-form-field>
  
            <mat-form-field class="col-md-3 col-sm-12 f14">
              <mat-label>Car ID</mat-label>
              <input matInput type="text" formControlName="propertyCode" />
            </mat-form-field>
  
            <mat-form-field class="col-md-3 col-sm-12 f14">
              <mat-label>Transaction Type</mat-label>
              <mat-select formControlName="Transtype">
                <mat-option *ngFor="let f of FilterBy" [value]="f.value">{{
                  f.text
                }}</mat-option>
              </mat-select>
            </mat-form-field>
  
  
            <div class="col-md-3 col-sm-12 f14  custom-slider">
          
             
              <ngx-slider  [(value)]="value" [(highValue)]="highValue" [options]="options"></ngx-slider>
            
       
            </div>
  
            
            <!-- <mat-card-actions align="center" class="col-3 row">
              <div class="col-6">
                <button
                  mat-raised-button
                  class="roboto-medium f12"
                  color="primary"
                  (click)="filterRecords()"
                >
                  Filter
                </button>
              </div>
              <div class="col-6">
                <button
                  mat-raised-button
                  class="bg-dusty-orange white roboto-medium f12"
                  color="warning"
                  (click)="reset()"
                >
                  Reset
                </button>
              </div>
            </mat-card-actions> -->
          </div>
          <div class="col-md-2 col-sm-12 row">
         
              <div class="col-md-12 col-sm-12 pb-10">
                <button
                  mat-raised-button
                  class="roboto-medium f12 filter-btn"
                  color="primary"
                  (click)="filter()"
                >
                  Filter
                </button>
              </div>
    
              <div class="col-md-12 col-sm-12">
                <button
                  mat-raised-button
                  class="bg-dusty-orange filter-btn white roboto-medium f12"
                  color="warning"
                  (click)="reset()"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
  
          
        </form>
      </div>
    </div>
  </div>
  
  <div class="mat-elevation-z8 scroll-x">
    <table mat-table [dataSource]="dataSource">
      <!-- Position Column -->
      <ng-container matColumnDef="refundDate">
        <th mat-header-cell *matHeaderCellDef>Refund Date</th>
        <td mat-cell *matCellDef="let element">{{ element.refundDate | date: 'dd-MMM-yyyy' }}</td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th> 
        <td mat-cell *matCellDef="let element">
          <div class="user-container">
            <div class="right-container">
          {{ element.email }}
        
            </div>
          </div>
  
        </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="carID">
        <th mat-header-cell *matHeaderCellDef>Car ID</th>
        <td mat-cell *matCellDef="let element">{{ element.code }}</td>
      </ng-container>
  
  
      <!-- <ng-container matColumnDef="accountName">
        <th mat-header-cell *matHeaderCellDef>Account Name</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex tooltip-container">
            <span class="value account-name" tooltip="{{element.accountName}}" placement="right" delay="100">{{element.accountName}} </span>
            <img src="../../../../assets/icons/building-ico-alt.png" *ngIf="element?.userType !== 'INDIVIDUAL'"/>
            <img src="../../../../assets/icons/individual-alt.png" *ngIf="element?.userType === 'INDIVIDUAL'"/> 
          </div>
        </td>
      </ng-container> -->
  
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">
          <div class="fullName-container">
            <div class="right-container">
          {{ element.name }}
            </div>
          </div>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="carMake">
        <th mat-header-cell *matHeaderCellDef>Car Make</th>
        <td mat-cell *matCellDef="let element">{{ element.carMake }}</td>
      </ng-container>
  
      <ng-container matColumnDef="carModel">
        <th mat-header-cell *matHeaderCellDef>Car Model</th>
        <td mat-cell *matCellDef="let element">{{ element.carModel }}</td>
      </ng-container>
  
      <!-- Symbol Column -->
      <!-- <ng-container matColumnDef="brickletID">
        <th mat-header-cell *matHeaderCellDef>Shares ID</th>
        <td mat-cell *matCellDef="let element">
          <div class="brickletID-container">
            <div class="right-container">
          {{ element.brickletID }}
            </div>
          </div>
  
        </td>
      </ng-container> -->
  
      <!-- <ng-container matColumnDef="brickletPrice">
        <th mat-header-cell *matHeaderCellDef>Shares Price</th>
        <td mat-cell *matCellDef="let element">${{ element.amount | number: "1.0-2"}}</td>
      </ng-container> -->
  
      <!-- <ng-container matColumnDef="fee">
        <th mat-header-cell *matHeaderCellDef>Fee</th>
        <td mat-cell *matCellDef="let element">${{ element.fee | number: "1.0-2"}}</td>
      </ng-container> -->
  
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let element">{{ element.quantity | number: "1.0-2"}}</td>
      </ng-container>
  
      <ng-container matColumnDef="refundAmount">
        <th mat-header-cell *matHeaderCellDef>Refund Amount</th>
        <td mat-cell *matCellDef="let element">${{ element.refundAmount | number: "1.0-2"}}</td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  
    <app-loader *ngIf="isLoading"></app-loader>
    <span *ngIf="length === 0">
        <div *ngIf="length === 0" class="roboto-medium f14 text-center mt5">No records found</div>
    </span>
  
  
  </div>
  <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="onPaginateChange($event)"
  showFirstLastButtons
  ></mat-paginator>