import { Injectable } from '@angular/core';
import { LeaseTypeService } from './leasetype.service';
import { PropertyDeveloperService } from './propertydeveloper.service';
import { PropertyManagerService } from './propertymanager.service';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { LeaseType, PropertyDeveloper, PropertyManager, StaticSuccessMessage, UploadImage } from '../enum/constants';
import { CommonService } from '../utils/common.service';
import { HelperService } from '../utils/helper.service';

@Injectable({
    providedIn: 'root'
})
export class UploadImageService {

    dialogRef: any;
    imageList: any = [];
    isExists = false;
    form!: FormGroup;
    leaseType = LeaseType;
    propertyDeveloper = PropertyDeveloper;
    propertyManager = PropertyManager;
    constructor(
        private leasetypeService: LeaseTypeService,
        private propertyDeveloperService: PropertyDeveloperService,
        private propertyManagerService: PropertyManagerService,
        private commonService: CommonService,
        private helper: HelperService

    ) { }



    onSave(data: any, typevalue: any, typename: any, dialogComponent: any) {
        this.form = data;
        this.dialogRef = dialogComponent;
        this.dialogRef.componentInstance.isbtnLoading = true;
        this.imageList = this.dialogRef.componentInstance.imageList;
        this.isExists = false;
        this.getisExists(typevalue, this.f.guid.value, this.f.imageName.value).subscribe(
            (res: any) => {
            // console.log(res);
            const result: any = res;
            if (res !== null) {
                this.isExists = result.data.isExist;
                if (this.isExists === false) {
                    const formData = new FormData();
                    formData.append('Description', this.f.imageName.value);
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < this.imageList.length; i++) {
                        formData.append('logo', this.imageList[i]?.imageFile);
                    }
                      formData.append('isActive',this.f.isActive.value);
                    this.save(formData, typevalue, typename);
                } else {
                    this.dialogRef.componentInstance.isbtnLoading = false;
                    this.helper.error('already name exists! ');
                }
            } else {
                this.dialogRef.componentInstance.isbtnLoading = false;
                this.helper.error(result.message);
            }
        },
            (error: any) => {
                this.dialogRef.componentInstance.isbtnLoading = false;
               // this.helper.error(error);
                const err = error.message !== undefined ? error.message : error;
                if (err === 'Session Timed Out') {
                  this.helper.info(err);
                } else {
                  this.helper.error(err);
                }
            });
    }

    getisExists(typevalue: any, guid: any, imageName: any): any{
        if (this.leaseType.addtypevalue === typevalue) {
            return this.leasetypeService
                .getIsExists(guid, imageName);
        } else if (this.propertyDeveloper.addtypevalue === typevalue) {
            return this.propertyDeveloperService
                .getIsExists(guid, imageName);
        } else if (this.propertyManager.addtypevalue === typevalue) {
            return this.propertyManagerService
                .getIsExists(guid, imageName);
        }
    }

    save(data: any, typevalue: any, typename: any) {
        this.getAddEvent(data, typevalue).subscribe(
            (res: any) => {
                this.dialogRef.componentInstance.isbtnLoading = false;
                const result: any = res;
                if (result.data != null) {
                    this.helper.success(
                        typename + ' ' + StaticSuccessMessage.save
                    );
                    if (typename === this.leaseType.typename) {
                        this.leasetypeService.changeMessage(true);
                    } else if (typename === this.propertyDeveloper.typename) {
                        this.propertyDeveloperService.changeMessage(true);
                    } else if (typename === this.propertyManager.typename) {
                        this.propertyManagerService.changeMessage(true);
                    }
                    this.form.reset();
                    this.imageList = [];
                    this.dialogRef.componentInstance.showImg = false;
                    this.dialogRef.componentInstance.form.reset();
                    this.dialogRef.componentInstance.imageList = [];
                    this.dialogRef.componentInstance.imageSrc = [];
                    this.dialogRef.componentInstance.isFileSelected = false;
                    this.dialogRef.close();
                } else {
                    this.dialogRef.componentInstance.isbtnLoading = false;
                    this.helper.error(result.message);
                }
            },
            (error: any) => {
              this.dialogRef.componentInstance.isbtnLoading = false;
              // this.helper.error(error);
              const err = error.message !== undefined ? error.message : error;
              if (err === 'Session Timed Out') {
                this.helper.info(err);
              } else {
                this.helper.error(err);
              }
            }
        );
    }


    getAddEvent(data: any, typevalue: any): any {
        if (typevalue === this.leaseType.addtypevalue) {
            return this.leasetypeService.addLeaseType(data);
        } else if (typevalue === this.propertyDeveloper.addtypevalue) {
            return this.propertyDeveloperService.addPropertyDeveloper(data);
        } else if (typevalue === this.propertyManager.addtypevalue) {
            return this.propertyManagerService.addPropertyManager(data);
        }
    }

    get f() {
        return this.form.controls;
    }


    uploadImage(file: File, config: any, reader: FileReader, callback: any) {
        let fileType = file.type;
        const fileSize = file.size;
        const imageType = this.findAndReplace(fileType, 'image/', '.');
        const maxFileSize = config.size === null ? null : this.getSizeText(config.size);
        const validExts = this.checkExt(imageType, config.fileExt);
        const validFileSize = maxFileSize === null ? true : (maxFileSize < fileSize) ? false : true;
        const dimension = config.dimension;

        if (!validExts) {
            callback({
                status: false,
                msg: UploadImage.invalidExtension + ' ' + UploadImage.imageFormat + config.fileExt
            });
        }
        if (!validFileSize) {
            callback({
                status: false,
                msg: 'Max image size should be ' + maxFileSize
            });
        }

        if (validExts && validFileSize) {
            reader.onload = (e: any) => {
                const target = e.target;
                const fileResult = target.result;


                if (dimension.height !== null && dimension.width !== null && dimension.operation !== '') {
                    const image: any = new Image();
                    image.src = typeof fileResult === 'string' ? fileResult : null;
                    image.onload = () => {
                        switch (dimension.operation) {
                            case 'min':
                                if ((dimension.height > image.height) || (dimension.width > image.width)) {
                                    callback({
                                        status: false,
                                        msg: 'Image minimum resolution should be ' + dimension.width + 'x' + dimension.height
                                    });
                                } else {
                                    callback({
                                        imagePreview: fileResult,
                                        status: true,
                                        msg: 'Upload Successfully',
                                        imageFile: file,
                                        type: imageType
                                    });
                                }
                                break;
                            case 'max':
                                if ((dimension.height < image.height) || (dimension.width < image.width)) {
                                    callback({
                                        status: false,
                                        msg: 'Image maximum resolution should be ' + dimension.width + 'x' + dimension.height
                                    });
                                } else {
                                    callback({
                                        imagePreview: fileResult,
                                        status: true,
                                        msg: 'Upload Successfully',
                                        imageFile: file,
                                        type: imageType
                                    });
                                }
                                break;
                        }
                    };
                } else {

                    callback({
                        imagePreview: fileResult,
                        status: true,
                        msg: 'Upload Successfully',
                        imageFile: file,
                        type: imageType
                    });
                }
            }
            reader.readAsDataURL(file);
        }


    }


    uploadiconImage(file: File, config: any, reader: FileReader, callback: any) {
        let fileType = file.type;
        const fileSize = file.size;
        const imageType = this.findAndReplace(fileType, 'image/', '.');
        const maxFileSize = config.size === null ? null : this.getSizeText(config.size);
        const validExts = this.checkExt(imageType, config.fileType);
        const validFileSize = maxFileSize === null ? true : (maxFileSize < fileSize) ? false : true;
        const dimension = config.dimension;

        if (!validExts) {
            callback({
                status: false,
                msg: UploadImage.invalidExtension + ' ' + UploadImage.imageFormat + config.fileExt
            });
        }
        if (!validFileSize) {
            callback({
                status: false,
                msg: 'Max image size should be ' + maxFileSize
            });
        }

        if (validExts && validFileSize) {
            reader.onload = (e: any) => {
                const target = e.target;
                const fileResult = target.result;


                if (dimension.height !== null && dimension.width !== null && dimension.operation !== '') {
                    const image: any = new Image();
                    image.src = typeof fileResult === 'string' ? fileResult : null;
                    image.onload = () => {
                        switch (dimension.operation) {
                            case 'min':
                                if ((dimension.height > image.height) || (dimension.width > image.width)) {
                                    callback({
                                        status: false,
                                        msg: 'Image minimum resolution should be ' + dimension.width + 'x' + dimension.height
                                    });
                                } else {
                                    callback({
                                        imagePreview: fileResult,
                                        status: true,
                                        msg: 'Upload Successfully',
                                        imageFile: file,
                                        type: imageType
                                    });
                                }
                                break;
                            case 'max':

                                if ((dimension.height < image.height) || (dimension.width < image.width)) {
                                    callback({
                                        status: false,
                                        msg: 'Image maximum resolution should be ' + dimension.width + 'x' + dimension.height
                                    });
                                } else {
                                    callback({
                                        imagePreview: fileResult,
                                        status: true,
                                        msg: 'Upload Successfully',
                                        imageFile: file,
                                        type: imageType
                                    });
                                }
                                break;
                        }
                    };
                } else {

                    callback({
                        imagePreview: fileResult,
                        status: true,
                        msg: 'Upload Successfully',
                        imageFile: file,
                        type: imageType
                    });
                }
            }
            reader.readAsDataURL(file);
        }


    }


    checkExt(fileExt: string, filterExts: string[]) {
        const result = filterExts.filter(element =>
            element.toUpperCase() === fileExt.toUpperCase()
        );
        if (result.length > 0) {
            return true;
        } else {
            return false;
        }
    }



    findAndReplace(string: any, target: any, replacement: any) {
        return (string = string.replace(target, replacement));
    }


    getSizeText(bytes: any) {
        if (bytes >= 1073741824) {
            bytes = (bytes / 1073741824).toFixed(2) + ' GB';
        } else if (bytes >= 1048576) {
            bytes = (bytes / 1048576).toFixed(2) + ' MB';
        } else if (bytes >= 1024) {
            bytes = (bytes / 1024).toFixed(2) + ' KB';
        } else if (bytes > 1) {
            bytes = bytes + ' bytes';
        } else if (bytes === 1) {
            bytes = bytes + ' byte';
        } else {
            bytes = '0 bytes';
        }
        return bytes;
    }
}
