<div *ngIf="propertyGallery.length > 0" id="carouselExampleControls" class="carousel slide mb-4 carousel-box"
    data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item slider" *ngFor="let img of _propertyGallery;let idx=index;"
        [ngClass]="{'active' : idx == counter}" [@thumbState]="idx === counter ? 'active' : 'inactive'">

        <div class="slider-box" *ngIf="img?.video">
          <img *ngIf="!videoInPlay" src="/assets/images/video-play.svg" (click)="playVideo()" class="play-icon"
            alt="">
          <video class="slider-img" id="videoPlayer" (click)="playVideo()">
            <source [src]="img?.video">
          </video>
          
         </div>
       <!-- <div class="slider-box" *ngIf="img.image">
          <img class="slider-img" src={{img.image}} alt="">
        </div> -->
          <div class="slider-box" *ngIf="img.image">
        <img class="slider-img d-block w-100" src={{img.image}} alt="property image">
        </div>
        <!-- <button  class="btn btn-watching btn-info col-lg-3 offset-lg-8" (click)="onWatch()">
          <img src="../../../../assets/images/watch-icon.svg">Watch Property</button>
      </div> -->
    </div>
    <!-- <a class="carousel-control-prev"  role="button" data-slide="prev" (click)="prev()">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next"  role="button" data-slide="next" (click)="next()">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a> -->
  </div>

</div>



  <!-- <div class="col-12 carousel-box p0">
    <div [@thumbState]="idx === counter ? 'active' : 'inactive'" class="tmb" *ngFor="let img of propertyGallery; let idx = index">
        <img class="d-block w-100" src={{img.image}} alt="property image">
      </div>

    <a class="carousel-control-prev"  role="button" data-slide="prev" (click)="prev()">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next"  role="button" data-slide="next" (click)="next()">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div> -->



  <div class="bootstrap-wrapper">
  <div class="container">
    <div slickContainer #slickController="slick" [slickConfig]="config" class="thumbnail-slider row">
      <ng-container *ngIf="slickController.initialize">
        <div *ngFor="let img of _propertyGallery;let index = index;" class="row">
          <!-- <div class="slider-box" *ngIf="img?.video">
                  <img *ngIf="!videoInPlay" src="/assets/images/video-play.svg" (click)="playVideo()" class="play-icon" alt="">
                  <video slickItem class="slider-img" id="videoPlayer" (click)="playVideo()">
                    <source [src]="img?.video">
                  </video>
                </div>
                <div class="slider-box" *ngIf="img.image">
                  <img slickItem class="slider-img d-block w-100" src={{img.image}} alt="property image">
                </div> -->
          <div class="col-4" slickItem>
            <div class="thumbnail-slider-box" *ngIf="img?.video">
              <img src="/assets/images/video-play.svg" class="thumbnail-play-icon" alt="">
              <video id="videoPlayer" class="thumbnail-slider-img" *ngIf="img?.video" (click)="onPreview(index)">
                <source [src]="img?.video">
              </video>
            </div>
            <div class="thumbnail-slider-box" *ngIf="img.image">
              <img *ngIf="img?.image" class="thumbnail-slider-img" src={{img.image}} alt="property image" (click)="onPreview(index)">
            </div>
          </div>
        </div>
      <!-- <img slickItem *ngFor="let item of getArray(arrayLength); let index = index" src="https://placeholder.pics/svg/400/DEDEDE/555555/{{ index + 1 }}" class="p-10"> -->
      </ng-container>
    </div>
    
   
    </div>
    
    </div>
