<div class="bootstrap-wrapper mt-div">
    <div class="container">
        <div class="row modal-header p5">
            <div class="col-lg-10 col-md-10 col-sm-6 roboto-medium f16 title"></div>
            <div class="col-lg-2 col-md-2 col-sm-6 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20 title"
                    (click)="onClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        
        <div class="title-div row col-lg-12 col-md-12 col-sm-12">
            <div class="pl20 pt10 col-md-12 col-sm-12 p0">
                <div class="col-md-12 col-sm-12 row">
                    <div class="col-lg-3 col-md-4 col-sm-12 header-id-info">
                        <span class="name">Property ID</span>
                        <span class="value">{{propertyCode}}</span>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-12 name-info-div">
                        <span class="label">Property Name</span>
                        <span class="value">{{propertyName}} </span>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-12 header-value-info-div align-item-end">
                        <span class="label">Total No. of Shares</span>
                        <span class="value pr-55">{{noofBricklet}}</span>
                    </div>


                </div>

            </div>



        </div>

        <div class="main-div row scroll-x col-lg-12 col-md-12 col-sm-12 p0">
        
            <div class="detail-div pl20" >
            <mat-accordion multi class="mat-table col-lg-12 col-md-12 col-sm-12 p0 pt-8" is-open="true">
                <mat-expansion-panel class="p0 mb-8" *ngFor="let record of propertyRecord">
                  <mat-expansion-panel-header class="mat-row p0 h-70">
                    <mat-panel-title class="col-lg-9 col-md-9 col-sm-12">
                        <div class="col-lg-6 col-md-6 col-sm-12 user-info-div pl-10">
                            <span class="name roboto-bold">{{record.accountName}} <span class="pl-12"><i class="fa fa-map-marker torch-red" tooltip="{{getaddress(record)}}" placement="right" delay="100" aria-hidden="true"></i></span></span>
                            <span class="value">{{record.email}}</span>
                        </div>
                    </mat-panel-title>
                    <mat-panel-description class="col-lg-3 col-md-3 col-sm-12 justify-content-end">
                        <div class="col-lg-12 col-md-12 col-sm-12 id-info-div pl-33 align-item-start pr0">
                            <span class="label roboto-bold">Number of Shares: {{record.noOfBricklets}}</span>
                            <span class="value">Requested on: <span class="text-transform-upper">{{record.requestReceivedDate | date:
                                'dd-MMM-yyyy'}}</span></span>
                        </div>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="col-lg-12 col-md-12 col-sm-12 row pl-7 space-between desc-content">
                    <div class="col-lg-4 col-md-4 col-sm-12 user-info-div pl-30">
                        <span class="name roboto-bold">Each Shares Price</span>
                        <!-- <span class="value">${{record.buyingPrice | number : '1.2-2'}}(including Tax)</span> -->
                        <span class="value"><a class="view-link" (click)="onView(record?.buyPriceRange)">View Price</a></span>

                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 price-info-div pl-10">
                        <span class="name roboto-bold">Total Shares price</span>
                        <!-- <span class="value">${{getTotalPrice(record.buyPriceRange) | number : '1.2-2'}}(including Tax)</span> -->
                        <span class="value">${{record?.buyPriceRange[0]?.total | number : '1.2-2'}}(including Tax)</span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 id-info-div pl-10 pr0 pr-15">
                            <button mat-raised-button class="col-md-12 col-sm-12 mb10 roboto-medium f12 btn-filter" color="primary"
                                (click)="openApprovalDialoguebox(record.provisionalBuyHeaderGUID)">Approve</button>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 row pl-7 space-between">
                    
                    <div class="col-lg-9 col-md-9 col-sm-12 user-info-div pl-30">
                        <!-- <span class="name roboto-bold">Tenant in common interest(%)</span>
                        <span class="value">{{getInterest(record.totalNoOfBricklet, record.noOfBricklets) | number : '1.2-2'}}</span> -->
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 id-info-div pl-10 pr0 pr-15">
                            <button mat-raised-button class="col-md-12 col-sm-12 mb10 bg-torch-red white roboto-medium f12 btn-filter"
                                (click)="reject(record.provisionalBuyHeaderGUID)" color="warning">Reject</button>
                    </div>
                </div>
                  
                </mat-expansion-panel>
              </mat-accordion>
              
              </div>
              

            
            <app-loader class="loader" *ngIf="isLoading"></app-loader>
           
        </div>
        <mat-paginator #paginator class="table mt-2" [length]="resultsLength" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPaginateChange($event)"
        showFirstLastButtons></mat-paginator>
    </div>
</div>