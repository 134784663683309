<div class="col-12 pl5 pt10 pr5">
        <div class="mat-elevation-z8 mt-2 bg-light-grey-two">
            <div class="pl20 col-12">
                <mat-form-field class="col-2 f14">
                    <input matInput [matDatepicker]="fromDate" placeholder="Start Date" class="roboto-regular f14" #from>
                    <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="col-2 f14">
                    <input matInput [matDatepicker]="endDate" placeholder="End Date" class="roboto-regular f14" #to>
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
                <mat-card-actions  class="pl20">
                    <button mat-raised-button class="col-2 mr15 roboto-medium f12" [class.spinner]="isbtnLoading" color="primary" (click)="filterRecords()">Filter</button>
                    <button mat-raised-button class="col-2 ml10 bg-dusty-orange white roboto-medium f12" color="warning" (click)="reset()">Reset</button>
                </mat-card-actions>
            </div>
        </div>
    </div>
    
    <div class="col-12 pl5 pr5 pt5 pb5">
        <div class="mat-elevation-z8 mt-2">
            <div class="table-responsive col-12">
                <table mat-table [dataSource]="dataSource" class="table col-12">
                    <ng-container matColumnDef="token">
                        <th mat-header-cell *matHeaderCellDef class="roboto-medium f14"> Txn ID </th>
                        <td mat-cell *matCellDef="let element" class="td-pl roboto-regular f13"> {{element.token}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="fullName">
                        <th mat-header-cell *matHeaderCellDef class="roboto-medium f14"> Name </th>
                        <td mat-cell *matCellDef="let element" class="roboto-regular f13"> {{element.fullName}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef class="roboto-medium f14"> Email
                        </th>
                        <td mat-cell *matCellDef="let element" class="roboto-regular f13"> {{element.email}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="depositDate">
                        <th mat-header-cell *matHeaderCellDef class="roboto-medium f14"> Date </th>
                        <td mat-cell *matCellDef="let element" class="roboto-regular f13"> {{element.depositDate | date : 'dd-MM-yyyy' }}
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="gateway">
                        <th mat-header-cell *matHeaderCellDef class="roboto-medium f14"> Gateway </th>
                        <td mat-cell *matCellDef="let element" class="roboto-regular f13"> {{element.gateway}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef class="roboto-medium f14"> Amount </th>
                        <td mat-cell *matCellDef="let element" class="roboto-regular f13"> {{element.amount}}
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="roboto-medium f14"> Status </th>
                        <td mat-cell *matCellDef="let element" class="roboto-regular f13"> {{element.status}} </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <app-loader *ngIf="isLoading"></app-loader>
                <span *ngIf="depositRecords">
                    <div *ngIf="depositRecords.length === 0" class="roboto-medium f14 text-center mt5">No records found</div>
                </span>
            </div>
            <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="onPaginateChange($event)"
                    showFirstLastButtons>
                </mat-paginator>
        </div>
    </div>