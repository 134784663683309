<div class="col-12 carousel-box p0">
    <div [@thumbState]="idx === counter ? 'active' : 'inactive'" class="tmb" *ngFor="let img of images; let idx = index">
        <img [src]="images[idx]?.imageID" class="d-block w-100" />
        <button mat-raised-button class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-8 bg-dusty-orange" id="submit" (click)="remove(img)">Remove</button>
    </div>
    <button mat-icon-button class="prev" (click)="prev()">
            <mat-icon>chevron_left</mat-icon>
        </button>
    <button mat-icon-button class="next" (click)="next()">
            <mat-icon>chevron_right</mat-icon>
        </button>
</div>
