import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { UsersService } from "src/app/core/services/users.service";
import { UserDetailComponent } from "src/app/shared/components/user-detail/user-detail.component";
import { KycIndividualComponent } from "src/app/shared/components/kyc-individual/kyc-individual.component";
import { KycOrgComponent } from "src/app/shared/components/kyc-org/kyc-org.component";
import { FormGroup, FormControl } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { Kyc2VerificationComponent } from "src/app/shared/components/kyc2-verification/kyc2-verification.component";
import { AgentService } from '../../../core/services/agent.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UserRecordInterface, AgentUserListFilterInterface, UserListInterface } from '../../../core/interfaces/users.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from '../../../core/utils/common.service';
import { MatDialog } from '@angular/material/dialog';
import { UserRolesService } from '../../../core/services/user-roles.service';
import { MatInput } from '@angular/material/input';
import { HelperService } from '../../../core/utils/helper.service';
import PageConfigs from '../../../core/utils/access-level-config';

@Component({
  selector: 'app-agent-user-list',
  templateUrl: './agent-user-list.component.html',
  styleUrls: ['./agent-user-list.component.scss']
})
export class AgentUserListComponent implements OnInit, AfterViewInit {

  length = 0;
  pageNo = 1;
  pageSize = 5;
  pageSizeOptions = [5, 10];
  pageIndex = 1;

  userList: UserRecordInterface[] = [];
  displayedColumns: string[] = ["name", "email", "accountName", "accountType", "balance", "kyc1"];
  dataSource = new MatTableDataSource<UserRecordInterface>(this.userList);
  isLoading = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | any;
  @Input() guid: any;

  filterBy = [
    { value: "PV", text: "Pending Verification" },
    { value: "KYC1RV", text: "KYC Rejected users" },
    // { value: 'KYC2RV', text: 'KYC 2 Rejected users' },
    { value: "KYC1VU", text: "KYC Verified users" },
    // { value: 'KYC2VU', text: 'KYC 2 verified users' },
    { value: "UVU", text: "Unverified users" },
    { value: "PU", text: "Premium users" },
    { value: "NU", text: "Normal users" },
    { value: "AU", text: "Active users" },
    { value: "IAU", text: "Inactive users" },
    { value: "IU", text: "International users" },
    { value: "AUD", text: "AUD greater than zero" }
  ];

  filterForm: FormGroup | any;

  hoverRejectReason: any;

  userActions = {
    updateKycVerify: false,
    update: false,
  };
  minDate: any = null;
  @ViewChild('from', { static: false, read: MatInput }) fromDate: MatInput | any;
  @ViewChild('to', { static: false, read: MatInput }) toDate: MatInput | any;
  constructor(
    private usersService: UsersService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private userRolesService: UserRolesService,
    private datePipe: DatePipe,
    private agentService: AgentService,
    private helper: HelperService
  ) { }

  ngOnInit() {
    // this.getPagePermissions();
    this.filterForm = new FormGroup({
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      filterBy: new FormControl(null),
      emailSearch: new FormControl(null),
    });
    this.getUsers();
    this.dataSource.paginator = this.paginator;
    this.usersService.currentMessage.subscribe((msg) => {
      if (msg === true) {
        this.getUsers();
      }
    });
  }

  getPagePermissions() {
    let permissions = this.userRolesService.getRoleAccessByPageName(
      PageConfigs.Users.name
    );
    this.userActions.updateKycVerify = permissions.updateKycVerify;
    this.userActions.update = permissions.update;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit() {
    // const ky1rejectreason = document.getElementById('ky1rejectreason') as HTMLInputElement;
    // ky1rejectreason.addEventListener('click', this.debounce(() => {
    //   alert('345');
    // }, 100));
  }

  debounce(func: any, delay: any) {
    let debounceTimer: any;
    return (...args: any[]) => {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  getUsers() {
    this.isLoading = true;
    const emailSearch = this.filterForm.get("emailSearch").value;
    let data: AgentUserListFilterInterface | any = {
      AgentGUID: this.guid,
      PageNum: this.pageNo,
      PageSize: this.pageSize,
      EmailSearch: emailSearch !== "" ? emailSearch : null,
      StartDate: this.datePipe.transform(
        this.filterForm.get("startDate").value,
        "yyyy-MM-dd"
      ),
      EndDate: this.datePipe.transform(
        this.filterForm.get("endDate").value,
        "yyyy-MM-dd"
      ),
    };

    const filterBy = this.filterForm.get("filterBy").value;
    if (filterBy) {
      data = { ...data, ...this.getFilterBy(filterBy) };
    }

    this.commonService.showSpinner();
    this.commonService.showLoader();
    this.agentService.getAgentUserListByGUID(data).subscribe(
      (r: any) => {
        this.isLoading = false;
        const result: UserListInterface = r.data.userList;
        this.userList = result.records;
        this.dataSource = new MatTableDataSource(this.userList);
        this.length = result.totalRecord;
        this.commonService.hideSpinner();
        this.commonService.hideLoader();
      },
      (e: any) => {
        this.isLoading = false;
        this.commonService.hideSpinner();
        this.commonService.hideLoader();
        this.helper.error(e);
      }
    );
  }

  onPaginateChange(event: any) {
    this.pageIndex = ++event.pageIndex;
    this.pageNo = this.pageIndex;
    this.pageSize = event.pageSize;
    this.getUsers();
  }

  filterRecords(): any {
    if((this.email.value === null || this.email.value === "") && this.from.value === null && this.to.value === null && this.filterby.value === null) {
      this.helper.info("Please choose filter options");
      return false;
    }
    if(this.fromDate.value != null) {
      if(this.toDate.value == null) {
        this.helper.info("Please Select To date!");
        return false;
      }
    } else if(this.fromDate.value == null){
      if(this.toDate.value != null) {
        this.helper.info("Please Select From date!");
        return false;
      }
    }
    if (!this.filterForm.valid) {
      if (!this.email.valid && this.email.hasError('email')) {
        this.helper.info("Please enter a valid email");
        return false;
      } 
    }
    this.pageNo = 1;
    this.pageSize = 5;
    this.pageIndex = 1;
    this.setPage(0);
    this.getUsers();
  }

  reset() {
    this.filterForm.reset();
    this.pageNo = 1;
    this.pageSize = 5;
    this.pageIndex = 1;
    this.setPage(0);
    this.getUsers();
  }

  setPage(index: number) {
    this.paginator.pageIndex = index;
  }

  openUserDetail(userId: string, usertype: string) {
    const dialogRef = this.dialog.open(UserDetailComponent, {
      data: { id: userId, userType: usertype, update: this.userActions.update },
    });
  }

  openKycIndividual(userId: string) {
    const dialogRef = this.dialog.open(KycIndividualComponent, {
      data: {
        id: userId,
        title: "KYC Verification - Individual",
        btnDisable: false,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.refresh) {
        this.getUsers();
      }
    });
  }

  openKycOrg(userId: string) {
    const dialogRef = this.dialog.open(KycOrgComponent, {
      data: {
        id: userId,
        title: "KYC Verification - Organization",
        btnDisable: false,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.refresh) {
        this.getUsers();
      }
    });
  }

  openKyc2(userId: string) {
    const dialogRef = this.dialog.open(Kyc2VerificationComponent, {
      data: { id: userId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.refresh) {
        this.getUsers();
      }
    });
  }

  getFilterBy(filters: string[]) {
    let filterObjects = {};
    filters.forEach((filterBy: string) => {
      switch (filterBy) {
        case "PV":
          filterObjects = { ...filterObjects, PendingVerification: true };
          break;
        case "KYC1RV":
          filterObjects = { ...filterObjects, KYC1RejectedVerification: true };
          break;
        // case 'KYC2RV':
        //   filterObjects = { ...filterObjects, KYC2RejectedVerification: true };
        //   break;
        case "KYC1VU":
          filterObjects = { ...filterObjects, KYC1verifiedUser: true };
          break;
        // case 'KYC2VU':
        //   filterObjects = { ...filterObjects, KYC2verifiedUser: true };
        //   break;
        case "UVU":
          filterObjects = { ...filterObjects, UnverifiedUsers: true };
          break;
        case "PU":
          filterObjects = { ...filterObjects, PremiumUsers: true };
          break;
        case "NU":
          filterObjects = { ...filterObjects, NormalUsers: true };
          break;
        case "AU":
          filterObjects = { ...filterObjects, ActiveUsers: true };
          break;
        case "IAU":
          filterObjects = { ...filterObjects, InactiveUsers: true };
          break;
        case "IU":
          filterObjects = { ...filterObjects, InternationalUsers: true };
          break;
          case "AUD":
          filterObjects = { ...filterObjects, IsHaveAUDBalance: true };
          break;
      }
    });
    return filterObjects;
  }

  over() {
    this.hoverRejectReason = "test";
    return "test";
  }

  rejectReason(guid: any, level: any) {
    this.hoverRejectReason = "";
    this.usersService.getKYCRejectReason(guid, level).subscribe(
      (r: any) => {
        this.isLoading = false;
        const result = r.data;
        //this.commonService.toastWarning(result.reason);
        this.hoverRejectReason = result.reason;
      },
      (e) => {
        this.isLoading = false;
        this.hoverRejectReason = "";
        this.helper.error(e);
      }
    );
  }

  onBlur() {
    this.hoverRejectReason = "";
  }

  fromDateInput(event: MatDatepickerInputEvent<Date>): void {
    // this.fromDate.value = moment(event.value).format('YYYY-MM-DD');
     this.minDate = event.value;
     if (this.fromDate.value > this.toDate.value) {
       this.toDate.value = '';
       this.filterForm.get("endDate").setValue(null);
       // this.to = null;
     }
 
   }
 
   toDateInput(event: MatDatepickerInputEvent<Date>): void {
    // this.toDate.value = moment(event.value).format('YYYY-MM-DD');
   }
 
   get email() { return this.filterForm.get('emailSearch'); }
   get filterby() { return this.filterForm.get('filterBy'); }
   get from() { return this.filterForm.get('startDate'); }
   get to() { return this.filterForm.get('endDate'); }
 
   getEmailError(): any {
     if (this.email.hasError('emailSearch')) {
       return 'Please enter a valid email.';
     } else if(this.email.touched){
       return 'Please enter a valid email2.';
     }
   }
}
