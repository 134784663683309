
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var $: any;


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  yes: string;
  no: string;
  id: string;
  remarks: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.yes = data.yes;
    this.no = data.no;
    this.id = data.id;
    this.remarks = data.remarks;

  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // $('#logoutModal').modal('show');
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onRejectConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(this.remarks);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}


export class ConfirmDialogModel {
  remarks: string;

  constructor(public title: string, public message: string, public yes: string, public no: string, public id: string) {
  }
}

export class ConfirmRejectDialogModel {

  constructor(public title: string, public message: string, public yes: string, public no: string, public id: string,public remarks: string) {
  }

  onRemarksInput(event: Event) {
    this.remarks = (event.target as HTMLTextAreaElement).value;
  }
}
