import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UsersService } from 'src/app/core/services/users.service';
import { DatePipe } from '@angular/common';
import { KycRejectPopupComponent } from '../kyc-reject-popup/kyc-reject-popup.component';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { KycLevelOneInterface } from '../../../core/interfaces/kyc.interface';

export interface DialogData {
  id: string;
  title: string;
  btnDisable: boolean;
  titleDisable: boolean;
  KycTypeOfUserGUID: string;
}

@Component({
  selector: 'app-kyc-individual',
  templateUrl: './kyc-individual.component.html',
  styleUrls: ['./kyc-individual.component.scss'],
  providers: [DatePipe],
})
export class KycIndividualComponent implements OnInit {
  personalInfo: UserInfoCardInterface | null = null;
  addressInfo: UserInfoCardInterface | null = null;
  idInfo: UserInfoCardInterface | null = null;
  addressVerifyInfo: UserInfoCardInterface | null = null;
  title: any;
  dialogBtnDisable = false;
  titleDisable: any;
  isbtnLoading = false;

  constructor(
    public dialogRef: MatDialogRef<KycIndividualComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: UsersService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private helper: HelperService
  ) { }

  ngOnInit() {

    this.title = this.data.title;
    this.dialogBtnDisable = this.data.btnDisable;
    this.titleDisable = this.data.titleDisable;
    
    // this.userService.getKycLevelOne(this.data.id, this.data.KycTypeOfUserGUID).subscribe(
    //   (r: any) => {
    //     const rData: KycLevelOneInterface = r.response !== undefined ? r.response : r.data;
    //     const result = rData.kYCDTO.individual;
    //     const personalInfo = result.personalInfo;
    //     const addressInfo = result.addressInfo;
    //     const proofOfIdentity1 = result.proofOfIdentity1;
    //     const proofOdIdentity2 = result.proofOdIdentity2;
    //     const addressProof = result.addressProof;

    //     const keyPairData = [
    //       {
    //         text: 'First name',
    //         value: personalInfo.firstName
    //       },
    //       {
    //         text: 'Middle name',
    //         value: personalInfo.middleName
    //       },
    //       {
    //         text: 'Last name',
    //         value: personalInfo.lastName
    //       },
    //       {
    //         text: 'Date of Birth',
    //         value: this.datePipe.transform(personalInfo.dateOfBirth, 'dd-MMM-yyyy')
    //       },
    //       {
    //         text: 'Gender',
    //         value: personalInfo.gender
    //       },
    //       {
    //         text: 'Mobile',
    //         value: personalInfo.mobile
    //       },
    //     ];
    //     this.personalInfo = {
    //       title: 'Personal Information',
    //       column: 4,
    //       type: 'text-only',
    //       data: keyPairData
    //     };

    //     const addressKeyPair = [
    //       {
    //         text: 'Unit Number',
    //         value: addressInfo.unitNo
    //       },
    //       {
    //         text: 'Street Number',
    //         value: addressInfo.streetNo
    //       },
    //       {
    //         text: 'Street Name',
    //         value: addressInfo.streetName
    //       },
    //       {
    //         text: 'Street Type',
    //         value: addressInfo.streetType
    //       },
    //       {
    //         text: 'Suburb',
    //         value: addressInfo.suburb
    //       },
    //       {
    //         text: 'Country',
    //         value: addressInfo.country
    //       },
    //       {
    //         text: 'State/Region',
    //         value: addressInfo.state
    //       },
    //       {
    //         text: 'PostCode/ZipCode',
    //         value: addressInfo.postalCode
    //       },
    //       {
    //         text: 'Additional Information',
    //         value: addressInfo.additionalInfo
            
    //       }
    //     ];
    //     this.addressInfo = {
    //       title: 'Address Information',
    //       column: 3,
    //       type: 'text-only',
    //       data: addressKeyPair
    //     };


    //     this.addressVerifyInfo = {
    //       title: 'Address verification documents',
    //       column: 0,
    //       type: 'doc-only',
    //       data: [{
    //         name: addressProof.proofType,
    //         images: [addressProof.proofURL],
    //         data: null
    //       }]
    //     };
    //     this.documentInfo(result);
    //   },
    //   (e) => {
    //     // this.helper.error(e);
    //     const errSt = e.message !== undefined ? e.message : e;
    //     if (errSt === 'Session Timed Out') {
    //       this.helper.info(errSt);
    //     } else {
    //       this.helper.error(errSt);
    //     }
    //   }
    // );

  }

  closeKycIndividual(): void {
    this.dialogRef.close({ refresh: true });
  }

  documentInfo(result: any) {
    const docData = [];
    if (result.proofOfIdentity1.passportNo !== '' && result.proofOfIdentity1.passportNo !== null) {
      const keyPair = [
        { text: 'Passport Number', value: result.proofOfIdentity1.passportNo },
        { text: 'Country Of Passport Issued', value: result.proofOfIdentity1.countryOfPassportIssued },
        { text: 'Country Of Residence', value: result.proofOfIdentity1.countryOfResidence },
      ];

      docData.push({
        name: 'PASSPORT',
        images: [result.proofOfIdentity1.passportImageURL],
        data: keyPair
      });
    }

    if (result.proofOdIdentity2.drivingLicenseProof !== '' && result.proofOdIdentity2.drivingLicenseProof !== null) {
      const keyPair = [
        { text: 'Driving License Number', value: result.proofOdIdentity2.drivingLicenseProof.drivingLicenseNumber },
        { text: 'State', value: result.proofOdIdentity2.drivingLicenseProof.state },
      ];
      docData.push({
        name: 'DRIVING LICENSE',
        images: [result.proofOdIdentity2.drivingLicenseProof.drivingLicenseFrontSideURL, result.proofOdIdentity2.drivingLicenseProof.drivingLicenseBackSideURL],
        data: keyPair
      });
    }

    if (result.proofOdIdentity2.medicareProof !== '' && result.proofOdIdentity2.medicareProof !== null) {
      const keyPair = [
        { text: 'Medicare Card No', value: result.proofOdIdentity2.medicareProof.cardNumber },
        { text: 'Reference No', value: result.proofOdIdentity2.medicareProof.individualNo },
        { text: 'Type', value: result.proofOdIdentity2.medicareProof.cardColor },
        { text: 'Full name', value: result.proofOdIdentity2.medicareProof.fullName },
        { text: 'Expiry date', value: this.datePipe.transform(result.proofOdIdentity2.medicareProof.expireDate, 'dd-MMM-yyyy') },
      ];
      docData.push({
        name: 'MEDICARE CARD',
        images: [result.proofOdIdentity2.medicareProof.medicareImageURL],
        data: keyPair
      });
    }

    this.idInfo = {
      title: 'Identity verification documents',
      column: 0,
      type: 'doc-data-only',
      data: docData
    };
  }

  rejectKyc() {
    const dialogRef = this.dialog.open(KycRejectPopupComponent, {
      data: { reason: '', status: false }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status) {
        this.userService.rejectKycLevelOne({
          UserGUID: this.data.id,
          Reason: result.reason,
          KYCTypeOfUserGUID: this.data.KycTypeOfUserGUID,
          Level: 1
        }).subscribe(
          (s: any) => {
            this.helper.success("KYC Level 1 Rejected Successfully");
            this.closeKycIndividual();
          },
          (e: any) => {
            // this.helper.error(e);
            const errSt = e.message !== undefined ? e.message : e;
            if (errSt === 'Session Timed Out') {
              this.helper.info(errSt);
            } else {
              this.helper.error(errSt);
            }
          }
        );

      }

    });
  }

  approveKyc() {
    this.isbtnLoading = true;
    const dataDTO = {
      UserGUID: this.data.id,
      KYCTypeOfUserGUID: this.data.KycTypeOfUserGUID,
      Level: '1'
    }
    this.userService.approveKyc(dataDTO).subscribe(
      (s: any) => {
        this.isbtnLoading = false;
        this.helper.success('Kyc Level 1 Verified Successfully');
        this.closeKycIndividual();
      },
      (e: any) => {
        // this.helper.error(e);
        this.isbtnLoading = false;
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

}
