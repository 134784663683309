import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { HelperService } from 'src/app/core/utils/helper.service';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit, OnChanges {

  @Input() img = null;
  imgUrl: any = null;
  constructor(
    private helperService: HelperService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }
  ngOnChanges(): void{
    this.getImage(this.img);
  }

  getImage(data: any){
    if (data !== "") {
      this.helperService.getUserProfileDecrypt(data).subscribe(
        (r: any) => {
          let blob = new Blob([r], { type: 'application/octet-stream' });
          this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
          return this.imgUrl;
        },
        (e) => {
          return this.imgUrl;
        }
      );
    }
  
  }

  imageViewer(url: string) {
    const dialogRef = this.dialog.open(ImageViewerComponent, {
      data: { imageUrl: url },
      panelClass: 'image-viewer-modal'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
