<div class="bootstrap-wrapper login-main-img h-100" @fade >
    
    <div class="justify-content-center align-items-center flex-column container-fluid h-100">
        <form autocomplete="off" class="login-form d-flex justify-content-center h-100" [formGroup]="loginForm" novalidate fxLayout="column wrap"
        fxLayoutAlign="center center" fxLayoutGap="10px">

            <div fxLayout="row" fxLayoutAlign="center center" class="container">
            <div class="row col-lg-12 p0">
                <div class="row text-center col-lg-12">
                    <div class="col-lg-12">
                        <img src="../../../assets/images/logo.png" class="img">
                    </div>
                </div>

                <div class="row col-lg-12 text-center mt-30">


                    <div class="form-group col-lg-12" id="login-title">
                        <p class="roboto-medium f36 mb-1 welcome">Welcome Back</p>
                        <p class="roboto-medium f24 cornflower-blue login"> Login</p>
                    </div>
                    

                    <div class="col-lg-12">
                        <div class="form-group">
                            <mat-form-field class="form-field col-sm-12 col-md-4">
                                <input type="email" matInput formControlName="txtEmail" class="f14" id="email" placeholder="Email">
                                <!-- <mat-hint align="end">Not more then 60 characters long.</mat-hint> -->
                                <mat-error *ngIf="helper.hasError(loginForm, 'txtEmail','required')">Email is required</mat-error>
                                <mat-error *ngIf="helper.hasError(loginForm, 'txtEmail','maxlength')">You have more than 60 characters</mat-error>
                                <mat-error *ngIf="helper.hasError(loginForm, 'txtEmail','patternInvalid')">Invalid Email</mat-error>
                                
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <mat-form-field class="form-field col-sm-12 col-md-4">
                                    <div class="inner-addon right-addon">
                                            <span class="glyphicon" (click)="toggleEye()">
                                                <span *ngIf="passwordHide">
                                                    <i class="fa fa-eye-slash"></i>
                                                </span>
                                                <span *ngIf="!passwordHide">
                                                    <i class="fa fa-eye"></i>
                                                </span>
                                            </span>
                                <input type="password" matInput formControlName="txtPassword" class="f14" id="pwd" [attr.type]="passwordHide ? 'text': 'password' " maxlength="15" 
                                    placeholder="Password">
                                </div>
                                    <mat-error *ngIf="helper.hasError(loginForm,'txtPassword','required')">Password is required</mat-error>

                            </mat-form-field>
                        </div>
                        <!-- <div class="form-group text-webkit-center mx-auto container col-md-4 col-sm-12 mt5">
                            <re-captcha formControlName="txtRecaptcha" (resolved)="resolved($event)" #regRecaptcha></re-captcha>
                        </div> -->

                        <div class="form-group">
                            <mat-card-actions>
                            <button mat-raised-button color="primary" class="btn btn-primary roboto-medium f16 col-md-2 col-sm-12 mt40" [disabled]="!loginForm.valid" [class.spinner]="loading"  (click)="onSignIn()">Sign In</button>
                        </mat-card-actions>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        </form>

    </div>
</div>