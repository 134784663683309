<div class="info-div">
  <p class="deposit-success">Deposit Success!</p>
  <img class="success-icon" src="../../../../assets/images/deposit-success.png" alt="">


  <table>
    <tr>
      <td class="label">Name</td>
      <td class="value">{{record.fullName}}</td>
    </tr>
    <tr>
      <td class="label">Email</td>
      <td class="value">{{record.email}}</td>
    </tr>
    <tr>
      <td class="label">Amount</td>
      <td class="value">${{record.amount | number: "1.0-2"}}</td>
    </tr>
  </table>




</div>

<button mat-raised-button class="deposit-btn" (click)="close()">Close</button>
