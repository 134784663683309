import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BankTransferService } from '../../../core/services/banktransfer.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../../core/utils/common.service';
import { SuccessResponseDTO } from '../../../core/interfaces/common-response';
import { HelperService } from '../../../core/utils/helper.service';

@Component({
  selector: 'app-deposit-amount',
  templateUrl: './deposit-amount.component.html',
  styleUrls: ['./deposit-amount.component.scss']
})
export class DepositAmountComponent implements OnInit {

isbtnLoading = false;
amountDTO: AmountDTO = {} as any;
depositForm = this.formBuilder.group({
  txtName: new FormControl({value: '', disable: true}),
  txtEmail: new FormControl({value: '', disabled: true}),
  txtAmount: new FormControl('', Validators.required)
});
  constructor(private formBuilder: FormBuilder,
              private bankTransferService: BankTransferService,
              private commonService: CommonService,
              private helper: HelperService,
              public dialogRef: MatDialogRef<DepositAmountComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
              //  this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    // $('#logoutModal').modal('show');
    this.depositForm.patchValue({
      txtName: this.data.fullName,
      txtEmail: this.data.Email
     });
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  onDepositAmount() {
    this.isbtnLoading = true;
    this.amountDTO.DepositGUID = this.data.bankGUID;
    this.amountDTO.Amount = this.f.txtAmount.value;
    this.bankTransferService.updateBanklist(this.amountDTO).subscribe((r: any) => {
        if (r.data !== null) {
          this.isbtnLoading = false;
          this.bankTransferService.changeMessage(true);
          this.dialogRef.close('');
          this.helper.success(r.data.message);
          } else {
            this.isbtnLoading = false;
            this.helper.error(r.message);
          }

    }, (e: any) => {
      this.isbtnLoading = false;
      // this.helper.error(e);
      const errSt = e.message !== undefined ? e.message : e;
      if (errSt === 'Session Timed Out') {
        this.helper.info(errSt);
      } else {
        this.helper.error(errSt);
      }
    }
    );
  }

  get f() {
    return this.depositForm.controls;
  }

}




export class AmountDTO {
  DepositGUID!: string;
  Amount!: number;
}
