import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class PropertyManagerService {
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(private apiManagerService: ApiManagerService) { }



  getPropertyManagerList() {
    return this.apiManagerService.getRequest(RestEnds.propertyManagerList, null);
  }


  addPropertyManager(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addPropertyManager,
      data
    );
  }
  editPropertyManager(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.editPropertyManager,
      data
    );
  }

  getPropertyManagerByGuid(data: any) {
    return this.apiManagerService.getRequest(RestEnds.getPropertyManager + data, null);
  }

  getIsExists(guid: any, name: any) {
    return this.apiManagerService.getRequest(RestEnds.getPropertyManagerIsExists + '?carManagerGUID=' + guid + '&description=' + name, null);
  }

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }

}
