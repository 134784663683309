
import { OnInit, Component, ChangeDetectorRef, Inject, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { trigger, state, style, transition, animate } from '@angular/animations';
import * as moment from 'moment';
import { PropertyService } from '../../../core/services/property.service';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';

@Component({
  selector: 'app-draft-property-detail',
  templateUrl: './draft-property-detail.component.html',
  styleUrls: ['./draft-property-detail.component.scss'],
  animations: [trigger('detail', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(500, style({ opacity: 1 }))
    ])
  ])]
})
export class DraftPropertyDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() guid: any = null;
  form!: FormGroup;
  type: any;
  coverImage: any;
  propertyDetails: any;
  addressInfo: any;
  // @ViewChild(HistoricalGrowthChartComponent, { static: true }) HistoricalGrowthChartComp;
  index: any;
  isLoading = false;
  isbtnLoading = false;
  chart: any;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private helper: HelperService,
    private dialog: MatDialog,
    private propertyService: PropertyService
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // this.coverImage = '../../../../assets/images/home-grow-market.png';
  }

  ngAfterViewInit(): void {
    this.type = this.data.type;
    this.cdr.detectChanges();
  }


  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  onClose() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  formatChartData(chartData: any) {
    if (chartData.length > 0) {
      // this.HistoricalGrowthChartComp.generateChart(chartData);
    }
  }

  onChange(idx: any) {
    this.index =  idx;
  }

  // load(data) {
  //   console.log('check');
  //   console.log(data);
  //   this.chart = data;
  //  // this.formatChartData(this.chart);
  //   // if (this.chart.length > 0) {
  //   //   this.HistoricalGrowthChartComp.generateChart(this.chart);
  //   // }
  // }

  getDate(data: any) {
    return data === null ? null : moment.utc(data).local().format('DD-MM-YYYY hh:mm a');
  }


  onPublish() {
    this.isbtnLoading = true;
    this.propertyService.publishProperty(this.data.guid).subscribe(
      (s: any) => {
this.isbtnLoading = false;
this.commonService.hideSpinner();
this.propertyService.changeMessage(true);
this.helper.success(s.data.message);
this.dialog.closeAll();
this.commonService.goto('property/list');

      },
      (e) => {
        this.isbtnLoading = false;
        this.commonService.hideSpinner();
        // this.helper.error(e);
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

}
