import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: "root",
})
export class AgentService {
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(
    private apiManagerService: ApiManagerService,
    private http: HttpClient
  ) {}

  getAgentList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getNewsList, data);
  }

  getAgentUserListByGUID(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getAgentUserList, data);
  }

  uploadPropertyImages(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.propertyImageUpload,
      data
    );
  }
  addAgent(data: any) {
    return this.apiManagerService.postRequest(RestEnds.addCPPAgent, data);
  }
  // editNews(data) {
  //   return this.apiManagerService.putRequest(
  //     RestEnds.NewsURL,
  //     data
  //   );
  // }

  // deleteNews(data) {
  //   return this.apiManagerService.deleteRequest(
  //     RestEnds.NewsURL + '/' +
  //     data
  //   );
  // }

  postAgentList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getCPPAgent, data);
  }

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }

  upload(data: any) {
    return this.apiManagerService.postRequest(RestEnds.uploadImage, data);
  }

  getAllAgentList() {
    return this.apiManagerService.getRequest(RestEnds.getAllAgentList, null);
  }

  agentResendMail(guid: string) {
    return this.apiManagerService.getRequest(
      RestEnds.agentResendMail + "?agentGUID=" + guid, null
    );
  }

  // getNewsCategoryList() {
  //   return this.apiManagerService.getRequest(RestEnds.NewsCategory);
  // }

  // addNewsCategory(data) {
  //   return this.apiManagerService.postRequest(
  //     RestEnds.NewsCategory,
  //     data
  //   );
  // }

  // editNewsCategroy(data) {
  //   return this.apiManagerService.putRequest(
  //     RestEnds.NewsCategory,
  //     data
  //   );
  // }

  // getIsExistsCategory(id, name) {
  //   let data;
  //   if (id != null) {
  //     data = '?categoryID=' + id + '&category=' + name;
  //   } else {
  //     data = '?category=' + name;
  //   }
  //   return this.apiManagerService.getRequest(
  //     RestEnds.getIsExistsNewsCategory + data
  //   );
  // }

  // getNewsCategory(guid) {
  //   return this.apiManagerService.getRequest(
  //     RestEnds.NewsCategory + '/' + guid
  //   );
  // }

  // deleteNewsType(data) {
  //   return this.apiManagerService.deleteRequest(
  //     RestEnds.NewsCategory + '/' +
  //     data
  //   );
  // }

  getMappingAgentList() {
    return this.apiManagerService.getRequest(RestEnds.getMappingAgentList, null);
  }

  getMappingUsersList() {
    return this.apiManagerService.getRequest(RestEnds.getMappingUsersList, null);
  }

  getMappingAccountList(data: any) {
    return this.apiManagerService.getRequest(RestEnds.getMappingAccountsList + "?userGUID=" + data, null);
  }

  postAgentUserMapping(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.submitAgentUserMapping,
      data
    );
  }
}
