<div class="bootstrap-wrapper">
        
        <div class="row mt15 mb10">
    
            <div class="col-12" *ngIf="basicSMSFInfo">
                <app-user-info-card [config]="basicSMSFInfo"></app-user-info-card>
            </div>
        </div>

<div class="row mt10" *ngIf="showTabs">
            <div class="col-12" id="tabcontent">
                <mat-tab-group>
                    <mat-tab label="Ultimate Beneficial Owners (UBOs)" class="body-content" id="body-content" ng-reflect-id="org-tab-content">
                        <div class="col-12 mt10 mb10">
                            <app-org-ubo [uboData]="emitData.ubo"></app-org-ubo>
                        </div>
                    </mat-tab>
                    <mat-tab label="Trustee">
                        <div class="col-12 mt10 mb10">
                            <app-org-trustee [trusteeData]="emitData.trustee"></app-org-trustee>
                        </div>
                    </mat-tab>
                    <mat-tab label="Settlor Type">
                        <div class="col-12 mt10 mb10">
                            <app-org-settlor [settlorData]="emitData.settlor"></app-org-settlor>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>