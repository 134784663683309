import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';

@Injectable({
  providedIn: 'root'
})
export class BuySellService {

  constructor(
    private apiManagerService: ApiManagerService
  ) { }

  sellBricklet(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.sellBricklet,
      data
    );
  }
  isBrickletAvailableToSell(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.sellIsAvailable,
      data
    );
  }

  isBrickletAvailableToBuy(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.buyIsAvailable,
      data
    );
  }

  buyBricklet(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.buyBricklet,
      data
    );
  }

  getMarketPrice(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.getMarketPrice, data
    );
  }
}
