import { Component, OnInit, Input } from '@angular/core';
import { KycConfigService } from 'src/app/core/services/kyc-config.service';
import { SoleTrader } from '../../../core/interfaces/kyc.interface';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';

@Component({
  selector: 'app-org-sole-trader',
  templateUrl: './org-sole-trader.component.html',
  styleUrls: ['./org-sole-trader.component.scss']
})
export class OrgSoleTraderComponent implements OnInit {

  @Input() soletraderData: SoleTrader | any;

  personalInfo: UserInfoCardInterface | null = null;
  addressInfo: UserInfoCardInterface | null = null;
  idInfo: UserInfoCardInterface | null = null;
  addressVerifyInfo: UserInfoCardInterface | null = null;

  basicInfo: UserInfoCardInterface | null = null;
  regOffice: UserInfoCardInterface | null = null;
  businessInfo: UserInfoCardInterface | null = null;

  stackholderType: UserInfoCardInterface | null = null;

  isPrivate = false;

  constructor(
    private kycConfigService: KycConfigService
  ) { }

  ngOnInit() {
    const businessInfo = this.kycConfigService.privateAddressInfo(this.soletraderData.principalPlaceOfBusiness);
    this.basicInfo = {
      title: 'Sole Trader',
      column: 12,
      type: 'text-only',
      data:  [
        {  text: 'ABN', value: this.soletraderData.abn, img: null },
        {  text: 'Name', value: this.soletraderData.name, img: null }
      ]
    };
    this.businessInfo = {
      title: 'Principal Place Of Business',
      column: 3,
      type: 'text-only',
      data: businessInfo
    };
    if (this.soletraderData.individual) {
      this.isPrivate = false;
      const result: any = this.kycConfigService.kycOrgArrangedData(this.soletraderData.individual);
      
      
      this.stackholderType = {
        title: '',
        column: 12,
        type: 'type-text-only',
        data: [
          {  text: 'Stackholder Type', value: this.soletraderData.stackholderType, img: null }
        ]
      };
      this.personalInfo = {
        title: 'Personal Information',
        column: 4,
        type: 'text-only',
        data: result.personalInfo
      };
      this.addressInfo = {
        title: 'Address Information',
        column: 3,
        type: 'text-only',
        data: result.addressInfo
      };

      this.addressVerifyInfo = {
        title: 'Address verification documents',
        column: 0,
        type: 'doc-only',
        data: result.addressVerifyDocs
      };

      this.idInfo = {
        title: 'Identity verification documents',
        column: 0,
        type: 'doc-data-only',
        data: result.idInfo
      };
    } 
  }

}
