import { Injectable } from '@angular/core';
import { FileUploadFilters } from '../interfaces/file-upload-filter.interface';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor() { }

  uploadFile(f: File, filters: FileUploadFilters, callback: any) {
    const reader = new FileReader();
    let fileExt: string | undefined = f.name.split('.').pop()?.toString(); // f.type.split('/')[1];
    let filterFlag = true;
    if (filters) {
      if (filters.ext) {
        if (!this.checkExt(fileExt, filters.ext)) {
          filterFlag = false;
          callback({
            status: false,
            msg: 'Invalid file extension.'
          });
        }
      }
      if (filterFlag && filters.size) {
        if (filters.size < f.size) {
          callback({
            status: false,
            msg: 'Max file size should be ' + this.getSizeText(filters.size)
          });
          filterFlag = false;
        }
      }
    }
    if (filterFlag) {
      reader.onload = (evt: any) => {
        if (evt && evt.target) {
          const fileResult = evt.target.result;

          if (filters && filters.dimension) {
            const image: any = new Image();
            image.src = typeof fileResult === 'string' ? fileResult : null;
            image.onload = () => {
              const dim = filters.dimension;
              switch (dim.operation) {
                case 'min':
                  if ((dim.height > image.height) || (dim.width > image.width)) {
                    callback({
                      status: false,
                      msg: 'Image minimum resolution should be ' + dim.width + 'x' + dim.height
                    });
                  } else {
                    callback({
                      preview: fileResult,
                      status: true
                    });
                  }
                  break;
                case 'max':
                  if ((dim.height < image.height) || (dim.width < image.width)) {
                    callback({
                      status: false,
                      msg: 'Image maximum resolution should be ' + dim.width + 'x' + dim.height
                    });
                  } else {
                    callback({
                      preview: fileResult,
                      status: true
                    });
                  }
              }
            };
          } else {

            callback({
              preview: fileResult,
              status: true
            });

          }

        }
      };
      reader.readAsDataURL(f);
    }
  }

  checkExt(fileExt: string | undefined, filterExts: string[]) {
    const result = filterExts.filter(element =>
      element.toUpperCase() === fileExt?.toString().toUpperCase()
    );
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getSizeText(bytes: any) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + ' GB';
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes > 1) {
      bytes = bytes + ' bytes';
    } else if (bytes === 1) {
      bytes = bytes + ' byte';
    } else {
      bytes = '0 bytes';
    }
    return bytes;
  }

  
  checkFileStatus(event: any, callback: any, fileCount?: number, fileTypes?: string[]) {

    const imageFile: File = event.target.files[0];

    const responseData: any = {
      fileType: true,
      previewPath: null,
      fileExt: imageFile.name.split('.').pop(),
      imageFile
    };

    if (fileTypes && fileTypes.length > 0) {
      if (!this.checkFileType(fileTypes, imageFile.type)) {
        responseData.fileType = false;
        callback(responseData);
      }
    }

    const reader = new FileReader();
    reader.onload = e => {
      responseData.previewPath = reader.result;
      callback(responseData);
    };
    reader.readAsDataURL(imageFile);

  }

  checkFileType(allowedTypes: string[], fileType: string): any | boolean {
    allowedTypes.forEach((type): any => {
      if (fileType === type) {
        return true;
      }
    });
    return false;
  }
}
