import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AllTransactionInterface } from 'src/app/core/interfaces/transaction';
import { TransactionService } from 'src/app/core/services/transaction.service';
import { CommonService } from 'src/app/core/utils/common.service';
import { HelperService } from 'src/app/core/utils/helper.service';
import { RefundDialogComponent } from '../refund-dialog/refund-dialog.component';
export interface PeriodicElement {
  transactionDate: string;
  code: string;
  email: string;
  fees: number;
  fullName: string;
  side: string;
  // User: string;
  // PropertyID: string;
  // BrickletID: string;
  amount: number;
  // Fee: number;
  total: number;
  refund:number
}

@Component({
  selector: 'app-refund-list',
  templateUrl: './refund-list.component.html',
  styleUrls: ['./refund-list.component.scss']
})
export class RefundListComponent implements OnInit {
  displayedColumns: string[] = [
    "refundDate",
    "fullName",
    "email",
    "carID",
    // "accountName",
    "carMake",
    "carModel",
    "quantity",
    // "side",
    // "brickletID",
    // "brickletPrice",
    // "fee",
    "refundAmount",
  ];
  FilterBy=[
    {value:'BUY',text:'BUY'},
    // {value:'SELL',text:'SELL'}
  ];
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | any;
  @ViewChild('from', { static: false, read: MatInput}) from: MatInput | any;
  @ViewChild('to', { static: false, read: MatInput}) to: MatInput | any;

  filterForm: FormGroup | any;

  autoTicks = false;
  disabled = false;
  invert = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;
  vertical = false;

  // value = 0;
  highValue = 100000;
  options = {
    floor: 10,
    ceil: 100000
  };

  length = 0;
  pageNo = 1;
  pageSize = 5;
  pageSizeOptions = [5, 10];
  pageIndex = 1;

  transactionRecords: any = null;
  isLoading = false;
  isbtnLoading = false;
  dataSource: any = null;
  minDate: any = null;

  constructor(
    private transactionService: TransactionService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private helper: HelperService
  ) { }

  ngOnInit() {
    this.filterForm = new FormGroup({
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      Transtype: new FormControl('BUY'),
      emailSearch: new FormControl(null),
      propertyCode: new FormControl(null),
      startValue: new FormControl(null),
      endValue: new FormControl(null),
      search :new FormControl(null),
    });

    // this.dataSource.paginator = this.paginator;

    this.getAllTransactionHistory();
  }

  filter(): any{
    this.filterForm.patchValue({
      startValue:this.value,
      endValue:this.highValue
    });
    // tslint:disable-next-line:max-line-length
    if((this.email.value === null || this.email.value === "") && this.from.value === null && this.to.value === null && this.transtype.value === null && this.propertyCode.value === null && this.startValue.value === null && this.endValue.value === null) {
      this.helper.info("Please choose filter options");
      return false;
    }
    if(this.from.value != "" && this.from.value != null) {
      if(this.to.value == null || this.to.value == "") {
        this.helper.info("Please Select To date!");
        return false;
      }
    } else if(this.from.value == "" || this.from.value == null){
      if(this.to.value != null && this.to.value != "") {
        this.helper.info("Please Select From date!");
        return false;
      }
    
    }
    if (!this.filterForm.valid) {
      if (!this.email.valid && this.email.hasError('email')) {
        this.helper.info("Please enter a valid email");
        return false;
      } 
    }
    this.filterForm.patchValue({
      startValue:this.value,
      endValue:this.highValue
    });
    // const filter=ELEMENT_DATA.filter(x=>x.Price>=this.filterForm.get("startValue").value && x.Price<= this.filterForm.get("endValue").value)
    // this.dataSource = new MatTableDataSource<PeriodicElement>(filter);
    // console.log(filter);
    this.setPage(0);
    this.getAllTransactionHistory();
  }

  reset(){
    this.filterForm.reset();
    this.value = 0;
    this.highValue = 100000;
  this.options = {
    floor: 10,
    ceil: 100000
  };
  this.minDate = null;
  this.setPage(0);
    this.getAllTransactionHistory();
  }

  onChangeValue(){
    this.filterForm.patchValue({
      startValue:this.value,
      endValue:this.highValue
    });


  }

  fromDateInput(event: MatDatepickerInputEvent<Date>): void {   
    
    if (this.from.value > this.to.value) {
      this.to.value = '';
    }
    this.minDate = event.value;
   
  }

  onPaginateChange(event: any) {
    this.pageIndex = ++event.pageIndex;
    this.pageNo = this.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllTransactionHistory();
  }

  getAllTransactionHistory(){

    this.isLoading = true;
    this.isbtnLoading = true;
    this.commonService.showLoader();


    
    const data = {
      PageNum: this.pageNo,
      PageSize: this.pageSize,
      StartDate: this.filterForm.controls.startDate.value === "" ? null : this.datePipe.transform(this.filterForm.get('startDate').value, 'yyyy-MM-dd'),
      EndDate: this.filterForm.controls.endDate.value === "" ? null : this.datePipe.transform(this.filterForm.get('endDate').value, 'yyyy-MM-dd'),
      // Search:this.filterForm.controls.search.value === "" ? null : this.filterForm.controls.search.value
      
      // TransStartDate: this.filterForm.controls.startDate.value === "" ? null : this.datePipe.transform(this.filterForm.get('startDate').value, 'yyyy-MM-dd'),
    
      // TransEndDate: this.filterForm.controls.endDate.value === "" ? null : this.datePipe.transform(this.filterForm.get('endDate').value, 'yyyy-MM-dd'),
      Email: this.filterForm.controls.emailSearch.value === "" ? null : this.filterForm.controls.emailSearch.value === null ? null : this.filterForm.controls.emailSearch.value.trim(),
      // Transtype: this.filterForm.controls.Transtype.value === "" ? null : this.filterForm.controls.Transtype.value,
      CarCode:this.filterForm.controls.propertyCode.value === "" ? null : this.filterForm.controls.propertyCode.value === null ? null : this.filterForm.controls.propertyCode.value.trim(),
      MinAmount:this.filterForm.controls.startValue.value === "" ? null : this.filterForm.controls.startValue.value,
      MaxAmount:this.filterForm.controls.endValue.value === "" ? null : this.filterForm.controls.endValue.value
      // DepositFromDate: this.fromDate ? this.fromDate.nativeElement.value : null,
      // DepositToDate: this.toDate ? this.toDate.nativeElement.value : null
    };
   
    this.transactionService.getAllRefundHistory(data).subscribe(
      (r: any) => {
        this.isLoading = false;
        this.isbtnLoading = false;
        this.commonService.hideLoader();
        if (r.data !== null) {
          const result: AllTransactionInterface = r.data.purchaseTransactionList;
          this.transactionRecords = result.purchaseTransactionList;
          this.length = result.totalRecord;
          this.dataSource = new MatTableDataSource(this.transactionRecords);

        }
      },
      (e: any) => {
        this.isLoading = false;
        this.isbtnLoading = false;
        this.commonService.hideLoader();
        // this.helper.error(e);
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );

  }

  get email() { return this.filterForm.get('emailSearch'); }
  get startDate() { return this.filterForm.get('startDate'); }
  get endDate() { return this.filterForm.get('endDate'); }
  get transtype() { return this.filterForm.get('Transtype'); }
  get propertyCode() { return this.filterForm.get('propertyCode'); }
  get startValue() { return this.filterForm.get('startValue'); }
  get endValue() { return this.filterForm.get('endValue'); }
  get search() { return this.filterForm.get('search'); }


  getEmailError(): any {
    if (this.email.hasError('emailSearch')) {
      return 'Please enter a valid email.';
    }
  }

  setPage(index: number) {
     this.paginator.pageIndex = index;
     this.pageIndex = index;
    this.pageNo = 1;
  }

  
}
