import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(
    private apiManagerService: ApiManagerService
  ) { }

  getPropertyList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.propertyList, data);
  }

  getPropertyDetails(GUID: any) {
    return this.apiManagerService.getRequest(`${RestEnds.propertyDetails}?carGUID=${GUID}`, null);
  }


  getAllStates() {
    return this.apiManagerService.getRequest(RestEnds.state, null);
  }

  getAllCountries(){
    return this.apiManagerService.getRequest(RestEnds.country, null);
  }

  getPropertyTypes() {
    return this.apiManagerService.getRequest(RestEnds.propertyType, null);
  }

  getPropertySubTypes(guid: any) {
    return this.apiManagerService.getRequest(RestEnds.propertySubType + "?ID_PropertyType=" + guid, null); 
  }

  getAmenities() {
    return this.apiManagerService.getRequest(RestEnds.amenity, null);
  }

  getAllAmenities() {
    return this.apiManagerService.getRequest(RestEnds.getAllAmenities, null);
  }

  getPropertyDeveloper() {
    return this.apiManagerService.getRequest(RestEnds.propertyDeveloper, null);
  }

  getPropertyManager() {
    return this.apiManagerService.getRequest(RestEnds.propertyManager, null);
  }

  getPropertyLeaseType() {
    return this.apiManagerService.getRequest(RestEnds.propertyLeaseType, null);
  }


  addNewProperty(data: any) {
    return this.apiManagerService.postRequest(RestEnds.property, data);
  }



  getAdminPropertyList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.propertyList, data);
  }


  addPropertyDocument(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.propertyDocument,
      data
    );
  }
  uploadPropertyPrimaryImages(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.propertyPrimaryImage,
      data
    );
  }
  uploadPropertyImages(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.propertyImageUpload,
      data
    );
  }

  addHistoricalSuburbGrowth(data: any) {
    return this.apiManagerService.postRequest(RestEnds.historicalSuburbGrowth, data);
  }

  addPropertyTermsCondition(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.propertyTermsCondition,
      data
    );
  }

  addLegal(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.propertyLegal,
      data
    );
  }


  addPropertyDeed(data: any){
    return this.apiManagerService.postRequest(
      RestEnds.uploadPropertyDeed,
      data
    );
  }


  getCityList(stateGuid: any) {
    return this.apiManagerService.getRequest(
      RestEnds.city + '?stateguid=' + stateGuid, null
    );
  }

  getCountryList(countryGuid: any) {
    return this.apiManagerService.getRequest(
      RestEnds.country + '?countryguid=' + countryGuid, null
    );
  }

  deleteProperty(guid: any) {
    return this.apiManagerService.deleteRequest(
      RestEnds.property + '?propertyGUID=' + guid
    );
  }

  createProperty(data: any) {
    return this.apiManagerService.postRequest('car', data);
  }

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }




  getBannerImage(guid: any){
    return this.apiManagerService.getRequest(
      `${RestEnds.getBannerImage}?carGUID=${guid}`, null
    );
  }

  getGalleryImage(guid: any){
    return this.apiManagerService.getRequest(
      `${RestEnds.getGalleryImage}?carGUID=${guid}`, null
    );
  }

  updatePropertyDeed(data: any){
    return this.apiManagerService.putRequest(
      RestEnds.updatePropertyDeeds,
      data
    );
  }

  getPropertyBasicDetails(guid: any) {
    return this.apiManagerService.getRequest(
      `${RestEnds.getPropertyBasicDetails}?carGUID=${guid}`, null
    );
  }

  updateProperty(data: any) {
    return this.apiManagerService.putRequest(RestEnds.updateProperty, data);
  }

  getAdminDraftList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.draftList, data);
  }

  updateBannerImages(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.updateBannerImage,
      data
    );
  }

  updateOdometerValue(data: any) {
    console.log("daataaaa",data);
    return this.apiManagerService.putRequest(RestEnds.updateOdometer+ '?carGUID=' + data.CarGUID + '&odometer=' + data.Odometer,null);
  }

  updateLegalDocument(data: any){
    return this.apiManagerService.putRequest(
      RestEnds.updateLegalDocument,
      data
    );
  }

  updateTermsDocument(data: any){
    return this.apiManagerService.putRequest(
      RestEnds.updateTermsDocument,
      data
    );
  }

  getPropertyDocuments(guid: any) {
    return this.apiManagerService.getRequest(
      `${RestEnds.getPropertyDocuments}?carGUID=${guid}`, null
    );
  }

  updatePropertyDocument(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.updatepropertyDocument,
      data
    );
  }

  publishProperty(guid: any){
      return this.apiManagerService.putRequest(`${RestEnds.publishProperty}/${guid}`, null);
  }

  removePropertyImage(data: any) {
    return this.apiManagerService.putRequest(`${RestEnds.updatePropertyImages}`, data);
  }

  cloneProperty(data: any) {
    return this.apiManagerService.putRequest(RestEnds.cloneProperty, data);
  }

  getBrickletPriceList(id: any) {
    return this.apiManagerService.getRequest(RestEnds.brickletPriceHistory + '?propertyGUID=' + id, null);
  }


  addPropertyVideo(data: any){
    return this.apiManagerService.postRequest(
      RestEnds.uploadPropertyVideo,
      data
    );
  }

  updateBPPTermsDocument(data: any){
    return this.apiManagerService.postRequest(
      RestEnds.updateBPPTermsDocumentFile,
      data
    );
  }
}
