import {
    Output,
    Directive,
    HostBinding,
    HostListener,
    EventEmitter,
  } from '@angular/core';

// tslint:disable-next-line:only-arrow-functions
const hasDragNDrop: boolean = (function() {
    const div = document.createElement('div');
    return ('draggable' in div) || ('ondragstart' in div && 'ondrop' in div);
  })();

@Directive({
    /* tslint:disable:directive-selector */
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[file-drop]',
  })
  export class FileDropDirective {
    @Output()
    public fileList: EventEmitter<FileList> | any = new EventEmitter();
    @Output()
    public files: EventEmitter<any> = new EventEmitter();

    @HostBinding('class.has-drag-n-drop')
    public hasDragNDrop: boolean;

    @HostBinding('class.is-over')
    public isOver: boolean = false;

    constructor() {
      this.hasDragNDrop = hasDragNDrop;
    }

    @HostListener('drop', ['$event'])
    public handleDrop(event: DragEvent | null | any) {
      this.handleDrag(event);

      this.fileList.emit(event.dataTransfer.files);
      this.files.emit(event);
    }

    @HostListener('drag', ['$event'])
    @HostListener('dragstart', ['$event'])
    @HostListener('dragenter', ['$event'])
    @HostListener('dragover', ['$event'])
    @HostListener('dragleave', ['$event'])
    @HostListener('dragend', ['$event'])
    public handleDrag(event: DragEvent) {
      event.stopPropagation();
      event.preventDefault();

      this.isOver = ['dragenter', 'dragover'].indexOf(event.type) >= 0;
    }
  }

