import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DepositService } from 'src/app/core/services/deposit.service';
import { CommonService } from '../../../core/utils/common.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from '../../../core/utils/helper.service';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit {

  length = 0;
  pageNo = 1;
  pageSize = 5;
  pageSizeOptions = [5, 10];
  pageIndex = 1;

  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  @ViewChild('from', { static: false }) fromDate!: ElementRef;
  @ViewChild('to', { static: false }) toDate!: ElementRef;

  displayedColumns: string[] = [
    'token',
    'fullName',
    'email',
    'depositDate',
    'gateway',
    'amount',
    'status'
  ];

  dataSource: any = null;
  depositRecords: any = null;
  isLoading = false;
  isbtnLoading = false;

  constructor(
    private depositService: DepositService,
    private commonService: CommonService,
    private helper: HelperService
  ) { }

  ngOnInit() {
    this.getDepositRecords();
  }

  getDepositRecords() {

    this.isLoading = true;
    this.isbtnLoading = true;
    const data = {
      PageNumber: this.pageNo,
      PageSize: this.pageSize,
      DepositFromDate: this.fromDate ? this.fromDate.nativeElement.value : null,
      DepositToDate: this.toDate ? this.toDate.nativeElement.value : null
    };
    this.depositService.getAllDepositRequests(data).subscribe(
      (r: any) => {
        this.isLoading = false;
        this.isbtnLoading = false;
        if (r.data !== null) {
          const result = r.data.depositHistory;
          this.depositRecords = result.records;
          this.length = result.totalRecord;
          this.dataSource = new MatTableDataSource(this.depositRecords);

        }
      },
      (e: any) => {
        this.isLoading = false;
        this.isbtnLoading = false;
        // this.helper.error(e);
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  onPaginateChange(event: any) {
    this.pageIndex = ++event.pageIndex;
    this.pageNo = this.pageIndex;
    this.pageSize = event.pageSize;
    this.getDepositRecords();
  }

  filterRecords() {
    this.getDepositRecords();
  }

  reset() {
    this.fromDate.nativeElement.value = '';
    this.toDate.nativeElement.value = '';
    this.getDepositRecords();
  }

}
