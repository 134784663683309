import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadImageComponent } from '../upload-image/upload-image.component';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss']
})
export class PropertyCardComponent implements OnInit {

  name: any;
  date: any;
  image: any;
  isActive: boolean = false;

  @Input() guid: any;
  @Input() typevalue: any;
  @Input() typename: any;
  @Input() item: any;
  @Input() isEditEnabled: boolean = false;

  @Output() outputUpdateEvent = new EventEmitter<any>();
  @Output() componentInstance = new EventEmitter<any>();
  @Output() guidEvent = new EventEmitter<any>();
  dialogRef:any;
  constructor(private dialog: MatDialog) { }

  ngOnInit() {
      this.name = this.item ? this.item.description : '';
      this.date = this.item ? this.item.createdDate : null;
      this.image = this.item ? this.item.imageName : null;
      this.isActive = this.item ? this.item.isActive : false;
  }


  edit(id: any) {
    this.dialogRef = this.dialog.open(UploadImageComponent, {
      width: '45%',
      data: { type: this.typevalue, name: this.typename, guid: id }
    });

    this.dialogRef.componentInstance.outputEvent.subscribe((data: any) => {
      this.outputUpdateEvent.emit(data);
    });
    this.componentInstance.emit(this.dialogRef);
    this.guidEvent.emit(id);
  }

}
