<div class="bootstrap-wrapper" @fade>
        <div class="container">
            <div class="row">
                <div class="col-10 p0 roboto-medium f16">{{type}}</div>
                <div class="col-2 p0">
                    <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20" (click)="onClose()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <form [formGroup]="bannerForm">
                <!-- <div class="p10 mt10"> -->
                    <mat-dialog-content>
                        <div class="row p2 justify-content-between minheight">
                            <div class="drop-zone mat-elevation-z8 col-md-12 col-lg-3 col-xl-3" for="file" file-drop (files)="submit($event)">
                                <div class="d-flex justify-content-center title">
                                    <label class="roboto-medium f14">Thumbnail image*</label>
                                </div>
                                <input type="file" id="file" (change)="handleInputChange($event)">
                                    <div *ngIf="!showImg" class="mt20">
                                        <div *ngIf="!isFileSelected; else showThumbnail">
                                            <div class="clearfix d-flex justify-content-center drag-label">
                                                <img src="../../../../assets/images/cloud_upload-24px (1).png">
                                            </div>
                                            <div class="clearfix d-flex justify-content-center drag-label">
                                                <span class="drag-drop-content">Drag and Drop image file</span>
                                            </div>
                                            <div class="clearfix d-flex justify-content-center drag-label mt5">
                                                <span class="slate-grey-two roboto-medium f14">or</span>
                                            </div>
                                            <div class="d-flex justify-content-center mt5">
                                                <label for="file" class="col-6 roboto-medium f12 btn btn-primary cursor-pointer">Browse
                                                    file</label>
                                            </div>
                                        </div>
                                        <ng-template #showThumbnail>
                                            
                                                <label for="file" class="d-flex justify-content-center preview-label p0" *ngIf="!readonly">
                                                    <img *ngFor="let preview of imageSrc" [src]="preview | safeHtml" class="preview-img" />
                                                </label>
                                                <!--- for readonly true-->
                                                <label class="d-flex justify-content-center p0" *ngIf="readonly">
                                                        <img *ngFor="let preview of imageSrc" [src]="preview | safeHtml" class="preview-img" />
                                                    </label>
                                        </ng-template>
                                    </div>

                                    <div class="mt-4 row">
                                        <span class="url-info" >Image Size Upto 1MB</span> <br/>
                                        <span class="url-info">Image Dimesion Min: 1280x400 pixels</span><br/>
                                    </div>
                          </div>
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-9">
                                <div class="mat-elevation-z8 row pl25 pr25 pb10">
                                    <mat-form-field class="col-12 f14 mt10 pl5 p0 pr5">
                                        <input matInput placeholder="Title*" class="roboto-regular f13" formControlName="title" maxlength="100">
                                    </mat-form-field>
                                    <div class="d-flex justify-content-between col-12 p0">
                                            <mat-form-field class="example-full-width col-6 f14 mt10 pl5 p0 pr5">
                                                <input  aria-label="Article" 
                                               placeholder="Article*"  autocomplete="off" type="search" 
                                                matInput formControlName="articleId" [matAutocomplete]="auto">
                                                <mat-autocomplete  #auto="matAutocomplete" [displayWith]="getArticle.bind(this)">
                                                  <mat-option *ngFor="let option of filteredOptions | async"
                                                  [value]="option.articleGUID">
                                                    {{option.articleName}}
                                                  </mat-option>
                                                </mat-autocomplete>
                                              </mat-form-field>
                                        <mat-form-field class="col-6 f14 mt10 pl5 p0 pr5">
                                            <mat-label>State*</mat-label>
                                             <mat-select class="roboto-regular f13" formControlName="state" >
                                                 <mat-option value="">
                                                     All State
                                                 </mat-option>
                                                 <mat-option *ngFor="let item of stateList" [value]="item.stateGUID">{{item.name}}</mat-option>
                                             </mat-select>
                                         </mat-form-field>
                                    </div>
                                  
                                    <mat-form-field class="col-12 f14 mt10 pl5 p0 pr5 mb-5">
                                        <textarea matInput placeholder="Description*" class="roboto-regular f13" formControlName="description" maxlength="4000"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                            
                        </div>
                       
                      
                    </mat-dialog-content>
    
                    <mat-dialog-actions class="d-flex justify-content-center pl5 mt30">
                        <button mat-raised-button color="primary" [disabled]="!bannerForm.valid || isbtnLoading" class="col-3 roboto-medium f12" [class.spinner]='isbtnLoading' (click)="onSubmit()" *ngIf="!readonly">Submit</button>
                    </mat-dialog-actions>
                <!-- </div> -->
            </form>
        </div>
    </div>