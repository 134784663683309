import { Component, Input, OnInit } from '@angular/core';
import Hls from 'hls.js';
import { PodcastService } from 'src/app/core/services/podcast.service';

@Component({
  selector: 'app-podcast-floating-window',
  templateUrl: './podcast-floating-window.component.html',
  styleUrls: ['./podcast-floating-window.component.scss']
})
export class PodcastFloatingWindowComponent implements OnInit {
  selPodcast = null;
  hls = new Hls();

  constructor(
    private podcastService: PodcastService
  ) { }

  ngOnInit(): void {

    this.podcastService.getFloatingWindowDetails.subscribe(
      (s) => {
        this.selPodcast = s;
        if(s){
          this.onAudioClick();
        }
      }
    );
  }

  onAudioClick() {
    
    setTimeout(() => {

      const audioContainer = document.getElementById('video-container') as HTMLDivElement;
      let audioSrc = this.selPodcast.streamingHlsURL;
      audioContainer.style.backgroundImage = 'url(' + this.selPodcast.thumbnailURL + ')';
      const audio = document.getElementById('audio') as HTMLAudioElement;
      if (audio.canPlayType('application/vnd.apple.mpegurl')) {

      } else if (Hls.isSupported()) {

        this.hls.loadSource(audioSrc);
        this.hls.attachMedia(audio);

      }
    }, 200);

  }

  onVideoClose() {
    this.hls.detachMedia();
    this.podcastService.updateFloatingWindow(null);
  }

}
