<div class="bootstrap-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-10 p0 roboto-medium f16">Agent User List</div>
                <div class="col-2 p0">
                    <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20">
                        <mat-icon>close</mat-icon>
                    </button> 
                </div>
            </div>
            
<div class="col-12 mt10 mb10 pl0 pr5">
        <div class="mat-elevation-z8 bg-light-grey-two">
          <div class="pt10 pb10 bootstrap-wrapper">
            <form [formGroup]="filterForm">
              <div class="row col-12">
                <mat-form-field class="col-2 f14">
                  <input
                    matInput
                    formControlName="startDate"
                    [matDatepicker]="fromDate"
                    placeholder="From Date"
                    class="roboto-regular f14"
                    #from
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="fromDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #fromDate></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="col-2 f14">
                  <input
                    matInput
                    formControlName="endDate"
                    [matDatepicker]="endDate"
                    placeholder="To Date"
                    class="roboto-regular f14"
                    #to
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="endDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="col-2 f14">
                  <mat-label>Filter by</mat-label>
                  <mat-select multiple formControlName="filterBy">
                    <mat-option *ngFor="let f of filterBy" [value]="f.value">{{
                      f.text
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-3 f14">
                  <mat-label>Email</mat-label>
                  <input matInput type="search" formControlName="emailSearch" />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <mat-card-actions  class="col-3 row">
                  <div class="col-6">
                    <button
                      mat-raised-button
                      class="roboto-medium f12"
                      color="primary"
                      (click)="filterRecords()"
                    >
                      Filter
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      mat-raised-button
                      class="bg-dusty-orange white roboto-medium f12"
                      color="warning"
                      (click)="reset()"
                    >
                      Reset
                    </button>
                  </div>
                </mat-card-actions>
              </div>
            </form>
          </div>
        </div>
      </div>
      <mat-dialog-content class="mat-typography">

      <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <div class="name-container">
                    <div class="profile-img-container">
                        <app-profile-image [img]="element?.profileImage"></app-profile-image>
                      <img
                        *ngIf="element.isInternationalUser"
                        src="assets/images/internet.svg"
                        alt="International User"
                        title="International User"
                        class="intl-user"
                      />
                    </div>
                    <div class="right-container">
                      <span class="name">{{ element.fullName }}</span>
                      <span
                        class="type"
                        [style.visibility]="element.isPremiumUsers ? 'visible' : 'hidden'"
                      >
                        <img src="assets/images/premium.jpg" alt="Premium Member" />
                        Premium user
                      </span>
                    </div>
                  </div>
                </td>
              </ng-container>
          
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email & Mobile</th>
                <td mat-cell *matCellDef="let element">
                  <span class="email"> {{ element.email }}</span>
                  <span class="phone"> {{ element.countryCode }}{{ element.phone }} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="accountName">
                  <th mat-header-cell *matHeaderCellDef>Account Name</th>
                  <td mat-cell *matCellDef="let element" class="bootstrap-wrapper">
                      <div class="accountName-container">
                        <div class="right-container">
                          <span class="text-left  d-flex text-wrap"> {{ element.accountName }}</span>
                        </div>
                      </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="accountType">
                  <th mat-header-cell *matHeaderCellDef>Account Type</th>
                  <td mat-cell *matCellDef="let element" class="bootstrap-wrapper">
                      <div class="accountType-container account-info">
                          <div class="right-container ">
                    <span class="d-flex text-info"> {{ element.userType }}</span>
                  </div>
                </div>
                  </td>
                </ng-container>
              
              <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef>Balance</th>
                <td mat-cell *matCellDef="let element">
                  $ {{ element.audBalance | number: "1.0-2" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="kyc1">
                <th mat-header-cell *matHeaderCellDef>KYC</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="!element.isKYC1FormSubmitted" class="txt-not-verify"
                    > Not Verified <img
                    src="assets/images/cancel.svg"
                    alt=""
                  /></span>

                  <span *ngIf="
                  element.isKYC1FormSubmitted &&
                  !element.isKYC1Approved &&
                  !element.isKYC1Rejected &&
                  element.userType !== 'ORGANISATION'
                " class="txt-not-verify"
                  >Not Verified</span
                >

                <span *ngIf="
                element.isKYC1FormSubmitted &&
                !element.isKYC1Approved &&
                !element.isKYC1Rejected &&
                element.userType === 'ORGANISATION'
              " class="txt-not-verify"
                >
              Not Verified</span>
                 
               
                  <span *ngIf="element.isKYC1Approved" class="txt-verified"
                    >Verified
                    <img src="assets/images/checked.png" alt="" />
                  </span>
                  <span
                    *ngIf="element.isKYC1Rejected"
                    class="txt-rejected"
                    (mouseenter)="rejectReason(element.userGUID, 1)"
                    >Rejected
                    <!-- <img src="assets/images/info-red.png" alt="" [matTooltip]="element.kyC1RejectReason"> -->
                    <img
                      src="assets/images/info-red.png"
                      alt=""
                      id="ky1rejectreason"
                      mat-raised-button
                      #tooltip="matTooltip"
                      matTooltip="{{ hoverRejectReason }}"
                      matTooltipPosition="right"
                      matTooltipClass="allow-cr"
                    />
                  </span>
                </td>
              </ng-container>
              
              
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <app-loader *ngIf="isLoading"></app-loader>
            <span *ngIf="length === 0">
              <div *ngIf="length === 0" class="roboto-medium f14 text-center mt5">
                No records found
              </div>
            </span>
         
          </div>
          
</mat-dialog-content>
<mat-paginator
[length]="length"
[pageSize]="pageSize"
[pageSizeOptions]="pageSizeOptions"
(page)="onPaginateChange($event)"
showFirstLastButtons
>
</mat-paginator>
</div>
</div>