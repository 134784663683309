import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NewsService } from '../../../core/services/news.service';
import { trigger, transition, animate, style } from '@angular/animations';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { UploadImageService } from '../../../core/services/upload-image.service';
import { ImageFiles } from '../upload-image/upload-image.component';
import { AgentService } from '../../../core/services/agent.service';
import { PatternValidator } from '../../validators/patternvalidator';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../../core/utils/common.service';
import { imageUploadConfig } from '../../../core/interfaces/image-upload.interface';
import { UploadImageOptions } from '../../../core/enum/constants';
import { HelperService } from '../../../core/utils/helper.service';
import { UsersService } from '../../../core/services/users.service';
import { debounceTime } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';
import { BPPBuySellService } from '../../../core/services/bpp-buy-sell.service';

@Component({
  selector: 'app-add-seller-permission',
  templateUrl: './add-seller-permission.component.html',
  styleUrls: ['./add-seller-permission.component.scss'],
  animations: [trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(1500, style({opacity: 1}))
    ])
  ])]
})
export class AddSellerPermissionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() type: any;
  typename: any;
  public form: FormGroup;
  
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef | any;




  GUID: any = null;
  readonly = false;
  Date: any;
  isbtnLoading = false;

  userList: any = [];
  accountList: any = [];
  AllUserList: any;
  AllAccountList: any;

  color: ThemePalette = 'primary';
  lblStatus = 'InActive';

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private agentService: AgentService,
    private dialog: MatDialog,
    private helper: HelperService,
    private userService: UsersService,
    private bppbuysellService: BPPBuySellService
  ) {
    this.dialogRef.disableClose = true;

    this.form = new FormGroup({
      guid: new FormControl(''),
      AccountGUID: new FormControl(''),
      selectedUsers: new FormControl('', Validators.required),
      selectedAccounts: new FormControl('', Validators.required),
      searchUser: new FormControl(''),
      searchAccount: new FormControl(''),
      isActive: new FormControl(true),
    });
   }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    this.getUsersList();
    this.searchUser();
    this.searchAccount();
  }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.GUID = this.data.guid;
    this.readonly = this.data.readOnly;
    this.cdr.detectChanges();
    // console.log(this.form);
  }





  



  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }
  onClose() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }




  onSubmit() {
    this.isbtnLoading = true;
    if(this.GUID != null) {
      this.onUpdateSellerPermission();
    } else {
     this.onSaveSellerPermission();
    }

  }


  onSaveSellerPermission() {

    const data = {
      AccountGUID: this.form.controls.selectedAccounts.value,
      IsEnable: this.form.controls.isActive.value

    }
    this.bppbuysellService.createSellerPermission(data).subscribe(
      (res: any) => {
        this.isbtnLoading = false;
        const result: any = res;
        if (result.data != null) {
          this.helper.success(result.data.message);
          this.form.reset();
          this.form.patchValue({
            guid: '',
            AccountGUID: '',
            selectedUsers: '',
            selectedAccounts: '',
            searchUser: '',
            searchAccount: '',
            isActive: false
          });
          this.bppbuysellService.changeMessage(true);
          this.onClose();
        } else {
          this.helper.error(result.data.message);
        }
      },
      (error: any) => {
        this.isbtnLoading = false;
        // console.log(error);
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );

  }

  onUpdateSellerPermission() {

    const data = {
      BPPWhiteListSellPermissionGUID: this.GUID,
      AccountGUID: this.form.controls.selectedAccounts.value,
      IsEnable: this.form.controls.isActive.value

    }
    this.bppbuysellService.updateSellerPermission(data).subscribe(
      (res: any) => {
        this.isbtnLoading = false;
        const result: any = res;
        if (result.data != null) {
          this.helper.success(result.data.message);
          this.form.reset();
          this.form.patchValue({
            guid: '',
            AccountGUID: '',
            selectedUsers: '',
            selectedAccounts: '',
            searchUser: '',
            searchAccount: '',
            isActive: false
          });
          this.bppbuysellService.changeMessage(true);
          this.onClose();
        } else {
          this.helper.error(result.data.message);
        }
      },
      (error: any) => {
        this.isbtnLoading = false;
        // console.log(error);
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );

  }

  
  openedChange(opened: boolean, inputName: any) {
    if (!opened) {
      this.form.get(inputName).patchValue('');
    }
  }
  onSelectedUser(event: any) {
    this.userService.getAccountListForProductOwnerMapping(event.value).subscribe(
      (res: any) => {
        if(res.data.accountList !== undefined){
          this.form.get("selectedAccounts").patchValue("");
        this.accountList = res.data.accountList;
        this.AllAccountList = res.data.accountList;
      }
      },
      (error) => {
        // this.helper.error(error);
        const err = error.message !== undefined ? error.message : error;
        if (err === 'Session Timed Out') {
          this.helper.info(err);
        } else {
          this.helper.error(err);
        }
      }
    );
  }

  clearSearch(event: any, inputName: any) {
    event.stopPropagation();
    this.form.get(inputName).patchValue("");
  }

  searchUser() {
    
    this.form
      .get("searchUser")
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value: any) => {
        let searchKey = this.form.get("searchUser").value;
        let userList = [];
        if (searchKey === "") {
          userList = this.AllUserList;
        } else {
          userList = this.AllUserList.filter((user: any) =>
            user.email.includes(searchKey)
          );
        }
        this.userList = userList;
      });
  }

  searchAccount(){
    this.form
      .get("searchAccount")
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value: any) => {
        let searchKey = this.form.get("searchAccount").value;
        let accountList = [];
        if (searchKey === "") {
          accountList = this.AllAccountList;
        } else {
          accountList = this.AllAccountList.filter((account: any) =>
            account.name.includes(searchKey)
          );
        }
        this.accountList = accountList;
      });
  }

  selectedUser(){
    this.form.get('selectedUsers').valueChanges.subscribe((val: any) => {
if (val !== null && val !== '') {
  this.userService.getAccountListForProductOwnerMapping(`${val}`).subscribe(
    (res: any) => {
      if (res.data.account !== undefined) {
        this.AllAccountList = res.data.account;
        this.accountList = res.data.account;
      }
    },
    (error: any) => {
      // this.helper.error(error);
      const err = error.message !== undefined ? error.message : error;
      if (err === 'Session Timed Out') {
        this.helper.info(err);
      } else {
        this.helper.error(err);
      }
    }
  );
}
    });
  }

  getaccountMapping(account: string) {
    let mapObjects = {};
    mapObjects = { ...mapObjects, accountGUID: account };
    return mapObjects;
  }

  getUsersList() {
    this.userService.getUsersForPropertyOwnerMapping().subscribe(
      (res: any) => {
        if (res.data.userList != null) {
          this.userList = res.data.userList;
          this.AllUserList = res.data.userList;
        }
      },
      error => {
        // this.helper.error(error);
        const err = error.message !== undefined ? error.message : error;
        if (err === 'Session Timed Out') {
          this.helper.info(err);
        } else {
          this.helper.error(err);
        }
      }
    );
  }

  hasError(fieldName: string) {
    return (
      this.form.controls[fieldName].touched &&
      this.form.controls[fieldName].status === 'INVALID'
    );
  }

  hasFieldError(fieldName: string, type: string) {
    const fieldControl = this.form.controls[fieldName]
      .errors as any;
    return fieldControl[type];
  }

  onToggle(event: any) {
    if (event.checked) {
      this.lblStatus = 'Active';
    } else {
      this.lblStatus = 'InActive';
    }
  }

  selectedAccountUser(event: any, record, accountList) {
    // this.GUID = record.BPPWhiteListSellPermissionGUID;
    this.userService.getAccountListForProductOwnerMapping(event.value).subscribe(
      (res: any) => {
        if(res.data.accountList !== undefined){
          this.form.get('selectedAccounts').patchValue('');
          this.accountList = res.data.accountList;
          this.AllAccountList = res.data.accountList;

          let accountList = [];

          accountList = this.AllAccountList.filter((account: any) =>
            account.name.includes(record.accountName)
          );

          this.accountList = accountList;

         

        this.form.patchValue({
          guid: record.BPPWhiteListSellPermissionGUID,
          selectedUsers: `${event.value}`,
          selectedAccounts: this.accountList[0].accountGUID,
          isActive: record.isEnabled,
        });
      }
      },
      (error) => {
        // this.helper.error(error);
        const err = error.message !== undefined ? error.message : error;
        if (err === 'Session Timed Out') {
          this.helper.info(err);
        } else {
          this.helper.error(err);
        }
      }
    );
  }
}
