import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class BankTransferService {
    private messageSource = new BehaviorSubject(false);
    currentMessage = this.messageSource.asObservable();
  constructor(
    private apiManagerService: ApiManagerService
  ) { }

  getAllBankTransfer(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getBanktransferList, data);
  }
  updateBanklist(data: any) {
    return this.apiManagerService.putRequest(RestEnds.updateDepositamount, data);
  }
  changeMessage(message: boolean) {
    this.messageSource.next(message)
  }
}
