import { Component, OnInit, OnChanges, Input, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../../../core/utils/helper.service';
import { CommonService } from '../../../core/utils/common.service';

@Component({
  selector: 'app-proof-document',
  templateUrl: './proof-document.component.html',
  styleUrls: ['./proof-document.component.scss']
})
export class ProofDocumentComponent implements OnInit, OnChanges {

  @Input() pdf = null;
  pdfUrl = null;
  loading = false;
  constructor(
    private helperService: HelperService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private commonService: CommonService
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(){
   // this.getPdf(this.pdf);
  }


  downloadFile = (data: any) => {
    this.loading = true;
    //debugger;
    this.helperService.getKYCPdfDecrypt(data).subscribe((blob) => {
      this.loading = false;
      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      link.download = data;
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      window.addEventListener('focus', e=>URL.revokeObjectURL(link.href), {once:true});
    },
    err => {
      this.loading = false;
      const error =
                    err.error.message || err.error.response.message || err.statusText;
        // this.helperService.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helperService.info(errSt);
        } else {
          this.helperService.error(errSt);
        }
        (error);
                    
    });    
  };

}
