import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(private apiManagerService: ApiManagerService, private http: HttpClient) { }



  getNewsList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getNewsList, data);
  }


  uploadPropertyImages(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.propertyImageUpload,
      data
    );
  }
  addNews(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.NewsURL,
      data
    );
  }
  editNews(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.NewsURL,
      data
    );
  }

  deleteNews(data: any) {
    return this.apiManagerService.deleteRequest(
      RestEnds.NewsURL + '/' +
      data
    );
  }

  getNews(data: any) {
    return this.apiManagerService.getRequest(
      RestEnds.NewsURL + '/' + data, null
    );
  }



  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }

  upload(data: any) {
    return this.apiManagerService.postRequest(
        RestEnds.uploadImage,
        data
      );
  }

  getNewsCategoryList() {
    return this.apiManagerService.getRequest(RestEnds.NewsCategory, null);
  }

  addNewsCategory(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.NewsCategory,
      data
    );
  }

  editNewsCategroy(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.NewsCategory,
      data
    );
  }

  getIsExistsCategory(id: any, name: any) {
    let data;
    if (id != null) {
      data = '?categoryID=' + id + '&category=' + name;
    } else {
      data = '?category=' + name;
    }
    return this.apiManagerService.getRequest(
      RestEnds.getIsExistsNewsCategory + data, null
    );
  }

  getNewsCategory(guid: any) {
    return this.apiManagerService.getRequest(
      RestEnds.NewsCategory + '/' + guid, null
    );
  }

  deleteNewsType(data: any) {
    return this.apiManagerService.deleteRequest(
      RestEnds.NewsCategory + '/' +
      data
    );
  }

}
