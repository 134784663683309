export enum Storage {
    IS_LOGGEDIN = 'isLoggedIn',
    AUTH_TOKEN = 'auth_token',
    REFRESH_TOKEN = 'refresh_token',
    TWOFA_TOKEN= 'twofa_token',
    ISNEED_2FA= 'isNeed2fa',
    ISCONFIG_2FA = 'isConfig2FA',
    PAGES = 'pages',
    GUID = 'guid',
    TYPE = 'type',
    FULL_NAME = 'full_name',
    ROLE = 'role',
    USER_TYPE = 'user_type',
    IS_ACCOUNT_SELECTED = 'is_account_selected',
    ACCOUNT_ID = 'accountGuid',
    CAPTCHA_TOKEN = 'captcha-token',
    IS_ENABLE2FA= 'isEnable2FA',
    ROLE_ROUTE = 'role_route',
}
