import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from './spinner.service';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(
    private router: Router,
    private spinner: SpinnerService,
    private toasterService: ToastrService,
    private loader: NgxSpinnerService
  ) {}

  goto(path: string) {
    this.router.navigateByUrl(path);
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  showLoader() {
    this.loader.show();
  }

  hideLoader() {
    this.loader.hide();
  }

}
