<div class="bootstrap-wrapper" @fade>
        <div class="container">
            <div class="row">
                <div class="col-10 p0 roboto-medium f16">{{type}}</div>
                <div class="col-2 p0">
                    <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20" (click)="onClose()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <form [formGroup]="videoForm">
                <!-- <div class="p10 mt10"> -->
                    <mat-dialog-content>
                        <div class="row p2 justify-content-between">
                            <div class="col-md-12 ">
                                <div class="mat-elevation-z8 row pl25 pr25 pb10">
                                    <mat-form-field class="col-12 f14 mt10 pl5 p0 pr5">
                                        <input matInput placeholder="Title*" class="roboto-regular f13" formControlName="title" maxlength="100">
                                        <mat-error *ngIf="videoForm.get('title')?.hasError('required')">Title is required</mat-error>
                                    </mat-form-field>              
                                    <mat-form-field class="col-12 f14 mt10 pl5 p0 pr5">
                                        <input   autocomplete="off" type="search"  matInput placeholder="URL*" class="roboto-regular f13" formControlName="videoURL"  (input)="updateVideoUrl(videoForm.get('videoURL')?.value)" (paste)="updateVideoUrl(videoForm.get('videoURL')?.value)">
                                        <mat-error *ngIf="videoForm.get('videoURL')?.hasError('required')">Video URL is required</mat-error>
                                        <mat-error *ngIf="videoForm.get('videoURL')?.value !== '' && hasError('videoURL') && hasFieldError('videoURL', 'validUrl')">Invalid Video URL</mat-error>
                                    </mat-form-field>
                                    <div>
                                        <span class="url-info" >(Video URL format should be - https://www.youtube.com/embed/TMcU_8RHb9s )</span>
                                    </div>

                                    <div class="card-item" *ngIf="previewURL !== null && previewURL !== ''">
                                        <iframe [src]="previewURL" width="240" height="180" scrolling="no" frameborder="1">
                                    </iframe>
                                    </div>

                                    <div class="d-flex justify-content-between col-12 p0">
                                        <mat-form-field class="col-6 f14 mt10 pl5 p0 pr5">
                                            <mat-label>Category*</mat-label>
                                             <mat-select class="roboto-regular f13" formControlName="category" >
                                                 <mat-option [value]="">
                                                     Select Category
                                                 </mat-option>
                                                 <mat-option *ngFor="let item of categoryList" [value]="item.subCategoryGUID">{{item.name}}</mat-option>
                                             </mat-select>
                                             <mat-error *ngIf="videoForm.get('category')?.hasError('required')">Category is required</mat-error>
                                         </mat-form-field>
                                        
                                         <mat-form-field class="col-6 f14 mt10 pl5 p0 pr5">
                                             <mat-label>State</mat-label>
                                              <mat-select class="roboto-regular f13" formControlName="state" >
                                                  <mat-option value="">
                                                      All State
                                                  </mat-option>
                                                  <mat-option *ngFor="let item of stateList" [value]="item.stateGUID">{{item.name}}</mat-option>
                                              </mat-select>
                                              <mat-error *ngIf="videoForm.get('state')?.hasError('required')">State is required</mat-error>
                                          </mat-form-field>              
                                    </div>
                                  
                                    <mat-form-field class="col-12 f14 mt10 pl5 p0 pr5">
                                        <textarea matInput placeholder="Description*" class="roboto-regular f13" formControlName="description" maxlength="4000"></textarea>
                                        <mat-error *ngIf="videoForm.get('description')?.hasError('required')">Description is required</mat-error>
                                    </mat-form-field>
                                    <div class="d-flex justify-content-between col-6 p0">
                                        <mat-form-field class="col-12 f14 mt10 pl5 p0 pr5">
                                            <input matInput placeholder="Written By*" class="roboto-regular f13" formControlName="WrittenBy" maxlength="60">
                                            <mat-error *ngIf="videoForm.get('WrittenBy')?.hasError('required')">Written By is required</mat-error>
                                            <mat-error *ngIf="videoForm.get('WrittenBy')?.hasError('maxlength')">You have more than 60 characters</mat-error>
                                        </mat-form-field>
                                        </div>
                                </div>
                             
                            </div>
                        </div>
                    </mat-dialog-content>
    
                    <mat-dialog-actions class="d-flex justify-content-center pl5 mt30">
                            <button mat-raised-button color="primary" [disabled]="videoForm.invalid || isbtnLoading" class="col-3 roboto-medium f12" [class.spinner]='isbtnLoading' (click)="onSubmit()" *ngIf="!readonly">Submit</button>
                    </mat-dialog-actions>
                <!-- </div> -->
            </form>
        </div>
    </div>