import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  templateUrl: './deposit-success-popup.component.html',
  styleUrls: ['./deposit-success-popup.component.scss']
})
export class DepositSuccessPopupComponent implements OnInit {

  record;

  constructor(public dialogRef: MatDialogRef<DepositSuccessPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.record = data;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close(false);
  }

}
