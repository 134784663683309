import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from './config.service';
import { RestEnds } from '../rest-api/rest-ends';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private toast: ToastrService,
    private configService: ConfigService,
    private http: HttpClient
  ) {

  }

  success(content: string, title?: string) {
    this.toast.success(content, title ? title : 'Success');
  }

  error(content: string, title?: string) {
    this.toast.error(content, title ? title : 'Error');
  }

  info(content: string, title?: string) {
    this.toast.info(content, title ? title : 'Info');
  }

  warning(content: string, title?: string) {
    this.toast.warning(content, title ? title : 'Warning');
  }

  canShowError(form: FormGroup, control: string) {
    const field = form.controls[control];
    return !field.valid && field.touched && field.dirty;
  }

  hasError(form: FormGroup, control: string, error: string) {
    return form.controls[control].hasError(error);
  }


  // previousPage() {
  //   this.location.back();
  // }

  formatCommaString(data: string[]) {
    let formatData = '';
    data.forEach((text) => {
      if (text) {
        const temp = text + ', ';
        formatData = formatData + temp;
      }
    });
    return formatData.slice(0, -2);
  }

  getUnixTimeStamp() {
    return Math.round((new Date()).getTime() / 1000);
  }


  getKYCImageDecrypt(filename: any) {
    return this.http.get(`${this.configService.getApiBaseUrl() + RestEnds.getKYCProofDecrypt}?filename=` + filename, { responseType: 'blob' });
  }

  getKYCPdfDecrypt(filename: any) {
        return this.http.get(`${this.configService.getApiBaseUrl() + RestEnds.getKYCProofDecrypt}?filename=` + filename, { observe: 'body', responseType: 'blob' as 'json' });
      }
  getUserProfileDecrypt(filename: any) {
    return this.http.get(`${this.configService.getApiBaseUrl() + RestEnds.getUserProfile}?filename=` + filename, { responseType: 'blob' });
  }

  getExcelFile(guid: any) {
    return this.http.get(`${this.configService.getApiBaseUrl() + RestEnds.getExcelFile}?propertyGUID=` + guid, { responseType: 'blob' });
  }

  getTermsFile(guid: any) {
    return this.http.get(`${this.configService.getApiBaseUrl() + RestEnds.getTermsFile}/` + guid);
  }

  getLegalFile(guid: any) {
    return this.http.get(`${this.configService.getApiBaseUrl() + RestEnds.getLegalFile}/` + guid);
  }

  getPropertyDeeds(guid: any){
    return this.http.get(`${this.configService.getApiBaseUrl() + RestEnds.getPropertyDeeds}/` + guid);
  }


  getPdfFile(url: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    // // tslint:disable-next-line:object-literal-shorthand
    return this.http.get(url, { headers, responseType: 'blob' });
    // return this.http.get(url, { responseType: 'blob' });
  }

  getBPPTerms() {
    return this.http.get(`${this.configService.getApiBaseUrl() + RestEnds.getBPPTermsFile}`);
  }


  uploadBPPTerms(guid: any) {
    return this.http.post(`${this.configService.getApiBaseUrl() + RestEnds.getTermsFile}`, guid);
  }
}
