import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class PropertyDeveloperService {
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(private apiManagerService: ApiManagerService) { }



  getPropertyDeveloperList() {
    return this.apiManagerService.getRequest(RestEnds.propertyDeveloperList, null);
  }


  addPropertyDeveloper(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addPropertyDeveloper,
      data
    );
  }
  editPropertyDeveloper(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.editPropertyDeveloper,
      data
    );
  }


  getPropertyDeveloperByGuid(data: any) {
    return this.apiManagerService.getRequest(RestEnds.getPropertyDeveloper + data, null);
  }

  getIsExists(guid: any, name: any) {
    return this.apiManagerService.getRequest(RestEnds.getPropertyIsExists + '?carDeveloperGUID=' + guid + '&description=' + name, null);
  }

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }

}
