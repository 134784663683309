import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UsersService } from 'src/app/core/services/users.service';
import { KycRejectPopupComponent } from '../kyc-reject-popup/kyc-reject-popup.component';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';

export interface DialogData {
  id: string;
}

@Component({
  selector: 'app-kyc2-verification',
  templateUrl: './kyc2-verification.component.html',
  styleUrls: ['./kyc2-verification.component.scss']
})
export class Kyc2VerificationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<Kyc2VerificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: UsersService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private helper: HelperService
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

  closeKyc2(): void {
    this.dialogRef.close({ refresh: true });
  }

  rejectKyc() {
    const dialogRef = this.dialog.open(KycRejectPopupComponent, {
      data: { reason: '', status: false }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status) {
        this.userService.rejectKycLevelTwo({
          UserGUID: this.data.id,
          Reason: result.reason,
          Level: 2
        }).subscribe(
          (s: any) => {
            this.helper.success("KYC Level 2 Rejected Successfully");
            this.closeKyc2();
          },
          (e: any) => {
            // this.helper.error(e);
            const errSt = e.message !== undefined ? e.message : e;
            if (errSt === 'Session Timed Out') {
              this.helper.info(errSt);
            } else {
              this.helper.error(errSt);
            }
          }
        );

      }

    });
  }

  approveKyc() {
    const dataDTO = {
      UserGUID: this.data.id,
      Level: '2'
    }
    this.userService.approveKyc(dataDTO).subscribe(
      (s: any) => {
        this.helper.success('KYC Level 2 Verified Successfully');
        this.closeKyc2();
      },
      (e: any) => {
        // this.helper.error(e);
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }


}
