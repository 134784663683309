import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UploadImageComponent } from '../upload-image/upload-image.component';
import { MatDialog } from '@angular/material/dialog';
import { UserDetailComponent } from '../user-detail/user-detail.component';
import { AgentUserListComponent } from '../agent-user-list/agent-user-list.component';
import { AgentService } from '../../../core/services/agent.service';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';

@Component({
  selector: 'app-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.scss']
})
export class AgentCardComponent implements OnInit {

  name: any;
  date: any;
  image: any;
  email: any;
  channelTitle: any;
  passportNumber: any;
  drivingLicenseNo: any;
  emailVerified: boolean = false;
  createdDate: any;
  agentGUID: any;

  @Input() guid: any;
  @Input() typevalue: any;
  @Input() typename: any;
  @Input() item: any;

  @Output() outputUpdateEvent = new EventEmitter<any>();
  @Output() componentInstance = new EventEmitter<any>();
  @Output() guidEvent = new EventEmitter<any>();
  @Output() loadEvent = new EventEmitter<any>();
  dialogRef: any;
  constructor(private dialog: MatDialog,
    private commonService: CommonService,
    private agentService: AgentService,
    private helper: HelperService) { }

  ngOnInit() {
      this.name = this.item ? this.item.fullName : '';
      this.date = this.item ? this.item.dob : null;
      this.image = this.item ? this.item.profileImg : null;
      this.email = this.item ? this.item.email : null;
      this.channelTitle = this.item ? this.item.channelTitle : null;
      this.passportNumber = this.item ? this.item.passportNumber : null;
      this.drivingLicenseNo = this.item ? this.item.drivingLicenseNo : null;
      this.emailVerified = this.item ? this.item.isEmailVerified : false;
      this.createdDate = this.item ? this.item.createdDate : null;
      this.agentGUID = this.item ? this.item.agentGUID : null;
  }


  // edit(id) {
  //   this.dialogRef = this.dialog.open(UploadImageComponent, {
  //     width: '45%',
  //     data: { type: this.typevalue, name: this.typename, guid: id }
  //   });

  //   this.dialogRef.componentInstance.outputEvent.subscribe((data) => {
  //     this.outputUpdateEvent.emit(data);
  //   });
  //   this.componentInstance.emit(this.dialogRef);
  //   this.guidEvent.emit(id);
  // }

  openUserList(guid: any){
    // console.log(guid);
    const dialogRef = this.dialog.open(AgentUserListComponent, {
      data: { id: 0, userType: 'usertype', update: '' },
    });
    dialogRef.componentInstance.guid = guid;
  }




  agentResendMail(_guid: any){
    this.loadEvent.emit(true);
    this.agentService.agentResendMail(_guid).subscribe(
      (res: any) => {
        const result: any = res;
        if (result.data != null) {
          this.loadEvent.emit(false);
          const data = result.data;
          this.agentService.changeMessage(true);
          this.helper.success(data.message);

          
        } else {
          this.loadEvent.emit(false);
          this.helper.error(result.message);
        }
      },
      (error: any) => {
        this.loadEvent.emit(false);
        this.helper.error(error);
      }
    );
  }

}

