<div class="bootstrap-wrapper" @fade>
        <div class="container">
            <div class="row">
                <div class="col-10 p0 roboto-medium f16">{{type}}</div>
                <div class="col-2 p0">
                    <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20" (click)="onClose()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <form [formGroup]="podcastForm">
                <!-- <div class="p10 mt10"> -->
                    <mat-dialog-content>
                        <div class="row p2 justify-content-between">
                            <div class="drop-zone mat-elevation-z8 col-md-12 col-lg-3 col-xl-3" for="file" file-drop (files)="submit($event)">
                                <div class="d-flex justify-content-center title">
                                    <label class="roboto-medium f14">Podcast thumbnail image*</label>
                                </div>
                                <input type="file" id="file" (change)="handleInputChange($event)">
                                
                                    <div *ngIf="!showImg" class="mt20">
                                        <div *ngIf="!isFileSelected; else showThumbnail">
                                            <div class="clearfix d-flex justify-content-center drag-label">
                                                <img src="../../../../assets/images/cloud_upload-24px (1).png">
                                            </div>
                                            <div class="clearfix d-flex justify-content-center drag-label">
                                                <span class="drag-drop-content">Drag and Drop image file</span>
                                            </div>
                                            <div class="clearfix d-flex justify-content-center drag-label mt5">
                                                <span class="slate-grey-two roboto-medium f14">or</span>
                                            </div>
                                            <div class="d-flex justify-content-center mt5">
                                                <label for="file" class="col-6 roboto-medium f12 btn btn-primary cursor-pointer">Browse
                                                    file</label>
                                            </div>
                                        </div>
                                        <ng-template #showThumbnail>
                                                <label for="file" class="d-flex justify-content-center preview-label p0" *ngIf="!readonly">
                                                    <img *ngFor="let preview of imageSrc" [src]="preview | safeHtml" class="preview-img" />
                                                </label>
                                                <!--- for readonly true-->
                                                <label class="d-flex justify-content-center p0" *ngIf="readonly">
                                                        <img *ngFor="let preview of imageSrc" [src]="preview | safeHtml" class="preview-img" />
                                                    </label>
                                        </ng-template>
                                    </div>

                                    <div class="mt-4 row">
                                        <span class="url-info" >Image Size Upto 1MB</span> <br/>
                                        <span class="url-info">Image Dimesion Min: 1280x400 pixels</span><br/>
                                    </div>
                            </div>
    
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-9">
                                <div class="mat-elevation-z8 row pl25 pr25 pb10">
                                    <mat-form-field class="col-12 f14 mt10 pl5 p0 pr5">
                                        <input matInput placeholder="Title*" class="roboto-regular f13" formControlName="title" maxlength="100">
                                        <mat-error *ngIf="podcastForm.get('title')?.hasError('required')">Title is required</mat-error>
                                    </mat-form-field>
                                    <div class="d-flex justify-content-between col-12 p0">
                                        <mat-form-field class="col-6 f14 mt10 pl5 p0 pr5">
                                            <mat-label>Category*</mat-label>
                                             <mat-select class="roboto-regular f13" formControlName="category" >
                                                 <mat-option [value]="">
                                                     Select Category
                                                 </mat-option>
                                                 <mat-option   *ngFor="let item of categoryList" [value]="item.podcastCatageryGUID">{{item.name}}</mat-option>
                                             </mat-select>
                                             <mat-error *ngIf="podcastForm.get('category')?.hasError('required')">Category is required</mat-error>
                                         </mat-form-field>
                                         <mat-form-field class="col-6 f14 mt10 pl5 p0 pr5">
                                            <mat-label>State</mat-label>
                                             <mat-select class="roboto-regular f13" formControlName="state" >
                                                 <mat-option value="">
                                                     All State
                                                 </mat-option>
                                                 <mat-option *ngFor="let item of stateList" [value]="item.stateGUID">{{item.name}}</mat-option>
                                             </mat-select>
                                             <mat-error *ngIf="podcastForm.get('state')?.hasError('required')">State is required</mat-error>
                                         </mat-form-field>
                                    </div>
                                    <mat-form-field class="col-12 f14 mt10 pl5 p0 pr5">
                                        <textarea matInput placeholder="Description*" class="roboto-regular f13" formControlName="description" maxlength="4000"></textarea>
                                        <mat-error *ngIf="podcastForm.get('description')?.hasError('required')">Description is required</mat-error>
                                    </mat-form-field>
                                    <div class="d-flex justify-content-between col-6 p0">
                                        <mat-form-field class="col-12 f14 mt10 pl5 p0 pr5">
                                            <input matInput placeholder="Written By*" class="roboto-regular f13" formControlName="WrittenBy" maxlength="60">
                                            <mat-error *ngIf="podcastForm.get('WrittenBy')?.hasError('required')">Written By is required</mat-error>
                                            <mat-error *ngIf="podcastForm.get('WrittenBy')?.hasError('maxlength')">You have more than 60 characters</mat-error>
                                        </mat-form-field>
                                        </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="mat-elevation-z8 row mt20 bg-status mb-2">
                            <div class="col-12 d-flex justify-content-between">
                                <div class="col-6 pt20">
                                    <label class="slate-grey-two f13 roboto-regular mr20" *ngIf="!viewfile">Podcast Audio*</label>
                                    <input type="file" formControlName="mediaFile" (change)="handleFiles($event)" *ngIf="!viewfile" id="upload" title="Upload File" />
                                </div>
                                <!-- <div class="mt20" [hidden]="!hlsUrl" > 
                                    <div><a id="hls"  target="_blank" >Play Audio</a></div>
                                   
                                </div> -->
                                <div class="" [hidden]="!hlsUrl">
                                    <audio id="edit-audio" class="audio-align" controls>
                                      </audio>
                                </div>
                                <div class="" [hidden]="hlsUrl">
                                    <audio id="audio" class="audio-align" controls>
                                        <source src="" id="src" />
                                      </audio>
                                </div>
                            </div>
                        </div>
                            <div class="mat-elevation-z8 row mt20 bg-status pr25 mb40">
                                <div class="col-12 d-flex justify-content-between pt20">
                                    <div class="col-6">
                                        <label class="slate-grey-two f13 roboto-regular">Is Banner</label>
                                    </div>
                                    <div class="col-2 pl10 d-flex justify-content-space-around">
                                        <!-- <label class="slate-grey-two f14 roboto-medium mt5">{{lblStatus}}</label> -->
                                        <mat-slide-toggle class="m10 pl10" formControlName="isBanner" [color]="color"   >
                                        </mat-slide-toggle>
                                    </div>
                                </div>

                        
                        </div>
                    
                    </mat-dialog-content>
                    <mat-dialog-actions class="d-flex justify-content-center pl5 mt30">
                            <button mat-raised-button color="primary" [disabled]="podcastForm.invalid || isbtnLoading" class="col-3 roboto-medium f12" [class.spinner]='isbtnLoading' (click)="onSubmit()" *ngIf="!readonly">Submit</button>
                    </mat-dialog-actions>
                <!-- </div> --->
            </form>
        </div>
    </div>