import {
  Component,
  OnInit,
  Input,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog
} from '@angular/material/dialog';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { PropertyService } from '../../../core/services/property.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { BuySellService } from '../../../core/services/buy-sell.service';
import { ActivatedRoute } from '@angular/router';
import { PriceHistoryComponent } from '../price-history/price-history.component';
declare var $: any;

export interface PropertyGallery {
  image: string;
}
export interface offer {
  key: string;
  value: string;
}
export interface ResponseDTO {
  response: {
    message: string;
    data: any;
  };
}
export interface propDetail {
  totalBricklet: Number;
  BrickletAvailable: Number;
  minSalePrice: string;
  maxSalePrice: string;
  brickletOwned: Number;
}
export interface Price {
  date: string;
  price: string;
  managerName: string;
}
export interface Document {
  documentName: string;
  documentDate: string;
  pdfFileName: string;
}
export interface BrickletValue {
  lastValuation: string;
  valuediff1: string;
  valuediffer2: string;
  latestSell: string;
  latestSellDays: Number;
}
export interface PropertyValue {
  lastValuation: string;
  valuediff1: string;
  valuediffer2: string;
  totalBricklets: string;
  totalPrice: string;
}

@Component({
  selector: 'app-draft-property-detail-new',
  templateUrl: './draft-property-detail-new.component.html',
  styleUrls: ['./draft-property-detail-new.component.scss']
})
export class DraftPropertyDetailNewComponent implements OnInit {
  @Input() guid: any = null;
  form!: FormGroup;
  type: any;
  coverImage: any;
  propertyDetails: any;
  addressInfo: any;
  // @ViewChild(HistoricalGrowthChartComponent, { static: true }) HistoricalGrowthChartComp;
  index: any;
  isLoading = false;
  chart: any;

  propertyGallery: PropertyGallery[] = [];

  offerInfo: offer[] = [];
  propDetail: propDetail | any;

  propertyDetailInfo: any;
  priceInfo: Price[] = [];
  propDocument: Document[] = [];
  brickletValue: BrickletValue | any;
  propertyValue: PropertyValue | any;
  propHighlights: any[] = [];

  propertyId = '';
  propertyAllDetails: any;

  sellAvailable = false;
  buyAvailable = false;

  isbtnLoading = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private helper: HelperService,
    private dialog: MatDialog,
    private propertyService: PropertyService,
    private buySellService: BuySellService,
    private route: ActivatedRoute
  ) {
    this.propertyId = this.route.snapshot.params['id'];
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    //this.getPropertyDetail();
    // this.checkTradable();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    this.type = this.data.type;
    this.guid = this.data.guid;
    this.cdr.detectChanges();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
    //$('#view-history').modal('hide');
  }

  onChange(idx: any) {
    this.index = idx;
  }

  getPropertyDetail() {
    this.commonService.showSpinner();
    this.commonService.showLoader();
    this.propertyService.getPropertyDetails(this.guid).subscribe(
      (s: any) => {
        this.propertyAllDetails = s.data.propertyDetails;
        this.propertyDetails = s.data.propertyDetails.propertyDetails;
        this.propertyGallery = s.data.propertyDetails.propertyGallery;
        this.propDocument = s.data.propertyDetails.propertyDocument;
        this.propHighlights = s.data.propertyDetails.propertyHighlights;
        this.offerInfo = s.data.propertyDetails.propertyDynamicAttributes;
        this.commonService.hideSpinner();
        this.commonService.hideLoader();
      },
      error => {
        this.commonService.hideSpinner();
        this.commonService.hideLoader();
      }
    );
  }

  checkTradable() {
    const data = {
      PropertyGUID: this.guid
    };
    forkJoin([
      this.buySellService.isBrickletAvailableToBuy(data),
      this.buySellService.isBrickletAvailableToSell(data)
    ]).subscribe(([b, s]: any) => {
      if (b) {
        console.log(b);
      }
      if (s) {
        console.log(s);
      }
    });
  }

  onView() {
    // this.toastrService.info('view inprogress');
    // $('#view-history').modal('show');
    const dialogRef = this.dialog.open(PriceHistoryComponent, {
      data: {
        id: this.guid,
        title: "Price History",
        btnDisable: false,
        titleDisable: false
        //KycTypeOfUserGUID: kycTypeOfUserGUID
      },
      width: "700px"
    });
    dialogRef.componentInstance.getPriceHistory(this.guid);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.refresh) {
        this.getPropertyDetail();
      }
    });
  }
  onBuy() {
    this.helper.info('buy inprogress');
  }
  onSell() {
    this.helper.info('sell inprogress');
  }
  onDownload(url: any) {
    this.helper.info('download inprogress');
  }
  onVisit() {
    this.helper.info('visit inprogress');
  }

  onClose() {
    this.dialogRef.close('all');
    this.dialog.closeAll();
  }

  onPublish() {
    this.isbtnLoading = true;
    this.propertyService.publishProperty(this.data.guid).subscribe(
      (s: any) => {
        this.isbtnLoading = false;
        this.commonService.hideSpinner();
        this.propertyService.changeMessage(true);
        this.helper.success(s.data.message);
        this.dialog.closeAll();
        this.commonService.goto('property/list');
      },
      (e: any) => {
        this.isbtnLoading = false;
        this.commonService.hideSpinner();
        // this.helper.error(e);
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

}
