import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BankTransferService } from '../../../core/services/banktransfer.service';
import { DepositAmountComponent } from '../deposit-amount/deposit-amount.component';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';

@Component({
  selector: 'app-bank-transfer',
  templateUrl: './bank-transfer.component.html',
  styleUrls: ['./bank-transfer.component.scss']
})
export class BankTransferComponent implements OnInit {
  length = 0;
  pageNo = 1;
  pageSize = 5;
  pageSizeOptions = [5, 10];
  pageIndex = 1;

  @ViewChild(MatSort, { static: true }) sort: MatSort | any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | any;

  @ViewChild('from', { static: false}) fromDate: ElementRef | any;
  @ViewChild('to', { static: false}) toDate: ElementRef | any;

  displayedColumns: string[] = [
    'name',
    'email',
    'BanktransferDate',
    'action'
  ];

  dataSource: any = null;
  banktransferRecords: any = null;
  isLoading = false;
  isbtnLoading = false;
  constructor(
    private banktransferService: BankTransferService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private helper: HelperService
  ) { }

  ngOnInit() {
    this.getBankTransfer();
  }

  getBankTransfer() {

    this.isLoading = true;
    this.isbtnLoading = true;
    const data = {
      PageNumber: this.pageNo,
      PageSize: this.pageSize,
      DepositFromDate: this.fromDate ? this.fromDate.nativeElement.value : null,
      DepositToDate: this.toDate ? this.toDate.nativeElement.value : null
    };
    this.banktransferService.getAllBankTransfer(data).subscribe(
      (r: any) => {
        this.isLoading = false;
        this.isbtnLoading = false;
        if (r.data !== null) {
          const result = r.data.bankTransfers;
          this.banktransferRecords = result.records;
          this.length = result.totalRecord;
          this.dataSource = new MatTableDataSource(this.banktransferRecords);

        }
      },
      (e) => {
        this.isLoading = false;
        this.isbtnLoading = false;
        this.helper.error(e);
      }
    );
  }

  onPaginateChange(event: any) {
    this.pageIndex = ++event.pageIndex;
    this.pageNo = this.pageIndex;
    this.pageSize = event.pageSize;
    this.getBankTransfer();
  }

  filterRecords() {
    this.getBankTransfer();
  }

  reset() {
    this.fromDate.nativeElement.value = '';
    this.toDate.nativeElement.value = '';
    this.getBankTransfer();
  }


  openDepostAmount(bankGUID: string, fullName: string, Email: string) {
    const DialogRef = this.dialog.open(DepositAmountComponent, {
      data: { bankGUID, fullName, Email }
  });

    DialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
          this.getBankTransfer();
      }
  });
  }
}
