import { Component, OnInit, AfterViewInit, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CoownerShipService } from '../../../core/services/coownership.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RejectPopupComponent } from '../reject-popup/reject-popup.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs';
import { CommonService } from '../../../core/utils/common.service';
import { CoOwnershipRequestDetails } from '../../../core/interfaces/coownership.interface';
import { HelperService } from '../../../core/utils/helper.service';
import { ApproveStaticRequestMessage, ApproveDialog } from '../../../core/enum/constants';
import { TitleTransferPriceHistoryComponent } from '../../title-transfer-price-history/title-transfer-price-history.component';

@Component({
  selector: 'app-co-ownership-request-detail',
  templateUrl: './co-ownership-request-detail.component.html',
  styleUrls: ['./co-ownership-request-detail.component.scss']
})
export class CoOwnershipRequestDetailComponent
  implements OnInit, AfterViewInit, OnDestroy {
  propertyRecord: any = [];
  resultsLength = 0;
  pageNo = 1;
  pageSize = 5;
  pageSizeOptions = [5, 10];
  pageIndex = 1;
  isLoading = false;

  propertyCode = null;
  propertyName = null;
  noofBricklet = null;

  guid = '';

  constructor(
    private coownerShipService: CoownerShipService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private helper: HelperService
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    // this.displayedRows$ = of(messages);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit() {
    // this.propertyCode = this.data.code;
    // this.propertyName = this.data.name;
    // this.noofBricklet = this.data.noofbricklet;
  }

  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  getDetails(id: any) {
    this.isLoading = true;
    const data = {
      propertyGUID: this.data.guid,
      pageNumber: this.pageNo,
      pageSize: this.pageSize
    };
    this.coownerShipService.getCoOwnerShipRequestDetailByGUID(data).subscribe(
      (r: any) => {
        this.isLoading = false;
        if (r.data !== null) {
          const result = r.data;
          const details: CoOwnershipRequestDetails | any =
            result.adminCoOwnershipRequestDetails;
          this.propertyRecord = details;
          this.resultsLength = result.totalRecord;

          if (this.propertyRecord.length === 0) {
            this.dialogRef.close();
            this.dialog.closeAll();
          } else {
            this.propertyName = details[0].propertyName;
            this.propertyCode = details[0].propertyCode;
            this.noofBricklet = details[0].totalNoOfBricklet;
          }
        }
      },
      (e: any) => {
        this.isLoading = false;
        // this.helper.error(e);
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  getTotalPrice_old(brickelt: any, price: any) {
    let total = 0;
    let buyingPrice = 0;
    let totalbricklet = 0;
    totalbricklet = brickelt === null || brickelt === undefined ? 0 : brickelt;
    buyingPrice = price === null || price === undefined ? 0 : price;
    total = totalbricklet * buyingPrice;

    return total;
  }

  getTotalPrice(price: any) {
    let total = 0;
    if (price.length > 0) {
      for (let i = 0; i < price.length; i++) {
        total = total + price[i].total * price[i].buyingCount;
      }
    }

    return total;
  }

  getInterest(totalBricklet: any, noofBricklet: any) {
    let interest = 0;
    let numberofBricklet = 0;
    let total = 0;

    total =
      totalBricklet === null || totalBricklet === undefined ? 0 : totalBricklet;
    numberofBricklet =
      noofBricklet === null || noofBricklet === undefined ? 0 : noofBricklet;
    interest = (numberofBricklet / totalBricklet) * 100;

    return interest;
  }

  onClose() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  onPaginateChange(event: any) {
    this.pageIndex = ++event.pageIndex;
    this.pageNo = this.pageIndex;
    this.pageSize = event.pageSize;
    this.getDetails(this.data.guid);
  }

  approve(provisionalBuyHeaderGUID: any) {
    const data = {
      ProvisionalBuyHeaderGUID: provisionalBuyHeaderGUID,
      Action: 'Approved'
    };
    this.coownerShipService.approveRequest(data).subscribe(
      (r: any) => {
        this.isLoading = false;
        if (r.data !== null) {
          const result: any = r;
          // this.commonService.toastSuccess(result.response.data.msg);
          this.helper.success('Request Approved Successfuly');
          this.getDetails(this.data.guid);
          this.coownerShipService.changeMessage(true);
        }
      },
      (error: any) => {
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  reject(provisionalBuyHeaderGUID: any) {
    const dialogRef = this.dialog.open(RejectPopupComponent, {
      data: { reason: '', status: false }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status) {
        this.coownerShipService
          .rejectRequest({
            ProvisionalBuyHeaderGUID: provisionalBuyHeaderGUID,
            Action: 'Rejected',
            RejectReason: result.reason
          })
          .subscribe(
            (s: any) => {
              this.helper.success('Request Rejected Successfuly');
              this.coownerShipService.changeMessage(true);
              this.getDetails(this.data.guid);
            },
            (e: any) => {
              // this.helper.error(e);
              const errSt = e.message !== undefined ? e.message : e;
              if (errSt === 'Session Timed Out') {
                this.helper.info(errSt);
              } else {
                this.helper.error(errSt);
              }
            }
          );
      }
    });
  }

  openApprovalDialoguebox(guid: any) {
    const message = ``;
    const dialogData = new ConfirmDialogModel(
      ApproveStaticRequestMessage.title,
      message,
      ApproveDialog.Yes,
      ApproveDialog.No,
      'request-title'
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '791px',
      height: '215px',
      data: dialogData,
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.approve(guid);
      }
    });
  }

  getaddress(data: any) {
    let retAddr = '';
    if (data.unitNo !== undefined && data.unitNo !== '') {
      retAddr = retAddr + data.unitNo + ',';
    }
    if (data.streetNo !== undefined && data.streetNo !== '') {
      retAddr = retAddr + data.streetNo + ',';
    }
    if (data.streetName !== undefined && data.streetName !== '') {
      retAddr = retAddr + data.streetName + ',';
    }
    if (data.streetType !== undefined && data.streetType !== '') {
      retAddr = retAddr + data.streetType + ',';
    }
    if (data.stateName !== undefined && data.stateName !== '') {
      retAddr = retAddr + data.stateName + ',';
    }
    if (data.country !== undefined && data.country !== '') {
      retAddr = retAddr + data.country + ',';
    }
    if (data.postalCode !== undefined && data.postalCode !== '') {
      retAddr = retAddr + data.postalCode;
    }
    return retAddr;
  }

  onView(record: any) {
    // this.toastrService.info('view inprogress');
    // $('#view-history').modal('show');
    const dialogRef = this.dialog.open(TitleTransferPriceHistoryComponent, {
      data: {
        id: this.guid,
        title: 'Shares Price',
        btnDisable: false,
        titleDisable: false,
        priceRange: record
        //KycTypeOfUserGUID: kycTypeOfUserGUID
      },
      width: '950px'
    });
    dialogRef.componentInstance.priceInfo = record;
    // dialogRef.componentInstance.getPriceHistory(this.guid);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.refresh) {
        // this.getPropertyDetail();
      }
    });
  }
}

