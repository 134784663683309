import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { PatternValidator } from '../../validators/patternvalidator';
import { UsersService } from '../../../core/services/users.service';

import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { HelperService } from '../../../core/utils/helper.service';
import { CommonService } from '../../../core/utils/common.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ReplaceManagerStaticMessage, ApproveDialog } from '../../../core/enum/constants';

export interface DialogData {
  id: string;
  userType: string;
  update: boolean;
  approve: boolean;
  KycTypeOfUserGUID: string;
  accountGUID: string;
  userRole: string;
}

@Component({
  selector: 'app-replace-manager',
  templateUrl: './replace-manager.component.html',
  styleUrls: ['./replace-manager.component.scss'],
})
export class ReplaceManagerComponent implements OnInit {

  public form: FormGroup;
  isbtnLoading = false;
  isbtnReplaceLoading = false;
  name: string = "";
  email: string = "";
  kyc: string = "";
  isKycVerified: boolean = false;
  isUser: boolean = false;
  isFlag = false;
  guid: string = "";

  constructor(
    private formbuilder: FormBuilder,
    private userService: UsersService,
    private commonService: CommonService,
    private helper: HelperService,
    public dialogRef: MatDialogRef<ReplaceManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog
  ) { 
    this.form = this.formbuilder.group({
      id: new FormControl(''),
      email: new FormControl('',[Validators.required, Validators.maxLength(60),
        PatternValidator(
          // tslint:disable-next-line:max-line-length
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )]),
      });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

  onSearch(){
    this.isbtnLoading = true;
    this.userService.getUserKYCDetail(this.f.email.value).subscribe(
      (r: any) => {
        this.isbtnLoading = false;
        const result = r.response !== undefined ? r.response : r;
        const detail = result.data.userDetails;
        this.isUser = true;
        this.isFlag = false;
        // this.commonService.toastSuccess(result.data.message);
        this.userService.changeMessage(true);
        this.name = detail.fullName;
        this.email = detail.email;
        this.isKycVerified = detail.isKYCVerified;
        this.guid = detail.userGUID;

        if(this.isKycVerified){
          this.kyc = "Verified";
        } else {
          this.kyc = "Not Verified";

        }

      },
      (e: any) => {
        this.isbtnLoading = false;
        this.isUser = false;
        this.isFlag = true;
        //this.commonService.toastError(e);
        this.name = "";
        this.email = "";
        this.kyc = "";
        this.guid = "";
      });
  }

  onReplaceManager(guid: any){
    this.isbtnReplaceLoading = true;
    this.userService.updateManager(this.guid, this.data.accountGUID).subscribe(
      (s: any) => {
        this.isbtnReplaceLoading = false;
        this.helper.success(s.data.message);
        this.dialogRef.close('all');
        this.userService.changeMessage(true);
      },
      (err: any) => {
        this.isbtnReplaceLoading = false;
        // this.helper.error(err);
        const errSt = err.message !== undefined ? err.message : err;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }


  openProceedDialoguebox() {
    const message = ``;
    const dialogData = new ConfirmDialogModel(
      ReplaceManagerStaticMessage.title,
      message,
      ApproveDialog.Yes,
      ApproveDialog.No,
      "replace-title"
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "591px",
      //height: "200px",
      data: dialogData,
      disableClose: false,
      id: "replace1"
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.onReplaceManager(this.guid);
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  hasError(fieldName: string) {
    return (this.form.controls[fieldName].touched) && (this.form.controls[fieldName].status === 'INVALID');
  }
}
