<div class="">
        <div class="modal-header">
            <h1 mat-dialog-title class="roboto-semibold f18">
                Deposit
            </h1>
        </div>
        <div mat-dialog-content>
                <form [formGroup]="depositForm" class="deposit-card">
                        <div class="form-group row ml-2">
                            <label for="fullName" class="control-label label-space f14 roboto-medium black">FullName</label>
                            <input type="text" value="" class="f14 exo2-medium" formControlName="txtName" #fullName>
                        </div>
                        <div class="form-group row ml-2">
                            <label for="email" class="control-label label-space email-txt f14 roboto-medium black">Email</label>
                            <input type="text" value="" class="f14 roboto-medium" formControlName="txtEmail" #email>
                        </div>
                        <div class="form-group row ml-2">
                            <label for="amount" class="control-label label-space amt-txt f14 roboto-medium black">Amount</label>
                            <input type="text" value="" class="f14 roboto-medium" NumberOnly formControlName="txtAmount" #amount>
                        </div>
                    </form>
        </div>
    
        <div mat-dialog-actions class="d-flex justify-content-end mb-4 pull-right">
            <button mat-raised-button color="warn" (click)="onDismiss()" class="confirm-btns btn btn-primary">No</button>
            <button mat-raised-button color="primary" (click)="onDepositAmount()" class="confirm-btns btn btn-danger" [class.spinner]="isbtnLoading">Yes</button>
        </div>
    
    </div>