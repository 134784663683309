<div class="bootstrap-wrapper">

    <div class="modal-header row">
      <div class="col-10 pl5 pr5">
        <h1 mat-dialog-title class="roboto-semibold f18 col-10 title" id="{{id}}">
          {{title}}
        </h1>
      </div>
      <div class="col-2 p0">
        <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div mat-dialog-content class="col-12">
        <table class="table viewhistory-table">
            <thead>
              <tr>
                
                <th class="price">
                  <div class="d-flex price-title">
                    Price
                    <!-- <span>(Per Shares)</span> -->
                  </div>
                </th>
                <th class="tax">
                  <div class="d-flex tax-title">
                    Tax
                  </div>
                </th>
                <th class="tax">
                  <div class="d-flex tax-title">
                    Total
                  </div>
                </th>
                <th class="date">
                    <div class="d-flex date-title">No. of Buy Shares
                    </div>
                  </th>
        
              </tr>
            </thead>
            <tbody>
        
            <tr *ngFor="let bricklet of priceInfo; let i=index;">
                <td>
                    <div class="d-flex flex-wrap text-left price">${{bricklet?.price | number : '0.0-2'}}</div>
                </td>
                <td>
                  <div class="d-flex flex-wrap text-left tax">${{bricklet?.tax | number : '0.0-2'}}</div>
              </td>
              <td>
                <div class="d-flex flex-wrap text-left tax">${{bricklet?.total | number : '0.0-2'}}</div>
            </td>
                <td>
                    <div class="d-flex flex-wrap text-left date"> {{bricklet?.buyingCount}}</div>
                </td>
            </tr>
        <tr *ngIf="priceInfo.length === 0">
            <td colspan="=2">
                <div class="d-flex flex-wrap text-center date">
                    No records found
                </div>
            </td>
        </tr>
            </tbody>
          </table>
    </div>
  
    <div class="col-12">
      <!-- <div mat-dialog-actions class="d-flex justify-content-end mb-4 pull-right row">
  
  
          </div> -->
  
      <!-- <mat-dialog-actions class="col-12">
        <div class="d-flex col-10 btn-items justify-content-flex-end buttons-div">
          <button mat-raised-button color="warn" (click)="onDismiss()"
            class=" btn bg-white cornflower-blue col-5 btn-border">{{no}}</button>
          <button mat-raised-button color="primary" (click)="onConfirm()"
            class=" btn bg-dusty-orange col-5 btn-border">{{yes}}</button>
        </div>
      </mat-dialog-actions>
   -->
    </div>
  
  
  </div>
  



