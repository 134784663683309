import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-kyc-reject-popup',
  templateUrl: './kyc-reject-popup.component.html',
  styleUrls: ['./kyc-reject-popup.component.scss']
})
export class KycRejectPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<KycRejectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { reason: string, status: boolean }
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

  onStatusClick(status: boolean): void {
    this.data.status = status;
    this.dialogRef.close(this.data);
  }

}
