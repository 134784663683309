<div class="bootstrap-wrapper" @fade>
    <div class="container">
        <div class="row">
            <div class="col-10 p0 roboto-medium f16">{{type}}</div>
            <div class="col-2 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20">
                    <mat-icon>close</mat-icon>
                </button> 
            </div>
        </div>
        <form [formGroup]="form">
            <div class="p10 mt10">
                <mat-dialog-content>
                    <div class="col-12 d-flex justify-content-center p0">
                        <div class="drop-zone" for="file">
                                <div class="p20 d-flex justify-content-start align-items-center border-bottom-ice-blue">
                                        <img  class="profile-img b-rad-circle mr20 c-pointer" [src]="avatarProfileImagePath"
                                            alt="">
                                            </div>

                            <!-- <img src="../../../../assets/images/news-category.png" class="preview-img" /> -->
                        </div>

                    </div>
                    <!-- <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                            <input matInput placeholder="News Category" class="roboto-regular f13" formControlName="category">
                        </mat-form-field>
                    </div> -->
                    <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                            <mat-label>First Name<span class="star-red">*</span></mat-label>
                            <input matInput formControlName="firstname" placeholder="First Name" class="roboto-regular f14" autocomplete="off">
                            <mat-error *ngIf="form.get('firstname')?.hasError('required')">First Name is required</mat-error>
                    
                        </mat-form-field>
                    </div>
                    <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                            <mat-label>Last Name<span class="star-red">*</span></mat-label>
                            <input matInput formControlName="lastname" placeholder="Last Name" class="roboto-regular f14" autocomplete="off">
                            <mat-error *ngIf="form.get('lastname')?.hasError('required')">Last Name is required</mat-error>
                    
                        </mat-form-field>
                    </div>
                        <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                            <mat-label>Email<span class="star-red">*</span></mat-label>
                            <input type="email" matInput formControlName="email" class="roboto-regular f14" id="email" placeholder="Email" autocomplete="off">
                            <!-- <mat-hint align="end">Not more then 60 characters long.</mat-hint> -->
                            <mat-error *ngIf="form.get('email')?.hasError('required')">Email is required</mat-error>
                            <mat-error *ngIf="form.get('email')?.hasError('maxlength')">You have more than 60 characters</mat-error>
                            <mat-error *ngIf="form.get('email')?.hasError('patternInvalid')">Invalid Email</mat-error>
                    
                        </mat-form-field>
                    </div>
                    <!-- <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                            <mat-label>Mobile No(optional)</mat-label>
                            <input type="text" matInput NumbersOnly [allowDecimals]="false" [allowSign]="false" formControlName="mobile" class="roboto-regular f14" id="mobile" autocomplete="off" placeholder="Mobile No(optional)" maxlength="15">
                            
                    
                        </mat-form-field>
                    </div> -->
                </mat-dialog-content>

                <mat-dialog-actions class="d-flex justify-content-center pl5 mb10">
                    <button mat-raised-button color="primary" class="col-5 roboto-medium f12" *ngIf="GUID === null" [disabled]="!form.valid || isbtnLoading" [class.spinner]='isbtnLoading'
                        (click)="onSave()">Register Conveyancer</button>
                    <!-- <button mat-raised-button color="primary" class="col-5 roboto-medium f12" *ngIf="GUID !== null" [disabled]="!form.valid || isbtnLoading" [class.spinner]='isbtnLoading'
                        (click)="onUpdate()">Update</button> -->
                </mat-dialog-actions>
            </div>
        </form>
    </div>
</div>