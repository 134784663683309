import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';

import { trigger, state, style, transition, animate } from '@angular/animations';
import { UsersService } from 'src/app/core/services/users.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserRolesService } from '../../../core/services/user-roles.service';
import PageConfigs from '../../../core/utils/access-level-config';

export interface DialogData {
  id: string;
  userType: string;
  update: boolean;
  approve: boolean;
  KycTypeOfUserGUID: string;
  accountGUID: string;
  userRole: string;
  manualapprove: boolean;
}

@Component({
  selector: 'app-user-info-layout',
  templateUrl: './user-info-layout.component.html',
  styleUrls: ['./user-info-layout.component.scss'],
  animations: [
    trigger("flyInOut", [
      state("in", style({ transform: "translateX(0)" })),
      transition("void => *", [
        style({ transform: "translateX(-100%)" }),
        animate(500),
      ]),
      transition("* => void", [
        animate(500, style({ transform: "translateX(100%)" })),
      ]),
    ]),
  ],
})
export class UserInfoLayoutComponent implements OnInit, OnDestroy {

  userGUID: any;
  type: any;
  isUpdateEnable: boolean = false;
  userActions = {
    updateKycVerify: false,
    update: false,
  };

  kycapprove: boolean = false;
  userType: string = "";
  accountGUID: string = "";
  userRole: string = "";
  ismanualapprove: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UserInfoLayoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private userRolesService: UserRolesService,
    private userService: UsersService
  ) { }

  ngOnInit() {
    // this.isUpdateEnable = this.data.update;
    // this.type = this.data.userType;
    // this.userGUID = this.data.id;
    this.kycapprove = this.data.approve;
    this.userType = this.data.userType;
    this.accountGUID = this.data.accountGUID;
    this.userRole = this.data.userRole;
    this.ismanualapprove = this.data.manualapprove;
    
  }

  ngOnDestroy(){
    this.dialog.closeAll();
    this.dialogRef.close();
  }

  getPagePermissions() {
    let permissions = this.userRolesService.getRoleAccessByPageName(
      PageConfigs.Users.name
    );
    this.userActions.updateKycVerify = permissions.updateKycVerify;
    this.userActions.update = permissions.update;
  }

  onClose(){
    this.userService.changeMessage(true);
  }

}
