<div class="bootstrap-wrapper">
        <div class="row" >
            <div class="col-4" *ngIf="basicInfo">
                <app-user-info-card [config]="basicInfo"></app-user-info-card>
            </div>
            <div class="col-8">
                <div class="row">
                    <div class="col-12" *ngIf="regOffice">
                        <app-user-info-card [config]="regOffice"></app-user-info-card>
                    </div>
                    <div class="col-12 mt10" *ngIf="businessInfo">
                        <app-user-info-card [config]="businessInfo"></app-user-info-card>
                    </div>
                </div>
            </div>
        </div>
    </div>