import { ModuleWithProviders, NgModule, Type, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// import { MatNativeDateModule, MatBadgeModule } from '@angular/material/badge';
import { DraftPropertyDetailComponent } from './components/draft-property-detail/draft-property-detail.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ModalImageComponent } from './components/modal-image/modal-image.component';
import { SafeHtml } from './pipes/safeHtml.pipe';
import { PropertyDetailComponent } from './components/property-detail/property-detail.component';
import { PropertyCardComponent } from './components/property-card/property-card.component';
import { LoaderComponent } from './loader/loader.component';
import { NavSidebarComponent } from './components/nav-sidebar/nav-sidebar.component';

import { UserInfoCardComponent } from './components/user-info-card/user-info-card.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SelectCheckAllComponent } from './components/select-check-all/select-check-all.component';
import { ProofImgComponent } from './components/proof-img/proof-img.component';
import { ProofDocumentComponent } from './components/proof-document/proof-document.component';
import { ProfileImageComponent } from './components/profile-image/profile-image.component';
import { UserInfoLayoutComponent } from './components/user-info-layout/user-info-layout.component';
import { PropertyGalleryComponent } from './components/property-gallery/property-gallery.component';
import { RejectPopupComponent } from './components/reject-popup/reject-popup.component';
import { ReplaceManagerComponent } from './components/replace-manager/replace-manager.component';
import { AddArticleComponent } from "./components/add-article/add-article.component";
import { AddVideoComponent } from "./components/add-video/add-video.component";
import { AddPodcastComponent } from "./components/add-podcast/add-podcast.component";
import { AddArticleBannerComponent } from "./components/add-article-banner/add-articlebanner.component";
import { FlexLayoutModule } from '@angular/flex-layout';
import { QuillModule } from 'ngx-quill';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material//radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBadgeModule } from '@angular/material/badge';
import { HistoricalGrowthChartComponent } from './components/historical-growth-chart/historical-growth-chart.component';
import { NotesDialogComponent } from './components/notes-dialog/notes-dialog.component';
import { DepositAmountComponent } from './components/deposit-amount/deposit-amount.component';
import { TwofactorResetComponent } from './components/twofactor-reset/twofactor-reset.component';
import { TwofactorDisableComponent } from './components/twofactor-disable/twofactor-disable.component';
import { KycRejectPopupComponent } from './components/kyc-reject-popup/kyc-reject-popup.component';
import { DepositPopupComponent } from 'src/app/shared/components/deposit-popup/deposit-popup.component';
import { KycIndividualComponent } from './components/kyc-individual/kyc-individual.component';
import { UserDetailComponent } from 'src/app/shared/components/user-detail/user-detail.component';
import { DepositSuccessPopupComponent } from 'src/app/shared/components/deposit-success-popup/deposit-success-popup.component';
import { OrgTrusteeComponent } from './components/org-trustee/org-trustee.component';
import { KycOrgComponent } from 'src/app/shared/components/kyc-org/kyc-org.component';
import { Kyc2VerificationComponent } from './components/kyc2-verification/kyc2-verification.component';
import { OrgUboComponent } from './components/org-ubo/org-ubo.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { OrgSettlorComponent } from './components/org-settlor/org-settlor.component';
import { NumbersOnly } from './directives/checkNumber.directive';
import { FileDropDirective } from './directives/file-drop.directive';
import { FileDirective } from './directives/file.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { OrgSmsfComponent } from './components/org-smsf/org-smsf.component';
import { OrgUnregulatedTrustComponent } from './components/org-unregulated-trust/org-unregulated-trust.component';
import { OrgRegulatedTrustComponent } from './components/org-regulated-trust/org-regulated-trust.component';
import { OrgPublicCompanyComponent } from './components/org-public-company/org-public-company.component';
import { OrgPartnershipComponent } from './components/org-partnership/org-partnership.component';
import { OrgSoleTraderComponent } from './components/org-sole-trader/org-sole-trader.component';
import { OrgPrivateCompanyComponent } from './components/org-private-company/org-private-company.component';
import { QrcodeViewComponent } from './components/qrcode-view/qrcode-view.component';
import { QrcodeKeyComponent } from './components/qrcode-key/qrcode-key.component';
import { PropertyDetailNewComponent } from './components/property-detail-new/property-detail-new.component';
import { PropertyDetailGalleryComponent } from './components/property-detail-gallery/property-detail-gallery.component';
import { DraftPropertyDetailNewComponent } from './components/draft-property-detail-new/draft-property-detail-new.component';
import { CoOwnershipHistoryDetailComponent } from './components/co-ownership-history-detail/co-ownership-history-detail.component';
import { CoOwnershipRequestDetailComponent } from './components/co-ownership-request-detail/co-ownership-request-detail.component';
import { BankTransferComponent } from './components/bank-transfer/bank-transfer.component';
import { AgentCardComponent } from './components/agent-card/agent-card.component';
import { AgentUserListComponent } from './components/agent-user-list/agent-user-list.component';
import { AddCppAgentComponent } from './components/add-cpp-agent/add-cpp-agent.component';
import { BuySellTransactionsComponent } from './components/buy-sell-transactions/buy-sell-transactions.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { PriceHistoryComponent } from './components/price-history/price-history.component';
import { AddCategoryComponent } from './components/add-category/add-category.component';
import { NewsCategoryComponent } from './components/news-category/news-category.component';
import { AddNewsComponent } from './components/add-news/add-news.component';
import { TitleTransferPriceHistoryComponent } from './title-transfer-price-history/title-transfer-price-history.component';
import { AddSubCategoryComponent } from './components/add-subcategory/add-subcategory.component';
import { VideoUploadComponent } from './components/video-upload/video-upload.component';
import { PropertyNewDetailPageComponent } from './components/property-new-detail-page/property-new-detail-page.component';
import {  NgxSlickJsModule } from 'ngx-slickjs';
import { DraftPropertyNewDetailPageComponent } from './components/draft-property-new-detail-page/draft-property-new-detail-page.component'
import { AllReportsComponent } from '../pages/reports/all-reports/all-reports.component';
import { PodcastFloatingWindowComponent } from './components/podcast-floating-window/podcast-floating-window.component';
import { UserOwnedPropertyListComponent } from './components/user-owned-property-list/user-owned-property-list.component';
import { BppCoownershipRequestDetailComponent } from './components/bpp-coownership-request-detail/bpp-coownership-request-detail.component';
import { BppLatePaymentInstallmentDetailComponent } from './components/bpp-late-payment-installment-detail/bpp-late-payment-installment-detail.component';
import { AddSellerPermissionComponent } from './components/add-seller-permission/add-seller-permission.component';
import { BppCoownershipHistoryDetailComponent } from './components/bpp-coownership-history-detail/bpp-coownership-history-detail.component';
import { UpdateBppSettingComponent } from './components/update-bpp-setting/update-bpp-setting.component';
import { NumericDirective } from './directives/numeric.validator';
import { UsersAccountBankDetailsPopupComponentComponent } from './components/users-account-bank-details-popup-component/users-account-bank-details-popup-component.component';
import { PropertyPayidPopupComponent } from './components/property-payid-popup/property-payid-popup.component';
import { NumericWithDecimalDirective } from './directives/numericwithdecimal.validator';
import { AmenitiesCardComponent } from './components/amenities-card/amenities-card.component';
import { DynamicAmenitiesComponent } from './components/dynamic-amenities/dynamic-amenities.component';
import { PendingOrderDetailComponent } from './components/pending-order-detail/pending-order-detail.component';
import { ConveyancerhistorydetailComponent } from './components/conveyancerhistorydetail/conveyancerhistorydetail.component';
import { SettlementDocumentComponent } from './components/settlement-document/settlement-document.component';
import { PendingOrderDetailAdminComponent } from './components/pending-order-detail-admin/pending-order-detail-admin.component';
import { ConveyancerHistoryDetailAdminComponent } from './components/conveyancer-history-detail-admin/conveyancer-history-detail-admin.component';
import { AddConveyancerComponent } from './components/add-conveyancer/add-conveyancer.component';
import { ConveyancerMappingAccountComponent } from './components/conveyancer-mapping-account/conveyancer-mapping-account.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { UpdateConveyancerMappingComponent } from './components/update-conveyancer-mapping/update-conveyancer-mapping.component';
import { AgmCoreModule } from '@agm/core';
import { DraftPropertyNewDetailPagesComponent } from './components/draft-property-new-detail-pages/draft-property-new-detail-pages.component';
import { PropertyNewDetailPagesComponent } from './components/property-new-detail-pages/property-new-detail-pages.component';
import { PropertyDetailGalleryNewComponent } from './components/property-detail-gallery-new/property-detail-gallery-new.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TransferConfirmDialogComponent } from './components/transfer-confirm-dialog/transfer-confirm-dialog.component';
import { RefundDialogComponent } from './components/refund-dialog/refund-dialog.component';
import { RefundListComponent } from './components/refund-list/refund-list.component';
import { DepositComponent } from './components/deposit/deposit.component';



const sharedComponents: any = [
  UploadImageComponent,
  DraftPropertyDetailComponent,
  ConfirmDialogComponent,
  ModalImageComponent,
  SafeHtml,
  PropertyDetailComponent,
  PropertyCardComponent,
  LoaderComponent,  
  AddArticleComponent,
  AddVideoComponent,
  AddPodcastComponent,
  AddArticleBannerComponent,
  NavSidebarComponent,
    UserInfoCardComponent,
    ImageViewerComponent,
    CarouselComponent,
    SpinnerComponent,
    PageNotFoundComponent,
    SelectCheckAllComponent,
    ProofImgComponent,
    ProofDocumentComponent,
    ProfileImageComponent,
    UserInfoLayoutComponent,
    ReplaceManagerComponent,
    RejectPopupComponent,
    // NumbersOnly,
    PropertyGalleryComponent,
    // NotesDialogComponent,
    HistoricalGrowthChartComponent,
    LoaderComponent,
    ModalImageComponent,
    ConfirmDialogComponent,
    DepositAmountComponent,
    // NewsCategoryComponent,
    // AddNewsComponent,
    TwofactorDisableComponent,
    TwofactorResetComponent,
    UserDetailComponent,
    DepositPopupComponent,
    DepositSuccessPopupComponent,
    KycIndividualComponent,
    KycRejectPopupComponent,
    KycOrgComponent,
    OrgUboComponent,
    OrgTrusteeComponent,
    OrgSettlorComponent,
    Kyc2VerificationComponent,
    // WithdrawReqBankDetailsPopupComponent,
    // WithdrawReqSuccessPopupComponent,
    // AddCppAgentComponent,
    AddUserComponent,
    AddCategoryComponent,
    AddSubCategoryComponent,
    // AgentUserListComponent,
    // CoOwnershipRequestDetailComponent,
    // CoOwnershipHistoryDetailComponent,
    NotesDialogComponent,
    OrgSmsfComponent,
    OrgUnregulatedTrustComponent,
    OrgRegulatedTrustComponent,
    OrgPublicCompanyComponent,
    OrgPartnershipComponent,
    OrgSoleTraderComponent,
    OrgPrivateCompanyComponent,
    QrcodeViewComponent,
    QrcodeKeyComponent,
    PropertyDetailNewComponent,
    PropertyDetailGalleryComponent,
    DraftPropertyDetailNewComponent,
    CoOwnershipRequestDetailComponent,
    CoOwnershipHistoryDetailComponent,
    BankTransferComponent,
    AgentCardComponent,
    AgentUserListComponent,
    AddCppAgentComponent,
    BuySellTransactionsComponent,
    AgentCardComponent,
    PriceHistoryComponent,
    TitleTransferPriceHistoryComponent,
    AddNewsComponent,
    NewsCategoryComponent,
    VideoUploadComponent,
    PropertyNewDetailPageComponent,
    DraftPropertyNewDetailPageComponent,
    PodcastFloatingWindowComponent,
    UserOwnedPropertyListComponent,
    AddSellerPermissionComponent,
    NumericDirective,
    NumericWithDecimalDirective,
    AmenitiesCardComponent,
    DynamicAmenitiesComponent,
    PendingOrderDetailComponent,
    ConveyancerhistorydetailComponent,
    SettlementDocumentComponent,
    PendingOrderDetailAdminComponent,
    ConveyancerHistoryDetailAdminComponent,
    AddConveyancerComponent,
    ConveyancerMappingAccountComponent,
    UpdateConveyancerMappingComponent,
    DraftPropertyNewDetailPagesComponent,
    PropertyNewDetailPagesComponent,
    PropertyDetailGalleryNewComponent,
    RefundListComponent,
    DepositComponent
];

const sharedModules: any  = [
  MatSidenavModule,
  MatButtonModule,
  MatInputModule,
  MatTableModule,
  MatDialogModule,
  MatSelectModule,
  MatCardModule,
  MatIconModule,
  MatToolbarModule,
  MatListModule,
  MatMenuModule,
  MatTabsModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatGridListModule,
  MatSliderModule,
  MatCheckboxModule,
  MatRadioModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  FlexLayoutModule,
  QuillModule.forRoot(),
  MatStepperModule,
  MatBadgeModule,
  NgxSliderModule,
  MatAutocompleteModule,
  NgxSlickJsModule.forRoot({
    links: {
      jquery: "https://code.jquery.com/jquery-3.4.0.min.js",
      slickJs: "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js",
      slickCss: "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css",
      slickThemeCss: "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
    }
}),
MatNativeDatetimeModule,
MatDatetimepickerModule,
AgmCoreModule.forRoot({
  // apiKey: 'AIzaSyAj7Jiy3HNBRJQR-14Arz6rkPuhX3JrUsc',
  apiKey: 'AIzaSyC9lGVACVaNLAT1DIKFkKiI8TwZRptEQx8',
  libraries: ['places']
}),
NgxSpinnerModule
// NgxMatDatetimePickerModule,
// NgxMatTimepickerModule,
// MatMomentDateModule
];

@NgModule({
  declarations: [
    sharedComponents,
    NumbersOnly,
    FileDropDirective,
    FileDirective,
    TooltipDirective,
    PriceHistoryComponent,
    TitleTransferPriceHistoryComponent,
    BppCoownershipRequestDetailComponent,
    BppLatePaymentInstallmentDetailComponent,
    BppCoownershipHistoryDetailComponent,
    UpdateBppSettingComponent,
    UsersAccountBankDetailsPopupComponentComponent,
    PropertyPayidPopupComponent,
    TransferConfirmDialogComponent,
    RefundDialogComponent,
    RefundListComponent,
    
    
    
    
    
    
  ],
  imports: [
    CommonModule,
    RouterModule,
    sharedModules
  ],
  exports: [
    sharedModules,
    sharedComponents,
    RouterModule,
    NumbersOnly,
    FileDropDirective,
    FileDirective,
    TooltipDirective
  ],
  providers: [
    sharedComponents,
    { provide: LOCALE_ID, useValue: 'en-EN' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-EN' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
