<div class="bootstrap-wrapper">
    <div class="container">
        <div class="row modal-header p5">
            <div class="col-10 roboto-medium f16 title">{{type}}</div>
            <div class="col-2">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20 title" (click)="onClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <form>
                <!-- <div class="p20 property-info-container">
                <app-loader *ngIf="isLoading"></app-loader>
            </div> -->
            <div class="p20 property-info-container" @detail>
                <div class="property-info d-flex align-items-center" [ngStyle]="{'background' : coverImage ? 'url('+ coverImage+')' : 'none' }">

                    <div class="property-info-inner-box ml40 pt30">
                        <p class="pl30 pt20 m-0 roboto-bold f28 white property-title text-ellipsis-multi-line">
                            {{propertyDetails?.propertyDetails.name}}</p>
                        <p class="pl30 pb20 pt5 m-0 roboto-regular f20 white property-address text-ellipsis">
                            {{addressInfo}}

                        </p>
                        <!-- AMENITIES-BOX --------------------------------------------------------------------->
                        <div class="row amenities-box m-0 ml0 pt10 pb10 pl10 mr1">

                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start align-items-center">
                                <img src="../../../../assets/images/bed.svg" alt="">
                                <span class="f16 roboto-bold white ml10">{{propertyDetails?.propertyDetails.beD_ROOM}}
                                    bed</span>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start align-items-center">
                                <img src="../../../../assets/images/bath.svg" alt="">
                                <span class="f16 roboto-bold white ml10">{{propertyDetails?.propertyDetails.batH_ROOM}}
                                    bath</span>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start align-items-center">
                                <img src="../../../../assets/images/car.svg" alt="">
                                <span class="f16 roboto-bold white ml10">{{propertyDetails?.propertyDetails.caR_PARKING}}
                                    cars</span>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start align-items-center">
                                <img src="../../../../assets/images/floorplan.svg" alt="">
                                <span class="f16 roboto-bold white ml10">{{propertyDetails?.propertyDetails.sqm}} sqm</span>
                            </div>

                        </div>
                        <!-- PROPERTY DATE --------------------------------------------------------------------->
                       
                        <div class="property-sale-time-box">
                            <div class="row m-0">
                              <div
                                *ngIf="propertyDetails?.propertyDetails.status == 2"
                                class="col-md-8 col-sm-8 col-xs-12 d-flex flex-column justify-content-center align-items-start pt15 pb15 pl30 bg-medium-green"
                              >
                                <p class="m-0 roboto-bold f30 white font-italic">
                                  Public-Sale Open
                                </p>
                                
                              </div>
                              <div
                                *ngIf="propertyDetails?.propertyDetails.status == 1"
                                class="col-md-8 col-sm-8 col-xs-12 d-flex flex-column justify-content-center align-items-start pt15 pb15 pl30 bg-dusty-orange"
                              >
                                <p class="m-0 roboto-bold f30 white font-italic">
                                  Pre-Sale Open
                                </p>
                                
                              </div>
                              <div
                                *ngIf="propertyDetails?.propertyDetails.status == 3"
                                class="col-md-12 col-sm-12 col-xs-12 d-flex flex-column justify-content-center align-items-start pt15 pb15 pl30 trade-bg"
                              >
                                <p class="m-0 roboto-bold f30 white font-italic">Tradable</p>
                              </div>
                              <div
                                *ngIf="propertyDetails?.propertyDetails.status != 3"
                                class="col-md-4 col-sm-4 col-xs-12 d-flex justify-content-center flex-column align-items-center sold-bricklet-box bg-dusty-orange"
                              >
                                <p class="roboto-medium white f20 m-0">Shares Sold</p>
                                <p class="roboto-medium f24 white m-0">
                                  {{ propertyDetails?.propertyDetails.soldBricklet }}/{{
                                    propertyDetails?.propertyDetails.noOfBricklet
                                  }}
                                </p>
                              </div>
                            </div>
                          </div>
                    </div>

                </div>


                <!-- ABOUT PROPERTY --------------------------------------------------------------------->

                <p class="roboto-bold f24 mt30">About this property</p>
                <p class="roboto-regular f14  text-justify mb20">{{propertyDetails?.propertyDetails.description}}</p>


                <!-- PROPERTY VALUES BOXES  --------------------------------------------------------------------->
                <div class="mt10">
                    <div class="row px15 mt-5 property-value-section">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mr5 mb15 property-value-box">

                                <div class="col-7 d-flex justify-content-start align-items-center">
                                    <p class="m-0 roboto-medium f16">Shares Price</p>

                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 torch-red">
                                        ${{propertyDetails?.propertyDetails.initialBrickletPrice|number:'0.0-2'}}</p>
                                </div>



                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mb15 property-value-box">


                                <div class="col-7 d-flex justify-content-start align-items-center">
                                    <p class="m-0 roboto-medium f16">Suburb Growth Last Year</p>

                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">{{propertyDetails?.propertyDetails.historicalSuburbGrowth}}%</p>
                                </div>


                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mr5 mb15 property-value-box">

                                <div class="col-7 d-flex justify-content-start align-items-center">
                                    <p class="m-0 roboto-medium f16">Total Number of Shares</p>

                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">{{propertyDetails?.propertyDetails.noOfBricklet}}</p>
                                </div>



                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mb15 property-value-box">
                                <div class="col-7 d-flex justify-content-start align-items-center">
                                    <p class="m-0 roboto-medium f16">1Y Potential Yield</p>

                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">{{propertyDetails?.propertyDetails.estNetRentalYield}}%</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mr5 mb15 property-value-box">

                                <div class="col-7 d-flex justify-content-start align-items-center">
                                    <p class="m-0 roboto-medium f16">Calculated Property Valuation</p>
                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">
                                        ${{propertyDetails?.propertyDetails.totalValueOfProperty|number:'0.0-2' }}</p>
                                </div>






                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mb15 property-value-box">
                                <div class="col-7 d-flex justify-content-start align-items-start flex-column">
                                    <p class="m-0 roboto-medium f16">Latest Property Valuation</p>
                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">
                                        ${{propertyDetails?.propertyDetails.latestPropertyValuation|number:'0.0-2'}}
                                    </p>
                                </div>
                            </div>
                        </div>


                        

                    </div>
                </div>

                <!-- <p class="roboto-bold f24 mt30">Tax Details</p>
                
                <div class="mt10">
                    <div class="row px15 mt-5 property-value-section">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mb15 property-value-box">
                                <div class="col-7 d-flex justify-content-start align-items-start flex-column">
                                    <p class="m-0 roboto-medium f16">Buyer Tax</p>
                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">
                                        ${{propertyDetails?.propertyDetails.latestPropertyValuation|number:'0.0-2'}}
                                    </p>
                                </div>
                            </div>
                        </div>
                
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mb15 property-value-box">
                                <div class="col-7 d-flex justify-content-start align-items-start flex-column">
                                    <p class="m-0 roboto-medium f16">Seller Tax</p>
                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">
                                        ${{propertyDetails?.propertyDetails.latestPropertyValuation|number:'0.0-2'}}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mb15 property-value-box">
                                <div class="col-7 d-flex justify-content-start align-items-start flex-column">
                                    <p class="m-0 roboto-medium f16">Buyer Stamp Duty</p>
                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">
                                        ${{propertyDetails?.propertyDetails.latestPropertyValuation|number:'0.0-2'}}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mb15 property-value-box">
                                <div class="col-7 d-flex justify-content-start align-items-start flex-column">
                                    <p class="m-0 roboto-medium f16">Buyer Property Transfer (%)</p>
                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">
                                        ${{propertyDetails?.propertyDetails.latestPropertyValuation|number:'0.0-2'}}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mb15 property-value-box">
                                <div class="col-7 d-flex justify-content-start align-items-start flex-column">
                                    <p class="m-0 roboto-medium f16">Buyer Administration Fees (%)</p>
                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">
                                        ${{propertyDetails?.propertyDetails.latestPropertyValuation|number:'0.0-2'}}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mb15 property-value-box">
                                <div class="col-7 d-flex justify-content-start align-items-start flex-column">
                                    <p class="m-0 roboto-medium f16">Seller Stamp Duty (%)</p>
                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">
                                        ${{propertyDetails?.propertyDetails.latestPropertyValuation|number:'0.0-2'}}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mb15 property-value-box">
                                <div class="col-7 d-flex justify-content-start align-items-start flex-column">
                                    <p class="m-0 roboto-medium f16">Seller Property Transfer (%)</p>
                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">
                                        ${{propertyDetails?.propertyDetails.latestPropertyValuation|number:'0.0-2'}}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row bg-white pt20 pb20 pl20 pr20 mb15 property-value-box">
                                <div class="col-7 d-flex justify-content-start align-items-start flex-column">
                                    <p class="m-0 roboto-medium f16">Seller Administration Fees (%)</p>
                                </div>
                                <div class="col-5 d-flex justify-content-end align-items-center">
                                    <p class="m-0 roboto-medium f20 cornflower-blue">
                                        ${{propertyDetails?.propertyDetails.latestPropertyValuation|number:'0.0-2'}}
                                    </p>
                                </div>
                            </div>
                        </div>
                
                    </div>
                </div> -->
                <!-- HISTORICAL GROWTH CHART  --------------------------------------------------------------------->
                <app-historical-growth-chart></app-historical-growth-chart>

                <!-- PROPERTY IMAGES --------------------------------------------------------------------->

                <div *ngIf="propertyDetails?.propertyGallery.length > 0" id="carouselExampleControls" class="carousel slide mt-4 mb-4"
                    data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item" *ngFor="let img of propertyDetails?.propertyGallery;let i=index;"
                            [ngClass]="{'active' : i == 0}">
                            <img class="d-block w-100 prop-image" src={{img.image}} alt="property image">
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>


                <div *ngIf="propertyDetails?.propertyGallery.length > 0" class="carousel slide mt-4 mb-4">
                        <!-- <app-carousel [images]="propertyDetails?.propertyGallery" (change)="onChange($event)"></app-carousel> -->
                </div>

                <!-- PROPERTY DOCUMENT SECTION --------------------------------------------------------------------->

                <div class="row mt20">
                    <div class="col-lg-3 col-md-12">
                        <div class="bg-white prop-owner-info-box p10">
                            <p class="roboto-medium f16 warm-grey mb10">Lease Type</p>
                            <div class="pl30 ml20 d-flex justify-content-center col-10 mt20 mb20">
                                <img class="prop-logo" *ngIf="propertyDetails?.propertyDetails.leaseType" [src]="propertyDetails?.propertyDetails.leaseType">
                            </div>

                            <p class="roboto-medium f16 warm-grey mb10">Property Developer</p>
                            <div class="pl30 ml20 d-flex justify-content-center col-10 mt20 mb20">
                                <img class="prop-logo" *ngIf="propertyDetails?.propertyDetails.propertyDeveloper" [src]="propertyDetails?.propertyDetails.propertyDeveloper">
                            </div>

                            <p class="roboto-medium f16 warm-grey mb10">Property Manager</p>
                            <div class="pl30 ml20 d-flex justify-content-center col-10 mt20 mb20">
                                <img class="prop-logo" *ngIf="propertyDetails?.propertyDetails.propertyManager" [src]="propertyDetails?.propertyDetails.propertyManager">
                            </div>
                        </div>
                    </div>
                    <div class=" col-lg-9 col-md-12">
                        <div>
                            <table *ngIf="propertyDetails?.propertyDocument.length > 0" class="table document-table"
                                border="0.5">
                                <thead>
                                    <tr class="bg-melrose table-header">
                                        <th class="w-50 f14 roboto-bold text-left pl30 ship-cove">Documents name</th>
                                        <th class="w-25 f14 roboto-bold text-center ship-cove">Date added</th>
                                        <th class="w-25 f14 roboto-bold text-center ship-cove">PDF Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of propertyDetails?.propertyDocument">
                                        <td class="pt20 pb20 pl10 pr10 w-50  f12 roboto-regular text-left pl30">{{file.documentName}}</td>
                                        <td class="pt20 pb20 pl10 pr10 w-25  f12 roboto-regular text-center">
                                            {{file.documentDate | date: 'dd MMM yyyy'}}
                                        </td>
                                        <td class="pt20 pb20 pl10 pr10 w-25 roboto-regular f12 text-center"><a [href]="file.pdfFileName"
                                                target="_blank" class="c-pointer view-link black">View document</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>







            <!-- <div class="p20 mt10">

                <mat-dialog-actions class="d-flex justify-content-center pl5 pr3">

                </mat-dialog-actions>
            </div> -->
        </form>
    </div>
</div>