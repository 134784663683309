import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';
import { HelperService } from '../../../core/utils/helper.service';
import { CommonService } from '../../../core/utils/common.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {
  l5OT: string = '';
  xt8y: string = '';
  name: string = '';
  emailConfirmationDTO: EmailConfirmationDTO = {} as any;

  constructor(
    private route: ActivatedRoute,
    private helper: HelperService,
    private authService: AuthService,
    private commonService: CommonService,
    private router: Router
  ) {
    this.route.params.subscribe(params => {
      this.l5OT = params["l5OT"];
      this.xt8y = params["xt8y"];
      this.name = params["name"];
    });
  }

  ngOnInit(): void {
    this.verifyEmail();
  }

  verifyEmail() {
    this.commonService.showSpinner();
    this.commonService.showLoader();
    this.emailConfirmationDTO.l5OT = this.l5OT;
    this.emailConfirmationDTO.xt8y = this.xt8y;
    
    this.authService.verifyEmail(this.emailConfirmationDTO).subscribe((res: any) => {
      let result: any = res;
      if (result.data !== null) {
        this.commonService.hideSpinner();
        this.commonService.hideLoader();
        this.router.navigate(['/login']);
        this.helper.success(result.msg);
      } else {
        this.commonService.hideSpinner();
        this.commonService.hideLoader();
        this.router.navigate(['/login']);
        this.helper.error(result.msg);
      }
    },
    (error: any) => {    
      this.commonService.hideSpinner();
      this.commonService.hideLoader();
      this.router.navigate(['/login']);
      this.helper.error(error);
    }
      );
  }

}

export class EmailConfirmationDTO {
  'l5OT': string;
  'xt8y': string;

}