<h2 mat-dialog-title [ngClass]="titleDisable ? 'mt-title': ''">
    <span>{{title}}</span>
    <span mat-dialog-close *ngIf="!titleDisable">X</span>
</h2>
<mat-dialog-content class="mat-typography" [ngClass]="titleDisable ? 'mh-none' : ''">
    <div class="bootstrap-wrapper">
        <div class="row mt10">
            <div class="col-12">
                <app-user-info-card [config]="entityProfile"></app-user-info-card>
            </div>
        </div>
        

        <div class="row mt-15" *ngIf="isSoleTrader">
            <div class="col-12" id="tabcontent">
                <mat-tab-group [(selectedIndex)]="selectedTab">
                    <mat-tab label="Sole Trader">
                        <div class="col-12 mt10 mb10">
                            <app-org-sole-trader [soletraderData]="emitData.soletrader"></app-org-sole-trader>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        
        <div class="row mt-15" *ngIf="isPartnerShip">
            <div class="col-12" id="tabcontent">
                <mat-tab-group [(selectedIndex)]="selectedTab">
                    <mat-tab label="PartnerShip">
                        <div class="col-12 mt10 mb10">
                            <app-org-partnership [partnershipData]="emitData.partnership"></app-org-partnership>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        
        <div class="row mt-15" *ngIf="isPublicCompany">
            <div class="col-12" id="tabcontent">
                <mat-tab-group [(selectedIndex)]="selectedTab">
                    <mat-tab label="Public Company">
                        <div class="col-12 mt10 mb10">
                            <app-org-public-company [publicCompanyData]="emitData.publicCompany"></app-org-public-company>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        
        <div class="row mt-15" *ngIf="isPrivate">
            <div class="col-12" id="tabcontent">
                <mat-tab-group [(selectedIndex)]="selectedTab">
                    <mat-tab label="Private Company">
                        <div class="col-12 mt10 mb10" >
                            <app-org-private-company [privateCompanyData]="emitData.privateCompany"></app-org-private-company>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        
        <div class="row mt-15" *ngIf="isRegulatedTrust">
            <div class="col-12" id="tabcontent">
                <mat-tab-group [(selectedIndex)]="selectedTab">
                    <mat-tab label="Regulated Trust">
                        <div class="col-12 mt10 mb10">
                            <app-org-regulated-trust [regulatedTrustData]="emitData.regulatedTrust"></app-org-regulated-trust>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        
        <div class="row mt-15" *ngIf="isUnRegulatedTrust">
            <div class="col-12" id="tabcontent">
                <mat-tab-group [(selectedIndex)]="selectedTab">
                    <mat-tab label="UnRegulated Trust">
                        <div class="col-12 mt10 mb10">
                            <app-org-unregulated-trust [unregulatedTrustData]="emitData?.unregulatedTrust"></app-org-unregulated-trust>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>

        <div class="row mt-15" *ngIf="isSMSF">
                <div class="col-12 p0" id="tabcontent">
                    <!-- <mat-tab-group>
                        <mat-tab label="SMSF"> -->
                            <div class="col-12 mt10 mb10">
                                <app-org-smsf [smsfData]="emitData.smsf"></app-org-smsf>
                            </div>
                        <!-- </mat-tab>
                    </mat-tab-group> -->
                </div>
            </div>
        
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="!dialogBtnDisable" class="kyc-org">
    <div class="bootstrap-wrapper">
        <div class="row mt-20">
            <!-- <div class="col-6">
                <button class="btn-watermelon" type="button" (click)="rejectKyc()">Reject</button>
            </div> -->
            <div class="col-12">
                <button class="btn-cornflower-blue" type="button" [class.spinner]="isbtnLoading" (click)="approveKyc()">Approve</button>
            </div>
        </div>
    </div>
    <!-- <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>