import { animate, group, query, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit, OnChanges } from '@angular/core';
import { HelperService } from '../../../core/utils/helper.service';
import { Slick } from 'ngx-slickjs';


export interface propertyGallery{
  image: string;
}
@Component({
  selector: 'app-property-detail-gallery-new',
  templateUrl: './property-detail-gallery-new.component.html',
  styleUrls: ['./property-detail-gallery-new.component.scss'],
  animations: [
    trigger('thumbState', [
      state('active', style({ transform: 'translateX({{position}})' }), { params: { position: '10px' } }),
      state('inactive', style({ transform: 'translateX({{position}})' }), { params: { position: '10px' } }),
      transition('* <=>*', animate('260ms ease-in'))
    ])
  ]
})
export class PropertyDetailGalleryNewComponent implements OnInit, AfterContentInit, OnChanges {
  @Input() propertyGallery: propertyGallery[] = [];
  @Input() propertyId: string = '';
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<number> = new EventEmitter<number>();
  slideControl: any[] = []
  counter = 0;

  _propertyGallery: any[] = [];
  videoInPlay = false;
  constructor(
    private helper: HelperService
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }

  ngOnChanges(changes: any){

    let tempSliders = [];
    this.propertyGallery.forEach((r: any, i) => {
      if (this.isVideo(r.image)) {
        tempSliders.push({ video: r.image })
      } else {
        tempSliders.push({ image: r.image })
      }
    });
    this._propertyGallery = tempSliders;
    // console.log(this._propertyGallery);
  }

  ngAfterContentInit() {
    
    this.change.emit(0);
  }

  onClickThumb(event: any) {
    const total = this.propertyGallery.length - 1;
    this.counter = event.layerX < 150 ? this.dec(total) : this.inc(total);
    this.change.emit(this.counter);
  }

  inc(total: any) {
    return (this.counter < total ) ? this.counter + 1 : 0 ;
  }

  dec(total: any) {
    return (this.counter > 0 ) ? this.counter - 1 : total ;
  }

  next() {
    const total = this.propertyGallery.length - 1;
    this.counter =  this.inc(total);
    this.pauseVideo();
    this.change.emit(this.counter);
  }

  prev() {
    const total = this.propertyGallery.length - 1;
    this.counter = this.dec(total);
    this.pauseVideo();
    this.change.emit(this.counter);
  }

  onWatch(){
    this.helper.info("watch property inprogress")
  }


  isVideo(url: string) {
    const urlSplit = url.split('.');
    const ext: string = urlSplit[urlSplit.length - 1];
    if (ext.includes('mp4') || ext.includes('wma') || ext.includes('mov')) {
      return true
    } else {
      return false;
    }
  }


  playVideo() {
    const videoPlayer = document.getElementById('videoPlayer') as HTMLVideoElement;
    if (!this.videoInPlay) {
      videoPlayer.play();
      this.videoInPlay = true;
    } else {
      videoPlayer.pause();
      this.videoInPlay = false;
    }
  }

  pauseVideo() {
    const videoPlayer = document.getElementById('videoPlayer') as HTMLVideoElement;
    if (this.videoInPlay) {
      videoPlayer.pause();
      this.videoInPlay = false;
    }

  }


  arrayLength = 10;

  config: Slick.Config = {
      infinite: false,
      slidesToShow: 2,
      slidesToScroll: 2,
      dots: true,
      autoplay: false,
      autoplaySpeed: 2000 
    }



  getArray(count: number) {
    return new Array(count)
  }

  onPreview(index: any) {
    this.counter = index;
  }
}


