<div class="bootstrap-wrapper" @fade>
    <div class="container">
        <div class="row">
            <div class="col-10 p0 roboto-medium f16">{{type}}</div>
            <div class="col-2 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20" (click)="onClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <form [formGroup]="form">
            <div class="p10 mt10">
             
                    
                    <div class="col-12 d-flex justify-content-center p0 mb10">
                        <div class="col-9 ml15 mat-elevation-z8 elevation-box">
                                <mat-dialog-content>

                                <div class="col-12 d-flex justify-content-center p0">
                                        
                                        <div class="personal-info-image-div" file-drop (files)="submit($event)">
                                            <div class="p20 d-flex justify-content-start align-items-center border-bottom-ice-blue">
                                                <img (click)="triggerFileInputButton()" class="profile-img b-rad-circle mr20 c-pointer" [src]="imageSrc == '' ? avatarProfileImagePath : imageSrc"
                                                    alt=""><br>
                                                    
                                                <div>
                                            
                                            
                                            
                                                    <input hidden type="file" name="profile" #fileInput class="custom-file-input" id="inputGroupFile04"
                                                        aria-describedby="inputGroupFileAddon04" (change)="handleInputChange($event)">
                                                </div>
                                                
                                            
                                            </div>
                                            <div class="d-flex justify-content-start align-items-center">
                                                    <a class="c-pointer upload-link" href="javascript:void(0)" (click)="triggerFileInputButton()">Upload Image</a><span class="star-red">*</span>
                                                </div>
                                        </div>
                                        
                                        </div>
                                        <div class=" row pl25 pr25 pb10">
                                        
                                            <div class="d-flex justify-content-between col-12 p0">
                                                <mat-form-field class="col-5 f14 mt10 pl5 p0 pr5">
                                                    <mat-label>Full Name<span class="star-red">*</span></mat-label>
                                                    <input matInput formControlName="fullname" placeholder="Full Name" class="roboto-regular f14">
                                                    <mat-error *ngIf="form.get('fullname')?.hasError('required')">Full Name is required</mat-error>
                                        
                                                </mat-form-field>
                                                <!-- <mat-form-field class="col-5 f14 mt10 pl5 p0 pr5">
                                                    <mat-label>Email<span class="star-red">*</span></mat-label>
                                                    <input matInput type="email" formControlName="email" placeholder="Email" class="roboto-regular f14">
                                                    
                                                            <mat-error *ngIf="form.get('email').errors.required">Email is required</mat-error>
                                                            <mat-error *ngIf="form.get('email').errors.maxlength">You have more than 60 characters</mat-error>
                                                            <mat-error *ngIf="form.get('email').errors.patternInvalid">Invalid Email</mat-error>

                                                            {{form.get('email').errors.patternInvalid | json}}
                                                </mat-form-field> -->

                                                <mat-form-field class="col-5 f14 mt10 pl5 p0 pr5">
                                                        <mat-label>Email<span class="star-red">*</span></mat-label>
                                                        <input type="email" matInput formControlName="email" class="roboto-regular f14" id="email" placeholder="Email">
                                                        <!-- <mat-hint align="end">Not more then 60 characters long.</mat-hint> -->
                                                        <mat-error *ngIf="form.get('email')?.hasError('required')">Email is required</mat-error>
                                                        <mat-error *ngIf="form.get('email')?.hasError('maxlength')">You have more than 60 characters</mat-error>
                                                        <mat-error *ngIf="form.get('email')?.hasError('patternInvalid')">Invalid Email</mat-error>
                                                        
                                                    </mat-form-field>
                                            </div>
                                            <div class="d-flex justify-content-between col-12 p0">
                                                <mat-form-field class="col-5 f14 mt10 pl5 p0 pr5">
                                                    <mat-label>DOB<span class="star-red">*</span></mat-label>
                                                    <input matInput [matDatepicker]="dob" formControlName="dob" readonly placeholder="DOB" class="roboto-regular f14"
                                                        (dateInput)="DateInput($event)">
                                                    <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                                                    <mat-datepicker #dob></mat-datepicker>
                                                    <mat-error *ngIf="form.get('dob')?.hasError('required')">DOB is required</mat-error>
                                        
                                                </mat-form-field>
                                                <mat-form-field class="col-5 f14 mt10 pl5 p0 pr5">
                                                    <mat-label>Company Name(optional)</mat-label>
                                                    <input matInput formControlName="channelTitle" placeholder="Company Name(optional)" class="roboto-regular f14">
                                                </mat-form-field>
                                            </div>
                                            <div class="d-flex justify-content-between col-12 p0">
                                                <mat-form-field class="col-5 f14 mt10 pl5 p0 pr5">
                                                    <mat-label>Passport Number<span class="star-red">*</span></mat-label>
                                                    <input matInput placeholder="Passport Number" class="roboto-regular f14" formControlName="passportNumber">
                                                    <mat-error *ngIf="form.get('passportNumber')?.hasError('required')">Passport Number is required</mat-error>
                                                </mat-form-field>
                                                <mat-form-field class="col-5 f14 mt10 pl5 p0 pr5">
                                                    <mat-label>Driving License Number<span class="star-red">*</span></mat-label>
                                                    <input matInput placeholder="Driving License Number" class="roboto-regular f14" formControlName="drivingLicenseNo">  
                                                    <mat-error *ngIf="form.get('drivingLicenseNo')?.hasError('required')">Driving LicenseNo is required</mat-error>
                                                </mat-form-field>
                                            </div>
                                        
                                        </div>
                                    </mat-dialog-content>

                                    <mat-dialog-actions class="d-flex justify-content-center pl5 mt30 mb35">
                                            <button mat-raised-button color="primary" [disabled]="form.invalid || isbtnLoading" class="col-3 roboto-medium f12" [class.spinner]='isbtnLoading' (click)="onSubmit()" *ngIf="!readonly">Submit</button>
                                    </mat-dialog-actions>

                        </div>
                    </div>

                
            </div>
        </form>

        
    </div>
</div>

<!-- <div class="bootstrap-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-10 p0 roboto-medium f16">{{type}}</div>
                <div class="col-2 p0">
                    <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <form [formGroup]="form" file-drop (files)="submit($event)">
                <div class="p20 mt10">
                    <mat-dialog-content>
                        <div class="col-12 d-flex justify-content-center p0">
                            <div class="drop-zone" for="file">
                                <input type="file" id="file" (change)="handleInputChange($event)" formControlName="files">
                                
                                    <div *ngIf="!showImg">
                                        <div *ngIf="!isFileSelected; else showThumbnail">
                                            <div class="clearfix d-flex justify-content-center drag-label">
                                                <img src="../../../../assets/images/cloud_upload-24px (1).png">
                                            </div>
                                            <div class="clearfix d-flex justify-content-center drag-label">
                                                <span class="drag-drop-content">Drag and Drop image file</span>
                                            </div>
                                            <div class="clearfix d-flex justify-content-center drag-label">
                                                <span class="slate-grey-two roboto-medium f14">or</span>
                                            </div>
                                            <div class="d-flex justify-content-center mt10">
                                                <label for="file" class="col-8 roboto-medium f12 btn btn-primary cursor-pointer">Browse
                                                    file</label>
                                            </div>
                                        </div>
                                        <ng-template #showThumbnail>
                                                <label for="file" class="d-flex justify-content-center preview-label p0">
                                            <img *ngFor="let preview of imageSrc" [src]="preview | safeHtml" class="preview-img pt5" />
                                            </label>
                                        </ng-template>
                                    </div>
                                
    
                            </div>
    
                        </div>
                        <div class="d-flex justify-content-center mt5">
                            <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                                <input matInput placeholder="Name" class="roboto-regular f13" formControlName="imageName">
                            </mat-form-field>
                        </div>
                        <div class="col-12 d-flex justify-content-center">
                            <div class="col-10 d-flex justify-content-between p0 mb10 pr5">
                                <label class="slate-grey-two f14 roboto-medium mt5 pl10">Status</label>
                                <mat-slide-toggle class="m10 pr2" formControlName="isActive" [color]="color" (change)="onToggle($event)">
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </mat-dialog-content>
    
                    <mat-dialog-actions  class="d-flex justify-content-center pl5 pr3">
                        <button mat-raised-button color="primary" class="col-9 roboto-medium f12" *ngIf="GUID === null" [disabled]="!form.valid || isbtnLoading" [class.spinner]='isbtnLoading' (click)="onSave()">Add</button>
                        <button mat-raised-button color="primary" class="col-9 roboto-medium f12" *ngIf="GUID !== null" [disabled]="!form.valid && !(f.imageURL.value!= null || imageList.length > 0) || isbtnLoading" [class.spinner]='isbtnLoading' (click)="onUpdate()">Update</button>
                    </mat-dialog-actions>
                </div>
            </form>
        </div>
    </div> -->