<div class="bootstrap-wrapper" *ngIf="!isPrivate">
    <div class="row">
        <div class="col-5" *ngIf="personalInfo">
            <app-user-info-card [config]="personalInfo"></app-user-info-card>
        </div>
        <div class="col-7" *ngIf="addressInfo">
            <app-user-info-card [config]="addressInfo"></app-user-info-card>
        </div>
    </div>
    <div class="row mt15 mb10">
        <div class="col-6" *ngIf="idInfo">
            <app-user-info-card [config]="idInfo"></app-user-info-card>
        </div>
        <div class="col-6" *ngIf="addressVerifyInfo">
            <app-user-info-card [config]="addressVerifyInfo"></app-user-info-card>
        </div>
    </div>
</div>

<div class="bootstrap-wrapper" *ngIf="isPrivate">
    <div class="row">
        <div class="col-4" *ngIf="basicInfo">
            <app-user-info-card [config]="basicInfo"></app-user-info-card>
        </div>
        <div class="col-8">
            <div class="row">
                <div class="col-12" *ngIf="regOffice">
                    <app-user-info-card [config]="regOffice"></app-user-info-card>
                </div>
                <div class="col-12 mt10" *ngIf="businessInfo">
                    <app-user-info-card [config]="businessInfo"></app-user-info-card>
                </div>
            </div>
        </div>
    </div>
</div>