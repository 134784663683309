export enum RestEnds {
  posts = 'posts',
  refreshToken = 'Account/refreshtoken',
  login = 'admin/login',
  state = 'Lookup/state',
  country = "Lookup/country",
  propertyType = 'lookup/cartype',
  propertySubType = 'Lookup/propertysubtype',
  city = 'Lookup/city',
  amenity = 'Lookup/amenity',
  property = 'Property',
  car = 'Car',
  propertyDeveloper = 'Lookup/carowner',
  propertyManager = 'Lookup/carmanager',
  propertyLeaseType = 'Lookup/leasetype',
  propertyTermsCondition = 'Property/termscondition',
  propertyLegal = 'Property/leagal',
  propertyPrimaryImage = 'Car/banner',
  propertyList = 'Admin/carlist',
  historicalSuburbGrowth = 'Property/excelupload',
  propertyImageUpload = 'Car/image',
  propertyDocument = 'Car/document',
  propertyDetails = 'Car/cardetaildraft',
  propertyDeveloperList = 'admin/carowners',
  addPropertyDeveloper = 'CarOwner',
  editPropertyDeveloper = 'CarOwner',
  getPropertyDeveloper = 'CarOwner/',
  getPropertyIsExists = 'CarOwner/isexist',
  leaseTypeList = 'LeaseType',
  addLeaseType = 'LeaseType',
  editLeaseType = 'LeaseType',
  getLeaseType = 'LeaseType/',
  getLeaseTypeIsExist = 'LeaseType/isexist',
  getCarOwnerIsExist = 'carowner/isexist',
  getCarMangerIsExist = 'carowner/isexist',
  propertyManagerList = 'admin/carmanagers',
  addPropertyManager = 'CarManager',
  editPropertyManager = 'CarManager',
  getPropertyManager = 'CarManager/',
  getPropertyManagerIsExists = 'CarManager/isexist',
  uploadImage = 'News/image',
  NewsURL = 'News',
  NewsCategory = 'NewsCategory',
  getIsExistsNewsCategory = 'NewsCategory/isexist',
  getNewsList = 'News/v1',
  getAllDepositRequests = 'admin/depositrequest',
  getAllDepositHistory = 'admin/deposit-history/list',
  approveDepositRequest = 'admin/depositamount',
  rejectDepositRequest = 'admin/canceldeposit',
  getAllWithdrawHistory = 'admin/withdrawal-history/list',
  getAllWithdrawRequest = 'admin/withdrawal-request/list',
  getAllWithdrawAccountsListRequest = 'admin/withdrawal-account/list',
  approveWithdrawRequest = 'admin/withdrawal-request/approval',
  approveWithdrawAccountRequest = 'admin/withdrawal-account/approval',
  rejectWithdrawRequest = 'admin/withdrawal-request/approval',
  getBanktransferList = 'admin/banktransferlist',
  updateDepositamount = 'admin/updatebanktransferlist',
  get2FAQRCode = 'admin/2faqrcode',
  verifyQRCode = 'admin/Verify2FASetup',
  getToggle2FA = 'admin/toggle2fa',
  verify2FA = 'admin/Verify2FA',
  get2FAStatus = 'admin/2fastatus',
  reset2FA = 'admin/reset2fa',
  userlist_old = 'admin/userlist',
  userlist = 'admin/userlist',
  userdetail = 'admin/userprofile',
  uploadPOA = 'admin/poa',
  // kyclevel1_old = 'admin/kycinfo',
  // kyclevel1 = "kyc/info",
  kyclevel1 = 'adminkyc/info',
  // acceptKycLevel1 = 'admin/kyclevel1',
  // acceptKycLevel2 = 'admin/kyclevel2',
  // rejectKycLevel1 = 'admin/kyclevel1reject',
  // rejectKycLevel2 = 'admin/kyclevel2reject',
  changeUserStatus = 'admin/changeuserstatus',
  changePremiumStatus = 'admin/updateperimumuser',
  approveKYC = 'adminkyc/approve',
  rejectKYC = 'adminkyc/reject',
  rejectReasonKYC = 'kyc/rejectreason',
  addCPPAgent = 'admin/agent',
  getCPPAgent = 'admin/agents',
  getAllTransactionHistory = 'admin/transactions/purchase/list',
  getAllRefundHistory = 'admin/transactions/purchase/refund/list',
  getPermissions = 'rolebasedaccess/userpermissions',
  addUser = 'admin/user',
  getAgentUserList = 'admin/agentuserList',
  getAllAgentList = 'Property/agents',
  agentResendMail = 'admin/agentresentlink',
  userResendMail = 'admin/resentlink',
  getKYCProofDecrypt = 'kyc/getkycproofadmin',
  getUserProfile = 'admin/getprofile',
  getMappingAgentList = 'admin/mappagent',
  getMappingUsersList = 'admin/mappuser',
  getMappingAccountsList = 'Admin/mappaccount',
  // submitAgentUserMapping = "admin/mapping",
  submitAgentUserMapping = 'Admin/mapping',
  userKycDetail = 'Admin/userkycdetails',
  updateManager = 'Admin/updatemanager',
  uploadPropertyDeed = 'Property/deeds',
  uploadPropertyVideo = 'property/video',
  coownershipRequest = 'admin/coownershiprequests',
  coownershipRequestDetail = 'admin/coownershiprequestdetails',
  approveCoOwnerShipRequest = 'admin/coownershiprequestupdate',
  coownershipHistory = 'admin/coownershiprequesthistory',
  coownershipHistoryDetail = 'admin/coownershiphistorydetails',
  getBannerImage = 'Admin/car/banner',
  getGalleryImage = 'Admin/car/image',
  getExcelFile = 'Property/excelupload',
  getTermsFile = 'Property/termcondition',
  getLegalFile = 'Property/legal',
  getPropertyDeeds = 'Property/deeds',
  updatePropertyDeeds = 'Property/deeds',
  getPropertyBasicDetails = 'Admin/cardetails',
  updateProperty = 'admin/car',
  draftList = 'admin/draftcarlist',
  updateBannerImage = 'car/banner',
  updateLegalDocument = 'Property/leagal',
  updateTermsDocument = 'Property/termscondition',
  getPropertyDocuments = 'Admin/car/document',
  updatepropertyDocument = 'Admin/car/document',
  publishProperty = 'Car/publish',
  updatePropertyImages = 'Admin/car/image',
  updateOdometer ='admin/car/odometer',
  cloneProperty = 'Property/clone',
  addArticle = 'admincommunity/createarticle',
  updateArticle = 'admincommunity/updatearticle',
  getArticleByGuid='admincommunity/readarticlebyguid?articleGUID=',
  getArticleList = 'admincommunity/readarticles',
  deleteArticle = 'admincommunity/deletearticle?articleGUID=',
  updateVideo = 'admincommunity/updatevideo',
  getVideoByGuid='admincommunity/videobyguid?videoGUID=',
  addVideo = 'admincommunity/createvideo',
  getVideoList = 'admincommunity/readvideos',
  deleteVideo = 'admincommunity/deletevideo?videoGUID=',
  addBanner = 'admincommunity/createbanner',
  getArticleSubCategoryByCategory = 'admincommunity/readsubcategory?CategoryGUID=',
  getVideosSubCategoryByCategory = 'admincommunity/readsubcategory?CategoryGUID=',
  getPodcastSubCategoryByCategory = 'admincommunity/readsubcategory?CategoryGUID=46a08ec7-90ec-4c86-a57a-6afe1124d085',
  stateByCountry='Lookup/state/319C6AC5-A2F5-400B-AE38-C2CEB0D62A9C',
  addPodcast = 'admincommunity/podcast',
  updatePodcast = 'admincommunity/podcast',
  getPodcastByGuid='admincommunity/podCastByGUID?podcastGUID=',
  getPodcastList = 'admincommunity/podcasts',
  deletePodcast = 'admincommunity/podcast?podcastGUID=',
  getPodcastCategories = "admincommunity/podcast-categories",
  category = "admincommunity/readcategory",
  subcategory = "admincommunity/readsubcategory",
  sellBricklet = "BuySell/sell",
  buyBricklet = "BuySell/buy",
  getMarketPrice = "BuySell/marketprice",
  sellIsAvailable = "BuySell/sellisavailable",
  buyIsAvailable = "BuySell/buyisavailable",
  article = "admincommunity/articles?search=",
  brickletPriceHistory="property/brickletpricehistory",
  addCategory="admincommunity/createcategory",
  addSubCategory="admincommunity/createsubcategory",
  getCategoryByGuid='admincommunity/categorybyguid?categoryGUID=',
  getSubCategoryByGuid='admincommunity/subcategorybyguid?subcategoryGUID=',
  updateCategory = 'admincommunity/category',
  updateSubCategory = 'admincommunity/subcategory',
  deleteCategory = 'admincommunity/category?categoryGUID=',
  deleteSubCategory = 'admincommunity/subcategory?subcategoryGUID=',

  // Admin reports
  getReportsAllUsers = 'admin/reports/userlist',
  getTradeCarsLists = 'admin/reports/tradecars',
  getRevenueLists = 'admin/reports/revenue',
  getIndividualUserList = 'adminreports/individualaccountholders',
  getOrganisationUserList = 'adminreports/organizationaccountholders',
  getPropertiesUsersList = 'adminreports/propertiesreport',
  getBrickletHolders = 'adminreports/brickletholders',
  getBuyerOrders = 'adminreports/buyorders',
  getTransactionReports = 'adminreports/transactionreport',
  getDepositReport = 'adminreports/depositsreport',
  getWithdrawReports = 'adminreports/withdrawreport',
  getTransactionBuySellReports = 'adminreports/transactionbuysellreport',
  getBrickletDetailsReports = 'adminreports/brickletdetailsreport',
  getCustomerDetailsReports = 'adminreports/customerdetailsreport',
  getBPPBuyReports = 'adminreports/bppbuyreport',
  getPendingBrickletDetailsReports = 'adminreports/brickletholderstransactionreport',
  getSellOrderReports = 'adminreports/sellordersreport',

  //download csv
  getDownloadAllUsers = 'admin/reports/userlist/download',
  getDownloadTradeCarsList = 'admin/reports/tradecars/download',
  getDownloadRevenueList = 'admin/reports/revenue/download',
  getDownloadIndividualUsersList = 'adminreports/downloadindividualholders',
  getDownloadOrganizationUsersList = 'adminreports/downloadorganizationholders',
  getDownloadPropertiesUsersList = 'adminreports/downloadproperties',
  getDownloadBrickletHoldersList = 'adminreports/downloadbrickletholders',
  getDownloadBuyerOrder = 'adminreports/downloadbuyorders',
  getDownloadTransactionTransferDone = 'adminreports/downloadtransaction',
  getDownloadDepositsUsersList = 'adminreports/downloaddepositreport',
  getDownloadWithdrawalUsersList = 'adminreports/downloadwithdrawreport',
  getDownloadBrickletDetailsList = 'adminreports/downloadbrickletdetailsreport',
  getDownloadCustomerDetailsList = 'adminreports/downloadcustomerdetailsreport',
  getDownloadPendingBrickletDetails = 'adminreports/downloadbrickletholderstransactionreport',
  getDownloadSellOrderReport = 'adminreports/downloadsellordersreport',
  

  //download excel
  getDownloadAllDataReport = 'adminreports/downloadalldatareports',
  getDownloadAllOperationDataReport = 'adminreports/downloadoperationdatareports',
  getDownloadTransactionBuySell = 'adminreports/downloadtransactionbuysellreport',
  getDownloadBPPBuyReport = 'adminreports/downloadbppbuyreport',

  // feature article
  getFeatureArticleList = 'admincommunity/banners',
  deleteFeatureArticle = 'admincommunity/banner?bannerGUID=',
  getFeatureArticleByGUID = 'admincommunity/banner?bannerGUID=',
  updateFeatureArticle= 'admincommunity/banner',

  // property owner mapping
  getUsersForPropertyOwnerMapping = 'admin/userlist',
  getAccountListForPropertyOwnerMapping = 'admin/accountlist?userGUID=',

  //get User Owned Proeprty
  getuserOwnedProperty = 'admin/usersownedproperty',

  //BPP coownership
  getbppcoownershiprequest = 'admin/bppcoownershiprequests',
  bppcoownershipRequestDetail = 'admin/bppcoownershiprequestdetails',
  approveBPPCoOwnerShipRequest = 'admin/bppcoownershiprequestupdate',
  getbppcoownershiphistory = 'admin/bppcoownershiprequesthistory',
  getbppcoownershiphistorydetails = 'admin/bppcoownershiphistoryrequestdetails',

  // bpp document & late payment
  updateBPPTermsDocumentFile = 'admin/bppdocumentupload',
  getBPPTermsFile = 'BuySell/bPPDocuments',
  getBPPlatepaymentpendingdetails = 'admin/latepaymentpendingdetails',
  getBPPpenaltyamountdetails = 'admin/penaltyamountdetails',
  getBPPlatePaymentHistory = 'admin/latepaymenthistory',

  // Seller Permission
  getSellerPermission = "admin/SellerPermission",
  createSellerPermission = "admin/CreateSellerPermission",
  updateSellerPermission = "admin/SellerPermission",
  deleteSellerPermission = "admin/SellerPermission",

  // BPP Settings
  getBPPSetting = 'Admin/bppsettings',
  updateBPPSetting = 'Admin/bppsettings',

  //rental
  postRentCalculation = 'Rent/calculation',
  rentSearchProperty = 'Rent/property',
  payRent = 'rent/distribution',
  rentPaymentHistory = 'Admin/rentdistributions',
  rentDepositHistory = 'rent/rentdeposits',
  uploadPropertyRent = 'rent/rentdocumentupload',
  generateRentReceipt = 'rent/rentalstatement',

  //Amenities
  amenitiesList = 'property/amenities',
  addCarOwner = 'carowner',
  editAmenities = '',
  deleteAmenities = 'property/amenities?amenityGUID=',
  getAmenitiesIsExist = '',
  getAllAmenities = 'property/amenities',
  addCarManager= 'carmanager',


  //conveyancer
  getAllConveyancerPendingOrder = 'admin/conveyancercoownershiprequests',
  getAllConveyancerHistory = 'admin/conveyancercoownershiphistory',
  uploadTitleTransferDocument = 'admin/titletransferdocumentupload',
  approvePendingOrder = 'admin/conveyancerapprove',
  rejectPendingOrder = 'admin/conveyancerreject',
  settlorDocument = 'admin/settlementinstructiondocresponse?conveyancerOrderGUID=',
  getAllConveyancerPendingOrderbysuperadmin = 'admin/conveyancercoownershipreqforsuperadmin',
  getAllConveyancerHistoryAdmin = 'admin/conveyancerhistoryforadmin',
  updateSettlementDate = 'admin/conveyancersettlementdate',

  //email-confirmation
  verifyEmail = "Account/verifyemail",
  addConveyancer = 'admin/conveyancer',
  getConveyancerList = 'admin/conveyancerslist',
  getConveyancerMappingAccount = 'admin/conveyanceraccounts',
  createConveyancerMapping = 'admin/conveyancermapping',
  getAllConveyancerList = 'admin/conveyancerlist',
  updateConveyancerMapping = 'Admin/updateconveyancermapping',

  //ledger
  getLedgerList = 'admin/ledgers/list',
  getLedgerSummary = 'admin/ledgers/summary',

  //user-roles
  userRoles = 'lookup/user-role',

  //kyc-verify
  getAllKycRequest = 'admin/kyc/pendinglist',
  getAllKycHistory = "admin/kyc/historylist",
  approveKycRequest = "admin/kyc/approve/",
  rejectKycRequest = "admin/kyc/reject/",
  downloadKYCDocuments = "admin/kyc/",

//divedends
 getcardata = 'lookup/searchcar',
 getbycarcode = 'admin/car/getbycode',
 payrequest='admin/dividends/pay',
 gethistory = "admin/dividends/history",

 //refund Initiate
 refundInitiate = 'admin/transactions/purchase/refund?orderGUID='

}
