import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NewsService } from 'src/app/core/services/news.service';
import { Observable } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { PatternValidator } from '../../validators/patternvalidator';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { StaticSuccessMessage } from '../../../core/enum/constants';
import { CommunityService } from 'src/app/core/services/community.service';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
  animations: [trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(1500, style({opacity: 1}))
    ])
  ])]
})
export class AddCategoryComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() type = null;
  public form: FormGroup;
  GUID = null;
  isbtnLoading = false;
  isExists = false;

  avatarProfileImagePath = '../../../../assets/images/avatar.png';
  lblStatus = 'InActive';
  color: ThemePalette = 'primary';
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private newsService: NewsService,
    private communityService: CommunityService,
    private helper: HelperService
  ) {
    this.form = this.formbuilder.group({
    id: new FormControl(''),
    categoryname: new FormControl('', [Validators.required,Validators.maxLength(40)]),
    description: new FormControl('', [Validators.required,Validators.maxLength(60)]),
    isActive: new FormControl(true)
    });
    this.dialogRef.disableClose = true;
   }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {

  }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.GUID = this.data.guid;
    if (this.GUID !== null) {
      this.getDetailByGUID(this.GUID);
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
  onClose() {
    this.dialogRef.close();
  }


  onSave() {
    this.isbtnLoading = true;
    this.isExists = false;
    const data = {
      Name: this.f.categoryname.value,
      Description: this.f.description.value,
      IsActive: this.f.isActive.value
    };
    if(this.GUID != null){
      data["CategoryGUID"] = this.GUID;
      this.update(data)
    }else{
     this.save(data)
    }
  } 

  save(data){
    this.communityService.addCategory(data).subscribe(
      res => {
        this.isbtnLoading = false;
        const result: any = res;
        if (result.data != null) {
          this.helper.success(
            result.data.message
          );
          this.communityService.changeMessage(true);
          this.form.reset();
          this.onClose();
        } else {
          this.isbtnLoading = false;
          this.helper.error(result.message);
        }
      },
      error => {
        this.isbtnLoading = false;
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  update(data) {
    this.communityService
      .updateCategory(data)
      .subscribe(
        res => {
          this.isbtnLoading = false;
          const result: any = res;
          if (result.data != null) {
            this.helper.success(result.data.message);
            this.onClose();
            this.communityService.changeMessage(true);
          } else {
            this.helper.error(result.data.message);
          }
        },
        error => {
          this.isbtnLoading = false;
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );
  }

  getDetailByGUID(id) {
    this.communityService.getCategoryByGuid(id).subscribe(
      (res) => {
        const result: any = res;
        if (result.data != null) {
          const data = result.data;
          const communityCategory = data.category;
          this.GUID = communityCategory.categoryGUID;
          this.form.patchValue({
            categoryname: communityCategory.name,
            description: communityCategory.description,
            isActive: communityCategory.isActive
          });
        } else {
          this.helper.error(result.message);
        }
      },
      (error) => {
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }



  get f() {
    return this.form.controls;
  }

  onToggle(event) {
    if (event.checked) {
      this.lblStatus = 'Active';
    } else {
      this.lblStatus = 'InActive';
    }
  }

}
