import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NewsService } from '../../../core/services/news.service';
import { trigger, transition, animate, style } from '@angular/animations';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { UploadImageService } from '../../../core/services/upload-image.service';
import { ImageFiles } from '../upload-image/upload-image.component';
import { AgentService } from '../../../core/services/agent.service';
import { PatternValidator } from '../../validators/patternvalidator';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../../core/utils/common.service';
import { imageUploadConfig } from '../../../core/interfaces/image-upload.interface';
import { UploadImageOptions } from '../../../core/enum/constants';
import { HelperService } from '../../../core/utils/helper.service';

@Component({
  selector: 'app-add-cpp-agent',
  templateUrl: './add-cpp-agent.component.html',
  styleUrls: ['./add-cpp-agent.component.scss'],
  animations: [trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(1500, style({opacity: 1}))
    ])
  ])]
})
export class AddCppAgentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() type: any;
  typename: any;
  public form: FormGroup;
  
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef | any;
  profileImage: any;
  avatarProfileImagePath = '../../../../assets/images/avatar.png';

  isFileSelected = false;
  fileList: any;
  fileType: any;
  imageSrc: any = "";
  imageList: ImageFiles[] = [];

  GUID: any = null;
  readonly = false;
  Date: any;
  isbtnLoading = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private newsService: NewsService,
    private agentService: AgentService,
    private dialog: MatDialog,
    private uploadImageService: UploadImageService,
    private helper: HelperService
  ) {
    this.dialogRef.disableClose = true;

    this.form = new FormGroup({
      guid: new FormControl(''),
      fullname: new FormControl('',Validators.required),
      email: new FormControl('',[Validators.required, Validators.maxLength(60),
        PatternValidator(
          // tslint:disable-next-line:max-line-length
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )]),
      channelTitle: new FormControl(''),
      dob: new FormControl('', Validators.required),
      passportNumber: new FormControl('', Validators.required),
      drivingLicenseNo:  new FormControl('', Validators.required),
      ProfileImage: new FormControl('', Validators.required)
  
    });
   }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.GUID = this.data.guid;
    this.readonly = this.data.readOnly;
    this.cdr.detectChanges();
    // console.log(this.form);
  }

  triggerFileInputButton() {
    this.fileInput.nativeElement.click();
  }

  public submit(e: any) {
    // console.log('files:', e.dataTransfer.files);
    // this.uploadImage(e);
    this.uploadImage_new(e);
  }

  handleInputChange(e: any) {
    // this.uploadImage(e);
    this.uploadImage_new(e);
  }

  

  uploadImage_new(e: any): any {
    this.fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    const reader = new FileReader();
    const filelist = [];
    const config: imageUploadConfig = {
      dimension: {
        width: UploadImageOptions.width,
        height: UploadImageOptions.height,
        operation: UploadImageOptions.operation
      },
      size: UploadImageOptions.size,
      fileExt: UploadImageOptions.exts,
      fileType: null
    };
    if (this.fileList.length > 1) {
      this.helper.warning('Please select one image');
      return false;
    }

    if (this.fileList.length > 0) {

      for (const item of this.fileList) {
        filelist.push(item);
        // this.showImg = false;
        this.imageSrc = "";
        this.imageList = []; // Single image upload
        this.isFileSelected = false;


        this.uploadImageService.uploadImage(item, config, reader, (result: any) => {
          if (result.status) {
            this.imageList.push({
              imageType: result.type,
              imageFile: result.imageFile
            });
            this.form.patchValue({
              ProfileImage: this.fileList
            });
            this.imageSrc = result.imagePreview;
            this.isFileSelected = true;
          } else {
            this.helper.warning(result.msg);
            this.form.patchValue({
              ProfileImage: ''
            });
          }
        });
      }
      this.cdr.markForCheck();
    }
  }

  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }
  onClose() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }


  DateInput(event: MatDatepickerInputEvent<Date>): void {
    this.Date = moment(event.value).format('YYYY-MM-DD');
  }


  onSubmit() {
    this.isbtnLoading = true;
    const formData = new FormData();
    if (this.GUID != null) {
      formData.append('GUID', this.GUID);
    }
    formData.append('FullName', this.form.controls.fullname.value);
    formData.append('Email', this.form.controls.email.value);
    formData.append('ChannelTitle', this.form.controls.channelTitle.value);
    formData.append('DOB', this.Date);
    formData.append('PassportNumber', this.form.controls.passportNumber.value);
    formData.append('DrivingLicenseNo', this.form.controls.drivingLicenseNo.value);
    // formData.append('ProfileImage', this.form.controls.ProfileImage.value);
    if (this.imageList.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.imageList.length; i++) {
        formData.append('ProfileImage', this.imageList[i].imageFile);
      }
    } else {
      // formData.append('BannerBlobURL', this.newsfeedForm.controls.bannerBlobURL.value);
    }

    if(this.GUID != null) {
      // this.onEditNews(formData);
    } else {
      this.onSaveAgent(formData);
    }

  }


  onSaveAgent(formData: any) {

    this.agentService
      .addAgent(formData)
      .subscribe(
        (res: any) => {
          this.isbtnLoading = false;
          const result: any = res;
          if (result.data != null) {
            this.helper.success(result.data.message);
            this.form.reset();
            this.form.patchValue({
              guid: '',
              fullname: '',
              email: '',
              channelTitle: '',
              dob: '',
              passportNumber: '',
              drivingLicenseNo: '',
              ProfileImage: ''
            });
            this.agentService.changeMessage(true);
            this.onClose();
          } else {
            this.helper.error(result.data.message);
          }
        },
        (error: any) => {
          this.isbtnLoading = false;
          // console.log(error);
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );

  }

}
