import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { HelperService } from 'src/app/core/utils/helper.service';
import { UsersService } from '../../../core/services/users.service';

@Component({
  selector: 'app-proof-img',
  templateUrl: './proof-img.component.html',
  styleUrls: ['./proof-img.component.scss']
})
export class ProofImgComponent implements OnInit, OnChanges {
  @Input() img = null;
  imgUrl: any;
  constructor(
    private helperService: HelperService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private userService: UsersService
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(){
     this.getImage(this.img);
  }

  getImage(data: any){
    this.helperService.getKYCImageDecrypt(data).subscribe(
      (r: any) => {
          let blob = new Blob([r], { type: 'application/octet-stream' });
         
           this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
          // this.imgUrl = window.URL.createObjectURL(blob);
          return this.imgUrl;
      },
      (e: any) => {
          return this.imgUrl;
      }
  );
  
  }

  imageViewer(url: string) {
    const dialogRef = this.dialog.open(ImageViewerComponent, {
      data: { imageUrl: url },
      panelClass: 'image-viewer-modal'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
