<div class="bootstrap-wrapper" @fade>
    <div class="container">
        <div class="row">
            <div class="col-10 p0 roboto-medium f16">{{type}}</div>
            <div class="col-2 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <form [formGroup]="form">
            <div class="p10 mt10">
                <mat-dialog-content>
                    <div class="col-12 d-flex justify-content-center p0">
                        <div class="drop-zone" for="file">
                            <img src="../../../../assets/images/news-category.png" class="preview-img" />
                        </div>

                    </div>
                    <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                            <input matInput placeholder="News Category" class="roboto-regular f13" formControlName="category">
                        </mat-form-field>
                    </div>
                </mat-dialog-content>

                <mat-dialog-actions class="d-flex justify-content-center pl5 mb10">
                    <button mat-raised-button color="primary" class="col-5 roboto-medium f12" *ngIf="GUID === null" [disabled]="!form.valid || isbtnLoading" [class.spinner]='isbtnLoading'
                        (click)="onSave()">Add</button>
                    <button mat-raised-button color="primary" class="col-5 roboto-medium f12" *ngIf="GUID !== null" [disabled]="!form.valid || isbtnLoading" [class.spinner]='isbtnLoading'
                        (click)="onUpdate()">Update</button>
                </mat-dialog-actions>
            </div>
        </form>
    </div>
</div>