import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UsersService } from 'src/app/core/services/users.service';
import { DatePipe } from '@angular/common';
import { KycRejectPopupComponent } from '../kyc-reject-popup/kyc-reject-popup.component';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';
import { CommonService } from '../../../core/utils/common.service';
import { KycLevelOneInterface } from '../../../core/interfaces/kyc.interface';
import { HelperService } from '../../../core/utils/helper.service';


export interface DialogData {
  id: string;
  title: string;
  btnDisable: boolean;
  titleDisable: boolean;
  KycTypeOfUserGUID: string;
}

@Component({
  selector: 'app-kyc-org',
  templateUrl: './kyc-org.component.html',
  styleUrls: ['./kyc-org.component.scss'],
  providers: [
    DatePipe
  ]
})
export class KycOrgComponent implements OnInit {

  entityProfile: UserInfoCardInterface | null = null;
  emitData: any = {
    ubo: null,
    settlor: null,
    trustee: null,
    entityType: null,
    soletrader: null,
    partnership: null,
    publicCompany: null,
    privateCompany: null,
    regulatedTrust: null,
    unregulatedTrust: null,
    smsf: null
  };
  showTabs = false;
  title: any;
  dialogBtnDisable = false;
  titleDisable: any;
  typeName: any;

  
  isPrivate = false;
  isSoleTrader = false;
  isRegulatedTrust = false;
  isPublicCompany = false;
  isPartnerShip = false;
  isUnRegulatedTrust = false;
  isSMSF = false;

  entityTypeProfile: UserInfoCardInterface | null = null;
  selectedTab = 0;
  isbtnLoading = false;

  constructor(
    public dialogRef: MatDialogRef<KycOrgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: UsersService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private helper: HelperService
  ) { }

  ngOnInit() {
    this.title = this.data.title;
    this.dialogBtnDisable = this.data.btnDisable;
    this.titleDisable = this.data.titleDisable;

    
    // this.typeName = this.organisationDTO.entityName;

    // this.entityTypeProfile = {
    //   title: 'Entity profile',
    //   column: 3,
    //   type: 'text-only',
    //   data: [
    //     { text: 'Country', value: this.organisationDTO.country },
    //     { text: 'Entity type', value: this.organisationDTO.entityType.toString() },
    //     { text: 'Entity name', value: this.organisationDTO.entityName },
    //   ]
    // };
    // this.emitData.entityType = this.organisationDTO;

    
    // this.userService.getKycLevelOne(this.data.id, this.data.KycTypeOfUserGUID).subscribe(
    //   (r: any) => {
    //     const rData: KycLevelOneInterface = r.data;
    //     this.showTabs = true;
    //     const result: any = rData.kYCDTO.organisation;
    //     // const entity = result.entityProfile;
    //     // const entity = result.smsfEntity;
    //     this.entityProfile = {
    //       title: 'Entity profile',
    //       column: 4,
    //       type: 'text-only',
    //       data: [
    //         { text: 'Country', value: result.country },
    //         // { text: 'Entity type', value: result.entityType != undefined ? result.entityType.toString() : null },
    //         // { text: 'ABN', value: entity.abn },
    //         { text: 'Entity Type', value: result.entityName },
    //       ]
    //     };

    //     // this.emitData.ubo = entity.ubOs;
    //     // this.emitData.settlor = entity.settlor;
    //     // this.emitData.trustee = entity.trustee;

        
    //     this.emitData.soletrader = result.soleTraderEntity;
    //     this.emitData.partnership = result.partnershipEntity;
    //     this.emitData.publicCompany = result.publicCompanyEntity;
    //     this.emitData.privateCompany = result.privateCompanyEntity;
    //     this.emitData.regulatedTrust = result.regulatedTrustEntity;
    //     this.emitData.unregulatedTrust =  result.unRegulatedTrustEntity;
    //     this.emitData.smsf = result.smsfEntity;


    //     if(result.privateCompanyEntity){
    //       this.typeName = "Private Company";
    //       this.isPrivate = true;
    //     } else if(result.soleTraderEntity) {
    //       this.typeName = "Sole Trader";
    //       this.isSoleTrader = true;
    //     } else if(result.regulatedTrustEntity) {
    //       this.typeName = "Regualted Trust";
    //       this.isRegulatedTrust = true;
    //     } else if(result.publicCompanyEntity) {
    //       this.typeName = "Public Company";
    //       this.isPublicCompany = true;
    //     } else if(result.partnershipEntity) {
    //       this.typeName = "PartnerShip";
    //       this.isPartnerShip = true;
    //     } else if(result.unRegulatedTrustEntity) {
    //       this.typeName = "UnRegulated Trust";
    //       this.isUnRegulatedTrust = true;
    //     } else if(result.smsfEntity){
    //       this.isSMSF = true;
    //     }


    //   },
    //   (e) => {
    //     // this.helper.error(e);
    //     const errSt = e.message !== undefined ? e.message : e;
    //     if (errSt === 'Session Timed Out') {
    //       this.helper.info(errSt);
    //     } else {
    //       this.helper.error(errSt);
    //     }
    //   }
    // );
  }

  closeKycOrg(): void {
    this.dialogRef.close({ refresh: true });
  }

  rejectKyc() {
    const dialogRef = this.dialog.open(KycRejectPopupComponent, {
      data: { reason: '', status: false }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status) {
        this.userService.rejectKycLevelOne({
          UserGUID: this.data.id,
          Reason: result.reason,
          KYCTypeOfUserGUID: this.data.KycTypeOfUserGUID,
          Level: 1
        }).subscribe(
          (s: any) => {
            this.helper.success("KYC Level 1 Rejected Successfully");
            this.closeKycOrg();
          },
          (e: any) => {
            // this.helper.error(e);
            const errSt = e.message !== undefined ? e.message : e;
            if (errSt === 'Session Timed Out') {
              this.helper.info(errSt);
            } else {
              this.helper.error(errSt);
            }
          }
        );

      }

    });
  }

  approveKyc() {
    this.isbtnLoading = true;
    const dataDTO = {
      UserGUID: this.data.id,
      KYCTypeOfUserGUID: this.data.KycTypeOfUserGUID,
      Level: '1'
    }
    this.userService.approveKyc(dataDTO).subscribe(
      (s: any) => {
        this.isbtnLoading = false;
        this.helper.success("KYC Level 1 Verified Successfully");
        this.closeKycOrg();
      },
      (e) => {
        // this.helper.error(e);
        this.isbtnLoading = false;
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

}
