<mat-dialog-content class="mat-typography" id="user_detail">
    <div class="bootstrap-wrapper col-12">
        <div class="row col-12">

            <div class="col-12 pb10 pt10">
                <mat-card class="card-height">
                    <div class="row h-20">
                        <div class="col-12 card-container pb10">

                            <form [formGroup]="form" class="w-100">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-dialog-content>


                                            <div class="d-flex justify-content-center">
                                                <mat-form-field class="col-6 f14 mt10 pl5 p0 pr5">
                                                    <mat-label>Email<span class="star-red">*</span></mat-label>
                                                    <input type="email" matInput formControlName="email" class="roboto-regular f14"
                                                        id="email" placeholder="Email" autocomplete="off">
                                                    <!-- <mat-hint align="end">Not more then 60 characters long.</mat-hint> -->
                                                    <!-- <mat-error *ngIf="form.get('email').hasError('required')">Email is
                                                        required</mat-error>
                                                    <mat-error *ngIf="form.get('email').hasError('maxlength')">You have
                                                        more than 60 characters</mat-error>
                                                    <mat-error *ngIf="form.get('email').hasError('patternInvalid')">Invalid
                                                        Email</mat-error> -->

                                                </mat-form-field>
                                            </div>
                                        </mat-dialog-content>

                                        <mat-dialog-actions class="d-flex justify-content-center pl5 mb35">
                                            <button mat-raised-button color="primary" [disabled]="form.invalid || isbtnLoading"
                                                class="col-3 roboto-medium f12" [class.spinner]='isbtnLoading' (click)="onSearch()">Search Manager</button>
                                        </mat-dialog-actions>
                                    </div>

                                </div>
                             
                            </form>

                        </div>

                    </div>
                    <div class="row h-100 pt-30 pb-30" *ngIf="isUser">
                    <div class="col-12 d-flex justify-content-center pb10 pt-30">
                            <div class="manager-container col-6" for="file">
                                    <mat-dialog-content>


                                            <div class="d-flex justify-content-left col-9 pb4">
                                                <div class="col-2 pl-2">
                                                    <div class="p-info">
                                                        <span class="roboto-medium">Name:</span>
                                                       
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                        <div class="p-info">
                                                            <span>{{name}}</span>
                                                           
                                                        </div>
                                                    </div>
                                                  
                                            </div>
                                            <div class="d-flex justify-content-left col-9 pb4">
                                                    <div class="col-2 pl-2">
                                                        <div class="p-info">
                                                            <span class="roboto-medium">Email:</span>
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                            <div class="p-info">
                                                                <span>{{email}}</span>
                                                               
                                                            </div>
                                                        </div>
                                                      
                                                </div>

                                                <div class="d-flex justify-content-left col-9 pb4">
                                                        <div class="col-2 pl-2">
                                                            <div class="p-info">
                                                                <span class="roboto-medium">KYC:</span>
                                                               
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                                <div class="p-info">
                                                                    <span>{{kyc}}</span>
                                                                   
                                                                </div>
                                                            </div>
                                                          
                                                    </div>
                                        </mat-dialog-content>

                                        <mat-dialog-actions class="d-flex justify-content-center pl5 mb35">
                                            <button mat-raised-button color="primary" [disabled]="isbtnReplaceLoading || !isKycVerified"
                                                class="col-6 roboto-medium f12" [class.spinner]='isbtnReplaceLoading' (click)="openProceedDialoguebox()">Proceed</button>
                                        </mat-dialog-actions>
                                    
    
                                
                            </div>
    
                        </div>
                    </div>
                    <div class="row h-100 pt-30 pb-30" *ngIf="!isUser && isFlag">
                        <div class="col-12 d-flex justify-content-center pb10 pt-30">
                            <p class="f20 dusty-orange roboto-medium text-center pb30 m-0" >User
                                Not Found</p>
                        </div>
                    </div>
                  
                </mat-card>
            </div>


        </div>
    </div>
</mat-dialog-content>