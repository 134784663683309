import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserRolesService } from '../services/user-roles.service';
import PageConfigs from '../utils/access-level-config';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  _roleName: any = [];
  constructor(
    private router: Router,
    private authService: AuthService,
    private userRolesService: UserRolesService
  ) {
    this._roleName = this.userRolesService.getRoleName();
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    
    // if (state.url.includes('pendingorder') && this._roleName === "SUPER ADMIN") {
    //   debugger;
    //   console.log(state.url);
    //  this.router.navigate(['/conveyancer/allpendingorder']);
     
    // } 
    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }


  checkLogin(url: string): boolean {
    let isLogin = this.authService.isLoggedIn();
    if (isLogin) {
      return this.getPermission(url);
    }

    this.authService.redirectUrl = url;
    this.router.navigate(["/login"]);
    return false;
  }

  getPermission(url) {
    let permission: boolean;
    switch (url) {
      case "":
        this.router.navigate(["/login"]);
        break;

      case "/property": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Properties,
          PageConfigs.LeaseTypes,
          PageConfigs.PropertyDevelopers,
          PageConfigs.PropertyManagers
        );

        break;
      }
      case "/deposits": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.DepositRequests,
          PageConfigs.DepositHistory
        );

        break;
      }

      case "/withdrawals": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.WithdrawRequests,
          PageConfigs.WithdrawHistory
        );

        break;
      }

      case "/dividends": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.DivedendsDetails,
          PageConfigs.DivedendsHistory
        );
        break;
      }

      case "/all-transactions": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Transactions
        );

        break;
      }

      case "/users": {
        permission = this.userRolesService.getMenuViewAccess(PageConfigs.Users);
        break;
      }

      case "/users/list": {
        permission = this.userRolesService.getMenuViewAccess(PageConfigs.Users);
        break;
      }

      case "/kyc": {
        permission = this.userRolesService.getMenuViewAccess(
             PageConfigs.Kyc
          // PageConfigs.KycRequests,
          // PageConfigs.KycHistory
        );

        break;
      }

      case "/coownership": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }
      case "/coownership/request": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }

      case "/coownership/history": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }

      case "/bppcoownership": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }
      case "/bppcoownership/request": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }
      case "/bppcoownership/history": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }

      case "/conveyancer": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Conveyancer
        );

        break;
      }
      case "/conveyancer/pendingorder": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Conveyancer
        );

        break;
      }
      case "/conveyancer/history": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Conveyancer
        );

        break;
      }

      case "/conveyanceradmin/allpendingorder": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Conveyancer
        );

        break;
      }

      case "/conveyanceradmin/allhistory": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Conveyancer
        );

        break;
      }

      case "/conveyanceradmin/conveyancerlist": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Conveyancer
        );

        break;
      }

      case "/conveyanceradmin/conveyancermapping": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Conveyancer
        );

        break;
      }
      
      // case "/news": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.NewsList,
      //     PageConfigs.NewsCategory
      //   );

      //   break;
      // }

      case "/agent": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CppAgent
        );

        break;
      }

      case "/agent/list": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CppAgent
        );

        break;
      }

      case "/agent/agentuser": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CppAgent
        );

        break;
      }

      case "/setting": {
        permission = true;
        break;
      }

      case "/2fa": {
        permission = true;
        break;
      }
      case "/property/list": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Properties,
          PageConfigs.LeaseTypes,
          PageConfigs.PropertyDevelopers,
          PageConfigs.PropertyManagers
        );

        break;
      }
      case "/property/draft": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Properties,
          PageConfigs.LeaseTypes,
          PageConfigs.PropertyDevelopers,
          PageConfigs.PropertyManagers
        );

        break;
      }
      case "/property/lease": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Properties,
          PageConfigs.LeaseTypes,
          PageConfigs.PropertyDevelopers,
          PageConfigs.PropertyManagers
        );

        break;
      }

      case "/property/manager": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Properties,
          PageConfigs.LeaseTypes,
          PageConfigs.PropertyDevelopers,
          PageConfigs.PropertyManagers
        );

        break;
      }

      case "/property/developer": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Properties,
          PageConfigs.LeaseTypes,
          PageConfigs.PropertyDevelopers,
          PageConfigs.PropertyManagers
        );

        break;
      }

      case "/property/amenities": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Properties,
          PageConfigs.LeaseTypes,
          PageConfigs.PropertyDevelopers,
          PageConfigs.PropertyManagers
        );

        break;
      }

      case "/reports": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/users": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/individualusers": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/organisationusers": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/propertiesusers": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/buyerorder": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/transactiontransfer": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/transactionbuysell": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/brickletsalestransactions": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }


      case "/reports/brickletholders": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/brickletdetails": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/customerdetails": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      } 

      case "/reports/bppbuy": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/depositsusers": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/withdrawalusers": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      }

      case "/reports/pendingbricklet": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      } 


      case "/reports/sellorder": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
        );

        break;
      } 



      // case "/FeatureArticle": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.CommunityCategory
      //   );

      //   break;
      // }

      // case "/Article": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.CommunityCategory
      //   );

      //   break;
      // }

      // case "/Podcast": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.CommunityCategory
      //   );

      //   break;
      // }

      // case "/Video": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.CommunityCategory
      //   );

      //   break;
      // }

      // case "/community": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.CommunityCategory
      //   );

      //   break;
      // }

      // case "/community/category": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.CommunityCategory
      //   );

      //   break;
      // }

      // case "/community/subcategory": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.CommunityCategory
      //   );

      //   break;
      // }

      case "/reports/allreports": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Reports
          );

        break;
      }

      case "/bpplatepayment": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }
      case "/bpplatepayment/request": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }
      case "/bpplatepayment/history": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }

      case "/bppdocument": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }

      case "/bppdocument/document": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }

      case "/bppsettings": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }

      case "/bppsettings/setting": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.CoownerShipRequest
        );

        break;
      }

      // case "/rent/distribute": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.Rent
      //   );

      //   break;
      // }
      // case "/rent": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.Rent
      //   );

      //   break;
      // }

      // case "/rent/transaction/deposit": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.Rent
      //   );

      //   break;
      // }

      // case "/rent/transaction/payment": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.Rent
      //   );

      //   break;
      // }
      
      case "/ledger": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Ledgers
        );

        break;
      } 

      case "/ledger/list": {
        permission = this.userRolesService.getMenuViewAccess(
          PageConfigs.Ledgers
        );

        break;
      } 

      // case "/bppseller": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.CoownerShipRequest
      //   );

      //   break;
      // }

      // case "/bppseller/list": {
      //   permission = this.userRolesService.getMenuViewAccess(
      //     PageConfigs.CoownerShipRequest
      //   );

      //   break;
      // }
      
      default: {
        permission = false;
        break;
      }
    }

    return permission;
  }

 

}
