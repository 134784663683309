<div class="bootstrap-wrapper mt-div">
    <div class="container">
        <div class="row modal-header p5">
            <div class="col-lg-10 col-md-10 col-sm-6 roboto-medium f16 title"></div>
            <div class="col-lg-2 col-md-2 col-sm-6 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20 title"
                    (click)="onClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <form [formGroup]="uploadForm">
        <div class="title-div row col-lg-12 col-md-12 col-sm-12">
            <div class="pl20 pt10 col-md-12 col-sm-12 p0">
                <div class="col-md-12 col-sm-12 row">
                    <div class="col-lg-3 col-md-4 col-sm-12 header-id-info">
                        <span class="name">Property ID</span>
                        <span class="value">{{basicInfo?.propertyCode}}</span>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-12 name-info-div">
                        <span class="label">Property Name</span>
                        <span class="value">{{basicInfo?.propertyName}} </span>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-12 header-value-info-div align-item-end">
                        <span class="label">Total No. of Shares</span>
                        <span class="value pr-55">{{basicInfo?.totalNoOfBricklet}}</span>
                    </div>


                </div>

            </div>



        </div>

        <div class="main-div row scroll-x col-lg-12 col-md-12 col-sm-12 p0">
        
            <div class="detail-div pl20">
            <div multi class="mat-table col-lg-12 col-md-12 col-sm-12 p0 pt-8" is-open="true">
                <div class="p0 mb-8" >
                    
                  <div class="mat-row p0 h-70 bd-0 d-flex justify-content-between">
                    <!-- <div class="col-lg-9 col-md-9 col-sm-12"> -->
                        <div class="col-lg-3 col-md-3 col-sm-12 user-info-div pl-12">
                            <span class="name roboto-bold">{{basicInfo.accountName}} <span class="pl-12"><i class="fa fa-map-marker torch-red" tooltip="{{getaddress(basicInfo)}}" placement="right" delay="100" aria-hidden="true"></i></span></span>
                            <span class="value">{{basicInfo.email}}</span>
                        </div>
                    <!-- </div> -->
                    <!-- <mat-panel-description class="col-lg-3 col-md-3 col-sm-12 justify-content-end"> -->
                        <!-- <div class="col-lg-3 col-md-3 col-sm-12 justify-content-end">
                        <div class="col-lg-12 col-md-12 col-sm-12 id-info-div pl-33 align-item-center pr0">
                            <span class="label roboto-bold">POA Document</span>
                            <span class="value" (click)="downloadDoc(basicInfo?.documentURL)"><i class="fas fa-download generic-palette-b-w-gray f18" title="POA Document"></i></span>
                        </div>
                        </div> -->

                        <div class="col-lg-4 col-md-5 col-sm-12 justify-content-end">
                            <div class="col-lg-12 col-md-12 col-sm-12 id-info-div pl-33 align-item-start pr0">
                                <span class="label roboto-bold pl-55">DOA</span>
                                <!-- <span class="value pl-70" (click)="openSettlementdoc(basicInfo)"><i class="fas fa-download generic-palette-b-w-gray f18" title="Settlement Document"></i></span> -->
                                <span class="value pl-56" *ngIf="basicInfo?.signedDocumentURL !== null && basicInfo?.signedDocumentURL !== ''" (click)="openDOAdoc(basicInfo?.signedDocumentURL)"><i class="fas fa-download generic-palette-b-w-gray f18" title="DOA"></i></span>
                                <span class="value pl-56" *ngIf="basicInfo?.signedDocumentURL === null && basicInfo?.signedDocumentURL === '' && basicInfo?.unSignedDocumentURL !== null && basicInfo?.unSignedDocumentURL !== ''" (click)="openDOAdoc(basicInfo?.unSignedDocumentURL)"><i class="fas fa-download generic-palette-b-w-gray f18" title="DOA"></i></span>
                                <span class="value pl-56 doa-disable" *ngIf="basicInfo?.signedDocumentURL === null && basicInfo?.signedDocumentURL === '' && basicInfo?.unSignedDocumentURL === null && basicInfo?.unSignedDocumentURL === ''"><i class="fas fa-download generic-palette-b-w-gray f18" title="DOA"></i></span>
                            </div>
                            </div>

                        <div class="col-lg-3 col-md-3 col-sm-12 justify-content-end">
                            <div class="col-lg-12 col-md-12 col-sm-12 id-info-div align-item-start pr0">
                                <span class="label roboto-bold">Number of Shares: {{basicInfo.noOfBricklets}}</span>
                                <span class="value">Requested on: <span class="text-transform-upper">{{basicInfo.requestReceivedDate | date:
                                    'dd-MMM-yyyy'}}</span></span>
                            </div>
                            </div>


                        </div>


                    <!-- </mat-panel-description>
                  </mat-expansion-panel-header> -->
                  <!-- <div class="col-lg-12 col-md-12 col-sm-12 row pl-7  desc-content" > -->
                      <div class="mat-row p0 h-70 bd-0">
                    <div class="col-lg-3 col-md-3 col-sm-12 user-info-div pl-12">
                        <span class="name roboto-bold">Shares Price</span>
                        <span class="value"><a class="view-link" (click)="onView(basicInfo?.buyPriceRange)">View Price</a></span>

                    </div>
                    <!-- <div class="col-lg-3 col-md-3 col-sm-12 price-info-div pl35">
                        <span class="name roboto-bold"></span>
                        <span class="value">
                            <mat-form-field class="example-full-width">
                            <input matInput formControlName="referenceID" placeholder="Reference ID" class="roboto-regular f14" autocomplete="off">
                    <mat-error *ngIf="uploadForm.get('referenceID')?.hasError('required')">Reference ID is required</mat-error>
                    </mat-form-field>
                        </span>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-12 price-info-div pl35">
                        <span class="name roboto-bold"></span>
                        <span class="value">
                            <mat-form-field class="example-full-width">
                                <input matInput formControlName="referenceIdExpiryDate" [min]="minExpiryDate" [matDatepicker]="expiryDate" placeholder="Reference ID Expiry Date" class="roboto-regular f14" #from
                                     readonly>
                                <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
                                <mat-datepicker #expiryDate></mat-datepicker>
                            </mat-form-field>
                        </span>
                    </div> -->

                    <div class="col-lg-4 col-md-4 col-sm-12 justify-content-end">
                        <div class="col-lg-12 col-md-12 col-sm-12 price-info-div align-item-center pr0">
                        <span class="name roboto-bold">State</span>
                        <span class="value">{{basicInfo?.propertyStateName}}</span>
                        </div>
                    </div>
                    
                    <!-- <div class="col-lg-4 col-md-4 col-sm-12 price-info-div pl-10">
                        <span class="name roboto-bold">Total Shares price</span>
                        <span class="value"></span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 user-info-div pl-30">
                        <span class="name roboto-bold">Tenant in common interest(%)</span>
                        <span class="value">{{getInterest(record.totalNoOfBricklets, record.noOfBricklets) | number : '1.2-2'}}</span>
                    </div> -->
                </div>
            
                <p class="label roboto-bold title-transfer-doc mb-3">Title Transfer Document</p>
                <div class="col-lg-12 col-md-12 col-sm-12 pl-7  d-flex flew-row  mt-2 prop-doc-container">
                
                    
                        <div class="col-3 my-auto">
                
                            <mat-form-field class="example-full-width">
                                <mat-label> Settlement Date & Time <span class="star-red">*</span></mat-label>
                                <!-- <input matInput formControlName="docDate" [matDatepicker]="docDate" readonly>
                                <mat-datepicker-toggle matSuffix [for]="docDate">
                                </mat-datepicker-toggle>
                                <mat-datepicker #docDate></mat-datepicker> -->
                                <input matInput formControlName="docDate" [min]="minDate" [matDatetimepicker]="docDate" readonly autocomplete="false"  (dateChange)="changedateEvent('change', $event, basicInfo?.conveyancerOrderGUID)">
                                <mat-datetimepicker-toggle [for]="docDate" matSuffix></mat-datetimepicker-toggle>
                                <mat-datetimepicker #docDate type="datetime" [openOnFocus]="true" [timeInterval]="5"></mat-datetimepicker>
                
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <input type="hidden">
                            <div class="custom-file-upload prop-doc" file-drop (files)="propertyDocSubmit($event,0)">
                                <input type="file" formControlName="file" (change)="propertyDocSubmit($event,0)">
                                <label *ngIf="uploadForm?.controls.fileName.value!==''">
                                    <span class="upload-img-name">{{uploadForm?.controls.fileName.value}}</span>
                                </label>
                               
                                <label *ngIf="uploadForm?.controls.fileName.value===''">
                                    <span>Drag and drop</span>
                                    <span>or</span>
                                    <span>Click here to upload file</span>
                                </label>
                
                                <p class="prop-docs-file-upload-info">File Format Should be .pdf file. File size upto 25MB</p>
                            </div>
                            <!-- <mat-error class="err-doc text-center"
                                *ngIf="propertyDocuments?.controls[0].controls?.file.errors && propertyDocuments?.controls[0].controls?.file.errors.required">
                                Upload a file</mat-error>
                            <mat-error class="err-doc text-center"
                                *ngIf="propertyDocuments?.controls[0].controls?.fileName.errors && propertyDocuments?.controls[0]?.controls?.fileName?.errors.duplicateFile">
                                File already exist</mat-error> -->
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12" >
                        <div class="col-lg-12 col-md-12 col-sm-12 id-info-div pl-10 pr0 pr-15 pb-0">
                            <button mat-raised-button class="col-md-12 col-sm-12 mb10 roboto-medium f12 btn-filter" color="primary" [disabled]="uploadForm.invalid"
                                (click)="openApprovalDialoguebox(basicInfo.conveyancerOrderGUID)">Approve</button>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 id-info-div pl-10 pr0 pr-15 pb-0">
                            <button mat-raised-button class="col-md-12 col-sm-12 mb10 bg-torch-red white roboto-medium f12 btn-filter"
                                (click)="reject(basicInfo.conveyancerOrderGUID)" color="warning">Reject</button>
                        </div>
                    </div>
                
                    <!-- <div class="justify-content-center p-0 d-flex flew-row col-lg-12 col-md-12 col-sm-12 mt-3">
                
                        
                    </div> -->
                </div>
            
            <!-- </div> -->
        </div>
              
              </div>
              

            
            <app-loader class="loader" *ngIf="isLoading"></app-loader>
           
        </div>
        </div>
        </form>
        <!-- <mat-paginator #paginator class="table mt-2" [length]="resultsLength" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPaginateChange($event)"
        showFirstLastButtons></mat-paginator> -->
    </div>
</div>