import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadImageComponent } from '../upload-image/upload-image.component';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-amenities-card',
  templateUrl: './amenities-card.component.html',
  styleUrls: ['./amenities-card.component.scss']
})
export class AmenitiesCardComponent implements OnInit {

  name: any;
  date: any;
  image: any;
  isActive: boolean = false;

  @Input() guid: any;
  @Input() typevalue: any;
  @Input() typename: any;
  @Input() item: any;
  @Input() isEditEnabled: boolean = false;

  @Output() outputUpdateEvent = new EventEmitter<any>();
  @Output() outputDeleteEvent = new EventEmitter<any>();
  @Output() componentInstance = new EventEmitter<any>();
  @Output() guidEvent = new EventEmitter<any>();
  retExtension: any;
  dialogRef:any;
  constructor(private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private http: HttpClient) {
     

     }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    this.name = this.item ? this.item.amenity : '';
    this.date = this.item ? this.item.createdDate : null;
    this.retExtension = this.imageFile(this.item.iconURL);
    
    this.image = this.item ? this.retExtension === 'image/svg+xml' ? this.getIconImg(this.item.iconURL, this.retExtension) : this.item.iconURL : null;

    this.isActive = this.item ? this.item.isActive : false;
    
  }


  edit(id: any) {
    this.dialogRef = this.dialog.open(UploadImageComponent, {
      width: '45%',
      data: { type: this.typevalue, name: this.typename, guid: id }
    });

    this.dialogRef.componentInstance.outputEvent.subscribe((data: any) => {
      this.outputUpdateEvent.emit(data);
    });
    this.componentInstance.emit(this.dialogRef);
    this.guidEvent.emit(id);
  }


  confirmDeleteDialog(guid: any) {
    const message = ``;

    const dialogData = new ConfirmDialogModel(
      "Delete Amenities?",
      message,
      "Yes",
      "No",
      "Amenities-title"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "40%",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.outputDeleteEvent.emit(guid);
      }
    });
  }


  getIconImg(img: any, ext: any){
    
    const url = this.toDataURL2(
      img,
      ext,
      async (dataUrl) => {
        this.image = dataUrl;
        this.cdr.detectChanges();
      }
    )
    return url;
  }
  

  


imageFile(str)
{
 
  let ext = str.split(".").pop()?.toString();
 
let retExts: string;
switch(ext) {
  
  case "svg": {
    retExts = "image/svg+xml";
    break;
  }
    case "png":{
      retExts = "image/png";
      break;
    }
      
      case "jpg": {
        retExts = "image/jpg";
        break;
      }
        

      default: {
        retExts = str;
        break;
      }
}
return retExts;

  
}




toDataURL2(url, exts, cb) {
  var xhr = new XMLHttpRequest();
  xhr.onload = async () => {
    
    const blob = new Blob([xhr.response], { type: exts });
    
    cb(window.URL.createObjectURL(blob));
  };
  xhr.open('GET', url);
  //xhr.responseType = 'blob';
  xhr.send();
}





  
}
