import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';

@Injectable({
  providedIn: 'root'
})
export class PodcastService {

  private FloatingWindowEvent = new BehaviorSubject(null);
  public getFloatingWindowDetails = this.FloatingWindowEvent.asObservable();

  constructor(
    private apiManager: ApiManagerService
  ) { }

  updateFloatingWindow(data: any) {
    this.FloatingWindowEvent.next(data);
  }


}
