import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-twofactor-reset',
  templateUrl: './twofactor-reset.component.html',
  styleUrls: ['./twofactor-reset.component.scss']
})
export class TwofactorResetComponent implements OnInit {

  title: string;
  message: string;
  form!: FormGroup;

  constructor(public dialogRef: MatDialogRef<TwofactorResetComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
              private formBuilder: FormBuilder) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      txtVerificationCode: ['', Validators.required]
    });
  }

  onConfirm(form: any): void {
    // Close the dialog, return true
    const data = {
      code: `${form.value.txtVerificationCode}`,
      status: true
    };
    this.dialogRef.close(data);
  }

  onDismiss(): void {
    const data = {
      code: '',
      status: false
    };
    // Close the dialog, return false
    this.dialogRef.close(data);
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

}



export class ConfirmDialogModel {

  constructor(public title: string, public message: string) {
  }
}
