import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  AfterViewInit,
  Input,
  OnDestroy,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { NewsService } from "src/app/core/services/news.service";
import { Observable } from "rxjs";
import { trigger, transition, style, animate } from "@angular/animations";
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { StaticSuccessMessage } from '../../../core/enum/constants';

@Component({
  selector: "app-news-category",
  templateUrl: "./news-category.component.html",
  styleUrls: ["./news-category.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate(1500, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class NewsCategoryComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() type = null;
  public form: FormGroup;
  GUID = null;
  isbtnLoading = false;
  isExists = false;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private newsService: NewsService,
    private helper: HelperService
  ) {
    this.form = this.formbuilder.group({
      id: new FormControl(""),
      category: new FormControl("", [Validators.required]),
      isActive: new FormControl(true),
    });
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.type = this.data.type;
    this.GUID = this.data.guid;
    if (this.GUID !== null) {
      this.getDetailByGUID(this.GUID);
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    this.isbtnLoading = true;
    this.isExists = false;
    this.getisExists().subscribe(
      (res) => {
        const result: any = res;
        if (res !== null) {
          this.isExists = result.data.isExist;
          if (this.isExists === false) {
            const data = {
              Category: this.f.category.value,
              IsActive: this.f.isActive.value,
            };

            this.add(data);
          } else {
            this.isbtnLoading = false;
            this.helper.error("already name exists! ");
          }
        } else {
          this.isbtnLoading = false;
          this.helper.error(result.message);
        }
      },
      (error) => {
        this.isbtnLoading = false;
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  onUpdate() {
    this.isbtnLoading = true;
    this.isExists = false;
    this.getisExists().subscribe(
      (res) => {
        const result: any = res;
        if (res !== null) {
          this.isExists = result.data.isExist;
          if (this.isExists === false) {
            const data = {
              ID: this.GUID,
              Category: this.f.category.value,
              IsActive: this.f.isActive.value,
            };

            this.edit(data);
          } else {
            this.isbtnLoading = false;
            this.helper.error("already name exists! ");
          }
        } else {
          this.isbtnLoading = false;
          this.helper.error(result.message);
        }
      },
      (error) => {
        this.isbtnLoading = false;
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  getisExists(): Observable<any> {
    return this.newsService.getIsExistsCategory(
      this.GUID,
      this.f.category.value
    );
  }

  add(data) {
    this.newsService.addNewsCategory(data).subscribe(
      (res) => {
        this.isbtnLoading = false;
        const result: any = res;
        if (result.data != null) {
          this.helper.success(
            StaticSuccessMessage.saveNewsCategroy
          );
          this.newsService.changeMessage(true);
          this.form.reset();
          this.onClose();
        } else {
          this.isbtnLoading = false;
          this.helper.error(result.message);
        }
      },
      (error) => {
        this.isbtnLoading = false;
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  edit(data) {
    this.newsService.editNewsCategroy(data).subscribe(
      (res) => {
        this.isbtnLoading = false;
        const result: any = res;
        if (result.data != null) {
          this.helper.success(
            StaticSuccessMessage.updateNewsCategroy
          );
          this.newsService.changeMessage(true);
          this.form.reset();
          this.onClose();
        } else {
          this.isbtnLoading = false;
          this.helper.error(result.message);
        }
      },
      (error) => {
        this.isbtnLoading = false;
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  getDetailByGUID(id) {
    this.newsService.getNewsCategory(id).subscribe(
      (res) => {
        const result: any = res;
        if (result.data != null) {
          const data = result.data;
          const newsCategory = data.newsCategory;

          this.GUID = newsCategory.id;
          this.form.patchValue({
            id: newsCategory.id,
            category: newsCategory.category,
            isActive: newsCategory.isActive,
            // createdDate: newsCategory.createdDate
          });
        } else {
          this.helper.error(result.message);
        }
      },
      (error) => {
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  get f() {
    return this.form.controls;
  }
}
