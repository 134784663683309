import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  switchMap,
  filter,
  take,
  catchError,
  finalize,
  map,
  tap
} from 'rxjs/operators';
import { HelperService } from '../utils/helper.service';
import { StorageService } from '../utils/storage.service';
import { Storage } from '../enum/storage';
import { AuthService } from '../services/auth.service';
import { CommonService } from '../utils/common.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  isRefreshProgress = false;
  private unauthorizedApis: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private helper: HelperService,
    private storageService: StorageService,
    private authService: AuthService,
    private commonService: CommonService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any> | any> {
    const token = this.storageService.getItem(Storage.AUTH_TOKEN);
    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      map((r: any) => {
        if (r instanceof HttpResponse) {
          let alterResponse: any = r;
          if(r.body.type === 'application/octet-stream' || r.body.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || r.body.type === 'text/csv') {
            return alterResponse;
          } else {
          alterResponse.body = {
            data: r.body.response !== undefined ? r.body.response.data !== undefined ? r.body.response.data : r.body.response : r.body,
            msg: r.body.response !== undefined ? r.body.response.message !== undefined ? r.body.response.message : r.body.response : r.body
          };
          return alterResponse;
        }
          //this.helper.success(alterResponse.body.msg);
          
        }
        return r;
      }),
      catchError(error => {
        // this.helper.error(error.error.response.message);
        if (error instanceof HttpErrorResponse && (error.status === 400 || error.status === 450)) {
          const err = error.error.message || error.error.response.message || error.statusText || 'Something went wrong. Try again later.';
          return throwError(err);
        } else if (error instanceof HttpErrorResponse && error.status === 401) {
          if (error.status === 401 && error.error.response === undefined) {
            if (error.error.type === 'application/json') {
              return this.errorUnauthorized(request, next);
            } else {
              if(error.error.response === undefined){
                const parseError = JSON.parse(error.error)
                if (error.status === 401 && parseError.response.message === 'Token is expired') {
                  return this.errorUnauthorized(request, next);
                }
              }

              
              const err = error.error.message || error.error.response.message || error.statusText;
              return throwError(err);
            }
          }  else if (error.status === 401 && error.error.response.message === 'Refresh Token is expired') {
            this.isRefreshProgress = false;
            this.authService.changeStatus(false);
            this.authService.logout();
            // const err = error.error.message || error.error.response.message || error.statusText;
            const err = "Session Timed Out";
            return throwError(err);
          } else if (error.status === 401 && error.error.response.message === 'Invalid JWT Token') {
            this.isRefreshProgress = false;
            this.authService.changeStatus(false);
            this.authService.logout();
            //const err = error.error.message || error.error.response.message || error.statusText;
            const err = "Session Timed Out";
            return throwError(err);
          } else {
            return this.errorUnauthorized(request, next);
          }
        } 
        else if (error instanceof HttpErrorResponse && error.status === 404) {
          return throwError('Something went wrong. Try again later.');
        }
        else if (error instanceof HttpErrorResponse && error.status === 0) {
          return throwError('Something went wrong. Try again later.');
        }
        else if (error instanceof HttpErrorResponse && error.status === 500) {
          if (error.error.response.message) {
            return throwError(error.error.response.message);
          }
          return throwError('Something went wrong. Try again later.');
        }
        
        else {
          const err = error.error.message || error.error.response.message || error.statusText ;
          this.commonService.hideLoader();
          return throwError(err);
        }
      
      
      })
    );
  }

  errorUnauthorized(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshProgress) {
      this.isRefreshProgress = true;
      this.unauthorizedApis.next(null);
      return this.authService.getRefreshToken().pipe(
        switchMap((token: any) => {
          if (token) {
            this.isRefreshProgress = false;
            this.unauthorizedApis.next(token);
            return next.handle(this.addToken(request, token)).pipe(
              map((r: any) => {
                if (r instanceof HttpResponse) {
                  let alterResponse: any = r;
                  if(r.body.type === 'application/octet-stream' || r.body.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || r.body.type === 'text/csv') {
                    return alterResponse;
                  } else {
                  alterResponse.body = {
                    data: r.body.response !== undefined ? r.body.response.data !== undefined ? r.body.response.data : r.body.response : r.body,
                    msg: r.body.response !== undefined ? r.body.response.message !== undefined ? r.body.response.message : r.body.response : r.body
                  };
                  //this.helper.success(alterResponse.body.msg);
                  return alterResponse;

                }

                }
                return r;
              })
            );
          }else {
            return this.authService.logout() as any;
          }
        })
        // catchError((err) => {
        //   return this.authService.logout() as any;
        // }),
        // finalize(() => {
        //   this.isRefreshing = false;
        // })
      );
    } else {
      return this.unauthorizedApis.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

  // private addToken(request: HttpRequest<any>, token: string) {
  //   return request.clone({
  //     setHeaders: {
  //       'Authorization': `Bearer ${token}`
  //     }
  //   });
  // }

  addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ` + token
      }
    });
  }
}
