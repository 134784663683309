import { Component, OnInit, Input } from '@angular/core';
import { KycConfigService } from 'src/app/core/services/kyc-config.service';
import { Trustee } from '../../../core/interfaces/kyc.interface';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';

@Component({
  selector: 'app-org-trustee',
  templateUrl: './org-trustee.component.html',
  styleUrls: ['./org-trustee.component.scss']
})
export class OrgTrusteeComponent implements OnInit {

  @Input() trusteeData: Trustee | any;

  personalInfo: UserInfoCardInterface | null = null;
  addressInfo: UserInfoCardInterface | null = null;
  idInfo: UserInfoCardInterface | null = null;
  addressVerifyInfo: UserInfoCardInterface | null = null;

  basicInfo: UserInfoCardInterface | null = null;
  regOffice: UserInfoCardInterface | null = null;
  businessInfo: UserInfoCardInterface | null = null;

  isPrivate = false;

  constructor(
    private kycConfigService: KycConfigService
  ) { }

  ngOnInit() {
   
    if (this.trusteeData.privateCompany) {
      this.isPrivate = true;
      const result: any = this.kycConfigService.kycArrangePrivateData(this.trusteeData.privateCompany);

      this.basicInfo = {
        title: 'Private Company',
        column: 12,
        type: 'doc-data-text-only',
        data: result.basicInfo
      };

      this.regOffice = {
        title: 'Registered Office',
        column: 3,
        type: 'text-only',
        data: result.regOffice
      };

      this.businessInfo = {
        title: 'Principal Place Of Business',
        column: 3,
        type: 'text-only',
        data: result.businessInfo
      };
      

    } else {
      const result: any = this.kycConfigService.kycOrgArrangedData(this.trusteeData.individual);
      this.personalInfo = {
        title: 'Personal Information',
        column: 4,
        type: 'text-only',
        data: result.personalInfo
      };
      this.addressInfo = {
        title: 'Address Information',
        column: 3,
        type: 'text-only',
        data: result.addressInfo
      };

      this.addressVerifyInfo = {
        title: 'Address verification documents',
        column: 0,
        type: 'doc-only',
        data: result.addressVerifyDocs
      };

      this.idInfo = {
        title: 'Identity verification documents',
        column: 0,
        type: 'doc-data-only',
        data: result.idInfo
      };

    }
  }

}
