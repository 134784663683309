<h2 mat-dialog-title [ngClass]="titleDisable ? 'mt-title': ''">
    <span>{{title}}</span>
    <span mat-dialog-close *ngIf="!titleDisable">X</span>
</h2>
<mat-dialog-content class="mat-typography" [ngClass]="titleDisable ? 'mh-none' : ''">
    <div class="bootstrap-wrapper">
        <div class="row">
            <div class="col-5">
                <app-user-info-card [config]="personalInfo"></app-user-info-card>
            </div>
            <div class="col-7">
                <app-user-info-card [config]="addressInfo"></app-user-info-card>
            </div>
        </div>
        <div class="row mt15 mb10">
            <div class="col-7">
                <app-user-info-card [config]="idInfo"></app-user-info-card>
            </div>
            <div class="col-5">
                <app-user-info-card [config]="addressVerifyInfo"></app-user-info-card>
                
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!dialogBtnDisable" class="kyc-ind">
        <div class="bootstrap-wrapper">
        <div class="row mt15">
                <!-- <div class="col-6">
                    <button class="btn-watermelon" type="button" (click)="rejectKyc()">Reject</button>
                </div> -->
                <div class="col-12">
                    <button class="btn-cornflower-blue" type="button" [class.spinner]="isbtnLoading" (click)="approveKyc()">Approve</button>
                </div>
            </div>
            </div>

</mat-dialog-actions>