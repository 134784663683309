<h2 mat-dialog-title>
    <span>Reject verification ?</span>
    <span mat-dialog-close>X</span>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="bootstrap-wrapper">
        <div class="row">
            <div class="col-12">
            <mat-form-field>
                <mat-label class="roboto-regular f14 black">Reason for rejection ?</mat-label>
                <textarea matInput rows="4" [(ngModel)]="data.reason"></textarea>
            </mat-form-field>
            </div>
            <div class="col-12">
                <div class="row adjust-flex">
                    <div class="col-3">
                        <button class="btn-watermelon" type="button" (click)="onStatusClick(true)">Reject</button>
                    </div>
                    <div class="col-3">
                        <button class="btn-cornflower-blue" type="button" (click)="onStatusClick(false)">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>