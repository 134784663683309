<div class="bootstrap-wrapper" @fade>
    <div class="container">
        <div class="row">
            <div class="col-10 p0 roboto-medium f16">{{type}}</div>
            <div class="col-2 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20" (click)="onClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <form [formGroup]="form">
            <div class="p10 mt10">
             
                    
                    <!-- <div class="col-12 d-flex justify-content-center p0 mb10">-->
                        <div class="col-sm-12- col-md-12 col-lg-12 col-xl-12 ml15 mat-elevation-z8 elevation-box"> 
                                <mat-dialog-content>

                                    <div class="row justify-content-center">
                                <div class="col-lg-8 col-md-8 col-sm-12  p0">
                                
                                
                                    <div class="d-flex justify-content-center mt5">
                                        <mat-form-field class="example-full-width col-12">
                                            <mat-label>Select Users<span class="star-red">*</span></mat-label>
                                            <mat-select formControlName="selectedUsers"  id="select-user" (openedChange)="openedChange($event, 'searchUser')"
                                                (selectionChange)="onSelectedUser($event)">
                                                <mat-form-field style="width: 100%; padding: 10px;">
                                                    <input #searchUser autocomplete="off" placeholder="Search email" aria-label="SearchUser" matInput
                                                        formControlName="searchUser" (change)="selectedUser()" />
                                                    <button [disableRipple]="true" *ngIf="searchUser.value" matSuffix mat-icon-button aria-label="Clear"
                                                        (click)="clearSearch($event, 'searchUser')">
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                                <div class="list-items">
                                                <mat-option *ngFor="let user of userList" [value]="user.userGUID" class="dropdown-panel">{{ user.fullName }}
                                                    <span style="font-size: 12px; color: blueviolet;">
                                                        ({{ user.email }})
                                                    </span>
                                                </mat-option>
                                            </div>
                                            </mat-select>
                                            <mat-error align="end" *ngIf="hasError('selectedUsers') && hasFieldError('selectedUsers', 'required')">Users
                                                is
                                                required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="d-flex justify-content-center mt5">
                                        <mat-form-field class="example-full-width col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <mat-label>Select Accounts<span class="star-red">*</span></mat-label>
                                            <mat-select formControlName="selectedAccounts" id="select-account" (openedChange)="openedChange($event, 'searchAccount')">
                                                <mat-form-field style="width: 100%; padding: 10px;">
                                                    <input #searchAccount autocomplete="off" placeholder="Search Account" aria-label="SearchAccount"
                                                        matInput formControlName="searchAccount" />
                                                    <button [disableRipple]="true" *ngIf="searchAccount.value" matSuffix mat-icon-button aria-label="Clear"
                                                        (click)="clearSearch($event, 'searchAccount')">
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                                <div class="list-items">
                                                <mat-option *ngFor="let account of accountList" [value]="account?.accountGUID" class="dropdown-panel">{{
                                                    account?.name }}
                                                    <!-- <span style="font-size: 12px; color: blueviolet;">
                                                                                                                              ({{ account?.entityType }})
                                                                                                                            </span> -->
                                                </mat-option>
                                                </div>
                                            </mat-select>
                                            <mat-error align="end" *ngIf="hasError('selectedAccounts') && hasFieldError('selectedAccounts', 'required')">
                                                Account
                                                is
                                                required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center">
                                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between p0 mb10 pr5">
                                            <label class="slate-grey-two f14 roboto-medium mt5 pl10">Status</label>
                                            <mat-slide-toggle class="m10 pr2" formControlName="isActive" [color]="color" (change)="onToggle($event)" [disabled]="readonly">
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                
                                
                                
                                </div>
                            </div>
                                    </mat-dialog-content>

                                    <mat-dialog-actions class="d-flex justify-content-center pl5 mt30 mb35">
                                            <button mat-raised-button color="primary" [disabled]="form.invalid || isbtnLoading" class="col-3 roboto-medium f12" [class.spinner]='isbtnLoading' (click)="onSubmit()" >Submit</button>
                                    </mat-dialog-actions>

                       </div>
                   <!--   </div> -->

                
            </div>
        </form>

        
    </div>
</div>
