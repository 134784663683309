import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import * as $ from 'jquery';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';


@Injectable({
  providedIn: 'root'
})
export class CommunityService {
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(private apiManagerService: ApiManagerService, private http: HttpClient) { }
  

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }

  getStateList() {
    return this.apiManagerService.getRequest(RestEnds.stateByCountry, null);
  }

  getCategory(){
    return this.apiManagerService.getRequest(RestEnds.category, null);
  }

  postCategory(data: any){
    return this.apiManagerService.postRequest(RestEnds.category, data);
  }

  getSubCategory(){
    return this.apiManagerService.getRequest(RestEnds.subcategory, null);
  }

  postSubCategory(data: any){
    return this.apiManagerService.postRequest(RestEnds.subcategory, data);
  }


  getCategoryByGuid(data: any) {
    return this.apiManagerService.getRequest(
      RestEnds.getCategoryByGuid + data, null
    );
  }

  updateCategory(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.updateCategory,
      data
    );
  }

  updateSubCategory(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.updateSubCategory,
      data
    );
  }

  getSubCategoryByGuid(data: any) {
    return this.apiManagerService.getRequest(
      RestEnds.getSubCategoryByGuid + data, null
    );
  }

  getArticleData(val){
    return this.apiManagerService.getRequest(RestEnds.article+val, null);
  }

  saveBanner(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addBanner,
      data
    );
  }

  addArticle(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addArticle,
      data
    );
  }

  updateArticle(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.updateArticle,
      data
    );
  }

  getArticlesByGuid(data: any) {
    return this.apiManagerService.getRequest(
      RestEnds.getArticleByGuid + data, null
    );
  }

  getArticleCategoryList(guid : any) {
    return this.apiManagerService.getRequest(
      RestEnds.getArticleSubCategoryByCategory + guid , null
    );
  }

  getVideoCategoryList(guid : any) {
    return this.apiManagerService.getRequest(
      RestEnds.getVideosSubCategoryByCategory + guid , null
    );
  }


  getArticleList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getArticleList, data);
  }

  deleteArticle(data: any) {
    return this.apiManagerService.deleteRequest(
      RestEnds.deleteArticle +
      data
    );
  }

  deleteCategory(data: any) {
    return this.apiManagerService.deleteRequest(
      RestEnds.deleteCategory +
      data
    );
  }

  deleteSubCategory(data: any) {
    return this.apiManagerService.deleteRequest(
      RestEnds.deleteSubCategory +
      data
    );
  }



getContentFromURL(data: any) {
    //  data = "https://bricketstorageacc.blob.core.windows.net/communityarticleshtml/51fa11d7-57c0-43e4-85fc-2498d7aba18a.html?sp=r&st=2021-06-25T11:04:17Z&se=2021-07-05T18:30:00Z&spr=https&sv=2020-02-10&sr=b&sig=0WqkkF8arOB2bkJOP%2BfdsppbVDUNwHX6iT6H%2F%2BpALy8%3D";
    return this.apiManagerService.getHtml(
      data
    );
  }

  showGetResult (URL: any, callback: any) {
    return $.get(URL, {}, callback);
  }

  getContentFromURsL(URL: any){
    this.showGetResult(URL, function(data: any){ alert(data); return data });
  }

 
   getContentFromURLl(URL: any){
    return $.get(URL, function( my_var ) {
    // console.log(my_var);
      my_var;
    }, 'text');  // or 'text', 'xml', 'more'
   }

   addVideo(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addVideo,
      data
    );
  }

  addCategory(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addCategory,
      data
    );
  }

  addSubCategory(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addSubCategory,
      data
    );
  }

  updateVideo(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.updateVideo,
      data
    );
  }

  getVideoByGuid(data: any) {
    return this.apiManagerService.getRequest(
      RestEnds.getVideoByGuid + data, null
    );
  }

  getVideosList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getVideoList, data);
  }

  deleteVideo(data: any) {
    return this.apiManagerService.deleteRequest(
      RestEnds.deleteVideo  +
      data
    );
  }

  getVideosCategoryList() {
    return this.apiManagerService.getRequest(
      RestEnds.getVideosSubCategoryByCategory, null
    );
  }

  addPodcast(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addPodcast,
      data
    );
  }

  updatePodcast(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.updatePodcast,
      data
    );
  }

  getPodcastByGuid(data: any) {
    return this.apiManagerService.getRequest(
      RestEnds.getPodcastByGuid + data, null
    );
  }

  getPodcastsList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getPodcastList, data);
  }

  deletePodcast(data: any) {
    return this.apiManagerService.deleteRequest(
      RestEnds.deletePodcast  +
      data
    );
  }

  getPodcastsCategoryList() {
    return this.apiManagerService.getRequest(
      RestEnds.getPodcastSubCategoryByCategory, null
    );
  }

  getPodcastCategories() {
    return this.apiManagerService.getRequest(
      RestEnds.getPodcastCategories, null
    );
  }

  getBlob(URL:any){
    return this.http.get(URL, { observe: 'body', responseType: 'blob' as 'json' });
  }

  
  getFeatureArticleList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getFeatureArticleList, data);
  }

  deleteFeatureArticle(data: any) {
    return this.apiManagerService.deleteRequest(
      RestEnds.deleteFeatureArticle +
      data
    );
  }

  getFeatureArticlesByGuid(data: any) {
    return this.apiManagerService.getRequest(
      RestEnds.getFeatureArticleByGUID + data, null
    );
  }

  updateBanner(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.updateFeatureArticle,
      data
    );
  }
}
