import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class DepositService {

  constructor(
    private apiManagerService: ApiManagerService
  ) { }

  getAllDepositRequests(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getAllDepositRequests, data);
  }

  getAllDepositHistory(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getAllDepositHistory, data);
  }

  approveDepositRequest(data: any) {
    return this.apiManagerService.postRequest(RestEnds.approveDepositRequest, data);
  }

  rejectDepositRequest(guid: any) {
    return this.apiManagerService.deleteRequest(`${RestEnds.rejectDepositRequest}?depositGUID=${guid}`);
  }
}
