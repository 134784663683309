import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/authentication/auth-guard.service';
import { EmailConfirmationComponent } from './pages/authentication/email-confirmation/email-confirmation.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    data: {
      header: true
    }
  },
  {
    path: "admin",
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/layout/layout.module').then(m => m.LayoutModule),
    data: {
      header: true
    }
  },
  {
    path: "2fa",
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/two-factor-authentication/two-factor-authentication.module').then(m => m.TwoFactorAuthenticationModule),
    data: {
      header: true
    }
  },
  {
    path: 'emailconfirmation/:l5OT/:xt8y/:name',
    component: EmailConfirmationComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
