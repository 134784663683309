import {
    Directive,
    HostListener,
    ElementRef,
    forwardRef,
  } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

declare interface HTMLInputElement {
    files: FileList | null;
  }

@Directive({
    /* tslint:disable:directive-selector */
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[type=file]',
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FileDirective),
        multi: true
      }
    ]
  })
  export class FileDirective implements ControlValueAccessor {
    public onChange: any = (_: any) => {};
    public onTouched = () => {};

    constructor(private element: ElementRef) { }

    private get inputElement(): HTMLInputElement | any {
      return this.element.nativeElement;
    }

    public get file(): File {
      return this.inputElement.files[0];
    }

    public get files(): FileList {
      return this.inputElement.files;
    }

    public set files(files: FileList) {
      this.inputElement.files = files;
    }

    public writeValue(files: FileList) {
      if (files) {
        this.files = files;
      }
    }

    public registerOnChange(fn: any) { this.onChange = fn; }
    public registerOnTouched(fn: any) { this.onTouched = fn; }

    @HostListener('change', ['$event.target.files'])
    public handleChange(files: FileList) {
      this.onChange(files);
    }

    @HostListener('blur')
    public handleTouched() {
      this.onTouched();
    }
  }

