import { AbstractControl, ValidatorFn, FormControl, Validators } from "@angular/forms";

export class ValidationService {
    static getValidatorErrorMessage(
        validatorName: string,
        validatorValue?: any,
        controlname?: string
    ): any | null {
        let config: any = {
            required: `${controlname} is required`,
            invalidCreditCard: "Is invalid credit card number",
            invalidEmailAddress: "Invalid email address",
            invalidPassword:
                "Your Password must contain At least 7 Characters, 1 upper case and lower case letters, 1 numeral and 1 symbol.",
            minlength: `Minimum length ${validatorValue.requiredLength}`,
            userNameMinlength: `Username field must be at least 6 characters long`,
            userNameMaxlength: `Username can be max 30 characters long`,
            maxlength: `Maximum length ${validatorValue.requiredLength}`,
            invalidPhoneNumber: "Invalid contact no",
            invalidFromTime: "Start time should be less then end time",
            invalidToTime: "End time should be greater then start time",
            OopsSomething: "Oops something went wrong ! Please try again",
            passwordNotMatch: "Password doesn't match",
            FromDateGreaterThenToDate:
                "End date should not be less than from date",
            invalidDateTime: "Date Time value invalid.",
            invalidCount: `Please enter 1 to 10`,
            portValidation: 'Must be numeric only',
            invalidInterval: "Invalid interval time",
            invalidGapPercentage:"Must be numeric only"
        };

        return config[validatorName];
    }

    static creditCardValidator(control: any) {
        // Visa, MasterCard, American Express, Diners Club, Discover, JCB
        if (
            control.value.match(
                /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
            )
        ) {
            return null;
        } else {
            return { invalidCreditCard: true };
        }
    }

    static emailValidator(control: any): any {
        // RFC 2822 compliant regex
        if (control.value) {
            if (
                control.value.match(
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
                )
            ) {
                return null;
            } else {
                return { invalidEmailAddress: true };
            }
        }
    }

    static contactNoValidator(control: any) {
        // RFC 2822 compliant regex
        if (control.value.match(/^\d{10,15}$/)) {
            return null;
        } else {
            return { invalidPhoneNumber: true };
        }
    }
    static invalidInterval(control: any) {
        let trigger = control.value,
            regexp = new RegExp('^[0-9]*$');
        if (regexp.test(trigger)) {
            return null;
        } else {
            return { invalidInterval: true };
        }
    }

    static UrlValidator(control: any): any {
        // RFC 2822 compliant regex
        if(control.value){
        if (control.value.match(
            "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)"
        )) {
            return null;
        } else {
            return { invalidUrl: true };
        }}
    }

    static invalidGapPercentage(control: any) {
        let trigger = control.value,
            regexp = new RegExp('^[0-9]*$');
        if (regexp.test(trigger)) {
            return null;
        } else {
            return { invalidGapPercentage: true };
        }
    }
    static passwordValidator(control: any) {
        let trigger = control.value,
            regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})/);
        if (regexp.test(trigger)) {
            return null;
        } else {
            return { invalidPassword: true };
        }
    }
    static passwordWitoutSymbolValidator(control: any) {
        let trigger = control.value,
            regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})/);
        if (regexp.test(trigger)) {
            return null;
        } else {
            return { invalidPassword: true };
        }
    }
    static MatchPassword(AC: AbstractControl | any): any {
        let password: any = AC.get("password").value; // to get value in input tag
        let confirmPassword = AC.get("confirmPassword").value; // to get value in input tag
        if (password != confirmPassword) {
            return AC.get("confirmPassword").setErrors({
                passwordNotMatch: true
            });
        } else {
            if (AC.get("confirmPassword").errors) {
                return AC.get("confirmPassword").setErrors(null);
            } else {
                return null;
            }
        }
    }

    static FromDateGreaterThenToDate(AC: AbstractControl | any) {
        let fromDate = AC.get("fromdate").value; // to get value in input tag
        let toDate = AC.get("todate").value; // to get value in input tag
        if (fromDate && toDate) {
            if (fromDate == toDate) {
                return AC.get("todate").setErrors({
                    FromDateGreaterThenToDate: true
                });
            } else {
                if (AC.get("todate").errors) {
                    return AC.get("todate").setErrors(null);
                } else {
                    return null;
                }
            }
        }
    }

    static fromTimeValidator(control: any): any {
        let fromTime = control.value;
        if (fromTime) {
            let toTime = control._parent.value.notificationEndTime
                ? control._parent.value.notificationEndTime
                : "23:59";
            if (+fromTime.split(":")[0] == +toTime.split(":")[0]) {
                if (+fromTime.split(":")[1] >= +toTime.split(":")[1]) {
                    return { invalidFromTime: true };
                }
                control._parent.controls.notificationEndTime.touched = false;
                return null;
            } else if (+fromTime.split(":")[0] > +toTime.split(":")[0]) {
                return { invalidFromTime: true };
            }
            control._parent.controls.notificationEndTime.touched = false;
            return null;
        }
    }

    static toTimeValidator(control : any): any {
        let toTime = control.value;
        if (toTime) {
            let fromTime = control._parent.value.notificationStartTime
                ? control._parent.value.notificationStartTime
                : "00:00";
            control._parent.controls.notificationStartTime.touched = false;
            if (+fromTime.split(":")[0] == +toTime.split(":")[0]) {
                if (+fromTime.split(":")[1] >= +toTime.split(":")[1]) {
                    return { invalidToTime: true };
                }
                control._parent.controls.notificationStartTime.touched = false;

                return null;
            } else if (+fromTime.split(":")[0] > +toTime.split(":")[0]) {
                return { invalidToTime: true };
            }
            control._parent.controls.notificationStartTime.touched = false;

            return null;
        }
    }

    static userNameMinLength(control: any) {
        if (control.value.length > 6) {
            return null;
        } else {
            return { userNameMinlength: true };
        }
    }
    static userNameMaxLength(control: any) {
        if (control.value.length < 30) {
            return null;
        } else {
            return { userNameMaxlength: true };
        }
    }

    static titleMaxLength(control: any): any {       
    if(control.value){
      if (control.value.length <= 100) {
          return null;
      } else {
          return { userNameMaxlength: true };
      }}
  }

  
  static descriptionMaxLength(control: any): any {
    if(control.value){
    if (control.value.length <= 4000) {
        return null;
    } else {
        return { userNameMaxlength: true };
    }
}
}


static urlMaxLength(control: any): any {
    if(control.value){
    if (control.value.length <= 1000) {
        return null;
    } else {
        return { userNameMaxlength: true };
    }
}
}

    static maxValue(max: Number): ValidatorFn | null {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const input = control.value,
                isValid = input > max;
            if (isValid)
                return { enterOneToTen: true };
            else
                return null;
        };
    }

    static multipleEmailvalidation(control: any) {
        let regexp = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
        if (regexp.test(control.value)) {
            return null;
        } else {
            return { invalidEmailAddress: true };
        }
    }

    static portValidation(control: any) {
        let trigger = control.value,
            regexp = new RegExp('^[0-9]*$');
        if (regexp.test(trigger)) {
            return null;
        } else {
            return { portValidation: true };
        }
    }

    static commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
        const emails = control.value.split(',').map((e: any) => e.trim());
        const forbidden = emails.some((email: any) => Validators.email(new FormControl(email)));
        return forbidden ? { invalidEmailAddress: true } : null;
    };
}
