import { Component, OnInit, ChangeDetectorRef, AfterViewInit, Input } from '@angular/core';
import { TwoFactorAuthService } from '../../../core/services/twofactorauth.service';

@Component({
  selector: 'app-qrcode-view',
  templateUrl: './qrcode-view.component.html',
  styleUrls: ['./qrcode-view.component.scss']
})
export class QrcodeViewComponent implements OnInit, AfterViewInit {

  @Input() imageSrc: any;
  qrSrc: any = null;
  constructor(
    private twoFactorAuthService: TwoFactorAuthService,
    private cdr: ChangeDetectorRef
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    // console.log(this.imageSrc);
  }

  ngAfterViewInit() {
    // console.log(this.imageSrc);
    if(this.imageSrc !== null || this.imageSrc !== "") {
      this.qrSrc = this.imageSrc;
    } else {
      this.qrSrc = "../../../../assets/images/QRCode.png";
    }
    
    this.cdr.detectChanges();
    
  }

}
