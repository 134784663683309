<h2 mat-dialog-title class="mb-2 d-flex space-between justify-content-between mt-18">
    <span> </span>
    <span mat-dialog-close>X</span>
</h2>
<mat-dialog-content class="dialog-content">
<div id="main">
    <div class="container">
        <div class="text-center my-3">
            <img src="/assets/images/logo.png" alt="" class="img"> 
        </div>
        <div id="nsw">
            <h3 class="text-center mb-4">IPANX User Instructions</h3>
            <table class="table table-bordered table-outer">
                <tbody>
                    <tr>
                        <th>Property address</th>
                        <td>{{basicInfo?.propertyAddress}}</td>
                    </tr>
                    <tr>
                        <th>Number of shares</th>
                        <td>{{basicInfo?.noOfBricklets}}</td>
                    </tr>
                    <tr>
                        <th>Price per shares</th>
                        <td>{{basicInfo?.pricePerBricklet}}</td>
                    </tr>
                    <tr>
                        <th>Seller of shares</th>
                        <td>{{basicInfo?.seller}}</td>
                    </tr>
                    <tr>
                        <th>Your name or entity that will own the shares <br /> <i>(Note: if trust, insert individual
                                trustee or company trustee names and also the name of the trust)</i></th>
                        <td class="p-0">
                            <table class="table table-bordered m-0 table-inside">
                                <tbody>
                                    <tr>
                                        <td>Individual 1 full name:</td>
                                        <td>{{basicInfo?.individual1Name}}</td>
                                    </tr>
                                    <tr>
                                        <td>If joint owners: <br /> Individual 2 full name:</td>
                                        <td>{{basicInfo?.individual2Name}}</td>
                                    </tr>
                                    <tr>
                                        <td>Company name:</td>
                                        <td>{{basicInfo?.companyName}}</td>
                                    </tr>
                                    <tr>
                                        <td>Company ACN:</td>
                                        <td>{{basicInfo?.companyACN}}</td>
                                    </tr>
                                    <tr>
                                        <td>Company directors (full names): </td>
                                        <td>{{basicInfo?.companyDirectors}}</td>
                                    </tr>
                                    <tr>
                                        <td>Trust name:</td>
                                        <td>{{basicInfo?.trustName}}</td>
                                    </tr>
                                    <tr>
                                        <td>Trust ABN:</td>
                                        <td>{{basicInfo?.trustABN}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <th>Your address</th>
                        <td>{{basicInfo?.address}}</td>
                    </tr>
                    <tr>
                        <th>Your email</th>
                        <td>{{basicInfo?.email}}</td>
                    </tr>
                    <tr>
                        <th>Your phone number</th>
                        <td>{{basicInfo?.phoneNumber}}</td>
                    </tr>
                    <tr>
                        <th>Your date(s) of birth (for individuals)</th>
                        <td>{{basicInfo?.dob | date: 'dd/MM/yyyy'}}</td>
                    </tr>
                    <tr>
                        <th>Your nationality (for individuals)</th>
                        <td>
                            <div class="d-flex">
                                <div class="form-check col-3">
                                    <input class="form-check-input" type="checkbox" [checked]="settlor?.IndividualNationality" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Australian
                                    </label>
                                </div>
                                <div class="form-check col-3">
                                    <input class="form-check-input" type="checkbox" [checked]="!settlor?.IndividualNationality" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Other
                                    </label>
                                </div>
                            </div>
                            <label>If other:</label>
                            <ul>
                                <li>Former surname (if any):{{basicInfo?.formersurname}}</li>
                                <li>Country of tax residence:{{basicInfo?.countryOfTaxResidence}}</li>
                                <li>Country of citizenship:{{basicInfo?.countryOfCitizenship}}</li>
                                <li>Overseas ID:{{basicInfo?.overseasID}}</li>
                                <li>
                                    <div class="d-flex">
                                        <div class="col-2">PR</div>
                                        <div class="form-check col-2">
                                            <input class="form-check-input" type="checkbox" [checked]="!settlor?.pr" value="" id="flexCheckDefault" [disabled]="true">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                No
                                            </label>
                                        </div>
                                        <div class="form-check col-2">
                                            <input class="form-check-input" type="checkbox" [checked]="settlor?.pr" value="" id="flexCheckDefault" [disabled]="true">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Yes
                                            </label>
                                        </div>
                                    </div></li>
                                <li>
                                    In Australia for last 200 days of last 12 months?
                                    <div class="d-flex">
                                        <div class="form-check col-2">
                                            <input class="form-check-input" type="checkbox" [checked]="!settlor?.InAustraliaForLast12Months" value="" id="flexCheckDefault" [disabled]="true">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                No
                                            </label>
                                        </div>
                                        <div class="form-check col-2">
                                            <input class="form-check-input" type="checkbox" [checked]="settlor?.InAustraliaForLast12Months" value="" id="flexCheckDefault" [disabled]="true">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                </li>
                                <li>Passport number:{{basicInfo?.passportNumber}}</li>
                                <li>Visa number:{{basicInfo?.visaNumber}}</li>
                                <li>Visa Subclass:{{basicInfo?.visaSubclass}}</li>
                                <li>Visa expiry:{{basicInfo?.visaExpiry}}</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>Company status</th>
                        <td>
                            Country of incorporation: 
                            <div class="d-flex">
                                <div class="form-check col-3">
                                    <input class="form-check-input" type="checkbox" [checked]="settlor?.countryOfCorporation" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Australian
                                    </label>
                                </div>
                                <div class="form-check col-3">
                                    <input class="form-check-input" type="checkbox" [checked]="!settlor?.countryOfCorporation" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Other
                                    </label>
                                </div>
                            </div>
                            <label>Is the corporation classed as a foreign corporation*?</label>
                            <div class="d-flex">
                                <div class="form-check col-2">
                                    <input class="form-check-input" type="checkbox" [checked]="!settlor?.classedofforeignCorporation" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        No
                                    </label>
                                </div>
                                <div class="form-check col-2">
                                    <input class="form-check-input" type="checkbox" [checked]="settlor?.classedofforeignCorporation" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Yes
                                    </label>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Trust status</th>
                        <td>
                            <label for="">Trust type:</label>
                            <div class="d-flex">
                                <div class="form-check col-2">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Discretionary 
                                    </label>
                                </div>
                                <div class="form-check col-2">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Fixed 
                                    </label>
                                </div>
                                <div class="form-check col-2">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Unit 
                                    </label>
                                </div>
                                <div class="form-check col-2">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Super fund
                                    </label>
                                </div>
                                <div class="form-check col-2">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Hybrid
                                    </label>
                                </div>
                            </div>

                            <label>Is the trust classed as a foreign trust*?</label>
                            <div class="d-flex">
                                <div class="form-check col-2">
                                    <input class="form-check-input" type="checkbox" [checked]="!settlor?.isForeignTrust" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        No
                                    </label>
                                </div>
                                <div class="form-check col-2">
                                    <input class="form-check-input" type="checkbox" [checked]="settlor?.isForeignTrust" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Yes
                                    </label>
                                </div>
                            </div>

                            <label>Is the trustee classed as a foreign*?</label>
                            <div class="d-flex">
                                <div class="form-check col-2">
                                    <input class="form-check-input" type="checkbox" [checked]="!settlor?.isForeignTrustee" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        No
                                    </label>
                                </div>
                                <div class="form-check col-2">
                                    <input class="form-check-input" type="checkbox" [checked]="settlor?.isForeignTrustee" value="" id="flexCheckDefault" [disabled]="true">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Yes
                                    </label>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Acknowledgements</th>
                        <td>
                            <ul>
                                <li>You warrant that the above information is accurate.</li>
                                <li>You acknowledge that the information you have provided in this form will be used in
                                    the
                                    legal process of transferring title to you and completing the appropriate government
                                    forms.</li>
                                <li>You acknowledge that joint owners may only hold a shares as joint tenants with
                                    each
                                    other.</li>
                                <li>You acknowledge that you have been recommended and given an opportunity to obtain
                                    independent legal advice on the terms of the IPANX Platform and its documents
                                    prior
                                    to joining the IPANX Platform.</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</mat-dialog-content>