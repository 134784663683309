import { Component, OnInit, Inject } from '@angular/core';
import { AmenityService } from '../../../core/services/amenities.service';
import { HelperService } from '../../../core/utils/helper.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-settlement-document',
  templateUrl: './settlement-document.component.html',
  styleUrls: ['./settlement-document.component.scss']
})
export class SettlementDocumentComponent implements OnInit {

  basicInfo: any
  resultsLength = 0;
  settlor= {
    IndividualNationality: false,
    countryOfCorporation: false,
    classedofforeignCorporation: false,
    pr: false,
    formersurname: "",
    InAustraliaForLast12Months: false,
    isForeignTrust: false,
    isForeignTrustee: false
  }


  
  constructor(private amenityService: AmenityService,
    private helper: HelperService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }



  getDetails(id: any) {
    //this.isLoading = true;
    
    this.amenityService.getSettlorDocument(this.data.guid).subscribe(
      (r: any) => {
        //this.isLoading = false;
        if (r.data !== null) {
          const result = r.data;
          const details: any = result[0];
          this.basicInfo = details;
          this.resultsLength = result.totalRecord;

          this.settlor.IndividualNationality = details.isAustralianNationality === 1 ? true: false;
          this.settlor.countryOfCorporation = details.countryOfIncorporation !== null && details.countryOfIncorporation !== "" &&  details.countryOfIncorporation === 1  ? true: false;
          this.settlor.classedofforeignCorporation = details.isClassesForeignCorporation;
          this.settlor.pr = details.pr;
          this.settlor.formersurname = details.formerSurName;
          this.settlor.InAustraliaForLast12Months = details.isInAustraliaForLast12Months;
          this.settlor.isForeignTrust = details.isForeignTrust;
          this.settlor.isForeignTrustee = details.isForeignTrustee;
        }
      },
      (e: any) => {
        //this.isLoading = false;
        // this.helper.error(e);
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }


}
