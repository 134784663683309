import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginModule } from './pages/login/login.module';
import { AuthTokenInterceptor } from './core/interceptors/auth-token-interceptor';
import { HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { LayoutModule } from './pages/layout/layout.module';
import { CoreModule } from './core/core.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EmailConfirmationComponent } from './pages/authentication/email-confirmation/email-confirmation.component';
// import * as $ from "jquery";


@NgModule({
  declarations: [
    AppComponent,
    EmailConfirmationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    LoginModule,
    SharedModule,
    LayoutModule,
    CoreModule,
    FlexLayoutModule,
    ToastrModule.forRoot({
      timeOut:10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      iconClasses: {
        error: 'bk-toast-error',
        info: 'bk-toast-info',
        success: 'bk-toast-success',
        warning: 'bk-toast-warning',
      }
    })

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
