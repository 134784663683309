import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';

@Directive({
    selector: '[numericDecimal]'
})

export class NumericWithDecimalDirective {

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('decimals') decimals: any = 0;

    private check(value: string, decimals: any)
    {
      if (decimals <= 0) {
        return String(value).match(new RegExp(/^\d+$/));
      } else {
          var regExpString = "^\\s*((\\d+(\\.\\d{0," + decimals + "})?)|( (\\d*(\\.\\d{1," + decimals + "})) ) )\\s*$"
          // var regExpString = "^\\s*(([5-9]+\\.?))\\s*$"
          // var regExpString = "^\\b([0-9]|[1-9][0-9])\\s*$"

            // var regExpString = "^(([0-9]|[0-9][1-9])(\\.\\d{0," + decimals + "})?)$"
         //   var regExpString = "^(([0-9]|[0-9])(\\.\\d{0," + decimals + "})?)$"

          //var regExpString = "^(\d+(\.\d{0,2})?|\.?\d{1,2})$"
          // var regExpString = "([0-4]\.[0-9]|[0-5])"
          return String(value).match(new RegExp(regExpString));
      }
    }

    private specialKeys = [ 
      'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
    ];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', [ '$event' ])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key);
        if ( next && !this.check(next, this.decimals) ) {
           event.preventDefault();
        }
    }
}