<div class="bootstrap-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-10 p0 roboto-medium f16">{{type}}</div>
            <div class="col-2 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <form [formGroup]="form" file-drop (files)="submit($event)">
            <div class="p20 mt10">
                <mat-dialog-content>
                    <div class="col-12 d-flex justify-content-center p0">
                        <div class="drop-zone" for="file">
                            <input type="file" id="file" (change)="handleInputChange($event)">
                            
                                <div *ngIf="!showImg">
                                    <div *ngIf="!isFileSelected; else showThumbnail">
                                        <div class="clearfix d-flex justify-content-center drag-label">
                                            <img src="../../../../assets/images/cloud_upload-24px (1).png">
                                        </div>
                                        <div class="clearfix d-flex justify-content-center drag-label">
                                            <span class="drag-drop-content">Drag and Drop image file&nbsp;<span class="star-red">*</span></span>
                                        </div>
                                        <div class="clearfix d-flex justify-content-center drag-label">
                                            <span class="slate-grey-two roboto-medium f14">or</span>
                                        </div>
                                        <div class="d-flex justify-content-center mt10">
                                            <label for="file" class="col-8 roboto-medium f12 btn btn-primary cursor-pointer">Browse
                                                file</label>
                                        </div>
                                    </div>
                                    <ng-template #showThumbnail>
                                            <label for="file" class="d-flex justify-content-center preview-label p0">
                                        <img *ngFor="let preview of imageSrc" [src]="preview | safeHtml" class="preview-img pt5" />
                                        </label>
                                    </ng-template>
                                </div>
                            

                        </div>

                    </div>
                    <div class="d-flex justify-content-center mt5">
                        <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                            <input matInput placeholder="Name" class="roboto-regular f13" formControlName="imageName">
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-12 d-flex justify-content-center">
                        <div class="col-10 d-flex justify-content-between p0 mb10 pr5">
                            <label class="slate-grey-two f14 roboto-medium mt5 pl10">Status</label>
                            <mat-slide-toggle class="m10 pr2" formControlName="isActive" [color]="color" (change)="onToggle($event)">
                            </mat-slide-toggle>
                        </div>
                    </div> -->
                </mat-dialog-content>

                <mat-dialog-actions  class="d-flex justify-content-center pl5 pr3">
                    <button mat-raised-button color="primary" class="col-9 roboto-medium f12" *ngIf="GUID === null" [disabled]="checkValid()" [class.spinner]='isbtnLoading' (click)="onSave()">Add</button>
                    <button mat-raised-button color="primary" class="col-9 roboto-medium f12" *ngIf="GUID !== null" [disabled]="UpdatecheckValid()" [class.spinner]='isbtnLoading' (click)="onUpdate()">Update</button>
                </mat-dialog-actions>
            </div>
        </form>
    </div>
</div>