<div class="row d-flex justify-content-center">
<div class="clearfix mt10 col-12 d-flex justify-content-center">
        <span class="roboto-medium f14 col-9 slate-grey-2">Manual Setup Key:</span>
    </div>
    <div class="clearfix mt15 col-10 d-flex justify-content-center">
        <label class="roboto-medium f10 text-key col-10">{{manualSetupKey}}<span class="pt5 pl2"><img src="../../../../assets/images/file_copy.png"
                class="pl2 cursor-pointer img-copy" (click)="onCopy(manualSetupKey)"></span></label>
    </div>
    <div class="clearfix mt15 col-10 d-flex justify-content-center bg-dusty-orange b-rad2">
        <label class="roboto-regular f12 white mb1 p5">Keep this manual setup key somewhere safe
            and accessible</label>
    </div>
    <div class="clearfix mt15 col-10 d-flex justify-content-center">
        <label class="roboto-regular f12 mb1 p5 text-key cornflower-blue">If you lose or have
            your phone stolen, this will ensure that you'll be able to get back into Shares.</label>
    </div>
</div>