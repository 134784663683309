<mat-sidenav-container class="sidenav-container" @layout>
    <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="!(isHandset$ | async)">
        <mat-toolbar color="primary"><img src="../../../assets/images/header/white.png" class="brand-logo mx-auto" alt="">
        </mat-toolbar>
        <mat-nav-list color="primary">
            <app-nav-sidebar></app-nav-sidebar>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="white" class="bg-white">
            <span>
                <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
                    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
                <button mat-button [matMenuTriggerFor]="belowMenu" *ngIf="getUserRole()"
                    class="cornflower-two roboto-regular f13 add-btn"><i class="fa fa-plus" aria-hidden="true"></i>
                    &nbsp; Add New &nbsp; <i class="fa fa-angle-down"></i>
                </button>
                <mat-menu #belowMenu="matMenu" yPosition="below">
                    <ng-container *ngFor="let item of items; let i=index">
                        <button mat-menu-item *ngIf="item.status !== getUserRolePermissions(item.menuLabel,'create')"
                            (click)="item.link? commonService.goto(item.link) : add(item.Value, item.Name)"
                            class="roboto-regular h40">
                            <img src="{{item.img}}" width="19" height="19" class="vertical-middle"><span
                                class="vertical-middle pl5">{{item.Name}}</span></button>
                    </ng-container>
                </mat-menu>
            </span>

            <span>{{ pageHeading }}</span>

            <span class="avatar-name">
                <p class="f10 roboto-bold f16 clearfix lh-0 m-0">Hi, {{userName === null ? '' : userName}}</p>
                <span class="roboto-regular f13 slate-grey-two">{{_roleName === null? '' : _roleName}}</span>
            </span>


        </mat-toolbar>
        <!-- Add Content Here -->
        <section class="main-content">

            <router-outlet (activate)="onRouterActivate($event)"></router-outlet>

        </section>
    </mat-sidenav-content>

</mat-sidenav-container>