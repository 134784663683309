import { Component, OnInit } from '@angular/core';
import { Storage } from './core/enum/storage';
import { AuthService } from './core/services/auth.service';
import { UserRolesService } from './core/services/user-roles.service';
import { StorageService } from './core/utils/storage.service';
import { HelperService } from './core/utils/helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'INVCAR-X-Admin';
  isLogged = false;
as
  constructor(private authService: AuthService,
    private userRolesService: UserRolesService,
    private storageService: StorageService,
    private helper: HelperService){

  }

  ngOnInit(): void{
    this.authService.isLoggedIn().subscribe(
      (status) => {
        this.isLogged = status;
        if (this.isLogged) {
          this.userRolesService.getPages(JSON.parse(this.storageService.getItem(Storage.PAGES) as any));
        }
      },
      (e) => {}
    );
    let _loginstatus = Boolean(this.storageService.getItem(Storage.IS_LOGGEDIN));
    if(_loginstatus === true){
      this.getUserRolePermissions();
    }
  }

  getUserRolePermissions() {
    this.userRolesService.getUserRolePermissions().subscribe(
      (result: any) => {
        let res = result.response !== undefined ? result.response : result;
        let _pages = JSON.stringify(res.data.data.Pages);
        localStorage.setItem(Storage.PAGES, _pages);
        localStorage.setItem(Storage.ROLE, res.data.data.UserRole.Role);
        localStorage.setItem(Storage.ROLE_ROUTE,String(res.data.data.UserRole.Role).replace(" ", "_"));
        this.storageService.setItem(Storage.PAGES, _pages);
        this.storageService.setItem(Storage.ROLE, res.data.data.UserRole.Role);
        this.storageService.setItem(Storage.ROLE_ROUTE,String(res.data.data.UserRole.Role).replace(" ", "_"));
        this.userRolesService.getPages(res.data.data.Pages);
        this.userRolesService.getRole(res.data.data.UserRole.Role);
        this.userRolesService.getUserEmail(res.data.data.UserDetails.Email);
        this.userRolesService.navigatePage();
      },
      (error) => {
        // this.helper.error(error);
      }
    );
  }
}
