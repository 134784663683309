<h2 mat-dialog-title id="info-title">
    <span>User Information</span>
    <span mat-dialog-close (click)="onClose()">X</span>
  </h2>
  <mat-dialog-content class="mat-typography mt-20" id="tab-content">
<!-- <div class="bootstrap-wrapper bg-white">
    <div class="container p0">
      <div class="col-12 pt10 pb10 p0"> -->
        <mat-tab-group mat-align-tabs="start" class="pb10" [@flyInOut]="'in'">
          <mat-tab  label="Account Detail" id="user_detail">
                <div class="bootstrap-wrapper bg-white col-12">
                    <div class="container pl6">
                        <div class="col-12 pt25 pb10 pl0">
                            <app-user-detail></app-user-detail>
                        </div>
                    </div>
                </div>
          </mat-tab>
          <mat-tab label="KYC Detail" [disabled]="!kycapprove || ismanualapprove">
                <div class="bootstrap-wrapper bg-white col-12">
                        <div class="container">
                            <div class="col-12 pt10 pb10" *ngIf="kycapprove">
                                <app-kyc-individual *ngIf="userType === 'INDIVIDUAL' && !ismanualapprove" ></app-kyc-individual>
                                <app-kyc-org *ngIf="userType === 'ORGANISATION'"></app-kyc-org>
                            </div>
                        </div>
                        </div>
          </mat-tab>
          <mat-tab label="Replace Of Manager" [disabled]="!kycapprove" *ngIf="userType === 'ORGANISATION' && userRole === 'Account Manager'">
            <div class="bootstrap-wrapper bg-white col-12">
                    <div class="container">
                        <div class="col-12 pt10 pb10">
                            <app-replace-manager></app-replace-manager>
                        </div>
                    </div>
                    </div>
      </mat-tab>

      <mat-tab label="Owned Property" [disabled]="!kycapprove || ismanualapprove">
        <div class="bootstrap-wrapper bg-white col-12">
                <div class="container">
                    <div class="col-12 pt10 pb10">
                        <app-user-owned-property-list></app-user-owned-property-list>
                    </div>
                </div>
                </div>
  </mat-tab>
      
        </mat-tab-group>
      <!-- </div>
    </div>
  </div> -->
</mat-dialog-content>