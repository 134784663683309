import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { SharedModule } from '../../shared/shared.module';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { AuthService } from '../../core/services/auth.service';
import { ApiManagerService } from '../../core/rest-api/api-manager.service';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../../core/utils/helper.service';


@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    SharedModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  exports: [
    LoginComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LfqYiUkAAAAAJw4DNJAtxOmBpWUJGzPNzZOBXK8',
      } as RecaptchaSettings,
    }
  ]
})
export class LoginModule { }
