import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostBinding,
} from "@angular/core";

import { Router, ActivatedRoute } from "@angular/router";

import { AppService } from "../../../core/services/app.service";
import { AuthService } from '../../../core/services/auth.service';
import { StorageService } from '../../../core/utils/storage.service';
import { UserRolesService } from '../../../core/services/user-roles.service';
import { CommonService } from '../../../core/utils/common.service';
import PageConfigs from '../../../core/utils/access-level-config';


import {
  trigger,
  state,
  transition,
  animate,
  style,
} from "@angular/animations";
import { Storage } from "src/app/core/enum/storage";
import { AfterViewInit } from '@angular/core';

@Component({
  selector: "app-nav-sidebar",
  templateUrl: "./nav-sidebar.component.html",
  styleUrls: ["./nav-sidebar.component.scss"],
  animations: [
    trigger("indicatorRotate", [
      state("collapsed", style({ transform: "rotate(0deg)" })),
      state("expanded", style({ transform: "rotate(180deg)" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
      ),
    ]),
  ],
})
export class NavSidebarComponent implements OnInit, AfterViewInit {
  expanded: boolean = false;
  @HostBinding("attr.aria-expanded") ariaExpanded = this.expanded;

  menuItems = {
    news: true,
    cppAgent: true,
    properties: true,
    deposits: true,
    withdrawals: true,
    users: true,
    transactions: true,
    coownership: true,
    videos: true,
    communitycategory: true,
    reports: false,
    rent: true,
    conveyancer: false,
    adminconveyancer: false,
    superadminconveyancer: true,
    owners:true,
    managers:true,
    kyc:true,
    dividends: true,
    ledgers:true
  };
_roleName: any = [];
  constructor(
    private authService: AuthService,
    private router: Router,
    private localStorageService: StorageService,
    private appService: AppService,
    private commonService: CommonService,
    private userRolesService: UserRolesService
  ) {
    this._roleName = this.localStorageService.getItem(Storage.ROLE);
  }

  ngOnInit() {
    this.expanded = false;
    this.userRolesService.getPages(Storage.PAGES);
    this._roleName = this.localStorageService.getItem(Storage.ROLE);
    this.getMenuItemsAccess();
    
  }

  ngAfterViewInit(): void {
    this._roleName = this.localStorageService.getItem(Storage.ROLE);
  }

  getMenuItemsAccess() {

    this.menuItems.deposits = this.userRolesService.getMenuViewAccess(
      PageConfigs.DepositRequests,
      PageConfigs.DepositHistory
    );
    this.menuItems.withdrawals = this.userRolesService.getMenuViewAccess(
      PageConfigs.WithdrawAccount,
      PageConfigs.WithdrawRequests,
      PageConfigs.WithdrawHistory
    );
    this.menuItems.transactions = this.userRolesService.getMenuViewAccess(
      PageConfigs.Transactions
    );
    this.menuItems.users = this.userRolesService.getMenuViewAccess(
      PageConfigs.Users
    );
    this.menuItems.ledgers = this.userRolesService.getMenuViewAccess(
      PageConfigs.Ledgers
    );
    this.menuItems.kyc = this.userRolesService.getMenuViewAccess(
         PageConfigs.Kyc
      // PageConfigs.KycRequests,
      // PageConfigs.KycHistory
    );
    this.menuItems.properties = this.userRolesService.getMenuViewAccess(
      PageConfigs.Properties,
      PageConfigs.LeaseTypes,
      PageConfigs.PropertyDevelopers,
      PageConfigs.PropertyManagers
    );
    this.menuItems.reports = this.userRolesService.getMenuViewAccess(
      PageConfigs.Reports
    );
    this.menuItems.rent = this.userRolesService.getMenuViewAccess(
      PageConfigs.Rent
    );
    this.menuItems.conveyancer = this.userRolesService.getMenuViewAccess(
      PageConfigs.Conveyancer
    ) && this._roleName === 'CONVEYANCER';
    this.menuItems.adminconveyancer = this.userRolesService.getMenuViewAccess(
      PageConfigs.Conveyancer
    ) && this._roleName === 'ADMIN';
    this.menuItems.superadminconveyancer = this.userRolesService.getMenuViewAccess(
      PageConfigs.Conveyancer
    ) && this._roleName === 'SUPER ADMIN';
  }

  onLogout() {
    this.localStorageService.setItem(Storage.IS_LOGGEDIN, "false");
    this.appService.changeStatus(false);
    this.authService.logout();
    // this.router.navigate(['/login']);
    this.commonService.goto("/login");
  }

  // onNavigateURL(data) {
  //   this.router.navigateByUrl(data);
  // }

  itemselected(event: any) {
    this.expanded = !this.expanded;
  }
}
