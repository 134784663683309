import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PropertyService } from '../../core/services/property.service';

export interface Price {
  buyingCount: number;
  sNo: number;
  price: string;
  tax: string;
  total: string;
}

@Component({
  selector: 'app-title-transfer-price-history',
  templateUrl: './title-transfer-price-history.component.html',
  styleUrls: ['./title-transfer-price-history.component.scss']
})
export class TitleTransferPriceHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() guid: any = null;
  title: any;
  id: any;

  priceInfo: Price[] = [];
  
  type: any;
  constructor(
    private propertyService: PropertyService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  //   this.priceInfo = [
  //     {
  //       priceDate: "",
  // sNo: 1,
  // price: "10102"
  //     },
  //     {
  //       priceDate: "",
  // sNo: 2,
  // price: "15000"
  //     },
  //     {
  //       priceDate: "",
  // sNo: 3,
  // price: "16200"
  //     }
  //   ]
  }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.guid = this.data.id;
    this.title = this.data.title;
    this.id = this.data.id;
    this.cdr.detectChanges();
  }


  ngOnDestroy() {
    this.dialogRef.close(this.id);
    // this.dialog.closeAll();
  }


  getPriceHistory(id: any) {
    this.propertyService.getBrickletPriceList(id).subscribe(
      (s: any) => {
        this.priceInfo = s.data.priceInflation;
      },
      (e: any)=>{
        
      }
    );
  }
}