<div class="bootstrap-wrapper">

  <div class="modal-header row">
    <div class="col-10 pl5 pr5">
      <h1 mat-dialog-title class="roboto-semibold f18 col-10 title" id="{{id}}">
        {{title}}
      </h1>
    </div>
    <div class="col-2 p0">
      <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="col-12">
    <p>{{message}}</p>
  </div>

  <div class="col-12" *ngIf="data.id === 'reject-title'">
    <textarea [(ngModel)]="remarks" placeholder="Enter remarks" style="width: 100%;" maxlength="500"></textarea>
    <div class="text-right text-muted">
      {{remarks.length}} / 500 characters
    </div>
  </div>

  <div class="col-12">
    <!-- <div mat-dialog-actions class="d-flex justify-content-end mb-4 pull-right row">


        </div> -->

    <mat-dialog-actions class="col-12">
      <div class="d-flex col-10 btn-items justify-content-flex-end buttons-div">
        <button mat-raised-button color="warn" (click)="onDismiss()"
          class=" btn bg-white cornflower-blue col-5 btn-border">{{no}}</button>
        <button  *ngIf="data.id !== 'reject-title'" mat-raised-button color="primary" (click)="onConfirm()" 
          class=" btn bg-dusty-orange col-5 btn-border">{{yes}}</button>
      <button  *ngIf="data.id === 'reject-title'" mat-raised-button color="primary" (click)="onRejectConfirm()" [disabled]="remarks.trim() === ''"
      class=" btn bg-dusty-orange col-5 btn-border">{{yes}}</button>
  </div>
    </mat-dialog-actions>

  </div>


</div>
