import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NewsService } from 'src/app/core/services/news.service';
import { Observable } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { PatternValidator } from '../../validators/patternvalidator';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { StaticSuccessMessage } from '../../../core/enum/constants';
import { CommunityService } from 'src/app/core/services/community.service';

@Component({
  selector: 'app-add-subcategory',
  templateUrl: './add-subcategory.component.html',
  styleUrls: ['./add-subcategory.component.scss'],
  animations: [trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(1500, style({opacity: 1}))
    ])
  ])]
})
export class AddSubCategoryComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() type = null;
  public form: FormGroup;
  GUID = null;
  isbtnLoading = false;
  isExists = false;
  categoryList=[];

  avatarProfileImagePath = '../../../../assets/images/avatar.png';

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private newsService: NewsService,
    private communityService: CommunityService,
    private helper: HelperService
  ) {
    this.form = new FormGroup({
    subCategoryGUID: new FormControl(''),
    categoryguid :new FormControl('', [Validators.required]),
    subcategoryname: new FormControl('', [Validators.required,Validators.maxLength(40)]),
    description: new FormControl('', [Validators.required,Validators.maxLength(60)]),
    isActive: new FormControl(true)
    });
    this.dialogRef.disableClose = true;
    this.getCategory();
   }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

  getCategory() {
    this.communityService.getCategory()
       .subscribe(
         (res: any) => {
           const result: any = res;
           if (result.data != null) {
             this.categoryList = result.data.categories;
           }
         },
         (error: any) => {
         }
       );
   }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.GUID = this.data.guid;
    if (this.GUID !== null) {
      this.getDetailByGUID(this.GUID);
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
  onClose() {
    this.dialogRef.close();
  }

  getDetailByGUID(id) {
    this.communityService.getSubCategoryByGuid(id).subscribe(
      (res) => {
        const result: any = res;
        if (result.data != null) {
          const _data = result.data;
          const communityCategory = _data.subCategory;
          this.GUID = communityCategory.categoryGUID;
          this.form.patchValue({
            subCategoryGUID: communityCategory.subCategoryGUID,
            categoryguid: communityCategory.categoryGUID,
            subcategoryname: communityCategory.name,
            description: communityCategory.description,
            isActive: communityCategory.isActive
          });
        } else {
          this.helper.error(result.message);
        }
      },
      (error) => {
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }


  onSave() {
    this.isbtnLoading = true;
    this.isExists = false;
    const data = {
      CategoryGUID:this.f.categoryguid.value,
      Name: this.f.subcategoryname.value,
      Description: this.f.description.value,
      SubCategoryGUID: this.f.subCategoryGUID.value,
      IsActive: this.f.isActive.value
    };
    if(this.GUID != null){
      this.update(data)
    }else{
     this.save(data)
    }
  
  }
  
  save(data){
    this.communityService.addSubCategory(data).subscribe(
      res => {
        this.isbtnLoading = false;
        const result: any = res;
        if (result.data != null) {
          this.helper.success(result.data.message);
          this.communityService.changeMessage(true);
          this.form.reset();
          this.onClose();
        } else {
          this.isbtnLoading = false;
          this.helper.error(result.message);
        }
      },
      error => {
        this.isbtnLoading = false;
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }


  update(data) {
    this.communityService
      .updateSubCategory(data)
      .subscribe(
        res => {
          this.isbtnLoading = false;
          const result: any = res;
          if (result.data != null) {
            this.helper.success(result.data.message);
            this.onClose();
            this.communityService.changeMessage(true);
          } else {
            this.helper.error(result.message);
          }
        },
        error => {
          this.isbtnLoading = false;
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );
  }
 




  get f() {
    return this.form.controls;
  }

}
