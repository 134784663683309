<div class="bootstrap-wrapper" @fade>
        <div class="container">
            <div class="row">
                <div class="col-10 p0 roboto-medium f16">{{type}}</div>
                <div class="col-2 p0">
                    <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20" (click)="onClose()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <form [formGroup]="newsfeedForm">
                <!-- <div class="p10 mt10"> -->
                    <mat-dialog-content>
                        <div class="row p2 justify-content-between">
                            <div class="drop-zone mat-elevation-z8 col-md-12 col-lg-3 col-xl-3" for="file" file-drop (files)="submit($event)">
                                <div class="d-flex justify-content-center title">
                                    <label class="roboto-medium f14">News thumbnail image</label>
                                </div>
                                <input type="file" id="file" (change)="handleInputChange($event)">
                                
                                    <div *ngIf="!showImg" class="mt20">
                                        <div *ngIf="!isFileSelected; else showThumbnail">
                                            <div class="clearfix d-flex justify-content-center drag-label">
                                                <img src="../../../../assets/images/cloud_upload-24px (1).png">
                                            </div>
                                            <div class="clearfix d-flex justify-content-center drag-label">
                                                <span class="drag-drop-content">Drag and Drop image file</span>
                                            </div>
                                            <div class="clearfix d-flex justify-content-center drag-label mt5">
                                                <span class="slate-grey-two roboto-medium f14">or</span>
                                            </div>
                                            <div class="d-flex justify-content-center mt5">
                                                <label for="file" class="col-6 roboto-medium f12 btn btn-primary cursor-pointer">Browse
                                                    file</label>
                                            </div>
                                        </div>
                                        <ng-template #showThumbnail>
                                            
                                                <label for="file" class="d-flex justify-content-center preview-label p0" *ngIf="!readonly">
                                                    <img *ngFor="let preview of imageSrc" [src]="preview | safeHtml" class="preview-img" />
                                                </label>
                                                <!--- for readonly true-->
                                                <label class="d-flex justify-content-center p0" *ngIf="readonly">
                                                        <img *ngFor="let preview of imageSrc" [src]="preview | safeHtml" class="preview-img" />
                                                    </label>
                                        </ng-template>
                                    </div>
                                
    
                            </div>
    
                            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-9">
                                <div class="mat-elevation-z8 row pl25 pr25 pb10">
                                    <mat-form-field class="col-12 f14 mt10 pl5 p0 pr5">
                                        <input matInput placeholder="News Title" class="roboto-regular f13" formControlName="newsTitle">
                                    </mat-form-field>
                                    <div class="d-flex justify-content-between col-12 p0">
                                        <mat-form-field class="col-5 f14 mt10 pl5 p0 pr5">
                                            <mat-label>News Category</mat-label>
                                            <mat-select class="roboto-regular f13" formControlName="newsCategory" >
                                                <mat-option [value]="">
                                                    Select News Category
                                                </mat-option>
                                                <mat-option *ngFor="let item of newsCategoryList" [value]="item.id">{{item.category}}</mat-option>
                                            </mat-select>
                                            <!-- <input matInput placeholder="News Title" class="roboto-regular f13"> -->
                                        </mat-form-field>
                                        <mat-form-field class="col-5 f14 mt10 pl5 p0 pr5">
                                                <input matInput [matDatepicker]="newsFeedDate" formControlName="feedDate" placeholder="News feed date" class="roboto-regular f14" (dateInput)="NewsFeedDateInput($event)">
                                                <mat-datepicker-toggle matSuffix [for]="newsFeedDate"></mat-datepicker-toggle>
                                                <mat-datepicker #newsFeedDate></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="mat-elevation-z8 row mt20 bg-status pr25">
                                        <div class="col-12 d-flex justify-content-between pt20">
                                            <div class="col-6">
                                                <label class="slate-grey-two f13 roboto-regular">News Status</label>
                                            </div>
                                            <div class="col-2 pl10 d-flex justify-content-space-around">
                                                <label class="slate-grey-two f14 roboto-medium mt5">{{lblStatus}}</label>
                                                <mat-slide-toggle class="m10 pl10" formControlName="isActive" [color]="color"  (change)="onToggle($event)" >
                                                </mat-slide-toggle>
                                            </div>
                                        </div>

                                
                                </div>
                            </div>
                        </div>

                        <div class="row p2 mt20">
                            <label class="slate-grey-two f14 roboto-medium">News Editor</label>
                            <quill-editor #description formControlName="description" class="f14 roboto-medium mt10" theme="snow" [styles]="{'height': '300px'}"
                                (onEditorCreated)="getEditorInstance($event)"></quill-editor>
                            <div class="error-txt">
                                <label id="errorLog" #errorLog class="error-txt"></label>
                            </div>
                            <input type="file" style="display: none" id="fileInputField" />
                        </div>
                    </mat-dialog-content>
    
                    <mat-dialog-actions class="d-flex justify-content-center pl5 mt30">
                            <button mat-raised-button color="primary" [disabled]="newsfeedForm.invalid || isbtnLoading" class="col-3 roboto-medium f12" [class.spinner]='isbtnLoading' (click)="onSubmit()" *ngIf="!readonly">Submit</button>
                    </mat-dialog-actions>
                <!-- </div> -->
            </form>
        </div>
    </div>