<div class="col-12 mb-3">
    <mat-label class="f16 roboto-medium">Owned Property </mat-label>
    </div>

  <div class="mat-elevation-z8 scroll-x">
    <table mat-table [dataSource]="dataSource" class="table">
        <ng-container matColumnDef="PropertyCode">
            <th mat-header-cell *matHeaderCellDef class="table-header exo2-bold f14"> Property Code </th>
            <td mat-cell *matCellDef="let element" class="td-pl exo2-medium f14"> {{element.propertyCode}} </td>
        </ng-container>

        <ng-container matColumnDef="PropertyName">
            <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14"> Property Name </th>
            <td mat-cell *matCellDef="let element" class="roboto-regular f13 w-name"> {{element.propertyName}} </td>
        </ng-container>

       
      
      
        <ng-container matColumnDef="noofBricklets">
            <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14"> Number of Shares </th>
            <td mat-cell *matCellDef="let element" class="roboto-regular f13"> {{element.noOfBricklet}} </td>
        </ng-container>

       



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
        <app-loader *ngIf="isLoading"></app-loader>
        <span *ngIf="propertyList">
            <div *ngIf="propertyList.length === 0" class="roboto-medium f14 text-center mt5">No records found</div>
        </span>
  
  </div>
  <mat-paginator class="mat-elevation-z8"
  [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="onPaginateChange($event)"
  showFirstLastButtons
  >
  </mat-paginator>
  