
<!-- <h2 mat-dialog-title>Image</h2>   -->
<!-- <mat-card-title> -->
    <div class="bootstrap-wrapper">
        <div class="row">
            <div class="col-12 row pr-0">
                <div class="col-1"><h1 mat-dialog-title class="roboto-semibold f18 title">Notes</h1></div>
                <div class="col-10">
                <button mat-icon-button color="primary" mat-dialog-close class="btn-close">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            </div>
        </div>
        </div>
    <!-- </mat-card-title> -->
    
    <mat-dialog-content>
        <div class="bootstrap-wrapper col-12">
            <div class="row col-12">
    
                <div class="col-12 pb10 pt10">
                    <mat-card class="card-height">
                        <div class="row h-100" *ngIf="isUser">
                            <div class="col-12 card-container pb10">
    
                               <!-- Modal -->


      <div class="form-group bd-box">
        
        <mat-form-field appearance="outline" class="col-12">
            <textarea matInput name="questionText" cols="35" rows="8" [(ngModel)]="clientNotes" readonly></textarea>
               </mat-form-field>
      </div>

                            </div>
    
                        </div>
                       
                        <div class="row h-100 pt-30 pb-30" *ngIf="!isUser">
                            <div class="col-12 d-flex justify-content-center pb10 pt-30">
                                <p class="f20 dusty-orange roboto-medium text-center pb30 m-0" >Notes
                                    Not Available</p>
                            </div>
                        </div>
                      
                    </mat-card>
                </div>
    
    
            </div>
        </div>
    
    
    </mat-dialog-content>
    
    <mat-dialog-actions>
        <!-- <button mat-raised-button color="primary" mat-dialog-close>Close</button> -->
    
    </mat-dialog-actions>