<div class="bootstrap-wrapper" @fade>
    <div class="container">
        <div class="row">
            <div class="col-10 p0 roboto-medium f16">{{type}}</div>
            <div class="col-2 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20">
                    <mat-icon>close</mat-icon>
                </button> 
            </div>
        </div>
        <form [formGroup]="form">
            <div class="p10 mt-3">
                <mat-dialog-content>
                    <div class="col-12 d-flex justify-content-center p0">
                        <div class="drop-zone" for="file">
                                <div class="p0 d-flex justify-content-start align-items-center border-bottom-ice-blue">
                                        <img  class="profile-img b-rad-circle mr0 c-pointer" [src]="avatarProfileImagePath"
                                            alt="">
                                            </div>

                            <!-- <img src="../../../../assets/images/news-category.png" class="preview-img" /> -->
                        </div>

                    </div>
                    <!-- <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt10 pl5 p0 pr5">
                            <input matInput placeholder="News Category" class="roboto-regular f13" formControlName="category">
                        </mat-form-field>
                    </div> -->
                    <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt3 pl5 p0 pr5">
                            <mat-label>First Name<span class="star-red">*</span></mat-label>
                            <input matInput formControlName="firstname" placeholder="First Name" class="roboto-regular f14" autocomplete="off"  (keyup)="getEntityname()">
                            <mat-error *ngIf="form.get('firstname')?.hasError('required')">First Name is required</mat-error>
                    
                        </mat-form-field>
                    </div>
                    <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt3 pl5 p0 pr5">
                            <mat-label>Last Name<span class="star-red">*</span></mat-label>
                            <input matInput formControlName="lastname" placeholder="Last Name" class="roboto-regular f14" autocomplete="off"  (keyup)="getEntityname()">
                            <mat-error *ngIf="form.get('lastname')?.hasError('required')">Last Name is required</mat-error>
                    
                        </mat-form-field>
                    </div>
                        <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt3 pl5 p0 pr5">
                            <mat-label>Email<span class="star-red">*</span></mat-label>
                            <input type="email" matInput formControlName="email" class="roboto-regular f14" id="email" placeholder="Email" autocomplete="off">
                            <!-- <mat-hint align="end">Not more then 60 characters long.</mat-hint> -->
                            <mat-error *ngIf="form.get('email')?.hasError('required')">Email is required</mat-error>
                            <!-- <mat-error *ngIf="form.get('email')?.hasError('pattern')">Email is not valid</mat-error> -->
                            <!-- <mat-error *ngIf="form.get('email')?.hasError('maxlength')">You have more than 60 characters</mat-error> -->
                            <mat-error *ngIf="form.get('email')?.hasError('patternInvalid')">Invalid Email</mat-error>
                    
                        </mat-form-field>
                    </div>
                    <!-- <div class="d-flex justify-content-center">

                        <mat-form-field class="col-9 f14 mt3 pl5 p0 pr5">
                                <div class="inner-addon right-addon">
                                        <span class="glyphicon" (click)="toggleEye()">
                                            <span *ngIf="passwordHide">
                                                <i class="fa fa-eye-slash"></i>
                                            </span>
                                            <span *ngIf="!passwordHide">
                                                <i class="fa fa-eye"></i>
                                            </span>
                                        </span>
                            <input type="password" matInput formControlName="password"  class="roboto-regular f14" id="pwd" [attr.type]="passwordHide ? 'text': 'password' " maxlength="15" 
                                placeholder="Password">
                            </div>
                                <mat-error *ngIf="form.get('password')?.hasError('required')">Password is required</mat-error>
                        </mat-form-field>
                    </div> -->
                    <div class="d-flex justify-content-center">
                        
                        <div class="col-9 f14 mt3 pl5 p0 pr5 d-flex justify-content-between">
                            <mat-form-field class="col-3 p0 f14 ">
                                <mat-select formControlName="countryCode" class="selectCountryCode flag-icon-{{d?.value | lowercase}} flag-icon-squared">
                                    <mat-option *ngFor="let r of countryCodes" [value]="r.dial_code" (onSelectionChange)="onselectionChange($event, r)">
                                        <span class="flag-icon flag-icon-{{r.countryCode | lowercase}} flag-icon-squared"></span> <span class="pl5"> {{ r.dial_code.substr(1) }}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            
                            <mat-form-field class="col-8 f14  p0">
                                <mat-label>Mobile No<span class="star-red">*</span></mat-label>
                                <input type="text" matInput NumbersOnly [allowDecimals]="false" [allowSign]="false" formControlName="mobile" class="roboto-regular f14" id="mobile" autocomplete="off" placeholder="">
                                
                                <!-- <mat-error *ngIf="form.get('mobile').hasError('maxlength')">Mobile number up to 15 digits. No special characters allowed.</mat-error> -->
                               
                            <mat-error *ngIf="form.get('countryCode')?.hasError('required')">Please select country code</mat-error>
                                <mat-error *ngIf="form.get('mobile')?.hasError('required')">Mobile No is required</mat-error>
                                <mat-error *ngIf="form.get('email')?.hasError('pattern')">Mobile No is should be 10 digits</mat-error>
                        
                            </mat-form-field>
                        </div>
                    </div>   
                    <div class="d-flex justify-content-center">
                    <mat-form-field class="col-9 f14 mt3 pl5 p0 pr5">
                        <mat-label>User Type<span class="star-red">*</span></mat-label>
                        <mat-select formControlName="userType">
                            
                            <mat-option *ngFor="let opt of userType" [value]="[opt.role,opt.userRoleGUID]">
                                {{opt.role}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('userType')?.hasError('userType')">User Type is required</mat-error>
                    </mat-form-field>
                    </div>

                    <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt3 pl5 p0 pr5">
                            <mat-label>Entity Name / Identification Number(Company, Trust, SMSF details)<span class="star-red">*</span></mat-label>
                            <p class="lbl-entity">This must be the exact name/description you would like to appear on the property registry. This cannot be changed. Please ensure the below details are correct.</p>
                            <!-- <input matInput formControlName="entityName" placeholder="entityName" class="roboto-regular f14" autocomplete="off" (keypress)="keyPressChar($event)"> -->
                            <textarea matInput formControlName="entityName" class="roboto-regular f14" autocomplete="off" maxlength="255"  (change)="onEntityChange($event)"></textarea>
                            <mat-error *ngIf="form.get('entityName')?.hasError('required')">Entity details is required</mat-error>
                    
                        </mat-form-field>
                    </div>

                    <div class="d-flex justify-content-center">
                        <mat-form-field class="col-9 f14 mt3 pl5 p0 pr5">
                        <!-- <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"> -->
                            <mat-label>Address<span class="star-red">*</span></mat-label>
                            <textarea matInput formControlName="address" class="roboto-regular f14"></textarea>
                            <mat-error *ngIf="form.get('address')?.hasError('required')">Address is required</mat-error>
                            
                        <!-- </div> -->
                    </mat-form-field>
                    </div>
                </mat-dialog-content>

                <mat-dialog-actions class="d-flex justify-content-center pl5 mb10">
                    <button mat-raised-button color="primary" class="col-5 roboto-medium f12" *ngIf="GUID === null" [disabled]="!form.valid || isbtnLoading" [class.spinner]='isbtnLoading'
                        (click)="onSave()">Register User</button>
                    <!-- <button mat-raised-button color="primary" class="col-5 roboto-medium f12" *ngIf="GUID !== null" [disabled]="!form.valid || isbtnLoading" [class.spinner]='isbtnLoading'
                        (click)="onUpdate()">Update</button> -->
                </mat-dialog-actions>
            </div>
        </form>
    </div>
</div>