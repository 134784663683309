<div class="bootstrap-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-10 p0 roboto-medium f16">Conveyancer Mapped Accounts</div>
            <div class="col-2 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20">
                    <mat-icon>close</mat-icon>
                </button> 
            </div>
        </div>
        
<div class="col-12 mt10 mb10 pl0 pr5">
    <div class="mat-elevation-z8 bg-light-grey-two">
      <div class="pt10 pb10 bootstrap-wrapper">
        <form [formGroup]="filterForm">
          <div class="row col-12">
            <mat-form-field class="col-2 f14">
              <input
                matInput
                formControlName="startDate"
                [matDatepicker]="fromDate"
                placeholder="From Date"
                class="roboto-regular f14"
                #from
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fromDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-2 f14">
              <input
                matInput
                formControlName="endDate"
                [matDatepicker]="endDate"
                placeholder="To Date"
                class="roboto-regular f14"
                #to
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
          
            <mat-form-field class="col-3 f14">
              <mat-label>Email</mat-label>
              <input matInput type="search" formControlName="emailSearch" />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <mat-card-actions  class="col-5 row">
              <div class="col-6">
                <button
                  mat-raised-button
                  class="roboto-medium f12"
                  color="primary"
                  (click)="filterRecords()"
                >
                  Filter
                </button>
              </div>
              <div class="col-6">
                <button
                  mat-raised-button
                  class="bg-dusty-orange white roboto-medium f12"
                  color="warning"
                  (click)="reset()"
                >
                  Reset
                </button>
              </div>
            </mat-card-actions>
          </div>
        </form>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">

  <div class="mat-elevation-z8 mb-3">
        <table mat-table [dataSource]="dataSource">
      
      
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
                <div class="email-container">
                    <div class="right-container">
                        <span class="text-left email d-flex text-wrap"> {{ element.email }}</span>
                    </div>
                </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="accountName">
              <th mat-header-cell *matHeaderCellDef>Account Name</th>
              <td mat-cell *matCellDef="let element" class="bootstrap-wrapper">
                  <div class="accountName-container">
                    <div class="right-container">
                      <span class="text-left  d-flex text-wrap"> {{ element.accountName }}</span>
                    </div>
                  </div>
              </td>
            </ng-container>


            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let element" class="bootstrap-wrapper">
                <button mat-icon-button class="mr15 roboto-medium f12 btn-view" color="primary"
                  (click)="onEditConveyancer(element)">
                  <mat-icon class="f20">edit</mat-icon>
                </button>
              </td>
            </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <app-loader *ngIf="isLoading"></app-loader>
        <span *ngIf="length === 0">
          <div *ngIf="length === 0" class="roboto-medium f14 text-center mt5">
            No records found
          </div>
        </span>
     
      </div>
      
</mat-dialog-content>
<mat-paginator
[length]="length"
[pageSize]="pageSize"
[pageSizeOptions]="pageSizeOptions"
(page)="onPaginateChange($event)"
showFirstLastButtons
>
</mat-paginator>
</div>
</div>