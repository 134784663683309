import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NewsService } from '../../../core/services/news.service';
import { trigger, transition, animate, style } from '@angular/animations';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { UploadImageService } from '../../../core/services/upload-image.service';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { imageUploadConfig } from '../../../core/interfaces/image-upload.interface';
import { NewsUploadImageOptions, StaticMessage } from '../../../core/enum/constants';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss'],
  animations: [trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(1500, style({opacity: 1}))
    ])
  ])]
})
export class AddNewsComponent implements OnInit, AfterViewInit, OnDestroy {
  type;
  showImg = false;
  isFileSelected = false;
  imageSrc = [];
  imageList: ImageFiles[] = [];
  fileList;
  fileType;
  form: FormGroup;
  checked = false;
  disabled = false;
  lblStatus = 'InActive';
  color: ThemePalette = 'primary';
  newsfeedForm = this.formBuilder.group({
    id: new FormControl(''),
    newsCategory: new FormControl('', [Validators.required]),
    newsTitle: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    createdBy: new FormControl(''),
    isActive: new FormControl(false),
    bannerImage: new FormControl(null,[Validators.required]),
    bannerBlobURL: new FormControl(null),
    feedDate: new FormControl('', [Validators.required])
  });
  quillEditorRef;
  maxUploadFileSize = 1000000;
  // @ViewChild('feed', { static: false}) feedDate: ElementRef;
  feedDate;
  @ViewChild('errorLog', {static: false}) errorLog: ElementRef;
  @ViewChild('description', {static: false}) description: ElementRef;
  GUID = null;
  newsCategoryList;
  isbtnLoading = false;
  readonly = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private newsService: NewsService,
    private dialog: MatDialog,
    private uploadImageService: UploadImageService,
    private helper: HelperService
  ) {
    this.dialogRef.disableClose = true;
   }

   ngOnInit() {
   }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.getNewsCategoryList();
    this.GUID = this.data.guid;
    this.readonly = this.data.readOnly;
    if (this.GUID !== null) {
      this.getNewsByGUID(this.GUID);
    }
    if(this.readonly === true) {
      this.f.newsTitle.disable();
      this.f.newsCategory.disable();
      this.f.description.disable();
      this.f.isActive.disable();
      this.f.bannerImage.disable();
      this.f.feedDate.disable();
    } else {
      this.f.newsTitle.enable();
      this.f.newsCategory.enable();
      this.f.description.enable();
      this.f.isActive.enable();
      this.f.bannerImage.enable();
      this.f.feedDate.enable();
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }
  onClose() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  onToggle(event) {
    if (event.checked) {
      this.lblStatus = 'Active';
    } else {
      this.lblStatus = 'InActive';
    }
  }


  NewsFeedDateInput(event: MatDatepickerInputEvent<Date>): void {
    this.feedDate = moment(event.value).format('YYYY-MM-DD');
  }

  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
    // console.log(this.quillEditorRef)
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
  }

  imageHandler = (image: any, callback: any): any => {
    const input = document.getElementById('fileInputField') as HTMLInputElement;

    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      // file type is only image.
      if (/^image\//.test(file.type)) {
        if (file.size > this.maxUploadFileSize) {
           this.errorLog.nativeElement.innerHTML = 'Image needs to be less than 1MB';
           input.value = null;

        } else {
          this.errorLog.nativeElement.innerHTML = '';
          const reader  = new FileReader();
          reader.onload = () =>  {
            const range = this.quillEditorRef.getSelection();
            const img = '<img src="' + reader.result + '" />';
            this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
            const content = this.quillEditorRef.root.innerHTML;
            this.newsfeedForm.patchValue({
              description: content
            });
          };
          reader.readAsDataURL(file);
        }
      } else {
        input.value = null;
        this.errorLog.nativeElement.innerHTML = 'You could only upload images.';
      }

    };
    input.click();
  }


  public submit(e: any) {
    // console.log('files:', e.dataTransfer.files);
    // this.uploadImage(e);
    this.uploadImage_new(e);
  }

  handleInputChange(e: any) {
    // this.uploadImage(e);
    this.uploadImage_new(e);
  }

  uploadImage_new(e: any): any {
    this.fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    const reader = new FileReader();
    const filelist = [];
    const config: imageUploadConfig = {
      dimension: {
        width: NewsUploadImageOptions.width,
        height: NewsUploadImageOptions.height,
        operation: NewsUploadImageOptions.operation
      },
      size: NewsUploadImageOptions.size,
      fileExt: NewsUploadImageOptions.exts,
      fileType: null
    };
    if (this.fileList.length > 1) {
      this.helper.warning('Please select one image');
      return false;
    }

    if (this.fileList.length > 0) {

      for (const item of this.fileList) {
        filelist.push(item);
        this.showImg = false;
        this.imageSrc = [];
        this.imageList = []; // Single image upload
        this.isFileSelected = false;


        this.uploadImageService.uploadImage(item, config, reader, (result: any) => {
          if (result.status) {
            this.imageList.push({
              imageType: result.type,
              imageFile: result.imageFile
            });
            this.newsfeedForm.patchValue({
              bannerImage: this.fileList
            });
            this.imageSrc.push(result.imagePreview);
            this.isFileSelected = true;
          } else {
            this.helper.warning(result.msg);
            this.newsfeedForm.patchValue({
              bannerImage: ''
            });
          }
        });
      }
      this.cdr.markForCheck();
    }
  }

  uploadImage(e: any): any {
    this.fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    this.fileType = null;
    const reader = new FileReader();
    const filelist = [];

    if (this.fileList.length > 0) {
      for (const item of this.fileList) {
        this.fileType = item.type;
        const fileSize = item.size;
        filelist.push(item);

        if (
          this.fileType !== 'image/png' &&
          this.fileType !== 'image/jpg' &&
          this.fileType !== 'image/jpeg'
        ) {
          this.helper.error(StaticMessage.ImageFormat);
          e.target.value = null;
          return false;
        }
        if (fileSize > 1048576) {
          this.helper.error(
            'Please upload image size not more than 1 MB'
          );
          e.target.value = null;
          return false;
        }

        this.showImg = false;
        this.imageSrc = [];
        this.imageList = []; // Single image upload
        this.isFileSelected = false;

        this.imageList.push({
          imageType: this.findAndReplace(this.fileType, 'image/', '.'),
          imageFile: item
        });

        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(item);
      }
    }
  }

  _handleReaderLoaded(e: any) {
    const reader = e.target;
    this.imageSrc.push(reader.result);
    this.isFileSelected = true;
  }

  // tslint:disable-next-line:variable-name
  findAndReplace(string, target, replacement) {
    return (string = string.replace(target, replacement));
  }

  onSubmit() {
    this.isbtnLoading = true;
    const formData = new FormData();
    if (this.GUID != null) {
      formData.append('NewsGUID', this.GUID);
    }
    formData.append('Description', this.newsfeedForm.controls.description.value);
    formData.append('ID_NewsCategory', this.newsfeedForm.controls.newsCategory.value);
    formData.append('FeedDate', this.feedDate);
    formData.append('IsActive', this.newsfeedForm.controls.isActive.value);
    if (this.imageList.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.imageList.length; i++) {
        formData.append('BannerImage', this.imageList[i].imageFile);
      }
    } else {
      formData.append('BannerBlobURL', this.newsfeedForm.controls.bannerBlobURL.value);
    }
    formData.append('Title', this.newsfeedForm.controls.newsTitle.value);

    if(this.GUID != null) {
      this.onEditNews(formData);
    } else {
      this.onSaveNews(formData);
    }

  }


  onSaveNews(formData) {

    this.newsService
      .addNews(formData)
      .subscribe(
        res => {
          this.isbtnLoading = false;
          const result: any = res;
          if (result.data != null) {
            this.helper.success(result.message);
            this.newsfeedForm.reset();
            this.newsfeedForm.patchValue({
              newsCategory: '',
              isActive: true
            });
            this.newsService.changeMessage(true);
            this.onClose();
          } else {
            this.helper.error(result.message);
          }
        },
        error => {
          this.isbtnLoading = false;
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );

  }

  onEditNews(formData) {

    this.newsService
      .editNews(formData)
      .subscribe(
        res => {
          this.isbtnLoading = false;
          const result: any = res;
          if (result.data != null) {
            this.helper.success(result.message);
            this.onClose();
            this.newsService.changeMessage(true);
          } else {
            this.helper.error(result.message);
          }
        },
        error => {
          this.isbtnLoading = false;
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );




  }

  getNewsCategoryList() {
    this.newsService.getNewsCategoryList()
       .subscribe(
         res => {
           const result: any = res;
           if (result.data != null) {
             this.newsCategoryList = result.data.newsCategories;

           }
         },
         error => {
         }
       );
   }



   getNewsByGUID(guid) {
    this.newsService.getNews(guid).subscribe(
        res => {
          const result: any = res;
          if (result.data != null) {
            const data = result.data;
            const newsDetail = data.news;

            this.GUID = newsDetail.newsGUID;
            this.imageSrc.push(newsDetail.bannerImage);
            this.isFileSelected = true;
            this.feedDate = newsDetail.feedDate;
            if (newsDetail.isActive === true) {
              this.lblStatus = 'Active';
            } else {
              this.lblStatus = 'InActive';
            }
            this.newsfeedForm.patchValue({
              id: newsDetail.id,
              newsCategory: newsDetail.iD_NewsCategory,
              newsTitle: newsDetail.title,
              description: newsDetail.description,
              isActive: newsDetail.isActive,
              bannerBlobURL: newsDetail.bannerImage,
              feedDate: newsDetail.feedDate,
              bannerImage: newsDetail.bannerImage
            });
          } else {
            this.helper.error(result.message);
          }
        },
        error => {
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );
  }


  get f() {
    return this.newsfeedForm.controls;
  }

}

export interface ImageFiles {
  imageType: string;
  imageFile: any;
}
