import { Component, OnInit, Input, Inject, ChangeDetectorRef, OnDestroy, AfterViewInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { PropertyService } from '../../../core/services/property.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { BuySellService } from '../../../core/services/buy-sell.service';
import { ActivatedRoute } from '@angular/router';
import { KycIndividualComponent } from '../kyc-individual/kyc-individual.component';
import { PriceHistoryComponent } from '../price-history/price-history.component';
import { AgmMap, MapsAPILoader } from '@agm/core';
 declare var $: any;
//declare var $: any;


export interface PropertyGallery {
  image: string;
  video?: null | string;
}
export interface offer {
  key: string;
  value: string;
}
export interface ResponseDTO {
  response: {
    message: string;
    data: any;
  };
}
export interface propDetail {
  totalBricklet: Number;
  BrickletAvailable: Number;
  minSalePrice: string;
  maxSalePrice: string;
  brickletOwned: Number;
}
export interface Price {
  priceDate: string;
  price: string;
}
export interface Document {
  documentName: string;
  documentDate: string;
  pdfFileName: string;
}
export interface BrickletValue {
  lastValuation: string;
  valuediff1: string;
  valuediffer2: string;
  latestSell: string;
  latestSellDays: Number;
}
export interface PropertyValue {
  lastValuation: string;
  valuediff1: string;
  valuediffer2: string;
  totalBricklets: string;
  totalPrice: string;
}

@Component({
  selector: 'app-property-new-detail-pages',
  templateUrl: './property-new-detail-pages.component.html',
  styleUrls: ['./property-new-detail-pages.component.scss']
})
export class PropertyNewDetailPagesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() guid: any = null;
  form!: FormGroup;
  type: any;
  coverImage: any;
  propertyDetails: any;
  addressInfo: any;
  // @ViewChild(HistoricalGrowthChartComponent, { static: true }) HistoricalGrowthChartComp;
  index: any;
  isLoading = false;
  chart: any;

  propertyGallery: PropertyGallery[] = [];

  offerInfo: offer[] = [];
  propDetail: propDetail | any;

  propertyDetailInfo: any;
  priceInfo: Price[] = [];
  propDocument: Document[] = [];
  brickletValue: BrickletValue | any;
  propertyValue: PropertyValue | any;
  propHighlights: any[] = [];

  propertyAmenities: any[] = [];

  propertyId = '';
  propertyAllDetails: any;

  sellAvailable = false;
  buyAvailable = false;

  videoInPlay = false;

  imageSrc = '/assets/images/empty-state.svg';
  isbtnLoading = false;
  termConditionDoc: any = null;

  latitude: number = 0;
  longitude: number = 0;
  zoom: number = 14;
  address: any;
  private geoCoder;
  @ViewChild('search')
  public searchElementRef: any = "";
  @ViewChild('agmMap') agmMap: AgmMap;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private helper: HelperService,
    private dialog: MatDialog,
    private propertyService: PropertyService,
    private buySellService: BuySellService,
    private route: ActivatedRoute
  ) {
    this.propertyId = this.route.snapshot.params['id'];
   
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    //this.getPropertyDetail();
    // this.checkTradable();

  }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.guid = this.data.guid;
    this.cdr.detectChanges();
    this.getFile();
    
    
  }

  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
    //$('#view-history').modal('hide');
  }

  onChange(idx: any) {
    this.index = idx;
    this.cdr.detectChanges();
  }

  getPropertyDetail() {
    this.commonService.showSpinner();
    this.commonService.showLoader();
    this.propertyService.getPropertyDetails(this.guid).subscribe(
      (s: any) => {
        this.propertyAllDetails = s.data.propertyDetails;
        this.propertyDetails = s.data.propertyDetails.propertyDetails;
        this.propertyGallery = s.data.propertyDetails.propertyGallery;
        this.propDocument = s.data.propertyDetails.propertyDocument;
        this.propHighlights = s.data.propertyDetails.propertyHighlights;
        this.offerInfo = s.data.propertyDetails.propertyDynamicAttributes;
        this.commonService.hideSpinner();
        this.commonService.hideLoader();
        this.setSlider();
     
        
      },
      error => {
        this.commonService.hideSpinner();
        this.commonService.hideLoader();
      }
    );
  }

  

  markerDragEnd(infowindow) {
    // console.log($event);
    // this.latitude = $event.coords.lat;
    // this.longitude = $event.coords.lng;
    
   // this.getAddress(this.latitude, this.longitude);
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }







  checkTradable() {
    const data = {
      PropertyGUID: this.guid
    };
    forkJoin([
      this.buySellService.isBrickletAvailableToBuy(data),
      this.buySellService.isBrickletAvailableToSell(data)
    ]).subscribe(([b, s]: any) => {
      if (b) {
        console.log(b);
      }
      if (s) {
        console.log(s);
      }
    });
  }

  onView() {
    // this.toastrService.info('view inprogress');
    // $('#view-history').modal('show');
    const dialogRef = this.dialog.open(PriceHistoryComponent, {
      data: {
        id: this.guid,
        title: 'Price History',
        btnDisable: false,
        titleDisable: false
        //KycTypeOfUserGUID: kycTypeOfUserGUID
      },
      id: 'price-history',
      width: '700px'
    });
    dialogRef.componentInstance.getPriceHistory(this.guid);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.refresh) {
        this.getPropertyDetail();
      }
    });
  }
  onBuy() {
    this.helper.info('buy inprogress');
  }
  onSell() {
    this.helper.info('sell inprogress');
  }

  onClose() {
    this.dialogRef.close('all');
    this.dialog.closeAll();
  }

  setSlider_old() {
    //this.propertyGallery =
    let tempSliders = [];
    this.propertyGallery.forEach((r: any, i) => {
      if (this.isVideo(r.image)) {
        tempSliders.push({ video: r.image });
      } else {
        tempSliders.push({ image: r.image });
      }
    });
    this.propertyGallery = tempSliders;

    setTimeout(() => {
      $('.slider')
        .slick({
          infinite: true,
          arrows: false,
          adaptiveHeight: true
          //dots: true
        })
        .on('afterChange', (event, slick, currentSlide) => {
          if (this.videoInPlay) {
            this.playVideo();
          }
        });

      $('.thumbnail-slider')
        .slick({
          infinite: false,
          arrows: true,
          adaptiveHeight: true,
          slidesPerRow: 3,
          rows: 1,
          slidesToScroll: 1,
          dots: true
        })
        .on('afterChange', (event, slick, currentSlide) => {
          if (this.videoInPlay) {
            this.playVideo();
          }
        });
    }, 500);
  }

  setSlider() {

    //this.propertyGallery = 
    let tempSliders = [];
    this.propertyGallery.forEach((r: any, i) => {
      if (this.isVideo(r.image)) {
        tempSliders.push({ video: r.image })
      } else {
        tempSliders.push({ image: r.image })
      }
    });
    this.propertyGallery = tempSliders;

    setTimeout(() => {
      $(".slider").slick({
        infinite: true,
        arrows: true,
        adaptiveHeight: true,
        slidesToShow: 2,
        slidesToScroll: 1
        //dots: true
      }).on('afterChange', (event, slick, currentSlide) => {
        if (this.videoInPlay) {
          this.playVideo();
        }
      });

      $(".thumbnail-slider").slick({
        infinite: false,
        arrows: true,
        adaptiveHeight: true,
        slidesPerRow: 3,
        rows: 1,
        slidesToScroll: 1,
        dots: true
      }).on('afterChange', (event, slick, currentSlide) => {
        if (this.videoInPlay) {
          this.playVideo();
        }
      });

    }, 500);
  }

  playVideo() {
    const videoPlayer = document.getElementById(
      'videoPlayer'
    ) as HTMLVideoElement;
    if (!this.videoInPlay) {
      videoPlayer.play();
      this.videoInPlay = true;
    } else {
      videoPlayer.pause();
      this.videoInPlay = false;
    }
  }

  pauseVideo() {
    const videoPlayer = document.getElementById(
      'videoPlayer'
    ) as HTMLVideoElement;
    if (this.videoInPlay) {
      videoPlayer.pause();
      this.videoInPlay = false;
    }
  }

  thumbClick(index) {
    this.pauseVideo();
    $('.slider').slick('slickGoTo', index);
  }

  isVideo(url: string) {
    const urlSplit = url.split('.');
    const ext: string = urlSplit[urlSplit.length - 1];
    if (ext.includes('mp4') || ext.includes('wma') || ext.includes('mov')) {
      return true;
    } else {
      return false;
    }
  }

  onPublish() {
    this.isbtnLoading = true;
    this.propertyService.publishProperty(this.data.guid).subscribe(
      (s: any) => {
        this.isbtnLoading = false;
        this.commonService.hideSpinner();
        this.propertyService.changeMessage(true);
        this.helper.success(s.data.message);
        this.dialog.closeAll();
        this.commonService.goto('property/list');
      },
      (e: any) => {
        this.isbtnLoading = false;
        this.commonService.hideSpinner();
        // this.helper.error(e);
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  gotoVisit(link: string) {
    if (link.includes('https://') || link.includes('http://')) {
      window.open(link, '_blank');
    } else {
      window.open('https://' + link, '_blank');
    }
  }

  getFile() {
    // this.isLoading = true;
    // this.helper.getTermsFile(this.guid).subscribe(
    //   (r: any) => {
        this.isLoading = false;
        this.termConditionDoc = "Accept all terms and conditions";
        // this.termConditionDoc = r.data.termsConditions;
      // },
      err => {
        this.isLoading = false;
        const error =
          err.error.message || err.error.response.message || err.statusText;
        // this.helperService.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    // );
  }

  onPublishDialog() {
    if (this.termConditionDoc !== '' && this.termConditionDoc !== null) {
      this.onPublish();
    } else {
      this.helper.error("Property Can't publish, please update the Terms and Condition Document");
    }
  }


  onDownload(url: any) {
    this.helper.info('download inprogress');
  }


  webSiteRedirect() {
    window.open(this.propertyDetails.website);
  }


  previous

  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }


  



  
  textSearchGoogle(URL) {
    let search = this.propertyDetails.name + " " + this.propertyDetails.address + " " + this.propertyDetails.state;
    window.open('http://google.com/search?q=' + search);
  }

  





  public zoom_new: number;
  
  // initial center position for the map
  public lat_new: number = 0; 
  public lng_new: number = 0; 

  clickedMarker_new(label: string, index: number | any) {
    console.log(`clicked the marker: ${label || index}`);
  }
  
  mapClicked_new($event: MouseEvent | any) {
    this.markers_new.push({
      lat: $event?.coords.lat,
      lng: $event?.coords.lng,
      draggable: true
    });
  }
  
  markerDragEnd_new(m: marker | any, $event: MouseEvent | any) {
    console.log('dragEnd', m, $event);
  }
  
public  markers_new: any[] = [];



}

// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
	label?: any;
  position?: any;
	draggable: boolean;
}