import { Component, OnInit, Input } from '@angular/core';
import { KycConfigService } from '../../../core/services/kyc-config.service';
import { SMSF } from '../../../core/interfaces/kyc.interface';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';

@Component({
  selector: 'app-org-smsf',
  templateUrl: './org-smsf.component.html',
  styleUrls: ['./org-smsf.component.scss']
})
export class OrgSmsfComponent implements OnInit {

  @Input() smsfData: SMSF | any;

  personalInfo: UserInfoCardInterface | null = null;
  addressInfo: UserInfoCardInterface | null = null;
  idInfo: UserInfoCardInterface | null = null;
  addressVerifyInfo: UserInfoCardInterface | null = null;

  basicSMSFInfo: UserInfoCardInterface | null = null;
  regOffice: UserInfoCardInterface | null = null;
  businessInfo: UserInfoCardInterface | null = null;

  stackholderType: UserInfoCardInterface | null = null;

  showTabs = false;

  emitData = {
    ubo: null,
    settlor: null,
    trustee: null,
  }

  constructor(
    private kycConfigService: KycConfigService
  ) { }

  ngOnInit() {
    // console.log(this.smsfData);
    this.showTabs = true;

    this.basicSMSFInfo = {
      title: 'SMSF',
      column: 4,
      type: 'text-only',
      data: [
        {  text: 'ABN', value: this.smsfData.abn, img: null },
        {  text: 'Entiy Name', value: this.smsfData.entityName, img: null }
      ]
    };

    this.emitData.ubo = this.smsfData.ubOs;
    this.emitData.settlor = this.smsfData.settlor;
    this.emitData.trustee = this.smsfData.trustee;
  }

}
