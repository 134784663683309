import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NewsService } from 'src/app/core/services/news.service';
import { Observable } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { PatternValidator } from '../../validators/patternvalidator';
import { UsersService } from '../../../core/services/users.service';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { StaticSuccessMessage } from '../../../core/enum/constants';
import { countryCodes } from '../../../core/utils/countryCodes';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  animations: [trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(1500, style({opacity: 1}))
    ])
  ])]
})
export class AddUserComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() type = null;
  public form: FormGroup;
  GUID = null;
  isbtnLoading = false;
  isExists = false;

  avatarProfileImagePath = '../../../../assets/images/avatar.png';
  countryCodes = countryCodes;

  userType: any;
  userTypeDefault = 'INDIVIDUAL';
  abnEnable: Boolean = false;
  passwordHide = false;


  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private newsService: NewsService,
    private usersService: UsersService,
    private helper: HelperService,
    
  ) {
    this.form = this.formbuilder.group({
    id: new FormControl(''),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    countryCode: new FormControl('+61', [Validators.required]),
    countryFlag: new FormControl('AU'),
    // email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    email: new FormControl('', [Validators.required, Validators.maxLength(60),
      PatternValidator(
        // tslint:disable-next-line:max-line-length
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )]),
      // mobile: new FormControl('', [Validators.pattern(/^[0-9]{0,15}$/)]),
      // password: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      address: new FormControl("", [Validators.required]),
      userType: new FormControl('', [Validators.required]),
      entityName: new FormControl('', [Validators.required]),

    // isActive: new FormControl(true),
    });
    this.dialogRef.disableClose = true;
   }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    this.usersService.getUserRoles().subscribe((r: any) => {
      this.userType = r.data.userRoles;
    });

  }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.GUID = this.data.guid;
    // if (this.GUID !== null) {
    //   this.getDetailByGUID(this.GUID);
    // }
    this.getEntityname();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
  onClose() {
    this.dialogRef.close();
  }


  onSave() {
    if (this.f.mobile.value === '' || (this.f.mobile.value.length >= 10 && this.f.mobile.value.length <= 15)) {

    this.isbtnLoading = true;
    this.isExists = false;

    const data = {
      FirstName: this.f.firstname.value,
      LastName: this.f.lastname.value,
      Email: this.f.email.value,
      // Password:this.f.password.value,
      Mobile:  this.f.mobile.value,
      CountryCode : this.f.countryCode.value,
      Address: this.f.address.value,
      // userType: "INDIVIDUAL",
      UserType: this.f.userType.value !== null ? this.f.userType.value[0] : this.f.userType.value[0],
      entityName: this.f.entityName.value === "" ? null : this.f.entityName.value,
      userRoleGUID:this.f.userType.value !== null ? this.f.userType.value[1] : this.f.userType.value[1]
    };

    this.usersService.createUser(data).subscribe(
      res => {
        this.isbtnLoading = false;
        const result: any = res;
        if (result.data != null) {
          this.helper.success(
            StaticSuccessMessage.saveUsers
          );
          this.usersService.changeMessage(true);
          this.form.reset();
          this.onClose();
          this.commonService.goto('users/list');
        } else {
          this.isbtnLoading = false;
          this.helper.error(result.message);
        }
      },
      error => {
        this.isbtnLoading = false;
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );

  } else {
    if (this.f.mobile.value.length < 10 || this.f.mobile.value.length > 15) {
      this.helper.info('Mobile Number Length is Minimum 10 digits and Maximum 15 digits');

     }

  }



  }

  

  changingValue(e: any) {
    this.form.get('entityName').reset();
    if (e.value == 'Individual') {
      this.getEntityname();
      this.abnEnable = false;
      this.form.get('entityName').setValidators([]);
      this.form.get('entityName').updateValueAndValidity();
    } else {
      this.getEntityname();
      this.form.get('entityName').setValidators([Validators.required]);
      this.form.get('entityName').updateValueAndValidity();
      this.abnEnable = true;
    }




  }

  getEntityname(){
    const firstname = this.form.controls.firstname.value === null ? '' : this.form.controls.firstname.value;
    const lastname = this.form.controls.lastname.value === null ? '' : this.form.controls.lastname.value;
    this.form.get("entityName").setValue(firstname + " " + lastname);
    // console.log(this.form.get("entityName").value === null ? null : this.form.get("entityName").value);
  }

  onEntityChange(event){
    
    // console.log(event.target.value);
    if(event.target.value === ''){
      const firstname = this.form.controls.firstname.value === null ? '' : this.form.controls.firstname.value;
      const lastname = this.form.controls.lastname.value === null ? '' : this.form.controls.lastname.value;
      this.form.get("entityName").setValue(firstname + " " + lastname);
    }
  }

  keyPressChar(event: any) {
    
    const pattern = /[a-zA-Z0-9 ]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  onUpdate() {
    // this.isbtnLoading = true;
    // this.isExists = false;
    // this.getisExists().subscribe(res => {
    //   const result: any = res;
    //   if (res !== null) {
    //     this.isExists = result.response.data.isExist;
    //     if (this.isExists === false) {
    //       const data = {
    //         ID: this.GUID,
    //         Category: this.f.category.value,
    //         IsActive: this.f.isActive.value
    //       };

    //       this.edit(data);
    //     } else {
    //       this.isbtnLoading = false;
    //       this.commonService.toastError('already name exists! ');
    //     }
    //   } else {
    //     this.isbtnLoading = false;
    //     this.commonService.toastError(result.response.message);
    //   }
    // },
    //   error => {
    //     this.isbtnLoading = false;
    //     this.commonService.toastError(error);
    //   });


  }


  onselectionChange(event, r) {
    if (event.isUserInput && event.source.selected === true) {
      // console.log(event.isUserInput);
      // console.log(event.source.selected);
      // console.log(r.countryCode);
      this.form.patchValue({
        countryFlag: r.countryCode
      });
    }
    
  }


  get f() {
    return this.form.controls;
  }

  get d() {
    return this.form.get('countryFlag');
  }

  toggleEye() {
    this.passwordHide = !this.passwordHide;
  }
  
}
