<div class="addvideo-div">
    <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
  
      <label style="height:auto;" class="uploader upload-label" ondragover="return false;"  [style.outlineColor]="dragging ? activeColor : baseColor"
        (dragenter)="handleDragEnter()" (dragleave)="handleDragLeave()" (drop)="handleDrop($event)">
  
        <div>
          <p class="upload-video-icon text-center">
            <!-- <i class="fa fa-cloud-upload fa-5x" aria-hidden="true"></i> -->
            <img src="/assets/images/file-upload/cloud-upload.svg" class="upload-file" >
          </p>
          <p class="dragdrop-text1 text-center">Drag and drop  </p>
          <p class="dragdrop-text1 text-center">or</p>
          <p class="dragdrop-text2 text-center">click here to upload videos</p>
        </div>
        <input type="file" name="file" accept="acceptPattern" (change)="handleInputChange($event)">
      </label>
  
    </div>
    <p class="file-upload-info">Please upload video in .mp4, .mov format. <br /> File size upto 20MB</p>
  </div>