import { Injectable, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UbOs, Settlor, TrusteeIndividual, Individual, DocumentInterface, PrivateCompany, proofOfIdentityInterface, PublicCompanyEntity } from '../interfaces/kyc.interface';

@Injectable({
  providedIn: 'root'
})

export class KycConfigService {

  constructor(
    private datePipe: DatePipe
  ) { }

  kycOrgArrangedData(data: UbOs | Settlor | TrusteeIndividual | Individual | null | undefined) {
    if (data) {

      const personalInfo: any = this.personalInfo(data.personalInfo);
      const addressInfo: any = this.addressInfo(data.addressInfo);
      const addressVerifyDocs: any = [
        {
          name: data.proofOfAddress.proofType,
          images: [data.proofOfAddress.proofURL],
          data: null
        }
      ];

      let idInfo: any = [];
      idInfo = idInfo.concat(this.documentInfo(data.proofOfIdentity1));
      idInfo = idInfo.concat(this.documentInfo(data.proofOfIdentity2));
      // console.log(idInfo);

      return {
        personalInfo: personalInfo,
        addressInfo: addressInfo,
        addressVerifyDocs: addressVerifyDocs,
        idInfo: idInfo
      }

    }

    return null;
  }

  personalInfo(data: any) {
    return [
      { text: 'Country Residence', value: data.countryOfResidence},
      { text: 'First name', value: data.firstName },
      { text: 'Middle name', value: data.middleName },
      { text: 'Last name', value: data.lastName },
      { text: 'Date of Birth', value: this.datePipe.transform(data.dateOfBirth, 'dd-MMM-yyyy') },
      { text: 'Gender', value: data.gender },
      { text: 'Email', value: data.email },
    ];
  }

  addressInfo(data: any) {
    return [
      { text: 'Unit Number', value: data.unitNo },
      { text: 'Street Number', value: data.streetNo },
      { text: 'Street Name', value: data.streetName },
      { text: 'Street Type', value: data.streetType },
      { text: 'Suburb', value: data.suburb },
      { text: 'State', value: data.state },
      { text: 'Post Code', value: data.postalCode },
      { text: 'Additional Information', value: data.additionalInfo },
    ];
  }

  documentInfo_old(result: DocumentInterface) {
    const docData = [];
    if (result.passportNumber !== '' && result.passportNumber !== null) {
      const keyPair = [
        { text: 'Passport Number', value: result.passportNumber }
      ];

      docData.push({
        name: 'PASSPORT',
        images: [result.passportFile],
        data: keyPair
      });
    }

    if (result.drivingLicenseNumber !== '' && result.drivingLicenseNumber !== null) {
      const keyPair = [
        { text: 'Driving License Number', value: result.drivingLicenseNumber },
        { text: 'State', value: result.state },
      ];
      docData.push({
        name: 'DRIVING LICENSE',
        images: [result.drivingLicenseFrontSide, result.drivingLicenseBackSide],
        data: keyPair
      });
    }

    if (result.medicardCardNo !== '' && result.medicardCardNo !== null) {
      const keyPair = [
        { text: 'Medicare Card No', value: result.medicardCardNo },
        { text: 'Reference No', value: result.medicardReferenceNo },
        { text: 'Type', value: result.medicardCardColor },
        { text: 'Full name', value: result.medicardFullName },
        { text: 'Expiry date', value: this.datePipe.transform(result.medicardExpiryDate, 'dd-MMM-yyyy') },
      ];
      docData.push({
        name: 'MEDICARE CARD',
        images: [result.medicardCardFile],
        data: keyPair
      });
    }

    return docData;
  }

  documentInfo(result: proofOfIdentityInterface) {
    const docData = [];
    if (result.passport !== null) {
      const keyPair = [
        { text: 'Passport Number', value: result.passport.passportNo }
      ];

      docData.push({
        name: 'PASSPORT',
        images: [result.passport.passportImageURL],
        data: keyPair
      });
    }

    if (result.drivingLicense !== null) {
      const keyPair = [
        { text: 'Driving License Number', value: result.drivingLicense.drivingLicenseNo },
        { text: 'State', value: result.drivingLicense.stateIssued },
      ];
      docData.push({
        name: 'DRIVING LICENSE',
        images: [result.drivingLicense.drivingLicenseFrontSideURL, result.drivingLicense.drivingLicenseBackSideURL],
        data: keyPair
      });
    }

    if (result.medicare !== null) {
      const keyPair = [
        { text: 'Medicare Card No', value: result.medicare.cardNumber },
        { text: 'Reference No', value: result.medicare.individualNo },
        { text: 'Type', value: result.medicare.cardColor },
        { text: 'Full name', value: result.medicare.fullName },
        { text: 'Expiry date', value: this.datePipe.transform(result.medicare.expireDate, 'dd-MMM-yyyy') },
      ];
      docData.push({
        name: 'MEDICARE CARD',
        images: [result.medicare.medicareImageURL],
        data: keyPair
      });
    }

    return docData;
  }

  privateAddressInfo(data: any) {
    return [
      { text: 'Unit Number', value: data.unitNo },
      { text: 'Street Number', value: data.streetNo },
      { text: 'Street Name', value: data.streetName },
      { text: 'Street Type', value: data.streetType },
      { text: 'Suburb', value: data.suburb },
      { text: 'State', value: data.state },
      { text: 'Postcode', value: data.postalCode },
      { text: 'Additional Information', value: data.additionalInfo }
    ];
  }

  kycArrangePrivateData(data: PrivateCompany){
    return {
      basicInfo: [
        {  text: 'ACN', value: data.acn, img: null },
        {  text: 'ABN', value: data.abn, img: null },
        {  text: 'Company Name', value: data.companyName, img: null },
        {  text: 'Company Extract',value: null, img: data.companyExtractURL },
      ],
      regOffice: this.privateAddressInfo(data.registeredOffice),
      businessInfo: this.privateAddressInfo(data.principlePlaceOfBusiness)
    }
  }








  kycOrgArrangedPublicCompanyData(data: PublicCompanyEntity | null) {
    if (data) {

      const basicPublicCompanyInfo = [
        {  text: 'ABN', value: data.abn, img: null },
        {  text: 'ACN', value: data.acn, img: null },
        {  text: 'Company Name', value: data.companyName, img: null },
        {  text: 'Exchange Listed On', value: data.exchange, img: null }
      ];
      

      return {
        basicPublicCompanyInfo: basicPublicCompanyInfo
      }

    }

    return null;
  }


}
