<div class="header pt5 mb25">
  <span class="modal-title">Deposit Amount</span>
  <span class="close-icon" (click)="close()"><i class="material-icons">
      close
    </i></span>
</div>

<div class="info-div">
  <p class="title">Name</p>
  <p class="value">{{record?.fullName}}</p>


  <p class="title">Email</p>
  <p class="value">{{record?.email}}</p>

  <p class="title">Deposit amount</p>
  <p class="value">${{record?.amount}}</p>
</div>

<button mat-raised-button class="deposit-btn" (click)="deposit()" [class.spinner]='btnloading'>Deposit</button>
