import { Component, OnInit, AfterViewInit, Inject, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { CoownerShipService } from '../../../core/services/coownership.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RejectPopupComponent } from '../reject-popup/reject-popup.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs';
import { CommonService } from '../../../core/utils/common.service';
import { CoOwnershipRequestDetails } from '../../../core/interfaces/coownership.interface';
import { HelperService } from '../../../core/utils/helper.service';
import { ApproveStaticRequestMessage, ApproveDialog } from '../../../core/enum/constants';
import { TitleTransferPriceHistoryComponent } from '../../title-transfer-price-history/title-transfer-price-history.component';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileUploadFilters } from '../../../core/interfaces/file-upload-filter.interface';
import { FileUploadService } from '../../../core/services/file-upload.service';
import moment from 'moment';
import { ConveyancerService } from '../../../core/services/conveyancer.service';
import { SettlementDocumentComponent } from '../settlement-document/settlement-document.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


@Component({
  selector: 'app-pending-order-detail',
  templateUrl: './pending-order-detail.component.html',
  styleUrls: ['./pending-order-detail.component.scss']
})
export class PendingOrderDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  propertyRecord: any = [];
  resultsLength = 0;
  pageNo = 1;
  pageSize = 5;
  pageSizeOptions = [5, 10];
  pageIndex = 1;
  isLoading = false;

  propertyCode = null;
  propertyName = null;
  noofBricklet = null;

  guid = '';
  propertyDocuments: FormArray | any;
  uploadForm: FormGroup;

  basicInfo: any = [];
  minDate: any = null;
  minExpiryDate: any = null;
  
  constructor(
    private coownerShipService: CoownerShipService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private helper: HelperService,
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    private conveyancerService: ConveyancerService,
    private cdr: ChangeDetectorRef
  ) {

    this.uploadForm = this.fb.group({
      guid: [this.data.guid],
      docDate: ['', [Validators.required]],
      fileName: ['', [Validators.required]],
      file: ['', [Validators.required]],
      filePath: ['', [Validators.required]],
      propertyDocs: this.fb.array([]),
      referenceID: [''],
      referenceIdExpiryDate: [''],
    });
    this.propertyDocuments = this.uploadForm.get(
      'propertyDocs'
    ) as FormArray;

    this.minDate = new Date();
    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    this.minExpiryDate = new Date(year + 2, month, day);
    
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    // this.displayedRows$ = of(messages);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit() {
    this.minDate = new Date();
    // this.minExpiryDate = moment(new Date()).add(2, 'year').format("DD-MM-YYYY");
    // console.log(new Date().setFullYear(new Date().getFullYear() + 2));

    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    // this.minExpiryDate = new Date(year + 2, month, day);


    let date: any;
    let currentDate: any


    
    if(this.basicInfo?.poaReferanceIDExpiryDate !== null && this.basicInfo?.poaReferanceIDExpiryDate !== ''){
      this.minExpiryDate = new Date(this.basicInfo?.poaReferanceIDExpiryDate);
    } else {
      this.minExpiryDate = new Date(year + 2, month, day);
    }


    if(this.basicInfo?.settlementDate !== null && this.basicInfo?.settlementDate !== ''){
      date = new Date(this.basicInfo?.settlementDate);
      currentDate = new Date(date.getTime() - date.getTimezoneOffset()*60000);
    
      this.minDate = currentDate;
    } else {
      this.minDate = new Date();

    
      let curDate =  new Date();
    
       currentDate = new Date();
    }

    
    // let date = new Date(this.basicInfo?.settlementDate);
    // let currentDate: any = new Date(date.getTime() - date.getTimezoneOffset()*60000);


    // this.basicInfo = this.data.record;

    // this.propertyCode = this.basicInfo.propertyCode;
    // this.propertyName = this.basicInfo.propertyName;
    // this.noofBricklet = this.basicInfo.noOfBricklets;
    this.uploadForm.patchValue({
      referenceID:  this.basicInfo?.poaReferanceID,
      referenceIdExpiryDate: this.basicInfo?.poaReferanceIDExpiryDate,
      docDate: currentDate
    })

    this.cdr.detectChanges();
  }




  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  downloadDoc(url: any){
    window.open(url, "_blank");
  }

  getDetails(id: any) {
    this.isLoading = true;
    const data = {
      propertyGUID: this.data.guid,
      pageNumber: this.pageNo,
      pageSize: this.pageSize
    };
    this.coownerShipService.getCoOwnerShipHistoryDetailByGUID(data).subscribe(
      (r: any) => {
        this.isLoading = false;
        if (r.data !== null) {
          const result = r.data;
          const details: CoOwnershipRequestDetails | any =
            result.adminCoOwnershipHistoryDetails;
          this.propertyRecord = details;
          this.resultsLength = result.totalRecord;

          // for(let i=0; i < details.length; i++){
            this.addPropertyDocObject(this.basicInfo.provisionalBuyHeaderGUID);
          // }

          if (this.propertyRecord.length === 0) {
            this.dialogRef.close();
            this.dialog.closeAll();
          } else {
            this.propertyName = details[0].propertyName;
            this.propertyCode = details[0].propertyCode;
            this.noofBricklet = details[0].totalNoOfBricklet;
          }
        }
      },
      (e: any) => {
        this.isLoading = false;
        // this.helper.error(e);
        const errSt = e.message !== undefined ? e.message : e;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  getTotalPrice_old(brickelt: any, price: any) {
    let total = 0;
    let buyingPrice = 0;
    let totalbricklet = 0;
    totalbricklet = brickelt === null || brickelt === undefined ? 0 : brickelt;
    buyingPrice = price === null || price === undefined ? 0 : price;
    total = totalbricklet * buyingPrice;

    return total;
  }

  getTotalPrice(price: any) {
    let total = 0;
    if (price.length > 0) {
      for (let i = 0; i < price.length; i++) {
        total = total + price[i].total * price[i].buyingCount;
      }
    }

    return total;
  }

  getInterest(totalBricklet: any, noofBricklet: any) {
    let interest = 0;
    let numberofBricklet = 0;
    let total = 0;

    total =
      totalBricklet === null || totalBricklet === undefined ? 0 : totalBricklet;
    numberofBricklet =
      noofBricklet === null || noofBricklet === undefined ? 0 : noofBricklet;
    interest = (numberofBricklet / totalBricklet) * 100;

    return interest;
  }

  onClose() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  onPaginateChange(event: any) {
    this.pageIndex = ++event.pageIndex;
    this.pageNo = this.pageIndex;
    this.pageSize = event.pageSize;
    this.getDetails(this.data.guid);
  }

  approve(guid: any) {
    this.commonService.showLoader();
    const data = {
      ConveyancerOrderGUID: guid,
    SettlementDate: moment(this.uploadForm.controls.docDate.value).toISOString(),
    TitleTransferDocument: this.uploadForm.controls.filePath.value,
    POAReferanceID: this.uploadForm.controls.referenceID.value === "" ? null : this.uploadForm.controls.referenceID.value,
    // ExpiryDate: moment(this.uploadForm.controls.referenceIdExpiryDate.value).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').format("YYYY-MM-DD HH:mm:ss")
    ExpiryDate: null
    };
    this.conveyancerService.approveRequest(data).subscribe(
      (r: any) => {
        this.isLoading = false;
        this.commonService.hideLoader();
        if (r.data !== null) {
          const result: any = r;
          // this.commonService.toastSuccess(result.response.data.msg);
          this.helper.success('Request Approved Successfuly');
          this.conveyancerService.changeMessage(true);
          this.onClose();
        }
      },
      (error: any) => {
        // this.helper.error(error);
        this.commonService.hideLoader();
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  reject(guid: any) {
    const dialogRef = this.dialog.open(RejectPopupComponent, {
      data: { reason: '', status: false }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status) {
        this.conveyancerService
          .rejectRequest({
            ConveyancerOrderGUID: guid,
            RejectReason: result.reason
          })
          .subscribe(
            (s: any) => {
              this.helper.success('Request Rejected Successfuly');
              this.conveyancerService.changeMessage(true);
              this.onClose();
            },
            (e: any) => {
              // this.helper.error(e);
              const errSt = e.message !== undefined ? e.message : e;
              if (errSt === 'Session Timed Out') {
                this.helper.info(errSt);
              } else {
                this.helper.error(errSt);
              }
            }
          );
      }
    });
  }

  openApprovalDialoguebox(guid: any) {
    const message = ``;
    const dialogData = new ConfirmDialogModel(
      ApproveStaticRequestMessage.conveyancertitle,
      message,
      ApproveDialog.Yes,
      ApproveDialog.No,
      'request-title'
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '791px',
      height: '215px',
      data: dialogData,
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.approve(guid);
      }
    });
  }

  openSettlementdoc(data: any){
    this.dialogRef = this.dialog.open(SettlementDocumentComponent, {
      width: '96%',
      height: '95%',
      id: 'Settlement-doc',
      // tslint:disable-next-line:max-line-length
      data: { type: 'Settlement-document', guid: data.conveyancerOrderGUID  }
    });
    this.dialogRef.componentInstance.getDetails(data.conveyancerOrderGUID);
  }

  
  openDOAdoc(data: any){
    window.open(data, '_blank');
  }


  getaddress(data: any) {
    let retAddr = '';
    if (data.unitNo !== undefined && data.unitNo !== '') {
      retAddr = retAddr + data.unitNo + ',';
    }
    if (data.streetNo !== undefined && data.streetNo !== '') {
      retAddr = retAddr + data.streetNo + ',';
    }
    if (data.streetName !== undefined && data.streetName !== '') {
      retAddr = retAddr + data.streetName + ',';
    }
    if (data.streetType !== undefined && data.streetType !== '') {
      retAddr = retAddr + data.streetType + ',';
    }
    if (data.stateName !== undefined && data.stateName !== '') {
      retAddr = retAddr + data.stateName + ',';
    }
    if (data.country !== undefined && data.country !== '') {
      retAddr = retAddr + data.country + ',';
    }
    if (data.postalCode !== undefined && data.postalCode !== '') {
      retAddr = retAddr + data.postalCode;
    }
    return retAddr;
  }

  onView(record: any) {
    // this.toastrService.info('view inprogress');
    // $('#view-history').modal('show');
    const dialogRef = this.dialog.open(TitleTransferPriceHistoryComponent, {
      data: {
        id: this.guid,
        title: 'Shares Price',
        btnDisable: false,
        titleDisable: false,
        priceRange: record
        //KycTypeOfUserGUID: kycTypeOfUserGUID
      },
      width: '950px'
    });
    dialogRef.componentInstance.priceInfo = record;
    // dialogRef.componentInstance.getPriceHistory(this.guid);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.refresh) {
        // this.getPropertyDetail();
      }
    });
  }


  propertyDocSubmit(e: any, index: any) {
    const fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;

    if (fileList && fileList.length > 0) {
      for (const f of fileList) {
        const filters: FileUploadFilters = {
          dimension: null,
          size: 26214400,
          ext: ['pdf'],
          fileType: null
        };
        this.fileUploadService.uploadFile(f, filters, (fileResult: any) => {
          if (fileResult.status) {
            const docs = this.uploadForm;
            
            if (e.dataTransfer) {
              docs.patchValue({
                file: fileList,
                fileName: f.name
              });
            }
            docs.patchValue({
              file: fileList,
              fileName: f.name
            });
            this.uploadPropertyDocuments();
          } else {
            this.helper.warning(fileResult.msg);
            const docs = this.uploadForm;
            docs.patchValue({
              fileName: '',
              file: ''
            });
          }
        });
      }
    } else {
      const docs = this.uploadForm;
      docs.patchValue({
        fileName: '',
        file: ''
      });
    }
  }

  addPropertyDocObject(guid) {
    
    const item = this.uploadForm.get('propertyDocs') as FormArray;
    item.push(
      this.fb.group({
        guid: [guid],
        docDate: ['', [Validators.required]],
        fileName: ['', [Validators.required]],
        file: ['', [Validators.required]]
      })
    );
    console.log(item);
  }

  bindUploadForm(record: any) {
    this.addPropertyDocObject(record.provisionalBuyHeaderGUID);
  }



  uploadPropertyDocuments() {
    const docData = this.uploadForm;
    const formData = new FormData();
    const documentObj: any[] = [];
    
    formData.append('TitleTransferDocument', docData.controls.file.value[0]);
    
    formData.append('FileName', docData.controls.fileName.value);
    
    this.helper.info('uploading document....');
    this.commonService.showSpinner();
    this.commonService.showLoader();
    this.conveyancerService.uploadTitleTransferDocument(formData).subscribe(
      (s: any) => {
        
        this.uploadForm.patchValue({
          filePath: s.data.filePath
        });
        this.commonService.hideSpinner();
        this.commonService.hideLoader();
        this.helper.success('Title Transfer Document is uploaded');
      },
      e => {
        this.commonService.hideSpinner();
        this.commonService.hideLoader();
        // this.helperService.error(e);
        const err = e.message !== undefined ? e.message : e;
        if (err === 'Session Timed Out') {
          this.helper.info(err);
        } else {
          this.helper.error(err);
        }
      }
    );
  }

  changedateEvent(type: string, event: MatDatepickerInputEvent<Date> | any, guid: any) {
    // this.events.push(`${type}: ${event.value}`);
    // console.log(`${type}: ${event.value}`);
    const data = {
      ConveyancerOrderGUID: guid,
      SettlementDate: moment(event.value).toISOString(),
  }
    
    
    this.conveyancerService.updateSettlementDate(data).subscribe(
      (s: any) => {
        // console.log(s);
              
        if (s !== null) {
          this.conveyancerService.changeMessage(true);
          this.helper.success(s.data.message);
        }
  
      },
      e => {
        this.commonService.hideSpinner();
        // this.helperService.error(e);
        const err = e.message !== undefined ? e.message : e;
        if (err === 'Session Timed Out') {
          this.helper.info(err);
        } else {
          this.helper.error(err);
        }
      }
    );
  }

}
