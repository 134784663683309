<div class="bootstrap-wrapper mt-div">
    <div class="container">
        <div class="row modal-header p5">
            <div class="col-lg-10 col-md-10 col-sm-6 roboto-medium f16 title"></div>
            <div class="col-lg-2 col-md-2 col-sm-6 p0">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20 title"
                    (click)="onClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <form [formGroup]="uploadForm">
        <div class="title-div row col-lg-12 col-md-12 col-sm-12">
            <div class="pl20 pt10 col-md-12 col-sm-12 p0">
                <div class="col-md-12 col-sm-12 row">
                    <div class="col-lg-3 col-md-4 col-sm-12 header-id-info">
                        <span class="name">Property ID</span>
                        <span class="value">{{basicInfo?.propertyCode}}</span>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-12 name-info-div">
                        <span class="label">Property Name</span>
                        <span class="value">{{basicInfo?.propertyName}} </span>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-12 header-value-info-div align-item-end">
                        <span class="label">Total No. of Shares</span>
                        <span class="value pr-55">{{basicInfo?.totalNoOfBricklets}}</span>
                    </div>


                </div>

            </div>



        </div>

        <div class="main-div row scroll-x col-lg-12 col-md-12 col-sm-12 p0">
        
            <div class="detail-div pl20">
            <div multi class="mat-table col-lg-12 col-md-12 col-sm-12 p0 pt-8" is-open="true">
                <div class="p0 mb-8" >
                    
                  <!-- <div class="mat-row p0 h-70 bd-0"> -->
                    <div class="col-lg-12 col-md-12 col-sm-12 row pl-1  desc-content d-flex justify-content-between" >
                    <!-- <div class="col-lg-9 col-md-9 col-sm-12"> -->
                        <div class="col-lg-3 col-md-3 col-sm-12 user-info-div align-item-center pl-2">
                            <span class="name roboto-bold pr-40">{{basicInfo.accountName}} <span class="pl-12"><i class="fa fa-map-marker torch-red" tooltip="{{getaddress(basicInfo)}}" placement="right" delay="100" aria-hidden="true"></i></span></span>
                            <span class="value text-wrap pl-0 pr-30">{{basicInfo.email}}</span>
                        </div>
                    <!-- </div> -->
                    <!-- <mat-panel-description class="col-lg-3 col-md-3 col-sm-12 justify-content-end"> -->
                        <!-- <div class="col-lg-3 col-md-3 col-sm-12 justify-content-end">
                        <div class="col-lg-12 col-md-12 col-sm-12 id-info-div pl-12 align-item-center pr0">
                            <span class="label roboto-bold">POA Document</span>
                            <span class="value" (click)="downloadDoc(basicInfo?.documentURL)"><i class="fas fa-download generic-palette-b-w-gray f18" title="POA Document"></i></span>
                        </div>
                        </div> -->

                        <div class="col-lg-5 col-md-5 col-sm-12 justify-content-end pr0">
                            <div class="col-lg-12 col-md-12 col-sm-12 id-info-div align-item-center pr0 p0">
                                <span class="label roboto-bold w-155">Title Transfer Document</span>
                                <span class="value pl-7" (click)="downloadDoc(basicInfo?.titleTransferDocument)"><i class="fas fa-download generic-palette-b-w-gray f18" title="Title Transfer Document"></i></span>
                            </div>
                            </div>

                        <div class="col-lg-3 col-md-3 col-sm-12 justify-content-end pr0">
                            <div class="col-lg-12 col-md-12 col-sm-12 id-info-div pl-58 align-item-center pr0">
                                <span class="label roboto-bold cornflower-blue" *ngIf="basicInfo.iD_Status === 2">Approved</span>
                            <span class="value" *ngIf="basicInfo.iD_Status === 2">Approved on: <span class="text-transform-upper">{{basicInfo?.approvedOn | date:
                                'dd-MMM-yyyy'}}</span></span>
                                <span class="label roboto-bold torch-red" *ngIf="basicInfo.iD_Status === 3">Rejected<span class="pl5 c-pointer">
                                    <i class="fa fa-thin fa-info-circle torch-red" *ngIf=" basicInfo?.rejectReason === ''" aria-hidden="true"></i>
                                    <i class="fa fa-thin fa-info-circle torch-red" *ngIf="basicInfo?.rejectReason !== undefined && basicInfo?.rejectReason !== '' && basicInfo?.rejectReason !== null" tooltip="{{getrejectreason(basicInfo)}}" placement="right" delay="100" aria-hidden="true"></i></span></span>
                                <span class="value" *ngIf="basicInfo.iD_Status === 3">Rejected on: <span class="text-transform-upper">{{basicInfo?.approvedOn | date:
                                    'dd-MMM-yyyy'}}</span></span>
                            </div>
                            </div>


                        </div>


                    <!-- </mat-panel-description>
                  </mat-expansion-panel-header> -->

                  <div class="col-lg-12 col-md-12 col-sm-12 row pl-7  desc-content d-flex justify-content-between" >
                    <div class="col-lg-2 col-md-2 col-sm-12 user-info-div align-item-center">
                        <span class="name roboto-bold"> Settlement Date</span>
                        <span class="value cornflower-blue text-transform-upper">{{basicInfo?.settlementDate | date: 'dd-MMM-yyyy hh:mm aa'}}</span>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 price-info-div align-item-center">
                        <span class="name roboto-bold">DOA</span>
                        <!-- <span class="value c-pointer" (click)="openSettlementdoc(basicInfo)"><i class="fas fa-download generic-palette-b-w-gray f18" title="Settlement Document"></i></span> -->
                        <span class="value" *ngIf="basicInfo?.signedDocumentURL !== null && basicInfo?.signedDocumentURL !== ''" (click)="openDOAdoc(basicInfo?.signedDocumentURL)"><i class="fas fa-download generic-palette-b-w-gray f18" title="DOA"></i></span>
                        <span class="value" *ngIf="basicInfo?.signedDocumentURL === null && basicInfo?.signedDocumentURL === '' && basicInfo?.unSignedDocumentURL !== null && basicInfo?.unSignedDocumentURL !== ''" (click)="openDOAdoc(basicInfo?.unSignedDocumentURL)"><i class="fas fa-download generic-palette-b-w-gray f18" title="DOA"></i></span>
                        <span class="value doa-disable" *ngIf="basicInfo?.signedDocumentURL === null && basicInfo?.signedDocumentURL === '' && basicInfo?.unSignedDocumentURL === null && basicInfo?.unSignedDocumentURL === ''"><i class="fas fa-download generic-palette-b-w-gray f18" title="DOA"></i></span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 price-info-div align-item-center">
                        <!-- <span class="name roboto-bold w-155">Conveyancer Email</span>
                        <span class="value">{{basicInfo?.conveyancer}}</span> -->
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 row pl-7  desc-content" >
                    <!-- <div class="col-lg-2 col-md-2 col-sm-12 user-info-div align-item-center">
                        <span class="name roboto-bold"> Reference ID</span>
                        <span class="value pr-8">{{basicInfo?.poaReferanceID }}</span>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 price-info-div align-item-center">
                        <span class="name roboto-bold">Reference ID Expiry Date</span>
                        <span class="value">{{basicInfo?.poaReferanceIDExpiryDate | date: 'dd-MMM-yyyy'}}</span>
                    </div> -->
                    <div class="col-lg-2 col-md-2 col-sm-12 price-info-div align-item-center">
                        <span class="name roboto-bold">State</span>
                        <span class="value">{{basicInfo?.propertyStateName}}</span>
                    </div>
                </div>

                  <div class="col-lg-12 col-md-12 col-sm-12 row pl-7 space-between desc-content" >
                    <div class="col-lg-2 col-md-2 col-sm-12 user-info-div align-item-center">
                        <span class="name roboto-bold">Date of Purchase</span>
                        <span class="value cornflower-blue text-transform-upper">{{basicInfo?.dateOfPurchase | date: 'dd-MMM-yyyy hh:ss aa'}}</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 price-info-div align-item-center">
                        <span class="name roboto-bold">Date of Conveyance Completed</span>
                        <span class="value cornflower-blue text-transform-upper">{{basicInfo?.dateOfConveyanceCompleted | date: 'dd-MMM-yyyy hh:ss aa'}}</span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 price-info-div align-item-center">
                        <span class="name roboto-bold">Number of Shares</span>
                        <span class="value f12">{{basicInfo?.noOfBricklets}}</span>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 price-info-div pr0 align-item-center">
                        <span class="name roboto-bold">Total Shares Price</span>
                        <span class="value f12">${{basicInfo?.totalBrickletPrices === null ? '0' : basicInfo?.totalBrickletPrices | number : '1.2-2'}}</span>
                    </div>
                </div>
            
                <div class="col-lg-12 col-md-12 col-sm-12 row pl-7 space-between bg-table mt-10">
                    
                    
                        <div class="table-responsive  mt-2">

                        
                            
                            <table mat-table [dataSource]="getString(basicInfo?.brickletDetails)" class="table conveyancer">
                                <ng-container matColumnDef="ReferenceID">
                                    <th mat-header-cell *matHeaderCellDef class="table-header exo2-bold f14 th-w pl-22 black"> Shares Reference ID </th>
                                    <td mat-cell *matCellDef="let element" class="td-pl exo2-medium f12"> {{element.ReferenceID}} </td>
                                </ng-container>
                
                                <ng-container matColumnDef="SellRequestedOn">
                                    <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14 th-w-60 pl-4 black"> Sell requested On </th>
                                    <td mat-cell *matCellDef="let element" class="roboto-regular f12 text-transform-upper"> {{element.SellRequestedOn === '1900-01-01T00:00:00' ? '' : element.SellRequestedOn | date: 'dd-MMM-yyyy'}} </td>
                                </ng-container>
                
                
                                <ng-container matColumnDef="SellCompletedOn">
                                    <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14 th-w pl-1 black text-center"> Sell completed on </th>
                                    <td mat-cell *matCellDef="let element" class="roboto-regular f12 text-transform-upper text-left"> {{element.SellCompletedOn | date: 'dd-MMM-yyyy'}}
                                    </td>
                                </ng-container>
                
                
                                <ng-container matColumnDef="SellConveyanceCompletedOn">
                                    <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14 th-completed pl-12 black text-center"> Sell Conveyance Completed On </th>
                                    <td mat-cell *matCellDef="let element" class="roboto-regular f12 text-transform-upper text-center pl-2"> {{element.SellConveyanceCompletedOn | date: 'dd-MMM-yyyy'}} </td>
                                </ng-container>
                
                                <ng-container matColumnDef="Seller">
                                    <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14 th-w pl-12 black text-center"> Seller </th>
                                    <td mat-cell *matCellDef="let element" class="roboto-regular f12 text-center"> <p class="pl-1">{{element.AccountName}}</p>
                                    </td>
                                </ng-container>
                
                                <!-- <ng-container matColumnDef="BrickletPrices">
                                    <th mat-header-cell *matHeaderCellDef class="table-header roboto-medium f14 th-w pl-12 black"> Price(Including tax) </th>
                                    <td mat-cell *matCellDef="let element" class="roboto-regular f12 pl-25 cornflower-blue"><p class="pl-4"> ${{element.BrickletPrices | number : '1.2-2'}} </p></td>
                                </ng-container> -->
                
                                
                
                
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                                
                                
                        </div>
                
                
                   
                </div>
            
          
        </div>
              
              </div>
              

            
            <app-loader class="loader" *ngIf="isLoading"></app-loader>
           
        </div>
        </div>
        </form>
        <!-- <mat-paginator #paginator class="table mt-2" [length]="resultsLength" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPaginateChange($event)"
        showFirstLastButtons></mat-paginator> -->
    </div>
</div>