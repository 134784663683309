export class PageConfigs {
    public static NewsList = {
      name: 'news',
      read: 'read',
      create: 'create',
      edit: 'edit',
      delete: 'delete',
    };

    public static VideoList = {
      name: 'news',
      read: 'read',
      create: 'create',
      edit: 'edit',
      delete: 'delete',
    };

    public static PodcastList = {
      name: 'news',
      read: 'read',
      create: 'create',
      edit: 'edit',
      delete: 'delete',
    };
  
    public static NewsCategory = {
      name: 'news-category',
      read: 'read',
      create: 'create',
      edit: 'edit',
      delete: 'delete',
    };
  
    public static CppAgent = {
      name: 'cpp-agent',
      read: 'read',
      create: 'create',
    };
  
    public static Users = {
      name: 'users',
      read: 'read',
      update: 'update',
      updateKycVerify: 'updateKycVerify',
      create: 'create',
    };

    public static Kyc = {
      name: 'users',
      read: 'read',
      update: 'update'
    };

    // public static KycRequests = {
    //   name: 'kyc-requests',
    //   read: 'read',
    //   updateApprove: 'updateApprove',
    //   updateReject: 'updateReject',
    // };

    // public static KycHistory = {
    //   name: 'kyc-history',
    //   read: 'read',
    // };

    public static CommunityCategory = {
      name: 'community-category',
      read: 'read',
      create: 'create',
      delete: 'delete',
      edit: 'edit'
    }

    public static Reports = {
      name: 'reports',
      read: 'read'
    }

    public static Ledgers = {
      name: 'ledgers',
      read: 'read'
    }
  
    public static DepositRequests = {
      name: 'deposit-requests',
      read: 'read',
      updateDeposit: 'updateDeposit',
      updateReject: 'updateReject',
    };
  
    public static DepositHistory = {
      name: 'deposit-history',
      read: 'read',
    };

    public static WithdrawAccount = {
      name: 'withdrawal-account',
      read: 'read',
      updateApprove: 'updateApprove',
      updateReject: 'updateReject',
    };
  
    public static WithdrawRequests = {
      name: 'withdrawal-request',
      read: 'read',
      updateApprove: 'updateApprove',
      updateReject: 'updateReject',
    };

    public static DivedendsDetails = {
      name: 'dividend',
      read: 'read',
      updateApprove: 'updateApprove',
      updateReject: 'updateReject',
    };

    public static DivedendsHistory = {
      name: 'dividend',
      read: 'read',
      updateApprove: 'updateApprove',
      updateReject: 'updateReject',
    };
  
    public static WithdrawHistory = {
      name: 'withdrawal-history',
      read: 'read',
    };
  
    public static Transactions = {
      name: 'transactions',
      read: 'read',
    };

    public static Rent = {
      name: 'rent',
      read: 'read',
    };
  
    public static CoownershipRequest = {
      name: 'co-ownership-request',
      read: 'read',
      create: 'create'
    };
  
    public static LeaseTypes = {
      name: 'lease-types',
      read: 'read',
      create: 'create',
      edit: 'edit',
    };
  
    public static PropertyManagers = {
      name: 'property-managers',
      read: 'read',
      create: 'create',
      edit: 'edit',
    };
  
    public static PropertyDevelopers = {
      name: 'property-developers',
      read: 'read',
      create: 'create',
      edit: 'edit',
    };
  
    public static Properties = {
      name: 'cars',
      read: 'read',
      create: 'create',
      readDetails: 'readDetails',
    };
  
    public static UserRoles = {
      superAdmin: 'Super Admin',
      admin: 'ADMIN',
      propertyAdmin: 'PROPERTY ADMIN',
      financialAdmin: 'Financial Admin',
      marketingAdmin: 'MARKETING ADMIN',
      conveyancer: 'CONVEYANCER'
    };
  
    public static Conveyancer = {
      name: 'conveyancer',
      read: 'read',
      create: 'create'
    };

    public static CoownerShipRequest = {
      name: 'co-ownership-request',
      read: 'read',
      create: 'create'
    };
  
    public static CoownerShipHistory = {
      name: 'co-ownership-request',
      read: 'read',
      create: 'create'
    };
    public static ArticleList = {
      name: 'news',
      read: 'read',
      create: 'create',
      edit: 'edit',
      delete: 'delete',
    };
  
    public static ArticleCategory = {
      name: 'news-category',
      read: 'read',
      create: 'create',
      edit: 'edit',
      delete: 'delete',
    };

    public static VideoCategory = {
      name: 'news-category',
      read: 'read',
      create: 'create',
      edit: 'edit',
      delete: 'delete',
    };

    public static Category = {
      name: 'users',
      read: 'read',
      create: 'create',
      edit: 'edit',
      delete: 'delete',
      // name: 'users',
      // read: 'read',
      // update: 'update',
      // updateKycVerify: 'updateKycVerify',
      // create: 'create',
    };

    public static SubCategory = {
      name: 'category',
      read: 'read',
      create: 'create',
      edit: 'edit',
      delete: 'delete',
    };
  }
  
  export default PageConfigs;
  