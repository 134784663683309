import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConveyancerService {
    private messageSource = new BehaviorSubject(false);
    currentMessage = this.messageSource.asObservable();
  constructor(
    private apiManagerService: ApiManagerService
  ) { }

  getAllPendingOrderRequests(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getAllConveyancerPendingOrder, data);
  }

  getAllConveyancerHistory(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getAllConveyancerHistory, data);
  }

  getAllPendingOrderRequestsbyadmin(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getAllConveyancerPendingOrderbysuperadmin, data);
  }

  getAllConveyancerHistorybyadmin(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getAllConveyancerHistoryAdmin, data);
  }
  

  approveRequest(data: any){
    return this.apiManagerService.putRequest(RestEnds.approvePendingOrder, data);
  }

  rejectRequest(data: any){
    return this.apiManagerService.putRequest(RestEnds.rejectPendingOrder, data);
  }

  uploadTitleTransferDocument(data: any){
    return this.apiManagerService.postRequest(
        RestEnds.uploadTitleTransferDocument,
        data
      );
  }
  
  
  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }


  createConveyancer(data: any) {
    return this.apiManagerService.postRequest(RestEnds.addConveyancer, data);
  }

  getConveyancerList(data: any){
    return this.apiManagerService.postRequest(RestEnds.getConveyancerList, data);
  }
  
  getConveyancerMappingAccount(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getConveyancerMappingAccount, data);
  }

  getAllConveyancerList(){
    return this.apiManagerService.getRequest(RestEnds.getAllConveyancerList, null);
  }

  createConveyancerMapping(data: any) {
    return this.apiManagerService.postRequest(RestEnds.createConveyancerMapping, data);
  }

  getMappingAccountList(data: any) {
    return this.apiManagerService.getRequest(RestEnds.getMappingAccountsList + "?userGUID=" + data, null);
  }

  updateConveyancerMapping(data: any) {
    return this.apiManagerService.putRequest(RestEnds.updateConveyancerMapping, data);
  }

  updateSettlementDate(data: any) {
    return this.apiManagerService.putRequest(RestEnds.updateSettlementDate, data);
  }

}
