<div class="bootstrap-wrapper">
<form [formGroup]="form">
  <div class="">
    <div class="modal-header">
      <h1 mat-dialog-title class="pl1 roboto-medium f24">
        {{title}}
      </h1>
    </div>
    <div mat-dialog-content>
      <div class="col-11 p0">
        <p class="roboto-regular f12">{{message}}</p>
      </div>
      <div class="col-12 p0 mt20 mb20">
        <div class="col-4 p0">
          <input placeholder="Verification code" class="roboto-regular f14 verify-input col-12" maxlength="6"
            formControlName="txtVerificationCode" (keypress)="keyPress($event)">
        </div>
      </div>
    </div>

    <div mat-dialog-actions class="d-flex justify-content-end mb4 col-12 pr1">
      <button mat-raised-button color="warn" (click)="onDismiss()" class="confirm-btns roboto-medium f13 btn btn-cancel col-md-3 col-sm-6">Cancel</button>
      <button mat-raised-button color="primary" (click)="onConfirm(form)" class="confirm-btns roboto-medium f13 white btn btn-disable col-md-3 col-sm-6"
        [disabled]="!form.valid">Disable 2FA</button>
     
    </div>

  </div>
</form>
</div>