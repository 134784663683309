import { Component, OnInit, Input } from '@angular/core';
import { KycConfigService } from 'src/app/core/services/kyc-config.service';
import { UserInfoCardInterface } from '../../../core/interfaces/user-info-card.interface';
import { Settlor } from '../../../core/interfaces/kyc.interface';

@Component({
  selector: 'app-org-settlor',
  templateUrl: './org-settlor.component.html',
  styleUrls: ['./org-settlor.component.scss']
})
export class OrgSettlorComponent implements OnInit {

  @Input() settlorData: Settlor | any;

  personalInfo: UserInfoCardInterface | null = null;
  addressInfo: UserInfoCardInterface | null = null;
  idInfo: UserInfoCardInterface | null = null;
  addressVerifyInfo: UserInfoCardInterface | null = null;

  constructor(
    private kycConfigService: KycConfigService
  ) { }

  ngOnInit() {
    // console.log(this.settlorData);

    if (this.settlorData) {
      const result: any = this.kycConfigService.kycOrgArrangedData(this.settlorData);
      this.personalInfo = {
        title: 'Personal Information',
        column: 4,
        type: 'text-only',
        data: result.personalInfo
      };
      this.addressInfo = {
        title: 'Address Information',
        column: 3,
        type: 'text-only',
        data: result.addressInfo
      };

      this.addressVerifyInfo = {
        title: 'Address verification documents',
        column: 0,
        type: 'doc-only',
        data: result.addressVerifyDocs
      };

      this.idInfo = {
        title: 'Identity verification documents',
        column: 0,
        type: 'doc-data-only',
        data: result.idInfo
      };

    }
  }

}
