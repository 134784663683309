<h2 mat-dialog-title>
    <span>KYC 2 Verification</span>
    <span mat-dialog-close>X</span>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="bootstrap-wrapper">
        <div class="row adjust-row">
            <p class="roboto-medium slate-grey f18">
                User has <span class="f20 black">ACCEPTED</span> the Shares Terms and Conditions
            </p>
            <img src="assets/images/kyc2-verify.svg" alt="" class="mt10">
        </div>
        <div class="row mt15 align-center">
            <div class="col-4">
                <button class="btn-watermelon" type="button" (click)="rejectKyc()">Reject</button>
            </div>
            <div class="col-4">
                <button class="btn-cornflower-blue" type="button" (click)="approveKyc()">Approve</button>
            </div>
        </div>
    </div>
</mat-dialog-content>