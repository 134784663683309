import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NewsService } from '../../../core/services/news.service';
import { trigger, transition, animate, style } from '@angular/animations';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { UploadImageService } from '../../../core/services/upload-image.service';
import { CommunityService } from 'src/app/core/services/community.service';
import { ThemePalette } from '@angular/material/core';
import { ValidationService } from '../../../core/services/validation.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../../core/utils/common.service';
import { imageUploadConfig } from '../../../core/interfaces/image-upload.interface';
import { StaticMessage, CommunityUploadImageOptions } from '../../../core/enum/constants';
import { HelperService } from '../../../core/utils/helper.service';
import { Observable } from 'rxjs/internal/Observable';
import { startWith } from 'rxjs/internal/operators/startWith';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-add-articlebanner',
  templateUrl: './add-articlebanner.component.html',
  styleUrls: ['./add-articlebanner.component.scss'],
  animations: [trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(1500, style({opacity: 1}))
    ])
  ])]
})
export class AddArticleBannerComponent implements OnInit, AfterViewInit, OnDestroy {
  type: any;
  showImg = false;
  isFileSelected = false;
  imageSrc: any = [];
  imageList: ImageFiles[] = [];
  fileList: any;
  fileType: any;
  form!: FormGroup;
  checked = false;
  disabled = false;
  lblStatus = 'InActive';
  color: ThemePalette = 'primary';
  bannerForm = this.formBuilder.group({
    articleId: new FormControl('', [Validators.required,ValidationService.titleMaxLength]),
    articleGUID: new FormControl(''),
    title: new FormControl('', [Validators.required,ValidationService.titleMaxLength]),
    bannerImage: new FormControl(null,[Validators.required]),
    description:new FormControl('', [Validators.required,ValidationService.descriptionMaxLength]),
    state:new FormControl(null),
    bannerImageURL: new FormControl(null)
  });
  quillEditorRef: any;
  maxUploadFileSize = 1000000;
  // @ViewChild('article', { static: false}) feedDate: ElementRef;
  feedDate: any;
  @ViewChild('errorLog', {static: false}) errorLog!: ElementRef;
  @ViewChild('description', {static: false}) description!: ElementRef;
  GUID = null;
  stateList =[ {stateGUID:"1",name:"AU"} , {stateGUID:"2",name:"SA"}];
  isbtnLoading = false;
  readonly = false;
  options = [];
  filteredOptions: Observable<any[]>;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private communityService: CommunityService,
    private dialog: MatDialog,
    private uploadImageService: UploadImageService,
    private helper: HelperService
  ) {
    this.dialogRef.disableClose = true;
    this.filteredOptions = this.bannerForm.controls.articleId.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
            return this.filter(val || '')
       }) 
    )
   }

   articles=[];

   getArticle(guid: string) {
     if(guid !== "" && guid !== null && this.articles.length > 0){
      return this.articles.find(art => art.articleGUID === guid).articleName;
     } 
  }


   filter(val: any): Observable<any[]> {
    // call the service which makes the http-request
    return this.communityService.getArticleData(val)
     .pipe(
       map((response:any)=> 
         response.data.articles.filter((option:any) => { 
         this.articles = response.data.articles;
         return option.articleName.toLowerCase().indexOf(val.toLowerCase()) === 0
       }))
     )
   }  


   // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
   ngOnInit() {
    this.bannerForm.patchValue({
      state: ''
    });
   }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.getStateList();
    this.GUID = this.data.guid;
    this.readonly = this.data.readOnly;
    if (this.GUID !== null) {
      this.getArticleByGUID(this.GUID);
    }
    if(this.readonly === true) {
      this.f.title.disable();
      this.f.state.disable();
      this.f.description.disable();
      this.f.bannerImage.disable();
      this.f.articleId.disable();
    } else {
      this.f.title.enable();
      this.f.state.enable();
      this.f.description.enable();
      this.f.bannerImage.enable();
      this.f.articleId.enable();
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }
  onClose() {
    this.bannerForm.reset();
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  onToggle(event: any) {
    if (event.checked) {
      this.lblStatus = 'Active';
    } else {
      this.lblStatus = 'InActive';
    }
  }


  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
    // console.log(this.quillEditorRef)
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
  }

  imageHandler = (image: any, callback: any) => {
    const input: any = document.getElementById('fileInputField') as HTMLInputElement;

   let field: any = document.getElementById('fileInputField');
   field.onchange = () => {
      let file: File;
      file = input.files[0];
      // file type is only image.
      if (/^image\//.test(file.type)) {
        if (file.size > this.maxUploadFileSize) {
           this.errorLog.nativeElement.innerHTML = 'Image needs to be less than 1MB';
           input.value = null;

        } else {
          this.errorLog.nativeElement.innerHTML = '';
          const reader  = new FileReader();
          reader.onload = () =>  {
            const range = this.quillEditorRef.getSelection();
            const img = '<img src="' + reader.result + '" />';
            this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
            const content = this.quillEditorRef.root.innerHTML;
            this.bannerForm.patchValue({
              description: content
            });
          };
          reader.readAsDataURL(file);
        }
      } else {
        input.value = null;
        this.errorLog.nativeElement.innerHTML = 'You could only upload images.';
      }

    };
    input.click();
  }


  public submit(e: any) {
    this.uploadImage_new(e);
  }

  handleInputChange(e: any) {
    this.uploadImage_new(e);
  }

  uploadImage_new(e: any): any {
    this.fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    const reader = new FileReader();
    const filelist:any= [];
    const config: imageUploadConfig = {
      dimension: {
        width: CommunityUploadImageOptions.width,
        height: CommunityUploadImageOptions.height,
        operation: CommunityUploadImageOptions.operation
      },
      size: CommunityUploadImageOptions.size,
      fileExt: CommunityUploadImageOptions.exts,
      fileType: null
    };
    if (this.fileList.length > 1) {
      this.helper.warning('Please select one image');
      return false;
    }

    if (this.fileList.length > 0) {
      for (const item of this.fileList) {
        filelist.push(item);
        this.showImg = false;
        this.imageSrc = [];
        this.imageList = []; // Single image upload
        this.isFileSelected = false;
        this.uploadImageService.uploadImage(item, config, reader, (result: any) => {
          if (result.status) {
            this.imageList.push({
              imageType: result.type,
              imageFile: result.imageFile
            });
            this.bannerForm.patchValue({
              bannerImage: this.fileList
            });
            this.imageSrc.push(result.imagePreview);
            this.isFileSelected = true;
          } else {
            this.helper.warning(result.msg);
            this.bannerForm.patchValue({
              bannerImage: ''
            });
          }
        });
      }
      this.cdr.markForCheck();
    }
  }

  uploadImage(e: any): any {
    this.fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    this.fileType = null;
    const reader = new FileReader();
    const filelist :any = [];
    if (this.fileList.length > 0) {
      for (const item of this.fileList) {
        this.fileType = item.type;
        const fileSize = item.size;
        filelist.push(item);
        if (
          this.fileType !== 'image/png' &&
          this.fileType !== 'image/jpg' &&
          this.fileType !== 'image/jpeg'
        ) {
          this.helper.error(StaticMessage.ImageFormat);
          e.target.value = null;
          return false;
        }
        if (fileSize > 1048576) {
          this.helper.error(
            'Please upload image size not more than 1 MB'
          );
          e.target.value = null;
          return false;
        }

        this.showImg = false;
        this.imageSrc = [];
        this.imageList = []; // Single image upload
        this.isFileSelected = false;

        this.imageList.push({
          imageType: this.findAndReplace(this.fileType, 'image/', '.'),
          imageFile: item
        });

        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(item);
      }
    }
  }

  _handleReaderLoaded(e: any) {
    const reader = e.target;
    this.imageSrc.push(reader.result);
    this.isFileSelected = true;
  }

  // tslint:disable-next-line:variable-name
  findAndReplace(string: any, target: any, replacement: any) {
    return (string = string.replace(target, replacement));
  }

  onSubmit() {
    this.isbtnLoading = true;
    const formData = new FormData();
    if(this.GUID !== null) {
      formData.append('BannerGUID', this.GUID);
    }
    formData.append('Title', this.bannerForm.controls.title.value);
    formData.append('Description', this.bannerForm.controls.description.value);
    formData.append('StateGUID', this.bannerForm.controls.state.value !== null ? this.bannerForm.controls.state.value : "");
    formData.append('ArticleGUID', this.bannerForm.controls.articleId.value);
    formData.append('IsActive', "true");
    if(this.bannerForm.controls.bannerImageURL.value !== null && this.imageList.length === 0) {
      formData.append('ImageURL', this.bannerForm.controls.bannerImageURL.value);
    }
    if (this.imageList.length > 0) {
      for (let i = 0; i < this.imageList.length; i++) {
        formData.append('BannerImage', this.imageList[i].imageFile);
      }
    } 
    if(this.GUID === null) {
    this.onSaveBanner(formData);
    } else {
      this.onUpdateBanner(formData);
    }
  }

  onSaveBanner(formData: any) {
    this.communityService
      .saveBanner(formData)
      .subscribe(
        (res: any) => {
          this.isbtnLoading = false;
          const result: any = res;
          if (result.data != null) {
            this.helper.success("Feature Article Created Successfully");
            this.communityService.changeMessage(true);
            this.onClose();
          } else {
            this.helper.error(result.data.message);
          }
        },
        (error: any) => {
          this.isbtnLoading = false;
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );

  }

  onUpdateBanner(formData: any) {
    this.communityService
      .updateBanner(formData)
      .subscribe(
        (res: any) => {
          this.isbtnLoading = false;
          const result: any = res;
          if (result.data != null) {
            this.helper.success("Feature Article Updated Successfully ");
            this.communityService.changeMessage(true);
            this.onClose();
          } else {
            this.helper.error(result.data.message);
          }
        },
        (error: any) => {
          this.isbtnLoading = false;
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );

  }

  // allState ={
  //   'name': "All State",
  //   'shortName': "NSW",
  //   'stateGUID': "0"
  // }

  getStateList() {
    this.communityService.getStateList()
       .subscribe(
         (res: any) => {
           const result: any = res;
           if (result.data != null) {
             this.stateList = result.data.states;
            //  this.stateList.unshift(this.allState);
           }
         },
         (error: any) => {
         }
       );
   }


   getArticleByGUID(guid: any) {
    this.communityService.getFeatureArticlesByGuid(guid).subscribe(
        (res: any) => {
          const result: any = res;
          if (result.data != null) {
            const data = result.data;
            const articleDetail = data.banner;
            this.GUID = articleDetail.bannerGUID;
            this.imageSrc.push(articleDetail.imageURL);
            this.isFileSelected = true;
            let content: any;
            // let name = this.filter(articleDetail.articleName);
            // console.log(name);
            this.communityService.getArticleData(articleDetail.articleName).subscribe(
              (response: any) => {
              this.articles = response.data.articles;
              this.bannerForm.patchValue({
                articleId: this.f.articleGUID.value
              });
            });
            // this.communityService.showGetResult(articleDetail.blobGUID, function(URL: any){ 
            //   content = URL
            // });
          // setTimeout(()=>{
            this.bannerForm.patchValue({
              articleGUID: articleDetail.articleGUID,
              state: articleDetail.stateGUID === null ? '' : articleDetail.stateGUID,
              title: articleDetail.title,
              description: articleDetail.description,
              bannerImage: articleDetail.imageURL,
              bannerImageURL: articleDetail.imageURL,
              });
        // },2000);

          } else {
            this.helper.error(result.data.message);
          }
        },
        (error: any) => {
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );
  }

  get f() {
    return this.bannerForm.controls;
  }

}

export interface ImageFiles {
  imageType: string;
  imageFile: any;
}
