import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NewsService } from 'src/app/core/services/news.service';
import { Observable } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { PatternValidator } from '../../validators/patternvalidator';
import { UsersService } from '../../../core/services/users.service';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { StaticSuccessMessage } from '../../../core/enum/constants';
import { ConveyancerService } from '../../../core/services/conveyancer.service';
import { data } from 'jquery';

@Component({
  selector: 'app-add-conveyancer',
  templateUrl: './add-conveyancer.component.html',
  styleUrls: ['./add-conveyancer.component.scss'],
  animations: [trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(1500, style({opacity: 1}))
    ])
  ])]
})
export class AddConveyancerComponent implements OnInit {

  @Input() type = null;
  public form: FormGroup;
  GUID = null;
  isbtnLoading = false;
  isExists = false;

  avatarProfileImagePath = '../../../../assets/images/avatar.png';

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formbuilder: FormBuilder,
    private commonService: CommonService,
    private newsService: NewsService,
    private usersService: UsersService,
    private helper: HelperService,
    private conveyancerService: ConveyancerService
  ) {
    this.form = this.formbuilder.group({
    id: new FormControl(''),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.maxLength(60),
      PatternValidator(
        // tslint:disable-next-line:max-line-length
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )]),
      // mobile: new FormControl('', [Validators.pattern(/^[0-9]{0,15}$/)])
    // isActive: new FormControl(true),
    });
    this.dialogRef.disableClose = true;
   }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {

  }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.GUID = this.data.guid;
    // if (this.GUID !== null) {
    //   this.getDetailByGUID(this.GUID);
    // }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
  onClose() {
    this.dialogRef.close();
  }


  onSave() {
    // if (this.f.mobile.value === '' || (this.f.mobile.value.length >= 10 && this.f.mobile.value.length <= 15)) {

    this.isbtnLoading = true;
    this.isExists = false;

    const data = {
      FirstName: this.f.firstname.value,
      LastName: this.f.lastname.value,
      Email: this.f.email.value,
      // Mobile: this.f.mobile.value
    };


    this.conveyancerService.createConveyancer(data).subscribe(
      res => {
        this.isbtnLoading = false;
        const result: any = res;
        if (result.data != null) {
          this.helper.success(
            result.data.message
          );
          this.conveyancerService.changeMessage(true);
          this.form.reset();
          this.commonService.goto("conveyanceradmin/conveyancerlist")
          this.onClose();
        } else {
          this.isbtnLoading = false;
          this.helper.error(result.message);
        }
      },
      error => {
        this.isbtnLoading = false;
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );

  // } else {
  //   if (this.f.mobile.value.length < 10 || this.f.mobile.value.length > 15) {
  //     this.helper.info('Mobile Number Length is Minimum 10 digits and Maximum 15 digits');

  //    }

  // }



  }

  onUpdate() {
    // this.isbtnLoading = true;
    // this.isExists = false;
    // this.getisExists().subscribe(res => {
    //   const result: any = res;
    //   if (res !== null) {
    //     this.isExists = result.response.data.isExist;
    //     if (this.isExists === false) {
    //       const data = {
    //         ID: this.GUID,
    //         Category: this.f.category.value,
    //         IsActive: this.f.isActive.value
    //       };

    //       this.edit(data);
    //     } else {
    //       this.isbtnLoading = false;
    //       this.commonService.toastError('already name exists! ');
    //     }
    //   } else {
    //     this.isbtnLoading = false;
    //     this.commonService.toastError(result.response.message);
    //   }
    // },
    //   error => {
    //     this.isbtnLoading = false;
    //     this.commonService.toastError(error);
    //   });


  }




  get f() {
    return this.form.controls;
  }

}

