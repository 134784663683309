<div class="bootstrap-wrapper">

        <div class="container col-lg-12 col-md-12 col-sm-12">
          <div class="row modal-header p5">
            <div class="col-10 roboto-medium f16 title">Edit Conveyancer Mapping Account</div>
            <div class="col-2">
                <button mat-icon-button color="primary" mat-dialog-close class="pull-right lh0 h0 w20 title" (click)="onClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

      
        </div>

      
    </div>


<div class="row d-flex justify-content-center">
    <div class="col-7 d-flex justify-content-center">
  <form class="mappingForm col-12" [formGroup]="userMappingForm">
    <!-- <mat-form-field class="col-lg-11 col-md-10 col-sm-12">
      <mat-label>Select Conveyancer</mat-label>
      <mat-select formControlName="conveyancerName" #conveyancerNm (openedChange)="openedChange($event, 'searchConveyancer')">
        <mat-form-field style="width: 100%; padding: 10px;">
          <input
            #search
            autocomplete="off"
            placeholder="Search email"
            aria-label="Search"
            matInput
            formControlName="searchConveyancer"
            (change)="searchConveyancer()"
          />
          <button
            [disableRipple]="true"
            *ngIf="search.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearSearch($event, 'searchConveyancer')"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
  
        <mat-option *ngFor="let conveyancer of conveyancerList" [value]="conveyancer?.userGUID">
          {{ conveyancer?.fullName }}
          <span style="font-size: 12px; color: blueviolet;">
            ({{ conveyancer?.email }})
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
     -->
  
    <mat-form-field class="col-lg-11 col-md-10 col-sm-12">
      <mat-label>Select Users</mat-label>
      <mat-select  formControlName="selectedUsers" (openedChange)="openedChange($event, 'searchUser')" (selectionChange)="onSelectedUser($event)">
        <mat-form-field style="width: 100%; padding: 10px;">
          <input
            #searchUser
            autocomplete="off"
            placeholder="Search email"
            aria-label="SearchUser"
            matInput
            formControlName="searchUser"
            (change)="selectedUser()"
          />
          <button
            [disableRipple]="true"
            *ngIf="searchUser.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearSearch($event, 'searchUser')"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-option *ngFor="let user of userList" [value]="user.userGUID" 
          >{{ user.fullName }}
          <span style="font-size: 12px; color: blueviolet;">
            ({{ user.email }})
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field class="col-lg-11 col-md-10 col-sm-12">
        <mat-label>Select Accounts</mat-label>
        <mat-select formControlName="selectedAccounts" (openedChange)="openedChange($event, 'searchAccount')">
          <mat-form-field style="width: 100%; padding: 10px;">
            <input
              #searchAccount
              autocomplete="off"
              placeholder="Search Account"
              aria-label="SearchAccount"
              matInput
              formControlName="searchAccount"
            />
            <button
              [disableRipple]="true"
              *ngIf="searchAccount.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearSearch($event, 'searchAccount')"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-option *ngFor="let account of accountList" [value]="account?.accountGUID"
            >{{ account?.accountName }}
            <span style="font-size: 12px; color: blueviolet;">
              ({{ account?.entityType }})
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>
  
    <div class="btn-div row">
      <button
        [disabled]="isLoading || !userMappingForm.valid"
        (click)="onSubmit()"
        class="submit-btn col-lg-4 col-md-8 col-sm-12"
        mat-raised-button
        color="primary"
      >
        Submit
      </button>
  
      <button
        (click)="reset()"
        class="submit-btn col-lg-4 col-md-8 col-sm-12"
        mat-raised-button
        color="accent"
      >
        Reset
      </button>
    </div>
  </form>
  </div>
  </div>