import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RestEnds } from './rest-ends';

@Injectable({
  providedIn: 'root'
})
export class ApiManagerService {

  constructor(
    private httpClient: HttpClient
  ) { }

  baseUrl(): string {
    return environment.apiBaseURL;
  }

  prepareUrl(endPoint: RestEnds | string) {
    return this.baseUrl() + '' + endPoint;
  }

  getRequest(endPoint: any, option: any) {
    const restUrl = this.prepareUrl(endPoint);
    if (option) {
      return this.httpClient.get(restUrl, option);
    } else {
      return this.httpClient.get(restUrl);
    }
  }

  postRequest(restEnd: any, bodyParam: any,) {
    const restUrl = this.prepareUrl(restEnd);
    return this.httpClient.post(restUrl, bodyParam);
  }

  putRequest(restEnd: any, bodyParam = null) {
    const restUrl = this.prepareUrl(restEnd);
    return this.httpClient.put(restUrl, bodyParam);
  }

  deleteRequest(restEnd: any) {
    const restUrl = this.prepareUrl(restEnd);
    return this.httpClient.delete(restUrl);
  }

  patchRequest(restEnd: RestEnds, bodyParam: any) {
    const restUrl = this.prepareUrl(restEnd);
    return this.httpClient.patch(restUrl, bodyParam);
  }

  getHtml(bodyParam: any) {
    const restUrl = '';
    return this.httpClient.patch(restUrl, bodyParam);
  }

  postBlobRequest(endPoint: string, bodyParam: any, option: any) {
    const restUrl = this.prepareUrl(endPoint);
    if (option) {
      return this.httpClient.post(restUrl, bodyParam, option);
    } else {
      return this.httpClient.post(restUrl, bodyParam);
    }
  }
}
