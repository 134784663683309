
<!-- <h2 mat-dialog-title>Image</h2>   -->
<!-- <mat-card-title> -->
    <div class="bootstrap-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="col-1"></div>
            <div class="col-11">
            <button mat-icon-button color="primary" mat-dialog-close class="btn-close">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        </div>
    </div>
    </div>
<!-- </mat-card-title> -->

<mat-dialog-content>
    <img class="card-img-top" [src]="image" width="100" height="400">


</mat-dialog-content>

<mat-dialog-actions>
    <!-- <button mat-raised-button color="primary" mat-dialog-close>Close</button> -->

</mat-dialog-actions>