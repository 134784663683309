import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(
    private apiManagerService: ApiManagerService
  ) { }


  getAllTransactionHistory(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getAllTransactionHistory, data);
  }

  getAllRefundHistory(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getAllRefundHistory, data);
  }


  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }


  refundRequest(data: any){
    return this.apiManagerService.postRequest(RestEnds.refundInitiate+data.orderGUID, null);
  }

}
