import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../core/utils/storage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UploadImageComponent } from '../../shared/components/upload-image/upload-image.component';
import { HelperService } from '../../core/utils/helper.service';
import { PageConfigs } from 'src/app/core/utils/access-level-config';
import { UserRolesService } from '../../core/services/user-roles.service';
import { PropertyManagerService } from '../../core/services/propertymanager.service';
import { PropertyDeveloperService } from '../../core/services/propertydeveloper.service';
import { LeaseTypeService } from '../../core/services/leasetype.service';
import { StaticSuccessMessage, PropertyMenu } from '../../core/enum/constants';
import { AuthService } from '../../core/services/auth.service';
import { Storage } from 'src/app/core/enum/storage';
import { CommonService } from '../../core/utils/common.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { AddArticleComponent } from 'src/app/shared/components/add-article/add-article.component';
import { AddArticleBannerComponent } from 'src/app/shared/components/add-article-banner/add-articlebanner.component';
import { AddVideoComponent } from 'src/app/shared/components/add-video/add-video.component';
import { AddPodcastComponent } from 'src/app/shared/components/add-podcast/add-podcast.component';
import { AddUserComponent } from '../../shared/components/add-user/add-user.component';
import { AddCategoryComponent } from '../../shared/components/add-category/add-category.component';
import { NewsCategoryComponent } from '../../shared/components/news-category/news-category.component';
import { AddNewsComponent } from '../../shared/components/add-news/add-news.component';
import { AddSubCategoryComponent } from '../../shared/components/add-subcategory/add-subcategory.component';
import { AddCppAgentComponent } from '../../shared/components/add-cpp-agent/add-cpp-agent.component';
import { DynamicAmenitiesComponent } from '../../shared/components/dynamic-amenities/dynamic-amenities.component';
import { AmenityService } from '../../core/services/amenities.service';
import { AddConveyancerComponent } from '../../shared/components/add-conveyancer/add-conveyancer.component';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [trigger('layout', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(400, style({ opacity: 1 }))
    ])
  ])]
})
export class LayoutComponent implements OnInit {
  isLogged = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  public pageHeading = '';

  items = PropertyMenu;
  dialogRef: any;
  isbtnLoading = false;
  isExists = false;
  form!: FormGroup;
  _typename: any;
  _roleName: any;
  userName: any;
  imageList: any = [];
  constructor(
    private router: Router,
    public commonService: CommonService,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private helperService: HelperService,
    private userRolesService: UserRolesService,
    private leasetypeService: LeaseTypeService,
    private amenityService: AmenityService,
    private propertyDeveloperService: PropertyDeveloperService,
    private propertyManagerService: PropertyManagerService
  ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // this.helperService.error('invalid');
  }

  onLogout() {
    this.storageService.setItem(Storage.IS_LOGGEDIN, "false");
    this.authService.changeStatus(false);
    this.authService.logout();
    // // this.router.navigate(['/login']);
     this.router.navigate(['/login']);
  }


  onRouterActivate(component: any) {
    this.pageHeading = this.route.snapshot.children[0].data.title;
  }

  add(data: any, name: any) {    
    const value = data;
    const typename = name;
    this._typename = name;
    if (typename === 'Property Lease Type' || typename === 'Car Owner' || typename === 'Car Manager') {
      this.dialogRef = this.dialog.open(UploadImageComponent, {
        width: '45%',
        id: 'upload-image',
        data: { type: value, name: typename, guid: null }
      });
      this.dialogRef.componentInstance.outputAddEvent.subscribe((data: any) => {
        this.onSave(data);
      });
    }
    else if (typename === 'Property Amenities') {
      this.dialogRef = this.dialog.open(DynamicAmenitiesComponent, {
        width: '45%',
        id: 'property-amenities',
        data: { type: value, name: typename, guid: null }
      });
      this.dialogRef.componentInstance.outputAddEvent.subscribe((data: any) => {
        this.onSaveAmenities(data);
      });
    } 
    else if (typename === 'Article') {
      this.dialogRef = this.dialog.open(AddArticleComponent, {
        width: '97%',
        id : 'add-article',
        data: { type: value, name: typename, guid: null, readOnly: false }
      });
     }
     else if (typename === 'Feature Article') {
      this.dialogRef = this.dialog.open(AddArticleBannerComponent, {
        width: '97%',
        id : 'Feature Article',
        data: { type: value, name: typename, guid: null, readOnly: false }
      });
     }
     else if (typename === 'Video') {
      this.dialogRef = this.dialog.open(AddVideoComponent, {
        width: '97%',
        id : 'add-video',
        data: { type: value, name: typename, guid: null, readOnly: false }
      });
     } 
    //  console.log(typename)
     else if (typename === 'Podcast') {
      this.dialogRef = this.dialog.open(AddPodcastComponent, {
        width: '97%',
        id : 'add-video',
        data: { type: value, name: typename, guid: null, readOnly: false }
      });
     }
    else if (typename === 'News Category') {
      this.dialogRef = this.dialog.open(NewsCategoryComponent, {
        width: '40%',
        data: { type: value, name: typename, guid: null }
      });
    } 
    else if (typename === 'News') {
      this.dialogRef = this.dialog.open(AddNewsComponent, {
        width: '97%',
        id : 'add-news',
        data: { type: value, name: typename, guid: null, readOnly: false }
      });
    } 
    else if (typename === 'CPP Agent') {
      this.dialogRef = this.dialog.open(AddCppAgentComponent, {
        width: '60%',
        id : 'add-cpp-agent',
        data: { type: value, name: typename, guid: null, readOnly: false }
      });
    } 
    else if (typename === 'Create User') {
      this.dialogRef = this.dialog.open(AddUserComponent, {
        width: '40%',
        id : 'add-user',
        data: { type: value, name: typename, guid: null }
      });
    }
    else if (typename === 'Create Category') {
      this.dialogRef = this.dialog.open(AddCategoryComponent, {
        width: '40%',
        height: '440px',
        data: { type: value, name: typename, guid: null }
      });
    }
    else if (typename === 'Create SubCategory') {
      this.dialogRef = this.dialog.open(AddSubCategoryComponent, {
        width: '40%',
        data: { type: value, name: typename, guid: null }
      });
    }

    else if (typename === 'Create Conveyancer') {
      this.dialogRef = this.dialog.open(AddConveyancerComponent, {
        width: '40%',
        id : 'add-conveyancer',
        data: { type: value, name: typename, guid: null }
      });
    }
    
  }

  onSave(data: any) {
    this.form = data;
    this.dialogRef.componentInstance.isbtnLoading = true;
    this.imageList = this.dialogRef.componentInstance.imageList;
    this.isExists = false;
    this.getisExists().subscribe((res: any) => {
      const result: any = res;
      if (res !== null) {
        this.isExists = result.data.isExist;
        if (this.isExists === false) {
          const formData = new FormData();
          formData.append('Description', this.f.imageName.value);
          formData.append('isActive', this.f.isActive.value);

          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.imageList.length; i++) {
            formData.append('logo', this.imageList[i].imageFile);
          }

          this.save(formData);
        } else {
          this.dialogRef.componentInstance.isbtnLoading = false;
          this.helperService.error('already name exists! ');
        }
      } else {
        this.dialogRef.componentInstance.isbtnLoading = false;
        this.helperService.error(result.message);
      }
    },
      (error: any) => {
        this.dialogRef.componentInstance.isbtnLoading = false;
        // this.helperService.error(error);
        const err = error.message !== undefined ? error.message : error;
        if (err === 'Session Timed Out') {
          this.helperService.info(err);
        } else {
          this.helperService.error(err);
        }
      });
  }

  getisExists(): Observable<any> | any {
    if (this._typename === 'Car Owner') {
    return this.leasetypeService
    .getIsExistsCarOwner(this.f.guid.value, this.form.controls.imageName.value);
    } else if (this._typename === 'Car Manager') {
    return this.leasetypeService
    .getIsExistsManager(this.f.guid.value, this.form.controls.imageName.value);
    } else if (this._typename === 'Property Manager') {
    return this.propertyManagerService
    .getIsExists(this.f.guid.value, this.form.controls.imageName.value);
    }
}


onSaveAmenities(data: any) {
  this.form = data;
  this.dialogRef.componentInstance.isbtnLoading = true;
  this.imageList = this.dialogRef.componentInstance.imageList;
  this.isExists = false;
  // this.getisExistsAmenities().subscribe((res: any) => {
  //   const result: any = res;
  //   if (res !== null) {
  //     this.isExists = result.data.isExist;
  //     if (this.isExists === false) {
        const formData = new FormData();
        formData.append('Amenity', this.f.imageName.value);
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.imageList.length; i++) {
          formData.append('IconImage', this.imageList[i].imageFile);
        }

        this.saveAmenities(formData);
      // } 
      // else {
      //   this.dialogRef.componentInstance.isbtnLoading = false;
      //   this.helperService.error('already name exists! ');
      // }
  //   } else {
  //     this.dialogRef.componentInstance.isbtnLoading = false;
  //     this.helperService.error(result.message);
  //   }
  // },
  //   (error: any) => {
  //     this.dialogRef.componentInstance.isbtnLoading = false;
  //     // this.helperService.error(error);
  //     const err = error.message !== undefined ? error.message : error;
  //     if (err === 'Session Timed Out') {
  //       this.helperService.info(err);
  //     } else {
  //       this.helperService.error(err);
  //     }
  //   });
}

getisExistsAmenities(): Observable<any> | any {
  if (this._typename === 'Property Amenities') {
  return this.leasetypeService
  .getIsExists(this.f.guid.value, this.form.controls.imageName.value);
  } 
}

    get f() {
      return this.form.controls;
    }


    save(data: any) {
        this.getAddEvent(data).subscribe(
          //map(
          (res:any) => {
            this.dialogRef.componentInstance.isbtnLoading = false;
            const result: any = res;
            if (result.data != null) {
              this.helperService.success(
                this._typename + ' ' + StaticSuccessMessage.save
              );
              // if (this._typename === 'Property Lease Type') {
              //   this.leasetypeService.changeMessage(true);
              // } else if (this._typename === 'Property Developer') {
              //   this.propertyDeveloperService.changeMessage(true);
              // } else if (this._typename === 'Property Manager') {
              //   this.propertyManagerService.changeMessage(true);
              // }
              this.form.reset();
              this.imageList = [];
              this.dialogRef.componentInstance.showImg = false;
              this.dialogRef.componentInstance.form.reset();
              this.dialogRef.componentInstance.imageList = [];
              this.dialogRef.componentInstance.imageSrc = [];
              this.dialogRef.componentInstance.isFileSelected = false;
              this.dialogRef.close();
            } else {
              this.dialogRef.componentInstance.isbtnLoading = false;
              this.helperService.error(result.message);
            }
          },
          (error: any) => {
            this.dialogRef.componentInstance.isbtnLoading = false;
            const err = error.message !== undefined ? error.message : error;
            if (err === 'Session Timed Out') {
              this.helperService.info(err);
            } else {
              this.helperService.error(err);
            }
          }
        //)
        );
  }

  saveAmenities(data: any) {
    this.getAddEvent(data).subscribe(
      //map(
      (res:any) => {
        this.dialogRef.componentInstance.isbtnLoading = false;
        const result: any = res;
        if (result.data != null) {
          this.helperService.success(
            this._typename + ' ' + StaticSuccessMessage.save
          );
          if (this._typename === 'Property Amenities') {
            this.amenityService.changeMessage(true);
          } 
          this.form.reset();
          this.imageList = [];
          this.dialogRef.componentInstance.showImg = false;
          this.dialogRef.componentInstance.form.reset();
          this.dialogRef.componentInstance.imageList = [];
          this.dialogRef.componentInstance.imageSrc = [];
          this.dialogRef.componentInstance.isFileSelected = false;
          this.dialogRef.close();
          this.commonService.goto('property/amenities');
        } else {
          this.dialogRef.componentInstance.isbtnLoading = false;
          this.helperService.error(result.message);
        }
      },
      (error: any) => {
        this.dialogRef.componentInstance.isbtnLoading = false;
        // this.helperService.error(error);
        const err = error.message !== undefined ? error.message : error;
        if (err === 'Session Timed Out') {
          this.helperService.info(err);
        } else {
          this.helperService.error(err);
        }
      }
    //)
    );
}

  getAddEvent(data: any): Observable<any> | any {
    if (this._typename === 'Car Owner') {
    return this.amenityService.addCarOwner(data);
    } else if (this._typename === 'Car Manager') {
      return this.amenityService.addManager(data);
      } else if (this._typename === 'Property Developer') {
      return this.propertyDeveloperService.addPropertyDeveloper(data);
      } else if (this._typename === 'Property Manager') {
      return this.propertyManagerService.addPropertyManager(data);
      }
}


getUserRolePermissions(name: any, create: any) {
  
  this._roleName = this.userRolesService.getRoleName();
  if(name === "conveyancer"){
    
    if (this._roleName === 'SUPER ADMIN') {
      let status = this.userRolesService.getRoleAccessByActionType(
        name,
        create
      );
      return status;
    } else {
      return false;
    }
  } else {

  
  let status = this.userRolesService.getRoleAccessByActionType(
    name,
    create
  );
  return status;
}

}

getUserRole(){
  this._roleName = this.userRolesService.getRoleName();
  this.userName = this.userRolesService.getUserName();
  if(this._roleName === PageConfigs.UserRoles.financialAdmin) {
    return false;
  } else if(this._roleName === PageConfigs.UserRoles.conveyancer) {
    return false;
  } else {
    return true;
  }
}

}
