import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, Inject, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AgentService } from "src/app/core/services/agent.service";
import { debounceTime } from "rxjs/operators";
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { ConveyancerService } from '../../../core/services/conveyancer.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-update-conveyancer-mapping',
  templateUrl: './update-conveyancer-mapping.component.html',
  styleUrls: ['./update-conveyancer-mapping.component.scss']
})
export class UpdateConveyancerMappingComponent implements OnInit, AfterViewInit {
  userMappingForm: FormGroup | any;
  userList: any = [];
  conveyancerList: any = [];
  accountList: any = [];
  AllConveyancerList: any;
  AllUserList: any;
  AllAccountList: any;
  isLoading: boolean = false;
  basicInfo: any;

  constructor(
    private agentService: AgentService,
    private conveyancerService: ConveyancerService,
    private commonService: CommonService,
    private helper : HelperService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) {
    this.userMappingForm = new FormGroup({
      selectedUsers: new FormControl("", [Validators.required]),
      conveyancerName: new FormControl("", [Validators.required]),
      selectedAccounts: new FormControl("", [Validators.required]),
      searchConveyancer: new FormControl(""),
      searchUser: new FormControl(""),
      searchAccount: new FormControl("")
    });
    this.getConveyancerList();
    this.getUsersList();
    
  }

  ngOnInit() {
    this.searchConveyancer();
    this.searchUser();
    this.searchAccount();
    //this.onChanges();
    // this.userMappingForm.patchValue({
    //   selectedUsers: this.basicInfo.userGUID,
    //   conveyancerName: this.basicInfo.conveyancerGUID,
    // });
    

    
  }

  ngAfterViewInit(): void {
    
    this.userMappingForm.patchValue({
      selectedUsers: this.basicInfo.userGUID,
      conveyancerName: this.data.id,
    });

this.conveyancerService.getMappingAccountList(this.basicInfo.userGUID).subscribe(
  (res: any) => {
    if(res.data.account !== undefined){
      this.userMappingForm.get("selectedAccounts").patchValue("");
    this.AllAccountList = res.data.account;
    this.accountList = res.data.account;

    this.userMappingForm.patchValue({
      conveyancerName: this.data.id,
      selectedUsers: this.basicInfo.userGUID,
      selectedAccounts: this.basicInfo.accountGUID
    });
    
  }
  },
  (error) => {
    // this.helper.error(error);
    const err = error.message !== undefined ? error.message : error;
    if (err === 'Session Timed Out') {
      this.helper.info(err);
    } else {
      this.helper.error(err);
    }
  }
);

this.changeDetectorRef.detectChanges();
  }

  onClose() {
    this.dialogRef.close(this.data.id);
    // this.dialog.closeAll();
  }


  getConveyancerList() {
    this.conveyancerService.getAllConveyancerList().subscribe(
      (res: any) => {
        if(res.data.conveyancerList != undefined && res.data.conveyancerList != null) {
        this.AllConveyancerList = res.data.conveyancerList;
        this.conveyancerList = res.data.conveyancerList;
      }
      },
      (error) => {
        // this.helper.error(error);
        const err = error.message !== undefined ? error.message : error;
        if (err === 'Session Timed Out') {
          this.helper.info(err);
        } else {
          this.helper.error(err);
        }
      }
    );
  }

  getUsersList() {
    this.agentService.getMappingUsersList().subscribe(
      (res: any) => {
        if(res.data.user != undefined && res.data.user != null) {
        this.AllUserList = res.data.user;
        this.userList = res.data.user;

        this.userMappingForm.patchValue({
          conveyancerName: this.data.id,
          selectedUsers: this.basicInfo.userGUID,
        });

      }
      },
      (error) => {
        // this.helper.error(error);
        const err = error.message !== undefined ? error.message : error;
        if (err === 'Session Timed Out') {
          this.helper.info(err);
        } else {
          this.helper.error(err);
        }
      }
    );
  }

  getAccountList() {
    this.agentService.getMappingAccountList(this.userMappingForm.get("selectedUsers").value).subscribe(
      (res: any) => {
        this.AllAccountList = res.data.account;
        this.accountList = res.data.account;
      },
      (error) => {
        // this.helper.error(error);
        const err = error.message !== undefined ? error.message : error;
        if (err === 'Session Timed Out') {
          this.helper.info(err);
        } else {
          this.helper.error(err);
        }
      }
    );
  }

  onChanges(): void {
    
  }

  searchConveyancer() {
    this.userMappingForm
      .get("searchConveyancer")
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value: any) => {
        let searchKey = this.userMappingForm.get("searchConveyancer").value;
        let conveyancerList = [];
        if (searchKey === "") {
          conveyancerList = this.AllConveyancerList;
        } else {
          conveyancerList = this.AllConveyancerList.filter((conveyancer: any) =>
          conveyancer.email.includes(searchKey)
          );
        }
        this.conveyancerList = conveyancerList;
      });
  }

  searchUser() {
    
    this.userMappingForm
      .get("searchUser")
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value: any) => {
        
        let searchKey = this.userMappingForm.get("searchUser").value;
        let userList = [];
        if (searchKey === "") {
          userList = this.AllUserList;
        } else {
          userList = this.AllUserList.filter((user: any) =>
            user.email.includes(searchKey)
          );
        }
        this.userList = userList;
      });
  }

  clearSearch(event: any, inputName: any) {
    event.stopPropagation();
    this.userMappingForm.get(inputName).patchValue("");
  }

  onSubmit() {
    this.isLoading = true;
    let data = {
      ConveyancerMappingGUID: this.basicInfo.conveyancerMappingGUID,
      UserGUID: this.userMappingForm.get("conveyancerName").value,
      AccountGUID: this.userMappingForm.get("selectedAccounts").value
    };
    this.conveyancerService.updateConveyancerMapping(data).subscribe(
      (res: any) => {
        this.helper.success(res.data.message);
        this.reset();
        this.isLoading = false;
      },
      (error) => {
        // this.helper.error(error);
        const err = error.message !== undefined ? error.message : error;
        if (err === 'Session Timed Out') {
          this.helper.info(err);
        } else {
          this.helper.error(err);
        }
        this.isLoading = false;
      }
    );
  }

  reset() {
    this.userMappingForm.get("selectedUsers").patchValue("");
    this.userMappingForm.get("conveyancerName").patchValue("");
    this.userMappingForm.get("selectedAccounts").patchValue("");
    this.accountList = [];
  }

  searchAccount(){
    this.userMappingForm
      .get("searchAccount")
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value: any) => {
        let searchKey = this.userMappingForm.get("searchAccount").value;
        let accountList = [];
        if (searchKey === "") {
          accountList = this.AllAccountList;
        } else {
          accountList = this.AllAccountList.filter((account: any) =>
            account.accountName.includes(searchKey)
          );
        }
        this.accountList = accountList;
      });
  }

  selectedUser(){
    this.userMappingForm.get('selectedUsers').valueChanges.subscribe((val: any) => {
      this.agentService.getMappingAccountList(`${val}`).subscribe(
        (res: any) => {
          if(res.data.account !== undefined){
          this.AllAccountList = res.data.account;
          this.accountList = res.data.account;
        }
        },
        (error: any) => {
          // this.helper.error(error);
          const err = error.message !== undefined ? error.message : error;
          if (err === 'Session Timed Out') {
            this.helper.info(err);
          } else {
            this.helper.error(err);
          }
          
        }
      );
    });
  }

   onSelectedUser(event: any) {
    this.conveyancerService.getMappingAccountList(event.value).subscribe(
      (res: any) => {
        if(res.data.account !== undefined){
          this.userMappingForm.get("selectedAccounts").patchValue("");
        this.AllAccountList = res.data.account;
        this.accountList = res.data.account;
      }
      },
      (error) => {
        // this.helper.error(error);
        const err = error.message !== undefined ? error.message : error;
        if (err === 'Session Timed Out') {
          this.helper.info(err);
        } else {
          this.helper.error(err);
        }
      }
    );
  }
  openedChange(opened: boolean, inputName: any) {
    if(!opened) {
      this.userMappingForm.get(inputName).patchValue("");
    }
    // console.log(opened ? 'opened' : 'closed');
}

}
