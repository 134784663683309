import { Injectable } from '@angular/core';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BPPBuySellService {
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(
    private apiManagerService: ApiManagerService
  ) { }



  getSellerPermission(data: any) {
    return this.apiManagerService.postRequest(
        RestEnds.getSellerPermission,
        data
      );
  }

  createSellerPermission(data: any) {
    return this.apiManagerService.postRequest(
        RestEnds.createSellerPermission,
        data
      );
  }

  updateSellerPermission(data: any) {
    return this.apiManagerService.putRequest(
        RestEnds.updateSellerPermission,
        data
      );
  }

  deleteSellerPermission(guid: any) {
    return this.apiManagerService.deleteRequest(
        RestEnds.deleteSellerPermission + "?permissionGUID=" + guid
      );
  }


  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }

}
