import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { trigger, transition, animate, style } from '@angular/animations';
import { CommunityService } from 'src/app/core/services/community.service';
import { ThemePalette } from '@angular/material/core';
import { ValidationService } from '../../../core/services/validation.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ValidateVideoUrl } from '../../validators/videourl.validator';

@Component({
  selector: 'app-add-video',
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(1500, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class AddVideoComponent implements OnInit, AfterViewInit, OnDestroy {
  type: any;
  form!: FormGroup;
  color: ThemePalette = 'primary';

  videoForm: FormGroup;

  GUID = null;
  categoryList: any;
  stateList: any;
  isbtnLoading = false;
  readonly = false;
  previewURL = null;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private communityService: CommunityService,
    private dialog: MatDialog,
    private helper: HelperService,
    private domSanitizer: DomSanitizer
  ) {
    this.dialogRef.disableClose = true;
    
    this.videoForm = this.formBuilder.group({
      category: ['', [Validators.required]],
      title: ['', [
        Validators.required,
        ValidationService.titleMaxLength
      ]],
      description: ['', [
        Validators.required,
        ValidationService.descriptionMaxLength
      ]],
      videoURL: ['', [
        Validators.required,
        ValidateVideoUrl
      ]],
      state: [null],
      WrittenBy: ['', [
        Validators.required,
        Validators.maxLength(60)
      ]]
    });
    this.getCategory();
    this.getStateList();
    // this.videoForm.reset();
  }

  ngOnInit() {
    this.videoForm.patchValue({
      state: '',
      WrittenBy: 'Shares'
    });
  }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.getCategory();
    this.getStateList();
    this.GUID = this.data.guid;
    this.readonly = this.data.readOnly;
    if (this.GUID !== null) {
      this.getVideoByGUID(this.GUID);
    }
    if (this.readonly === true) {
      this.f.title.disable();
      this.f.category.disable();
      this.f.state.disable();
      this.f.description.disable();
      this.f.videoURL.disable();
      this.f.WrittenBy.disable();
    } else {
      this.f.title.enable();
      this.f.category.enable();
      this.f.state.enable();
      this.f.description.enable();
      this.f.videoURL.enable();
      this.f.WrittenBy.enable();
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }
  onClose() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  onSubmit() {
    this.isbtnLoading = true;
    let data: any = {
      SubCategoryGUID: this.videoForm.controls.category.value,
      StateGUID:
        this.videoForm.controls.state.value !== null
          ? this.videoForm.controls.state.value
          : '',
      VideoURL: this.videoForm.controls.videoURL.value,
      Title: this.videoForm.controls.title.value,
      Description: this.videoForm.controls.description.value,
      WrittenBy: this.videoForm.controls.WrittenBy.value
    };
    if (this.GUID != null) {
      data['VideoGUID'] = this.GUID;
    }
    if (this.GUID != null) {
      this.onEditfeed(data);
    } else {
      this.onSavefeed(data);
    }
  }

  onSavefeed(formData: any) {
    this.communityService.addVideo(formData).subscribe(
      (res: any) => {
        this.isbtnLoading = false;
        const result: any = res;
        if (result.data != null) {
          this.helper.success(result.data.message);
          // this.videoForm.reset();
          // this.videoForm.patchValue({
          //   state: '',
          //   category: ''
          // });
          // this.previewURL = null;
          this.communityService.changeMessage(true);
          this.onClose();
        } else {
          this.helper.error(result.data.message);
        }
      },
      (error: any) => {
        this.isbtnLoading = false;
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  onEditfeed(formData: any) {
    this.communityService.updateVideo(formData).subscribe(
      (res: any) => {
        this.isbtnLoading = false;
        const result: any = res;
        if (result.data != null) {
          this.helper.success(result.data.message);
          this.communityService.changeMessage(true);
          this.onClose();
        } else {
          this.helper.error(result.data.message);
        }
      },
      (error: any) => {
        this.isbtnLoading = false;
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  getVideoByGUID(guid: any) {
    this.communityService.getVideoByGuid(guid).subscribe(
      (res: any) => {
        const result: any = res;
        if (result.data != null) {
          const data = result.data;
          const videoDetail = data.video;
          this.GUID = videoDetail.videoGUID;
          this.previewURL = null;
          this.videoForm.patchValue({
            category: videoDetail.subCategoryGUID,
            state: videoDetail.stateGUID === null ? '' : videoDetail.stateGUID,
            title: videoDetail.title,
            description: videoDetail.description,
            videoURL: videoDetail.videoURL,
            WrittenBy:
              videoDetail.writtenBy === null
                ? 'Shares'
                : videoDetail.writtenBy
          });
          this.updateVideoUrl(videoDetail.videoURL);
        } else {
          this.previewURL = null;
          this.helper.error(result.data.message);
        }
      },
      (error: any) => {
        // this.helper.error(error);
        const errSt = error.message !== undefined ? error.message : error;
        if (errSt === 'Session Timed Out') {
          this.helper.info(errSt);
        } else {
          this.helper.error(errSt);
        }
      }
    );
  }

  getVideoByCategory(guid: any) {
    this.communityService.getVideoCategoryList(guid).subscribe(
      (res: any) => {
        const result: any = res;
        if (result.data != null) {
          this.categoryList = result.data.subCategories;
        }
      },
      (error: any) => {}
    );
  }

  getCategory() {
    this.communityService.getCategory().subscribe(
      (res: any) => {
        const result: any = res;
        if (result.data != null) {
          let guid = result.data.categories.find((x: any) => x.name == 'Video')
            .categoryGUID;
          this.getVideoByCategory(guid);
        }
      },
      (error: any) => {}
    );
  }

  getStateList() {
    this.communityService.getStateList().subscribe(
      (res: any) => {
        const result: any = res;
        if (result.data != null) {
          this.stateList = result.data.states;
        }
      },
      (error: any) => {}
    );
  }

  get f() {
    return this.videoForm.controls;
  }

  updateVideoUrl(url: string) {

    if (this.videoForm.get('videoURL')?.valid) {
      if (url !== null && url !== "") {
        const alterUrl = url + '?autoplay=0&controls=1&modestbranding=1&rel=0';
        const videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
          alterUrl
        );

        this.previewURL = videoUrl;
      } else {
        this.previewURL = null;
      }
    } else {
      this.previewURL = null;
    }
  }

  // onInput(content: string) {
  //   console.log('New content: ', content);
  // }

  hasError(fieldName: string) {
    return (
      this.videoForm.controls[fieldName].touched &&
      this.videoForm.controls[fieldName].status === 'INVALID'
    );
  }

  hasFieldError(fieldName: string, type: string) {
    const fieldControl = this.videoForm.controls[fieldName]
      .errors as any;
    return fieldControl[type];
  }
}

export interface ImageFiles {
  imageType: string;
  imageFile: any;
}
