import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NewsService } from '../../../core/services/news.service';
import { trigger, transition, animate, style } from '@angular/animations';
import { UploadImageService } from '../../../core/services/upload-image.service';
import * as $ from 'jquery';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { imageUploadConfig } from '../../../core/interfaces/image-upload.interface';
import { StaticMessage, CommunityUploadImageOptions } from '../../../core/enum/constants';
import { ValidationService } from 'src/app/core/services/validation.service';
import { CommunityService } from 'src/app/core/services/community.service';
import Hls from 'hls.js';

@Component({
  selector: 'app-add-podcast',
  templateUrl: './add-podcast.component.html',
  styleUrls: ['./add-podcast.component.scss'],
  animations: [trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(1500, style({opacity: 1}))
    ])
  ])]
})
export class AddPodcastComponent implements OnInit, AfterViewInit, OnDestroy {
  type: any;
  showImg = false;
  isFileSelected = false;
  imageSrc: any = [];
  imageList: ImageFiles[] = [];
  fileList: any;
  fileType: any;
  form!: FormGroup;
  checked = false;
  disabled = false;
  hlsUrl:any;
  color: ThemePalette = 'primary';
  podcastForm = this.formBuilder.group({
    id: new FormControl(''),
    category: new FormControl('', [Validators.required]),
    title: new FormControl('', [Validators.required,ValidationService.titleMaxLength]),
    description: new FormControl('', [Validators.required,ValidationService.descriptionMaxLength]),
    thumnailImage: new FormControl(null,[Validators.required]),
    state:new FormControl(null),
    bannerBlobURL: new FormControl(null),
    isBanner : new FormControl(false, [Validators.required]),
    mediaFile : new FormControl(null,[Validators.required]),
    mediaStreamURL: new FormControl(null),
    WrittenBy: new FormControl('', [Validators.required, Validators.maxLength(60)]),
  });
  quillEditorRef: any;
  maxUploadFileSize = 1000000;
  // @ViewChild('article', { static: false}) feedDate: ElementRef;
  feedDate: any;
  @ViewChild('errorLog', {static: false}) errorLog!: ElementRef;
  @ViewChild('content', {static: false}) content!: ElementRef;
  GUID: any = null;
  categoryList: any;
  stateList: any;
  isbtnLoading = false;
  readonly = false;

  selPodcast = null;
  hls = new Hls();

  viewfile = false;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private communityService: CommunityService,
    private dialog: MatDialog,
    private uploadImageService: UploadImageService,
    private helper: HelperService
  ) {
    this.dialogRef.disableClose = true;
   }

   ngOnInit() {
    this.podcastForm.reset();
    this.getCategoryList();
    this.getStateList();
    this.podcastForm.patchValue({
      state: '',
      WrittenBy: 'Shares',
      isBanner: false
    });
   }

  ngAfterViewInit() {
    this.type = this.data.type;
    this.getCategoryList();
    this.getStateList();
    this.GUID = this.data.guid;
    // this.GUID = '8b1c5ad6-5c40-454d-9711-bf9253b8ef3f';
    this.readonly = this.data.readOnly;
    if (this.GUID !== null) {
      this.getPodcastByGUID(this.GUID);
    }
    if(this.readonly === true) {
      this.f.title.disable();
      this.f.category.disable();
      this.f.state.disable();
      this.f.description.disable();
      this.f.thumnailImage.disable();
      this.f.mediaFile.disable();
      this.f.WrittenBy.disable();
      this.viewfile = true;
    } else {
      this.f.title.enable();
      this.f.category.enable();
      this.f.state.enable();
      this.f.description.enable();
      this.f.thumnailImage.enable();
      this.f.mediaFile.enable();
      this.f.WrittenBy.enable();
      this.viewfile = false;
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }
  onClose() {
    this.podcastForm.reset();
    this.dialogRef.close();
    this.dialog.closeAll();
  }

  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
  }

  imageHandler = (image: any, callback: any) => {
    const input: any = document.getElementById('fileInputField') as HTMLInputElement;
    let field: any = document.getElementById('fileInputField');
    field.onchange = () => {
      let file: File;
      file = input.files[0];
      // file type is only image.
      if (/^image\//.test(file.type)) {
        if (file.size > this.maxUploadFileSize) {
           this.errorLog.nativeElement.innerHTML = 'Image needs to be less than 1MB';
           input.value = null;

        } else {
          this.errorLog.nativeElement.innerHTML = '';
          const reader  = new FileReader();
          reader.onload = () =>  {
            const range = this.quillEditorRef.getSelection();
            const img = '<img src="' + reader.result + '" />';
            this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
            const content = this.quillEditorRef.root.innerHTML;
            this.podcastForm.patchValue({
              content: content
            });
          };
          reader.readAsDataURL(file);
        }
      } else {
        input.value = null;
        this.errorLog.nativeElement.innerHTML = 'You could only upload images.';
      }

    };
    input.click();
  }


  public submit(e: any) {
    // console.log('files:', e.dataTransfer.files);
    // this.uploadImage(e);
    this.uploadImage_new(e);
  }

  handleInputChange(e: any) {
    // this.uploadImage(e);
    this.uploadImage_new(e);
  }

  uploadImage_new(e: any): any {
    this.fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    const reader = new FileReader();
    const filelist = [];
    const config: imageUploadConfig = {
      dimension: {
        width: CommunityUploadImageOptions.width,
        height: CommunityUploadImageOptions.height,
        operation: CommunityUploadImageOptions.operation
      },
      size: CommunityUploadImageOptions.size,
      fileExt: CommunityUploadImageOptions.exts,
      fileType: null
    };
    if (this.fileList.length > 1) {
      this.helper.warning('Please select one image');
      return false;
    }

    if (this.fileList.length > 0) {

      for (const item of this.fileList) {
        filelist.push(item);
        this.showImg = false;
        this.imageSrc = [];
        this.imageList = []; // Single image upload
        this.isFileSelected = false;


        this.uploadImageService.uploadImage(item, config, reader, (result: any) => {
          if (result.status) {
            this.imageList.push({
              imageType: result.type,
              imageFile: result.imageFile
            });
            this.podcastForm.patchValue({
              thumnailImage: this.fileList
            });
            this.imageSrc.push(result.imagePreview);
            this.isFileSelected = true;
          } else {
            this.helper.warning(result.msg);
            this.podcastForm.patchValue({
              thumnailImage: ''
            });
          }
        });
      }
      this.cdr.markForCheck();
    }
  }

  uploadImage(e: any): any {
    this.fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    this.fileType = null;
    const reader = new FileReader();
    const filelist = [];

    if (this.fileList.length > 0) {
      for (const item of this.fileList) {
        this.fileType = item.type;
        const fileSize = item.size;
        filelist.push(item);
        if (
          this.fileType !== 'image/png' &&
          this.fileType !== 'image/jpg' &&
          this.fileType !== 'image/jpeg'
        ) {
          this.helper.error(StaticMessage.ImageFormat);
          e.target.value = null;
          return false;
        }
        if (fileSize > 1048576) {
          this.helper.error(
            'Please upload image size not more than 1 MB'
          );
          e.target.value = null;
          return false;
        }

        this.showImg = false;
        this.imageSrc = [];
        this.imageList = []; // Single image upload
        this.isFileSelected = false;

        this.imageList.push({
          imageType: this.findAndReplace(this.fileType, 'image/', '.'),
          imageFile: item
        });

        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(item);
      }
    }
  }

  _handleReaderLoaded(e: any) {
    const reader = e.target;
    this.imageSrc.push(reader.result);
    this.isFileSelected = true;
  }

  findAndReplace(string: any, target: any, replacement: any) {
    return (string = string.replace(target, replacement));
  }

  onSubmit() {
    this.isbtnLoading = true;
    const formData = new FormData();
    if (this.GUID != null) {
      formData.append('PodCastGUID', this.GUID);
    }   
    formData.append('Title', this.podcastForm.controls.title.value);
    formData.append('Description', this.podcastForm.controls.description.value);
    formData.append('PodCastCategoryGUID', this.podcastForm.controls.category.value);
    formData.append('StateGUID', this.podcastForm.controls.state.value !== null ? this.podcastForm.controls.state.value : "");
    formData.append('IsBanner', this.podcastForm.controls.isBanner.value);
    formData.append('WrittenBy', this.podcastForm.controls.WrittenBy.value);
    if (this.imageList.length > 0) {
      for (let i = 0; i < this.imageList.length; i++) {
        formData.append('ThumbnailImage', this.imageList[i].imageFile);
      }
    } else {
      formData.append('ThumbnailURL', this.podcastForm.controls.bannerBlobURL.value);
    }

    if(this.audioFile){
      formData.append('MediaFile', this.audioFile);
    }
    else{
      formData.append('StreamingHlsURL', this.podcastForm.controls.mediaStreamURL.value);
    }

    if(this.GUID != null) {
      this.updatePodcast(formData);
    } else {
      this.onSavePodcast(formData);
    }

  }

  onSavePodcast(formData: any) {
    this.communityService
      .addPodcast(formData)
      .subscribe(
        (res: any) => {
          this.isbtnLoading = false;
          const result: any = res;
            this.helper.success(result.data.message);
            this.podcastForm.reset();
            this.podcastForm.patchValue({
              category: '',
              state: '',
            });
            this.communityService.changeMessage(true);
            this.onClose();
        },
        (error: any) => {
          this.isbtnLoading = false;
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );
  }

  updatePodcast(formData: any) {
    this.communityService
      .updatePodcast(formData)
      .subscribe(
        (res: any) => {
          this.isbtnLoading = false;
          const result: any = res;
          if (result.data != null) {
            this.helper.success(result.data.message);
            this.onClose();
            this.communityService.changeMessage(true);
          } else {
            this.helper.error(result.data.message);
          }
        },
        (error: any) => {
          this.isbtnLoading = false;
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );
  }

  getCategoryList() {
    this.communityService.getPodcastCategories()
       .subscribe(
         (res: any) => {
           const result: any = res;
           if (result.data != null) {
             this.categoryList = result.data.podCastCategories;
           }
         },
         (error: any) => {
         }
       );
   }

   getStateList() {
    this.communityService.getStateList()
       .subscribe(
         (res: any) => {
           const result: any = res;
           if (result.data != null) {
             this.stateList = result.data.states;
           }
         },
         (error: any) => {
         }
       );
   }
   
   mediaUrl = "https://ampdemo.azureedge.net/azuremediaplayer.html?url="

   setValidators(){
      this.podcastForm.get('mediaFile')?.clearValidators();
      this.podcastForm.get('mediaFile')?.updateValueAndValidity();
   }

   getPodcastByGUID(guid: any) {
    this.communityService.getPodcastByGuid(guid).subscribe(
        (res: any) => {
          const result: any = res;
          if (result.data != null) {
            this.audioFile="";
            const data = result.data;
            const podCastDetail = data.podCast;
            this.GUID = podCastDetail.podCastGUID;
            this.imageSrc.push(podCastDetail.thumbnailURL);
            this.isFileSelected = true;
            this.hlsUrl = podCastDetail.streamingHlsURL;
            // (<any>document.getElementById("hls")).href = this.mediaUrl + podCastDetail.streamingHlsURL;
            this.setValidators();
            this.podcastForm.patchValue({
              category: podCastDetail.podCastCategoryGUID,
              state: podCastDetail.stateGUID === null ? '' : podCastDetail.stateGUID,
              title: podCastDetail.title,
              description: podCastDetail.description,
              thumnailImage: podCastDetail.thumbnailURL,
              bannerBlobURL: podCastDetail.thumbnailURL,
              isBanner: podCastDetail.isBanner,
              mediaStreamURL: podCastDetail.streamingHlsURL,
              WrittenBy: podCastDetail.writtenBy === null ? "Shares" : podCastDetail.writtenBy
              });


              // const audioContainer = document.getElementById('video-container') as HTMLDivElement;
              let audioSrc = podCastDetail.streamingHlsURL;
              // audioContainer.style.backgroundImage = 'url(' + podCastDetail.thumbnailURL + ')';
              const audio = document.getElementById('edit-audio') as HTMLAudioElement;
              if (audio.canPlayType('application/vnd.apple.mpegurl')) {
        
              } else if (Hls.isSupported()) {
        
                this.hls.loadSource(audioSrc);
                this.hls.attachMedia(audio);
        
              }
            
          } else {
            this.helper.error(result.data.message);
          }
        },
        (error: any) => {
          // this.helper.error(error);
          const errSt = error.message !== undefined ? error.message : error;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );
  }

audioFile:any;
audioFomratvalidation : boolean = false;

 handleFiles(event:any) {
  this.hlsUrl="";
  var files = event.target.files;
  if(files[0].name.includes('.mp3')){
    this.audioFile = files[0];
    this.hls.detachMedia();
    $("#src").attr("src", URL.createObjectURL(files[0]));

    let audioPlayer: any = document.getElementById("audio")
    audioPlayer.load();
    

  }
    else{
      (<HTMLInputElement>document.getElementById('upload')).value ='';
      this.audioFomratvalidation= true;
      this.hls.detachMedia();
    }
}

  get f() {
    return this.podcastForm.controls;
  }

}

export interface ImageFiles {
  imageType: string;
  imageFile: any;
}

