<mat-card *ngIf="config!==null && config.type=='text-only'">
    <h2>{{config.title}}</h2>
    <div class="bootstrap-wrapper">
        <div class="row adjust-card-margin">
            <div *ngFor="let c of config.data" [ngClass]="c.text !== 'Additional Information' ? 'key-value col-'+config.column : 'key-value col-12'">
                <span class="key">{{c.text}}</span>
                <span class="value">{{c.value}}</span>
            </div>
        </div>
    </div>
</mat-card>

<mat-card *ngIf="config!==null && config.type=='doc-data-only'">
    <h2>{{config.title}}</h2>
    <div class="bootstrap-wrapper">
        <div *ngFor="let d of config.data" class="row doc-img">
            <div class="col-12 mb10">
                <span class="roboto-medium f12 mr10">Document</span>
                <span class="roboto-regular f12 slate-grey">{{d.name}}</span>
            </div>
            <div class="col-4">
                <div class="row">
                    <div *ngFor="let img of d.images" class="col-12">
                        <app-proof-img [img]="img" *ngIf="getExt(img) !=='pdf'" alt="" width="100%"  ></app-proof-img>

                        <div class="col-12 bg-pdf" *ngIf="getExt(img) ==='pdf'">
                                <app-proof-document [pdf]="img"></app-proof-document>
                        
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-8">
                <div class="row adjust-card-margin">
                    <div *ngFor="let c of d.data" class="col-6 key-value">
                        <span class="key">{{c.text}}</span>
                        <span class="value">{{c.value}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>

<mat-card *ngIf="config!==null && config.type=='doc-only'">
    <h2>{{config.title}}</h2>
    <div class="bootstrap-wrapper">
        <div *ngFor="let d of config.data" class="row doc-img">
            <div class="col-12 mb10">
                <span class="roboto-medium f12 mr10">Document</span>
                <span class="roboto-regular f12 slate-grey">{{d.name}}</span>
            </div>
            <div class="col-6">
                <div class="row">
                    <div *ngFor="let img of d?.images" class="col-12">
                            <app-proof-img [img]="img" *ngIf="getExt(img) !=='pdf'" alt="" width="100%"></app-proof-img>
                        <div class="col-10 bg-pdf" *ngIf="getExt(img)==='pdf'">
                            <app-proof-document [pdf]="img"></app-proof-document>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>

<mat-card *ngIf="config!==null && config.type=='doc-data-text-only'">
    <h2>{{config.title}}</h2>
    <div class="bootstrap-wrapper">
        <div class="row adjust-card-margin">
            <div *ngFor="let c of config.data" [ngClass]="'key-value col-'+config.column">
                <span class="key">{{c.text}}</span>
                <span class="value" *ngIf="c.value !== null">{{c.value}}</span>
                <div class="col-6 p0" *ngIf="c.img !== null">
                    <app-proof-img [img]="c?.img" *ngIf="getExt(c?.img) !=='pdf'" alt="" width="100%"></app-proof-img>

                    <div class="col-12 bg-pdf" *ngIf="getExt(c?.img) ==='pdf'">
                            <app-proof-document [pdf]="c.img"></app-proof-document>
                        
                        </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>

<mat-card *ngIf="config!==null && config.type=='type-text-only'">
        <div class="bootstrap-wrapper">
            <div class="row adjust-card-margin">
                <div *ngFor="let c of config.data" [ngClass]=" 'type-key-value key-value row col-'+config.column">
                    <span class="key">{{c.text}}</span>
                    <span class="pl-30 value">{{c.value}}</span>
                </div>
            </div>
        </div>
    </mat-card>