import { Injectable } from '@angular/core';
import { Storage } from '../enum/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getItem(item: Storage) {
    return sessionStorage.getItem(item);
  }

  setItem(item: Storage, value: string) {
    sessionStorage.setItem(item, value);
  }

  removeItem(item: Storage){
    sessionStorage.removeItem(item);
  }

  clear() {
    sessionStorage.clear();
  }
}
