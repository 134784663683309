import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';

@Component({
  selector: 'app-qrcode-key',
  templateUrl: './qrcode-key.component.html',
  styleUrls: ['./qrcode-key.component.scss']
})
export class QrcodeKeyComponent implements OnInit {

  @Input() key: any;
  manualSetupKey: any;
  constructor(private commonService: CommonService,
    private helper: HelperService) { }

  ngOnInit() {
    this.manualSetupKey = this.key ? this.key : '';
  }

  onCopy(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.helper.success('copied to clipboard');
  }
}
