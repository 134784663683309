import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { LayoutComponent } from './layout.component';
import { AuthGuardService } from '../../core/authentication/auth-guard.service';
import { BppSellerPermissionModule } from '../bpp-seller-permission/bpp-seller-permission.module';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'property'
      },
      // {
      //   path: 'dashboard',
      //   canActivate: [AuthGuardService],
      //   loadChildren: '../dashboard/dashboard.module#DashboardModule',
      //   data: { title: 'Dashboard' }
      // },
      {
        path: 'property',
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('../property/property.module').then(m => m.PropertyModule),
        data: {
          header: true,
          title: 'Cars'
        }
      },
      {
        path: 'FeatureArticle',
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('../feature-article/feature-article.module').then(m => m.FeatureArticleModule),
        data: { 
          header: true,
          title: 'Feature Article' }
      },
      {
        path: 'Article',
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('../article/article.module').then(m => m.ArticleModule),
        data: { 
          header: true,
          title: 'Article' }
      },
      {
        path: 'Podcast',
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('../podcast/podcast.module').then(m => m.PodcastModule),
        data: {    
          header: true,
          title: 'Podcast' }
      },
       {
        path: 'Video',
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('../video/video.module').then(m => m.VideoModule),
        data: {   
           header: true,
          title: 'Video' }
      },
      {
        path: 'news',
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('../news/news.module').then(m => m.NewsModule),
        // loadChildren: '../news/news.module#NewsModule',
        data: { title: 'News',
        header: true }
      },
      // {
      //   path: 'transaction',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () =>
      //   import('../transaction/transaction.module').then(m => m.TransactionModule),
      //   data: { 
      //     header: true,
      //     title: 'Transaction' }
      // },
      {
        path: 'deposits',
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('../deposits/deposits.module').then(m => m.DepositsModule),
        data: { title: 'Deposits' }
      },
      {
        path: 'withdrawals',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../withdrawals/withdrawals.module').then(m => m.WithdrawalsModule),
        data: { title: 'withdrawals' }
      },

      {
        path: 'dividends',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../dividends/dividends.module').then(m => m.DividendsModule),
        data: { title: 'dividends' }
      },
       {
        path: 'all-transactions',
        canActivate: [AuthGuardService],
          loadChildren: () =>
        import('../all-transactions/all-transactions.module').then(m => m.AllTransactionsModule),
        data: { title: 'Transactions' }
      },

      {
        path: 'setting',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../settings/settings.module').then(m => m.SettingsModule),
        data: { 
          header: true,
          title: 'Settings' }
      },
      {
        path: 'users',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../users/users.module').then(m => m.UsersModule),
        data: {    header: true,
          title: 'Users' }
      },
      {
        path: 'kyc',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../kyc/kyc.module').then(m => m.KycModule),
        data: {    header: true,
          title: 'KYC' }
      },
      {
        path: 'agent',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../cpp-agent/cpp-agent.module').then(m => m.CppAgentModule),
        data: { header: true,
          title: 'CPP Agent' }
      },
      {
        path: 'coownership',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../co-ownership/co-ownership.module').then(m => m.CoOwnershipModule),
        data: {  header: true,
          title: 'Title Transfer' }
      },
      {
        path: 'bppcoownership',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../bpp-coownership/bpp-coownership.module').then(m => m.BPPCoownershipModule),
        data: {  header: true,
          title: 'BPP Title Transfer' }
      },

      {
        path: 'community',
        canActivate: [AuthGuardService],
        loadChildren: () =>
          import('../community/community.module').then(m => m.CommunityModule),
        data: { title: 'Community',
        header: true }
      },
      {
        path: 'reports',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../reports/reports.module').then(m => m.ReportsModule),
        data: {  header: true,
          title: 'Reports' }
      },
      {
        path: 'bpplatepayment',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../bpp-late-payment/bpp-late-payment.module').then(m => m.BppLatePaymentModule),
        data: {  header: true,
          title: 'BPP Late Payment' }
      },
      {
        path: 'bppdocument',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../bpp-document-upload/bpp-document-upload.module').then(m => m.BppDocumentUploadModule),
        data: {  header: true,
          title: 'BPP Document' }
      },
      {
        path: 'bppsettings',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../bpp-settings/bpp-settings.module').then(m => m.BppSettingsModule),
        data: {  header: true,
          title: 'BPP Settings' }
      },
      {
        path: 'bppseller',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../bpp-seller-permission/bpp-seller-permission.module').then(m => m.BppSellerPermissionModule),
        data: {  header: true,
          title: 'BPP Seller Permission' }
      },
      {
        path: 'rent',
        canActivate: [AuthGuardService],
          loadChildren: () =>
        import('../rent/rent.module').then(m => m.RentModule),
        data: { title: 'Rent' }
      },

      {
        path: 'conveyancer',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../conveyancer/conveyancer.module').then(m => m.ConveyancerModule),
        data: {  header: true,
          title: 'Conveyancer' }
      },
      {
        path: 'conveyanceradmin',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../conveyanceradmin/conveyanceradmin.module').then(m => m.ConveyanceradminModule),
        data: {  header: true,
          title: 'Conveyancer' }
      },
      {
        path: 'ledger',
        canActivate: [AuthGuardService],
        loadChildren: () =>
        import('../ledger/ledger.module').then(m => m.LedgerModule),
        data: {  header: true,
          title: 'Ledger' }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule {}
