import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';
import { Property } from '../interfaces/property';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class AmenityService {
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(private apiManagerService: ApiManagerService, private http: HttpClient) { }



  getAmenitiesList(data: any) {
    return this.apiManagerService.postRequest(RestEnds.amenitiesList, data);
  }


  uploadPropertyImages(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.propertyImageUpload,
      data
    );
  }
  addAmenities(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addCarOwner,
      data
    );
  }

  addCarOwner(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addCarOwner,
      data
    );
  }

  
  addManager(data: any) {
    return this.apiManagerService.postRequest(
      RestEnds.addCarManager,
      data
    );
  }

  editAmenities(data: any) {
    return this.apiManagerService.putRequest(
      RestEnds.editAmenities,
      data
    );
  }

  deleteAmenities(data: any) {
    return this.apiManagerService.deleteRequest(
      RestEnds.deleteAmenities +
      data
    );
  }

  getAmenities(data: any) {
    return this.apiManagerService.getRequest(RestEnds.getLeaseType + data, null);
  }

  getIsExistsAmenities(guid: any, name: any) {
    return this.apiManagerService.getRequest(RestEnds.getLeaseTypeIsExist + '?leaseTypeGUID=' + guid + '&description=' + name, null);
  }

  getSettlorDocument(data: any){
    return this.apiManagerService.getRequest(RestEnds.settlorDocument + data, null);
  }

  

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }
}
