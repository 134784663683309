<!-- <h2 mat-dialog-title>
  <span>User Detail</span>
  <span mat-dialog-close>X</span>
</h2> -->
<mat-dialog-content class="mat-typography" id="user_detail">
  <div class="bootstrap-wrapper col-12" *ngIf="userDetail != null">
    <div class="row col-12">
      <div class="col-12 pb10">
        <div id="tag-data-container">
          <div class="tags">
            <span class="tag-left">Registered on</span>
            <span class="tag-right">{{
              userDetail?.createdDate | date: "dd-MMM-yyy"
            }}</span>
          </div>
          <div class="tags">
            <span class="tag-left">Account type</span>
            <div class="tag-right premium">
              <img
                *ngIf="userDetail?.isPremiumUsers"
                class=""
                src="assets/images/premium.jpg"
                alt=""
              />
              <span *ngIf="userDetail?.isPremiumUsers">Premium</span>
              <span *ngIf="!userDetail?.isPremiumUsers">Normal</span>
            </div>
          </div>
          <div class="tags">
            <span class="tag-left">User type</span>
            <div class="tag-right intl">
              <img
                *ngIf="userDetail?.isInternationalUser"
                src="assets/images/internet.svg"
                alt=""
              />
              <span *ngIf="userDetail?.isInternationalUser">International</span>
              <span *ngIf="!userDetail?.isInternationalUser">National</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="row h-100">
          <div class="col-12 card-container">
            <mat-card class="w-100">
              <div class="row">
                <div class="col-4">
                  <div id="profile-img-container">
                      <app-profile-image [img]="userDetail?.profileImage"></app-profile-image>
                  </div>
                </div>
                <div class="col-4">
                  <div class="p-info">
                    <span>User Name</span>
                    <span>{{ userDetail?.accountName }}</span>
                  </div>
                  <div class="p-info" >
                    <span>Notes</span>
                    <span><a class="show-link" (click)="userNotes(userDetail?.notes)" *ngIf="userDetail?.agentEmail !== null">Show Notes</a></span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="p-info">
                    <span>Mobile</span>
                    <span>{{ userDetail?.phone }}</span>
                  </div>
                  <div class="p-info">
                      <span>Agent</span>
                      <span>{{ userDetail?.agentName }}</span>
                      <span>{{ userDetail?.agentEmail }}</span>
                    </div>
                </div>
                <div class="col-4">
                  <div class="p-info">
                  
                  </div>
                  
                </div>
                <div class="col-8">
                  <div class="p-info">
                    <span>Entity Name</span>
                    <span class="entity-info">{{ userDetail?.entityName }}</span>
                  
                  </div>
                  
                </div>
              </div>
            </mat-card>
          </div>
          <div class="col-12 card-container">
            <mat-card class="w-100">
              <h2 class="roboto-medium black f16">Verification Details</h2>
              <div class="row">
                <div class="col-6 verify-detail">
                  <mat-card>
                    <span class="robot-regular f12 black"
                      >Email verification</span
                    >

                    <div class="d-flex justify-content-center row mr-0" *ngIf="!userDetail?.isEmailVerified && userDetail?.isTemporaryPasswrod">
                        <button mat-raised-button class="bg-dusty-orange white roboto-regular f12 btn-mail" 
                            color="warning" (click)="userResendMail(userGUID)" [class.spinner]="isbtnResendLoading">
                            Resend Email
                        </button>
                    </div>
                    <img
                      *ngIf="!userDetail?.isEmailVerified && !userDetail?.isTemporaryPasswrod"
                      src="assets/images/cancel.svg"
                      alt=""
                    />
                    <img
                      *ngIf="userDetail?.isEmailVerified"
                      src="assets/images/check_circle.svg"
                      alt=""
                    />
                  </mat-card>
                </div>
                <div class="col-6 verify-detail">
                  <mat-card>
                    <span class="robot-regular f12 black"
                      >KYC verification</span
                    >
                    <img
                      *ngIf="!userDetail?.isKYCLevelOne"
                      src="assets/images/cancel.svg"
                      alt=""
                    />
                    <img
                      *ngIf="userDetail?.isKYCLevelOne"
                      src="assets/images/check_circle.svg"
                      alt=""
                    />
                  </mat-card>
                </div>
                <!-- <div class="col-6 verify-detail">
                                    <mat-card>
                                        <span class="robot-regular f12 black">KYC 2 verification</span>
                                        <img *ngIf="!userDetail?.isKYCLevelTwo" src="assets/images/cancel.svg" alt="">
                                        <img *ngIf="userDetail?.isKYCLevelTwo" src="assets/images/check_circle.svg"
                                            alt="">
                                    </mat-card>
                                </div> -->
              </div>

              <div class="card-POA-container">
                <h2 class="roboto-medium black f16 mt-2">
                  POA document File Upload
                </h2>

                <form [formGroup]="uploadPOAForm">
                  <div class="POA-container">
                    <div class="col-4 upload-pnl custom-file-upload">
                      <mat-form-field class="example-full-width pr10">
                        <mat-label class="f14 roboto-medium black"
                          >Document Name
                          <span class="star-red">*</span></mat-label
                        >
                        <input
                          matInput
                          class="name-input"
                          formControlName="fileName"
                        />

                        <mat-error
                          align="end"
                          *ngIf="
                            f.fileName.errors &&
                            f.fileName.errors.required
                          "
                        >
                          Document Name is required</mat-error
                        >
                        <!-- <mat-error align="end" *ngIf="f.get('file').errors && f.get('file').errors.required">
                                                            Upload a File</mat-error> -->
                        <!-- <mat-error align="end" *ngIf="hasFieldError('fileName', 'required')">
                                                Upload a File</mat-error> -->
                      </mat-form-field>
                    </div>
                    <div class="col-4 upload-pnl">
                      <div class="custom-file-upload prop-doc">
                        <input
                          type="file"
                          class="img-upload"
                          id="file-input"
                          #fileUpload
                          (change)="docsFileSubmit($event, 'POA')"
                        />

                        <button
                          class="btn-choose"
                          type="button"
                          [ngClass]="!isUpdateEnable ? 'opac-3' : ''"
                          [disabled]="!isUpdateEnable"
                          (click)="uploadImage()"
                        >
                          Choose File
                        </button>
                      </div>
                    </div>
                    <div class="col-4 upload-btn">
                      <div class="custom-file-upload prop-doc">
                        <button
                          class="btn-upload"
                          mat-raised-button
                          type="submit"
                          (click)="uploadFile()"
                          [disabled]="!uploadPOAForm.valid"
                          [class.spinner]="isbtnUploadLoading"
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <h2 class="roboto-medium black f16 mt-2">
                Last Uploaded POA File
                <label class="black roboto-regular"
                  >(Uploaded on:
                  <span class="roboto-medium">{{
                    userDetail?.poA_CreatedDate | date: "dd-MMM-yyyy"
                  }}</span
                  >)</label
                >
              </h2>

              <img
                src="../../../../assets/images/pdf-icon.png"
                class="pdf-icon"
              /><a
                href="{{ userDetail?.poA_FilePath }}"
                target="blank"
                class="pdf-name"
                >{{ userDetail?.poA_FileName }}</a
              >
            </mat-card>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row h-100">
          <div class="col-12 card-container">
            <mat-card class="center-flex w-100">
              <div class="center-flex card-content-center">
              <span class="roboto-medium f16 slate-grey">Account balance</span>
              <span class="roboto-regular f20 mt5 black"
                >$ {{ userDetail?.audBalance | number: "1.0-2"}}</span
              >
            </div>
            </mat-card>
          </div>
          <div class="col-12 card-container">
            <mat-card class="center-flex w-100">
                <div class="center-flex card-content-center">
                  <span class="roboto-medium f16 slate-grey">Withdrawal limit</span>
                  <span class="roboto-regular f20 mt5 black">$ {{ userDetail?.withdrawLimit | number: "1.0-2"}}</span>
                </div>
            </mat-card>
          </div>
          <div class="col-12 card-container">
            <mat-card class="center-flex w-100">
                <div class="center-flex card-content-center">
                  <span class="roboto-medium f16 slate-grey">Tax File Number</span>
                  <span class="roboto-regular f20 mt5 black">{{
                    userDetail?.tfn
                    }}</span>
                </div>
            </mat-card>
          </div>
          <div class="col-12 card-container">
            <mat-card class="flex-between w-100">
              <span class="roboto-medium f16 slate-grey">User Status</span>
              <mat-slide-toggle
                [disabled]="!isUpdateEnable"
                [checked]="userDetail?.isActive"
                (change)="updateUserStatus($event)"
              >
              </mat-slide-toggle>
            </mat-card>
          </div>
          <div class="col-12 card-container">
            <mat-card class="flex-between w-100">
              <span class="roboto-medium f16 slate-grey">Premium user</span>
              <mat-slide-toggle
                [disabled]="!isUpdateEnable"
                [checked]="userDetail?.isPremiumUsers"
                (change)="updatePremiumStatus($event)"
              ></mat-slide-toggle>
            </mat-card>
          </div>
        </div>
      </div>
      <!-- <div class="col-12">
        <div class="row justify-end">
          <div class="col-4">
            <button
              id="btn-view-kyc"
              [disabled]="!userDetail?.isKYCLevelOne"
              (click)="openKycDetail(userDetail)"
            >
              View KYC Documents
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->

