import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiManagerService } from '../rest-api/api-manager.service';
import { RestEnds } from '../rest-api/rest-ends';

@Injectable({
  providedIn: 'root'
})
export class CoownerShipService {

  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  constructor(
    private apiManagerService: ApiManagerService
  ) { }

  getAllCoOwnerShipRequest(data: any) {
    return this.apiManagerService.postRequest(RestEnds.coownershipRequest, data);
  }

  getAllCoOwnerShipHistory(data: any) {
    return this.apiManagerService.postRequest(RestEnds.coownershipHistory, data);
  }

  getCoOwnerShipRequestDetailByGUID(data: any){
      return this.apiManagerService.postRequest(RestEnds.coownershipRequestDetail, data);
  }

  getCoOwnerShipHistoryDetailByGUID(data: any){
    return this.apiManagerService.postRequest(RestEnds.coownershipHistoryDetail, data);
}

  approveRequest(data: any){
    return this.apiManagerService.postRequest(RestEnds.approveCoOwnerShipRequest, data);
  }

  rejectRequest(data: any){
    return this.apiManagerService.postRequest(RestEnds.approveCoOwnerShipRequest, data);
  }

  getAllBPPCoOwnerShipRequest(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getbppcoownershiprequest, data);
  }

  getBPPCoOwnerShipRequestDetailByGUID(data: any){
    return this.apiManagerService.postRequest(RestEnds.bppcoownershipRequestDetail, data);
}

getAllBPPCoOwnerShipHistory(data: any) {
  return this.apiManagerService.postRequest(RestEnds.getbppcoownershiphistory, data);
}

getAllBPPCoOwnerShipHistoryDetailsByGUID(data: any) {
  return this.apiManagerService.postRequest(RestEnds.getbppcoownershiphistorydetails, data);
}

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }

  approveBPPRequest(data: any){
    return this.apiManagerService.postRequest(RestEnds.approveBPPCoOwnerShipRequest, data);
  }

  rejectBPPRequest(data: any){
    return this.apiManagerService.postRequest(RestEnds.approveBPPCoOwnerShipRequest, data);
  }

  // late payment
  getAllBPPLatePaymentPendingDetails(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getBPPlatepaymentpendingdetails, data);
  }

  getBPPPenaltyAmountDetails(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getBPPpenaltyamountdetails, data);
    
  }

  getAllBPPLatePaymentHistory(data: any) {
    return this.apiManagerService.postRequest(RestEnds.getBPPlatePaymentHistory, data);
  }
}
