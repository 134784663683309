import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserRecordInterface, UserOwnedPropertyFilterInterface, UserOwnedPropertyInterface } from '../../../core/interfaces/users.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatInput } from '@angular/material/input';
import { FormGroup, FormControl } from '@angular/forms';
import { UsersService } from '../../../core/services/users.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../../core/utils/common.service';
import { HelperService } from '../../../core/utils/helper.service';
import { DatePipe } from '@angular/common';
import { UserRolesService } from '../../../core/services/user-roles.service';
import PageConfigs from '../../../core/utils/access-level-config';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export interface DialogData {
  id: string;
  userType: string;
  update: boolean;
  approve: boolean;
  KycTypeOfUserGUID: string;
  accountGUID: string;
  userRole: string;
}

@Component({
  selector: 'app-user-owned-property-list',
  templateUrl: './user-owned-property-list.component.html',
  styleUrls: ['./user-owned-property-list.component.scss']
})
export class UserOwnedPropertyListComponent implements OnInit {
  length = 0;
  pageNo = 1;
  pageSize = 5;
  pageSizeOptions = [5, 10];
  pageIndex = 1;

  
  propertyList: any[] = [];
  displayedColumns: string[] = [
    'PropertyCode',
    'PropertyName',
    'noofBricklets'];
  dataSource = new MatTableDataSource<UserRecordInterface>(this.propertyList);
  isLoading = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | any;
  @ViewChild('test', {static: false}) htmltest : ElementRef | any;



  filterForm: FormGroup | any;

  hoverRejectReason: any;

  userActions = {
    updateKycVerify: false,
    update: false,
  };
  minDate: any = null;
  @ViewChild('from', { static: false, read: MatInput }) fromDate: MatInput | any;
  @ViewChild('to', { static: false, read: MatInput }) toDate: MatInput | any;

  isbtnLoading = false;

  constructor(
    private usersService: UsersService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private userRolesService: UserRolesService,
    private datePipe: DatePipe,
    private helper: HelperService,
    public dialogRef: MatDialogRef<UserOwnedPropertyListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    this.getPagePermissions();
    this.filterForm = new FormGroup({
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      filterBy: new FormControl(null),
      emailSearch: new FormControl(null),
    });
    this.getUsers();
    this.dataSource.paginator = this.paginator;
    this.usersService.currentMessage.subscribe((msg) => {
      if (msg === true) {
        this.getUsers();
      }
    });
  }

  getPagePermissions() {
    let permissions = this.userRolesService.getRoleAccessByPageName(
      PageConfigs.Users.name
    );
    this.userActions.updateKycVerify = permissions.updateKycVerify;
    this.userActions.update = permissions.update;
  }


  getUsers() {
    this.isLoading = true;
    let data: UserOwnedPropertyFilterInterface | any = {
      PageNumber: this.pageNo,
      PageSize: this.pageSize,
      AccountGUID: this.data.accountGUID
      // StartDate: this.filterForm.get("startDate").value === null ? "" : this.datePipe.transform(
      //   this.filterForm.get("startDate").value,
      //   "yyyy-MM-dd"
      // ),
      // EndDate: this.filterForm.get("endDate").value === null ? "" : this.datePipe.transform(
      //   this.filterForm.get("endDate").value,
      //   "yyyy-MM-dd"
      // ),
      // Email: this.filterForm.controls.emailSearch.value === "" ? null : this.filterForm.controls.emailSearch.value === null ? "" : this.filterForm.controls.emailSearch.value.trim(),
    };

    if (this.data.accountGUID !== null) {
      this.commonService.showSpinner();
      this.commonService.showLoader();
      this.usersService.getUserOwnedProperty(data).subscribe(
        (r: any) => {
          this.isLoading = false;
          const res = r.response !== undefined ? r.response : r;
          const result: UserOwnedPropertyInterface | any =
            res.data.properties;
          this.propertyList = result.ownedProperties;
          this.dataSource = new MatTableDataSource(this.propertyList);
          this.length = result.totalRecord;
          this.commonService.hideSpinner();
          this.commonService.hideLoader();
        },
        e => {
          this.isLoading = false;
          this.commonService.hideSpinner();
          this.commonService.hideLoader();
          // this.helper.error(e.message);
          const errSt = e.message !== undefined ? e.message : e;
          if (errSt === 'Session Timed Out') {
            this.helper.info(errSt);
          } else {
            this.helper.error(errSt);
          }
        }
      );
    }
  }

  onPaginateChange(event: any) {
    this.pageIndex = ++event.pageIndex;
    this.pageNo = this.pageIndex;
    this.pageSize = event.pageSize;
    this.getUsers();
  }

  filterRecords(): any {
    if((this.email.value === null || this.email.value === "") && this.from.value === null && this.to.value === null && this.filterby.value === null) {
      this.helper.info("Please choose filter options");
      return false;
    }
    if(this.fromDate.value != null) {
      if(this.toDate.value == null) {
        this.helper.info("Please Select To date!");
        return false;
      }
    } else if(this.fromDate.value == null){
      if(this.toDate.value != null) {
        this.helper.info("Please Select From date!");
        return false;
      }
    }
    if (!this.filterForm.valid) {
      if (!this.email.valid && this.email.hasError('email')) {
        this.helper.info("Please enter a valid email");
        return false;
      } 
    }
    this.pageNo = 1;
    this.pageSize = 5;
    this.pageIndex = 1;
    this.setPage(0);
    this.getUsers();
  }

  reset() {
    this.filterForm.reset();
    this.pageNo = 1;
    this.pageSize = 5;
    this.pageIndex = 1;
    this.minDate = null;
    this.setPage(0);
    this.getUsers();
  }

  setPage(index: number) {
    this.paginator.pageIndex = index;
  }
  
  fromDateInput(event: MatDatepickerInputEvent<Date>): void {
    // this.fromDate.value = moment(event.value).format('YYYY-MM-DD');
     this.minDate = event.value;
     if (this.fromDate.value > this.toDate.value) {
       this.toDate.value = '';
       this.filterForm.get("endDate").setValue(null);
       // this.to = null;
     }
 
   }
 
   toDateInput(event: MatDatepickerInputEvent<Date>): void {
    // this.toDate.value = moment(event.value).format('YYYY-MM-DD');
   }
 
   get email() { return this.filterForm.get('emailSearch'); }
   get filterby() { return this.filterForm.get('filterBy'); }
   get from() { return this.filterForm.get('startDate'); }
   get to() { return this.filterForm.get('endDate'); }
 
   getEmailError(): any {
     if (this.email.hasError('emailSearch')) {
       return 'Please enter a valid email.';
     } else if(this.email.touched){
       return 'Please enter a valid email2.';
     }
   }



}

