import { Component, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../../core/utils/helper.service';



@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss']
})
export class VideoUploadComponent implements OnInit, OnChanges {
  @Output() uploadEvent = new EventEmitter<any>();

  VideoFormat = 'Please select .mp4, .mov files for video upload';
  VideoSize = 'Please upload video size not more than 20MB.';
  InvalidVideo = 'Could not recognize the format of this file. Please upload valid video file.';

  constructor(
    public router: Router,
    public toastr: HelperService
  ) {}

  ngOnInit() {
    this.acceptPattern = 'video/mp4';
  }

  ngOnChanges(changes: SimpleChanges) {}

  activeColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';

  dragging: boolean = false;
  isFileSelected: boolean = false;
  acceptPattern = null;
  fileType;
  fileList;

  
  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e: any) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleInputChange(e: any): any {
    this.fileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;

    if (this.fileList && this.fileList.length > 0) {
      let fileType = this.fileList[0].type;
      let fileSize = this.fileList[0].size;

      if (
        fileType != 'video/mp4' &&
        fileType != 'video/quicktime'
      ) {
        this.toastr.warning(this.VideoFormat, 'Oops!');
        e.target.value = null;
        return false;
      }

      // if (fileSize > 2147483648) {
      if (fileSize > 20971520) {
        this.toastr.warning(this.VideoSize, 'Oops!');
        e.target.value = null;
        return false;
      }

      // const file = e.dataTransfer
      //   ? e.dataTransfer.files
      //   : e.target.files && e.target.files[0];
      const file = e.dataTransfer
      ? e.dataTransfer.files
      : e.target.files;

      this.uploadEvent.emit(file);
    } else {
      this.uploadEvent.emit(this.fileList[0]);
    }
  }
}




