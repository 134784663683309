import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-historical-growth-chart',
  templateUrl: './historical-growth-chart.component.html',
  styleUrls: ['./historical-growth-chart.component.scss']
})
export class HistoricalGrowthChartComponent implements OnInit {

  @ViewChild('chartDiv', { static: false }) chartDiv!: ElementRef | any;
  isShowTitle = false;

  constructor(private elRef: ElementRef) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  setViewStyles() {
    this.isShowTitle = true;
    this.chartDiv.nativeElement.style.height = '500px';
    this.chartDiv.nativeElement.style.padding = '20px';
  }

  generateChart(chartData: any) {
    this.setViewStyles();
    const chart = am4core.create('chartdiv2', am4charts.XYChart);
    // Add data
    chart.data = chartData;

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    // dateAxis.title.text = 'Date Range';
    // dateAxis.renderer.grid.template.disabled = true;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Growth Value (dollars)';

    // valueAxis.renderer.grid.template.disabled = true;

    // Create series
    const series: any = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'growth';
    series.dataFields.dateX = 'date';
    series.strokeWidth = 2;
    series.minBulletDistance = 10;
    series.tooltipText = '{valueY}';
    series.tooltip.pointerOrientation = 'vertical';
    series.tooltip.background.cornerRadius = 20;
    series.tooltip.background.fillOpacity = 0.5;
    series.tooltip.label.padding(12, 12, 12, 12);
    series.fillOpacity = 0.5;
    series.stroke = am4core.color('#375ca0');
    series.fill = am4core.color('#375ca0');
    const lgrad = new am4core.LinearGradient();
    lgrad.rotation = 90;
    lgrad.addColor(am4core.color('#0084ff'));
    lgrad.addColor(am4core.color('#cedfff'));
    series.fill = lgrad;

    // Add scrollbar
    const scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = series;
  }
}
